import React from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import AutoAddress from "../Mapcomponents/AutoAddress";

var jwt = require("jsonwebtoken");

function AddCustomer({hadlerorg}) {
  const navigate = useNavigate();
  const [organisation, setDataID] = useState("");

  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [userphoto, setuserphoto] = useState("");
  const [userviewphoto, setuserviewphoto] = useState("");

  const handleClick = () => {
    const latInnerLat = document.getElementById("lat").innerHTML;
    console.log(latInnerLat);
    setLat(latInnerLat);
    const latInnerLong = document.getElementById("lon").innerHTML;
    console.log(latInnerLong);
    setLong(latInnerLong);
  };

  const AddFormData = async (e) => {
    const organisation = localStorage.getItem("ID");
     let token = localStorage.getItem("organizationToken");
    var decode1 = jwt.decode(token);
    let orgID = decode1.OrganisationID;

    console.log(organisation);
    setDataID(organisation);

    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    console.log(Formvlaues);
    let dataToSend2 = new FormData();
    dataToSend2.append("image", userphoto);
    dataToSend2.append("fullname", Formvlaues.fullname);
    dataToSend2.append("dateOfBirth", Formvlaues.dateOfBirth);
    dataToSend2.append("phone", Formvlaues.phone);
    dataToSend2.append("email", Formvlaues.email);
    dataToSend2.append("gender", Formvlaues.gender);
    dataToSend2.append("nationality", Formvlaues.nationality);
    dataToSend2.append("profession", Formvlaues.profession);
    dataToSend2.append("address", Formvlaues.address);
    dataToSend2.append("city", Formvlaues.city);
    dataToSend2.append("age", Formvlaues.age);
    // dataToSend2.append('organisation', Formvlaues.organisation);
    dataToSend2.append("nextFOKinName", Formvlaues.nextFOKinName);
    dataToSend2.append("nextFOKniPhone", Formvlaues.nextFOKniPhone);
    dataToSend2.append("landSize", Formvlaues.landSize);
    dataToSend2.append("residance", Formvlaues.residance);
    dataToSend2.append("locaDocument", Formvlaues.locaDocument);
    dataToSend2.append("landRegistration", Formvlaues.landRegistration);
    dataToSend2.append("Latitude", Formvlaues.Latitude);
    dataToSend2.append("Longitude", Formvlaues.Longitude);
    dataToSend2.append("assetType", Formvlaues.assetType);
    dataToSend2.append("assetID", Formvlaues.assetID);

    localStorage.setItem("phone", Formvlaues.phone);
    console.log("pushpak", Formvlaues.phone);
    axios.post(`/v1/org/DID/createCustomerByOrg`, dataToSend2,{ headers: {"Authorization" : `Bearer ${token}`}}).then((res) => {
      if (res.status || res.service) {
        let data = res.data;
        if (data.status) {
          toast.success(data.msg);
          navigate("/otp-org");
         // window.location = "/otp-org";
          e.target.reset();
        } else if (data.service) {
          Swal.fire({
            title: "This Customer Alredy Register You Want To Linked Service",
            imageUrl: "../assets_new/images/handshake.png",
            imageWidth: 100,
            imageHeight: 60,
            imageAlt: "Custom image",
            showCancelButton: true,
            confirmButtonText: "Yes",
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire("Saved!", "", "success");
              navigate("/org-customer-links");
              //window.location = "/org-customer-links";
            } else if (result.isDenied) {
              Swal.fire("Changes are not saved", "", "info");
            }
          });
        } else {
          toast.error(data.msg);
        }
      } else {
        toast.error(data.msg);
      }
    });
  };
  const gotophoto = () => {
    window.location = "/Orginization_customer_face_add";
  };

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) u8arr[n] = bstr.charCodeAt(n);

    return new File([u8arr], filename, { type: mime });
  };

  useEffect(() => {
    let img = localStorage.getItem("userviewphoto");
    setuserviewphoto(img);
    const convertedUrlToFile = dataURLtoFile(img, "user-image.jpg");
    setuserphoto(convertedUrlToFile);
    hadlerorg();
  }, []);

  return (
    <>
   
      <ToastContainer />
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl" >
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div className="card mb-5 mb-xl-10">
                  <div
                    className="card-header border-0 cursor-pointer"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_account_profile_details"
                    aria-expanded="true"
                    aria-controls="kt_account_profile_details"
                  >
                    <div className="card-title m-0">
                      <h3 className="fw-bold m-0">Add Customer</h3>
                    </div>
                  </div>
                  <div
                    id="kt_account_settings_profile_details"
                    className="collapse show"
                  >
                    <form
                      onSubmit={(e) => AddFormData(e)}
                      id="kt_account_profile_details_form"
                      className="form"
                    >
                      <div className="card-body border-top p-9">
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            ID Photo
                          </label>
                          <div className="col-lg-8">
                            <div
                              className="image-input image-input-outline"
                              data-kt-image-input="true"
                              style={{
                                backgroundImage:
                                  "url('/metronic8/demo4/assets/media/svg/avatars/blank.svg')",
                              }}
                            >
                              <div
                                className="image-input-wrapper w-125px h-125px"
                                onClick={() => gotophoto()}
                                style={{
                                  backgroundImage: `url(${userviewphoto})`,
                                }}
                              ></div>

                              {/* //////////map input filed hidden */}
                              <input
                                type="hidden"
                                name="Latitude"
                                value={lat}
                              />
                              <input
                                type="hidden"
                                name="Longitude"
                                value={long}
                              />
                              {/* ////////// end map input filed hidden */}
                              {/* onClick={()=>gotophoto()}  */}
                              <label
                                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                data-kt-image-input-action="change"
                                data-bs-toggle="tooltip"
                                title="Upload Image"
                              >
                                <i className="bi bi-pencil-fill fs-7"></i>
                                <input
                                  type="hidden"
                                  name="image"
                                  value={userphoto}
                                />
                                {/* <input type="hidden" name="avatar_remove" /> */}
                              </label>
                              <span
                                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                data-kt-image-input-action="cancel"
                                data-bs-toggle="tooltip"
                                title="Cancel avatar"
                              >
                                <i className="bi bi-x fs-2"></i>
                              </span>
                              <span
                                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                data-kt-image-input-action="remove"
                                data-bs-toggle="tooltip"
                                title="Remove avatar"
                              >
                                <i className="bi bi-x fs-2"></i>
                              </span>
                            </div>
                            <div className="form-text">
                              Allowed file types: png, jpg, jpeg.
                            </div>
                          </div>
                        </div>

                        {/* {/ <input type="file" name="image"  /> /} */}

                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                            Full Name
                          </label>
                          <div className="col-lg-8">
                            <div className="row">
                              <div className="col-lg-12 fv-row">
                                <input
                                  type="hidden"
                                  name="organisation"
                                  defaultValue={organisation}
                                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                  placeholder="Full Name"
                                />
                                <input
                                  type="text"
                                  name="fullname"
                                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                  placeholder="Full Name"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="row mb-6">

                                            <label className="col-lg-4 col-form-label required fw-semibold fs-6">Organisation</label>
                                            <div className="col-lg-8">
                                                <div className="row">
                                                    <div className="col-lg-12 fv-row">
                                                        <input type="text" name="organisation" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Organisation"  />
                                                    </div>
                                                </div>
                                            </div>

                                        </div> */}

                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                            Date of Birth
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="date"
                              name="dateOfBirth"
                              id="date_timepicker_end"
                              className="form-control form-control-lg form-control-solid position-relative"
                            />
                          </div>
                        </div>

                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            <span className="required">Contact Phone</span>
                            <i
                              className="fas fa-exclamation-circle ms-1 fs-7"
                              data-bs-toggle="tooltip"
                              title="Phone number must be active"
                            ></i>
                          </label>
                          <div className="col-lg-8 fv-row">
                            <PhoneInput
                              country={"eg"}
                              enableSearch={true}
                              inputProps={{
                                name: "phone",
                                required: true,
                                placeholder: "enter phone number",
                              }}
                              className="form-control form-control-lg form-control-solid"
                              placeholder="Phone number"
                            />
                          </div>
                        </div>
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            Email Address
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="email"
                              name="email"
                              className="form-control form-control-lg form-control-solid"
                              placeholder="Email Address"
                            />
                          </div>
                        </div>
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                            Gender
                          </label>
                          <div className="col-lg-8 fv-row">
                            <div className="d-flex align-items-center mt-3">
                              <label className="form-check form-check-inline form-check-solid me-5">
                                <input
                                  className="form-check-input"
                                  name="gender"
                                  type="radio"
                                  value="Male"
                                />
                                <span className="fw-semibold ps-2 fs-6">
                                  Male
                                </span>
                              </label>
                              <label className="form-check form-check-inline form-check-solid">
                                <input
                                  className="form-check-input"
                                  name="gender"
                                  type="radio"
                                  value="Fmale"
                                />
                                <span className="fw-semibold ps-2 fs-6">
                                  Female
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            Nationality
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="text"
                              name="nationality"
                              className="form-control form-control-lg form-control-solid"
                              placeholder="Nationality"
                            />
                          </div>
                        </div>

                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            Profession
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="text"
                              name="profession"
                              className="form-control form-control-lg form-control-solid"
                              placeholder="Profession"
                            />
                          </div>
                        </div>

                        <AutoAddress style={{ width: "100% !important" }} />

                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            City
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="text"
                              name="city"
                              className="form-control form-control-lg form-control-solid"
                              placeholder="City"
                            />
                          </div>
                        </div>
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            Age
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="text"
                              name="age"
                              className="form-control form-control-lg form-control-solid"
                              placeholder="Age"
                            />
                          </div>
                        </div>
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                            Next Of Kin Name
                          </label>

                          <div className="col-lg-8 fv-row">
                            <input
                              type="text"
                              name="nextFOKinName"
                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                              placeholder="Next Of Kin Name"
                            />
                          </div>
                        </div>
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            <span className="">Next Of Kin Phone</span>
                            {/* <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="Phone number must be active"></i> */}
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="number"
                              name="nextFOKniPhone"
                              className="form-control form-control-lg form-control-solid"
                              pattern="[0-9]{10}"
                              title=" Only number allow  Ten digits code"
                              required
                              placeholder="Next Of Kin Number"
                            />
                          </div>
                        </div>
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            <span className="">Land Size (HAC.)</span>
                            {/* <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="Phone number must be active"></i> */}
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="tel"
                              name="landSize"
                              className="form-control form-control-lg form-control-solid"
                              placeholder="Land Size"
                            />
                          </div>
                        </div>
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                            Asset Type
                          </label>

                          <div className="col-lg-8 fv-row">
                            {/* <input type="text" name="organisation" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Organisation"  /> */}

                            <select
                              className="form-select border-0 flex-grow-1"
                              data-control="select2"
                              data-placeholder="assetType"
                              data-hide-search="true"
                              name="assetType"
                            >
                              <option value="1" selected="selected">
                                {" "}
                                Select Asset
                              </option>
                              <option value="Land"> Land</option>
                              <option value="House"> House</option>
                              <option value="Car"> Car</option>
                              <option value="Store"> Store</option>
                              {/* {listorg.map((item)=>(
                            <option key={item._id} value={item._id}>{item.name}</option>
                        ))} */}
                            </select>
                          </div>
                        </div>
                        {/* <LandMap style={{width : "100% !important"}} /> */}
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                            ID Type
                          </label>

                          <div className="col-lg-8 fv-row">
                            {/* <input type="text" name="organisation" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Organisation"  /> */}

                            <select
                              className="form-select border-0 flex-grow-1"
                              data-control="select2"
                              data-placeholder="assetID"
                              data-hide-search="true"
                              name="assetID"
                            >
                              <option value="1" selected="selected">
                                {" "}
                                Select ID
                              </option>
                              <option value="National"> National ID</option>
                              <option value="Passport"> Passport</option>
                              <option value="Drivers"> Drivers Licence</option>
                              <option value="Notarised">
                                {" "}
                                Notarised Document
                              </option>
                              {/* {listorg.map((item)=>(
                                                                        <option key={item._id} value={item._id}>{item.name}</option>
                                                                    ))} */}
                            </select>
                          </div>
                        </div>
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                          Identification Document Image
                          </label>

                          <div className="col-lg-8 fv-row">
                            <input
                              type="file"
                              name="residance"
                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                              placeholder="Residance Image"
                            />
                          </div>
                        </div>
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                            Proof of Residence</label>

                          <div className="col-lg-8 fv-row">
                            <input
                              type="file"
                              name="locaDocument"
                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                              placeholder="Local Document"
                            />
                          </div>
                        </div>
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                          Assets Ownership Certificate
                          </label>

                          <div className="col-lg-8 fv-row">
                            <input
                              type="file"
                              name="landRegistration"
                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                              placeholder="Land Registration"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button
                          onClick={handleClick}
                          type="submit"
                          className="btn btn-light btn-sub me-2"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AddCustomer;
