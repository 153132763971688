import axios from "axios";
import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useNavigate } from "react-router-dom";
import CustomerLoader from "../../../SuperAgent/CRM/Modal/CustomerLoder";
import moment from "moment";
import { CSVLink } from "react-csv";

var jwt = require("jsonwebtoken");

function AdminSavingTransactions({ hadleradmin }) {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [pageCount, setpageCount] = useState("");
  const [csvData, setCSVData] = useState([]);
  const [total, settotal] = useState([]);
  const [SuperAgentList, setSuperAgentList] = useState([]);
  const [cooperativeFilter, setCooperativeFilter] = useState([]);

  const [ShowLoader, setShowLoader] = useState(true);
  const limit = 10;

  let token = localStorage.getItem("ChromePayAdmitToken");
  var decode1 = jwt.decode(token);
  let adminID = decode1.admminID;

  const AddFormData = async (e, page) => {
    e.preventDefault();
    setShowLoader(true);
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data?.entries());
    const formData = Formvlaues;
    console.log(Formvlaues);
    axios
      .post(`/v1/super-Agent/get-admin-loan-transaction`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        const data = resp.data;
        console.log("csv-test", { data });
        setData(data?.data);
        setCSVData(data?.csvData);

        const total = resp.data?.counts;
        console.log(total);
        settotal(total);

        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
        setShowLoader(false);
      });
  };

  const [formData, setFormData] = useState({
    search: "",
    category: "",
    superAgent: "",
    organization: "",
    type: "",
    fromDate: "",
    toDate: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    console.log("test form data", { formData });
  };

  const cooperativeFilterlist = async () => {
    setShowLoader(true);

    await axios
      .post(
        `/v1/super-Agent/get-cooperative-for-filter`,
        { page: 1, limit: 10 },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        const data = resp.data.getCooperative;
        console.log("-->", data);
        setCooperativeFilter(data);
      });
  };
  useEffect(() => {
    cooperativeFilterlist();
  }, []);

  const AdminSavingTransactions = async () => {
    setShowLoader(true);
    await axios
      .post(
        `/v1/super-Agent/get-admin-loan-transaction`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        const data = resp.data;
        setData(data?.data);
        setCSVData(data?.csvData);

        const total = resp.data?.counts;
        console.log(total);
        settotal(total);
         setFormData({
          search: "",
          category: "",
          superAgent: "",
          organization: "",
          type: "",
          fromDate: "",
          toDate: "",
        });
        console.log("saving History", data);
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
        setShowLoader(false);
      });
  };
  const AllSuperAgentData = async () => {
    setShowLoader(true);
    await axios
      .post(
        `/v1/super-Agent/get-admin-superagent-list`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        const data = resp.data;

        setSuperAgentList(data?.data);
      });
  };
  useEffect(() => {
    AllSuperAgentData();
    AdminSavingTransactions();
  }, []);

  ///////////////pagenestion///////////////
  const fetchComments = async (page) => {
    setShowLoader(true);
    const senData = { page: page, ...formData };
    axios
      .post(`/v1/super-Agent/get-admin-loan-transaction`, senData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        const data = resp.data;
        setData(data?.data);

        const total = resp.data?.counts;
        console.log(total);
        settotal(total);

        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
        setShowLoader(false);
      });
    return data;
  };

  const handlePageClick = async (data) => {
    const page = data?.selected + 1;
    const commentsFormServer = await fetchComments(page);
  };

  useEffect(() => {
    hadleradmin();
  }, []);

  return (
    <>
      <ToastContainer />
      <CustomerLoader loader={ShowLoader} />

      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl" id="kt_content_container">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div
                  className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                  style={{ marginTop: "2rem" }}
                >
                  <div className="card-body container-xxl pt-10 pb-8">
                    <div className="d-flex align-items-center">
                      <h1 className="fw-semibold me-3 text-white">Filter</h1>
                      <span className="fw-semibold text-white opacity-50">
                        Transactions
                      </span>
                    </div>

                    <div className="d-flex flex-column">
                      <div className="d-lg-flex align-lg-items-center">
                        <form onSubmit={AddFormData}>
                          <div className="rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body p-5 h-lg-60px me-lg-10 my-5">
                            <div className="row flex-grow-1 mb-5 mb-lg-0">
                              {/* Transaction Type Select */}
                              <div className="col-lg-3 col-sm-3 d-flex align-items-center mb-3 mb-lg-0">
                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                  {/* SVG Icon */}
                                </span>
                                <select
                                  className="form-select border-0 flex-grow-1"
                                  name="type"
                                  value={formData.type}
                                  onChange={handleChange}
                                  data-control="select2"
                                  data-placeholder="Category"
                                  data-hide-search="true"
                                >
                                  <option value="" disabled>
                                    Transaction Type
                                  </option>
                                  <option value="saving">Savings</option>
                                  <option value="share">Shares</option>
                                  <option value="loan">Loans</option>
                                  <option value="store">Store</option>
                                </select>
                              </div>

                              {/* Super Agent Select */}
                              <div className="col-lg-3 d-flex align-items-center mb-5 mb-lg-0">
                                <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                <select
                                  name="superAgent"
                                  className="form-select border-0 flex-grow-1"
                                  value={formData.superAgent}
                                  onChange={handleChange}
                                  data-control="select2"
                                  data-placeholder="Super Agent"
                                  data-hide-search="true"
                                >
                                  <option value="" disabled>
                                    Select Super Agent
                                  </option>
                                  {cooperativeFilter?.map((item) => (
                                    <option key={item._id} value={item._id}>
                                      {item?.firstName}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              {/* Start Date Input */}
                              <div className="col-lg-3 col-sm-3 d-flex align-items-center mb-3 position mb-lg-0">
                                <label className="small_label">
                                  Start Date
                                </label>
                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                  <i className="fad fa-calendar fs-2"></i>
                                </span>
                                <input
                                  type="date"
                                  className="form-control unstyled form-control-flush position-relative flex-grow-1"
                                  name="fromDate"
                                  value={formData.fromDate}
                                  onChange={handleChange}
                                  placeholder="From Date.."
                                />
                              </div>

                              {/* End Date Input */}
                              <div className="col-lg-3 col-sm-3 d-flex align-items-center mb-3 mb-lg-0">
                                <label className="small_label">End Date</label>
                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                  <i className="fad fa-calendar fs-2"></i>
                                </span>
                                <input
                                  type="date"
                                  className="form-control unstyled form-control-flush position-relative flex-grow-1"
                                  name="toDate"
                                  value={formData.toDate}
                                  onChange={handleChange}
                                  placeholder="To Date.."
                                />
                              </div>
                            </div>

                            {/* Buttons */}
                            <div className="min-w-175px gap-1 gap-sm-5 d-flex">
                              <button
                                type="submit"
                                className="btn btn-dark"
                                id="kt_advanced_search_button_1"
                              >
                                Search
                              </button>
                              <button
                                type="reset"
                                onClick={AdminSavingTransactions}
                                className="btn btn-secondary"
                                id="kt_advanced_search_button_1"
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div class="card-header border-0 pt-6 d-flex align-items-center">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold fs-3 mb-1">
                        Saving Transactions
                      </span>
                      <span className="text-muted mt-1 fw-semibold fs-7">
                        {" "}
                        Over {total} Transactions
                      </span>
                    </h3>
                    <div className="text-end">
                      <CSVLink
                        data={csvData}
                        filename={"table_data.csv"}
                        className="btn btn-success mb-3"
                        target="_blank"
                      >
                        Export Data to CSV
                      </CSVLink>
                    </div>
                  </div>

                  <div className="card-body py-3">
                    <div className="table-responsive">
                      <table
                        className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
                        id="table-to-xls"
                      >
                        <thead>
                          <tr className="fw-bold text-muted th-title">
                            <th className="min-w-300px">Transaction Id</th>

                            <th className="min-w-150px">Amount</th>
                            <th className="min-w-150px"> Type</th>
                            <th className="min-w-150px"> API Type</th>
                            <th className="min-w-150px">Customer Name</th>
                            <th className="min-w-150px">
                              Customer D-ID Ref. No.
                            </th>
                            <th className="min-w-150px">SuperAgent</th>
                            <th className="min-w-150px">Organization</th>
                            <th className="min-w-150px">Payment Status</th>
                            <th className="min-w-150px">Date OF API Call</th>
                            <th className="min-w-150px">Time OF API Call</th>
                          </tr>
                        </thead>

                        <tbody className="text-center">
                          {data?.map((item) => (
                            <tr>
                              <td>{item?.transactionId}</td>
                              <td>{item?.amount}</td>
                              <td>{item?.transactionType}</td>
                              <td>{item?.apiType}</td>
                              <td>{item?.name}</td>
                              <td>{item?.customerRefId}</td>
                              <td>{item?.superAgentName}</td>
                              <td>{item?.orgName || "Loading..."}</td>
                              <td>{item?.transactionstatus || "Success"}</td>

                              {moment(item?.date).format("DD/MM/YYYY")}
                              <td> {moment(item?.date).format("HH:mm:ss")}</td>

                              <td>{item?.savingType}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="col-lg-12 mt-2 text-end">
                      <ReactPaginate
                        PreviousLabel={"Previous"}
                        NextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        onPageChange={handlePageClick}
                        containerClassName={
                          "pagination justify-content-end gap-1 align-items-center"
                        }
                        pageClassName={"page-item mx-2"}
                        pageLinkClassName={"page-link"}
                        PreviousClassName={"page-item"}
                        PreviousLinkClassName={"page-link pagestyle"}
                        NextClassName={"page-item"}
                        NextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AdminSavingTransactions;
