import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";

import CustomerLoader from "../CRM/Modal/CustomerLoder";
const NonMemberStore = ({ hadlersuper }) => {
  let token = localStorage.getItem("SuperAgentChromepayToken");
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState("");
  const [formData, setFormData] = useState();
  const [ShowPassword, setShowPassword] = useState(false);
  const [ShowLoader, setShowLoader] = useState(false);
  const [memberData, setMemberData] = useState(false);
  const [selectedProfession, setSelectedProfession] = useState("");
  const [otherProfession, setOtherProfession] = useState("");
  const [phone, setPhoneNumber] = useState(null);



  const handlePhoneChange = (value) => {
    // You can update your state or perform any logic here
    console.log("Phone number:", value);
    console.log("selectedProfession:", selectedProfession);
    // For example, if you have a state to store the phone number:
    setPhoneNumber(value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      professoin: selectedProfession,
      phone,
    });
  };

 
  
  const handleFileChange = (data) => {
    let formdata = new FormData();
   
    // console.log("@#@#@123" + data);

    if (data) {
      const url = URL.createObjectURL(data);
      setImageUrl(url);
      formdata.append("file", data);

      console.log("image first data", formdata);
      formdata.append("Image", data);
      axios
        .post(`/v1/DID/ImageUploader`, formdata, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          const imgurl = res.data.data;
          console.log("image url", imgurl);
          //   setCutsmerImage(imgurl);
          setFormData({
            ...formData,
            image: imgurl,
          });
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
        });
    } else {
      toast.error(data.msg);
    }
    // })
    // .catch(error => {
    //   console.error("Error checking face API:", error);
    // });
    // } else {

    // }
  };

  const handleProfessionChange = (value) => {
    setSelectedProfession(value);
    console.log({ selectedProfession });
  };

  const formcheck = (e) => {
    e.preventDefault();
    setShowPassword(true);
  };
  const AddFormData = async (e) => {
    // const data = new FormData(e.target);
    // const Formvlaues = Object.fromEntries(data.entries());
    formData.professoin = selectedProfession
    formData.phone = phone
    axios
      .post(`/v1/super-Agent/create-non-member`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })

      .then((resp) => {
        let data = resp.data;
        if (resp.status) {
          let data = resp.data;
          let memberData = resp.data.data;
          console.log("member data --- >>", memberData);
          setMemberData(memberData);
          if (data.status) {
            toast.success(data.msg);
            localStorage.setItem("isNonMember", true);
            navigate(`/Strore/ManagementList/${data.data._id}`, {
              state: { memberData },
            });
            e.target.reset();
          } else {
            toast.error(data.msg);
            setShowPassword(false);
          }
        } else {
          toast.error(data.msg);
          setShowPassword(false);
        }
      });
  };

  const profession = [
    { value: "Not Employeed", label: "Not Employeed" },
    { value: "Student", label: "Student" },
    { value: "Farmer", label: "Farmer" },
    { value: "Merchant", label: "Merchant" },
    { value: "Teacher", label: "Teacher" },
    { value: "Accountant", label: "Accountant" },
    { value: "Manager", label: "Manager" },
    { value: "Software developer", label: "Software developer" },
    { value: "Banker", label: "Banker" },
    { value: "Driver", label: "Driver" },
    { value: "Assistant", label: "Assistant" },
  ];

  const VerifyPassword = async (e) => {
    e.preventDefault();
    setShowLoader(true);
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;

    try {
      const response = await axios.post(
        `/v1/super-Agent/match-agent-pass`,
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      let data = response.data;

      if (data.status) {
        setShowLoader(false);
        AddFormData();
      } else {
        toast.error(response.data.message);
        setShowPassword(false);
        setShowLoader(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message);
      }
    }
  };

  useEffect(() => {
    hadlersuper();
  }, []);

  const EthiopianCities = [
    { value: "addis-ababa", label: "Addis Ababa" },
    { value: "gode", label: "Godē" },
    { value: "erer-sata", label: "Ērer Sātā" },
    { value: "nazret", label: "Nazrēt" },
    { value: "gondar", label: "Gonder" },
    { value: "mekele", label: "Mekele" },
    { value: "awasa", label: "Āwasa" },
    { value: "dire-dawa", label: "Dire Dawa" },
    { value: "bahir-dar", label: "Bahir Dar" },
    { value: "shashemene", label: "Shashemenē" },
    { value: "sodo", label: "Sodo" },
    { value: "arba-minch", label: "Ārba Minch" },
    { value: "dese", label: "Desē" },
    { value: "hosaina", label: "Hosaina" },
    { value: "jima", label: "Jīma" },
    { value: "harar", label: "Harar" },
    { value: "jijiga", label: "Jijiga" },
    { value: "dila", label: "Dīla" },
    { value: "nekemte", label: "Nekemtē" },
    { value: "debre-birhan", label: "Debre Birhan" },
    { value: "debre-markos", label: "Debre Markos" },
    { value: "ferfer", label: "Ferfer" },
    { value: "aware", label: "Āwarē" },
    { value: "kombolcha", label: "Kombolcha" },
    { value: "debre-tabor", label: "Debre Tabor" },
    { value: "adigrat", label: "Ādīgrat" },
    { value: "giyon", label: "Giyon" },
    { value: "asela", label: "Āsela" },
    { value: "kebri-dehar", label: "Kebrī Dehar" },
    { value: "debre-zeyit", label: "Debre Zeyit" },
    { value: "hagere-hiywet", label: "Hāgere Hiywet" },
    { value: "aksum", label: "Āksum" },
    { value: "boditi", label: "Bodītī" },
    { value: "finote-selam", label: "Finote Selam" },
    { value: "semara", label: "Semera" },
    { value: "goba", label: "Goba" },
    { value: "yirga-alem", label: "YirgaAlem" },
    { value: "adwa", label: "Ādwa" },
    { value: "gambela", label: "Gambēla" },
    { value: "bedesa", label: "Bedēsa" },
    { value: "azezo", label: "Āzezo" },
    { value: "gimbi", label: "Gīmbī" },
    { value: "areka", label: "Āreka" },
    { value: "asosa", label: "Āsosa" },
    { value: "wikro", label: "Wikro" },
    { value: "welkite", label: "Welkītē" },
    { value: "metu", label: "Metu" },
    { value: "fiche", label: "Fichē" },
    { value: "kolito", label: "Kolīto" },
    { value: "genet", label: "Genet" },
    { value: "agaro", label: "Āgaro" },
    { value: "gelemso", label: "Gelemso" },
    { value: "maychew", label: "Maychew" },
    { value: "mizan-teferi", label: "Mīzan Teferī" },
    { value: "sekota", label: "Sekota" },
    { value: "himora", label: "Himora" },
    { value: "bonga", label: "Bonga" },
    { value: "bichena", label: "Bichena" },
    { value: "bedele", label: "Bedelē" },
    { value: "kemise", label: "Kemisē" },
    { value: "bedesa", label: "Bedēsa" },
    { value: "cheraro", label: "Cheraro" },
    { value: "huruta", label: "Huruta" },
    { value: "korem", label: "Korem" },
    { value: "hirna", label: "Hīrna" },
    { value: "gesuba", label: "Gesuba" },
    { value: "kofele", label: "Kofelē" },
    { value: "kwihā", label: "Kwīhā" },
    { value: "sendafa", label: "Sendafa" },
    { value: "negelle", label: "Negēlē" },
    { value: "chencha", label: "Chencha" },
    { value: "hagere-selam", label: "Hāgere Selam" },
    { value: "indibir", label: "Indibir" },
    { value: "ejersa-goro", label: "Ējersa Goro" },
    { value: "yetman", label: "Yetman" },
  ];
  return (
    <>
      <ToastContainer />
      <CustomerLoader loader={ShowLoader} />
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div className="card mb-5 mb-xl-10">
                  <div
                    className="card-header border-0 cursor-pointer"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_account_profile_details"
                    aria-expanded="true"
                    aria-controls="kt_account_profile_details"
                  >
                    <div className="card-title m-0">
                      <h3 className="fw-bold m-0">Register Customer</h3>
                    </div>
                    <div className="card-title m-0">
                      <button
                        type="button"
                        onClick={() => navigate(-1)}
                        className="btn btn-dark"
                        id="kt_advanced_search_button_1"
                      >
                        Back
                      </button>
                    </div>
                  </div>
                  <div
                    id="kt_account_settings_profile_details"
                    className="collapse show"
                  >
                    <form
                      onSubmit={(e) => formcheck(e)}
                      id="kt_account_profile_details_form"
                      className="form"
                    >
                      <div className="card-body border-top p-9">
                        <div className="row mb-8">
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                First Name
                              </label>
                              <div className="col-lg-8 fv-row">
                                <input
                                  type="text"
                                  name="first_name"
                                  onChange={handleChange}
                                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                  placeholder="First Name"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                Last Name
                              </label>
                              <div className="col-lg-8 fv-row">
                                <input
                                  type="text"
                                  name="last_name"
                                  onChange={handleChange}
                                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                  placeholder="Last Name"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                Email
                              </label>
                              <div className="col-lg-8 fv-row">
                                <input
                                  type="email"
                                  name="email"
                                  onChange={handleChange}
                                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                  placeholder="Email"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-6 mb-6">
                            <div className="row">
                              <label class="col-lg-4 col-form-label  fw-semibold fs-6">
                                Gender
                              </label>
                              <div class="col-lg-8 fv-row">
                                <div class="d-flex align-items-center mt-3">
                                  <label class="form-check form-check-inline form-check-solid me-5">
                                    <input
                                      class="form-check-input"
                                      name="gender"
                                      type="radio"
                                      onChange={handleChange}
                                      value="Male"
                                    />
                                    <span class="fw-semibold ps-2 fs-6">
                                      Male
                                    </span>
                                  </label>

                                  <label class="form-check form-check-inline form-check-solid">
                                    <input
                                      class="form-check-input"
                                      name="gender"
                                      type="radio"
                                      onChange={handleChange}
                                      value="Female"
                                    />
                                    <span class="fw-semibold ps-2 fs-6">
                                      Female
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                <span>Contact No</span>
                                <i
                                  className="fas fa-exclamation-circle ms-1 fs-7"
                                  data-bs-toggle="tooltip"
                                  title="Phone number must be active"
                                ></i>
                              </label>
                              <div className="col-lg-8 fv-row">
                                <PhoneInput
                                  country="et" // Ethiopian phone number format
                                  enableSearch={true}
                                  inputProps={{
                                    name: "phone",
                                    required: true,
                                    placeholder: "Enter phone number",
                                  }}
                                  onChange={handlePhoneChange} // This function will update the state
                                  containerClass="phone-input-container"
                                  inputClass="form-control form-control-lg form-control-solid"
                                  placeholder="Phone number"
                                  value={phone} // Bind the value to the state
                                />
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-6 mb-6">
                            <div className="row">
                              <label class="col-lg-4 col-form-label fw-semibold fs-6">
                                City
                              </label>
                              <div class="col-lg-8 fv-row">
                                <select
                                  type="text"
                                  name="city"
                                  onChange={handleChange}
                                  class="form-control form-control-lg form-control-solid"
                                  placeholder="Education"
                                >
                                  <option value="">Select an option</option>
                                  {EthiopianCities.map((option) => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                Address
                              </label>
                              <div className="col-lg-8 fv-row">
                                <input
                                  type="text"
                                  name="address"
                                  onChange={handleChange}
                                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                  placeholder="Address"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-6 mb-6">
                            <div className="row">
                              <label class="col-lg-4 col-form-label fw-semibold fs-6">
                                Profession
                              </label>
                              <div class="col-lg-8 fv-row">
                                <select
                                  type="text"
                                  name="professoin"
                                  class="form-control form-control-lg form-control-solid"
                                  placeholder="professoin"
                                  onChange={(e) => {
                                    handleProfessionChange(e.target.value);
                                    // handleChange();
                                  }}
                                >
                                  <option value="">Select an option</option>
                                  {profession.map((option) => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                                  <option value="Other">Other</option>
                                </select>
                                {selectedProfession === "Other" && (
                                  <input
                                    type="text"
                                    name="otherProfession"
                                    class="form-control form-control-lg form-control-solid mt-2"
                                    placeholder="Enter your profession"
                                    value={otherProfession}
                                    onChange={(e) =>
                                      setOtherProfession(e.target.value)
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button
                          type="submit"
                          className="btn btn-light btn-active-light-primary me-2"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="modal-center"
        show={ShowPassword}
        // show={Candelhandler}
        onHide={() => setShowPassword(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Verify Password</Modal.Title>
        </Modal.Header>
        <form onSubmit={VerifyPassword}>
          <Modal.Body>
            <div className="col-lg-12 ">
              <div className="row">
                <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                  {" "}
                  Password
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type="password"
                    name="password"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Enter password"
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit" className="center">
              {" "}
              Verify
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default NonMemberStore;
