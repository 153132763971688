import React from "react";
import { useState } from "react";
import Webcam from "react-webcam";
import Sidebar from "./Sidebar";
import Header from "./Header";
import axios from "axios";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { error } from "jquery";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";

export default function Webcamera() {
  let { _id } = useParams();

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) u8arr[n] = bstr.charCodeAt(n);

    return new File([u8arr], filename, { type: mime });
  };

  const [UserImage, setUserImage] = useState();
  const [show, setshow] = useState(false);
  const [show21, setshow21] = useState(false);
  const videoConstraints = {
    width: 450,
    height: 320,
    facingMode: "user",
  };

  const webcamRef = React.useRef(null);
  const capture = React.useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setUserImage(imageSrc);

    setshow(true);
  }, [webcamRef]);

  const scan_uese_face = () => {
    setshow21(true);
    const convertedUrlToFile = dataURLtoFile(UserImage, "user-image.jpg");
    let dataToSend2 = new FormData();
    dataToSend2.append("image", convertedUrlToFile);
    let { custID } = { custID: _id };
    axios
      .post(`/cust_Face_ditect/${custID}`, dataToSend2)
      .then((res) => {
        let data = res.data.status;
        let data1 = res.data.msg;
        {
          data
            ? Swal.fire({
                position: "center",
                icon: "success",
                title: `${data1}`,
                showConfirmButton: false,
                timer: 7500,
              })
            : Swal.fire({
                icon: "error",
                title: "Oops...",
                text: `${data1}`,
              });
          setshow(false);
        }

        console.log(res.data);
      })
      .catch(error);
  };

  console.log("state", UserImage);
  return (
    <>
  

      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl" id="kt_content_container">
            <div className="container w-75 m-auto">
              <div class="card  d-flex justify-content-center ">
                <div class="card-body">
                  <div class="border  border-success scanz-1 w-50 m-auto">
                    <Webcam
                      audio={false}
                      height={320}
                      ref={webcamRef}
                      screenshotFormat="image/png"
                      width={370}
                      // videoConstraints={videoConstraints()}
                    />
                  </div>
                </div>

                {/* <button type='button' onClick={()=>sendData()}> submit</button> */}

                <div class="card-footer ">
                  {" "}
                  <button class=" btn btn-primary" onClick={() => capture()}>
                    Capture photo
                  </button>
                </div>
              </div>
              <ToastContainer />
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={() => setshow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {show21 ? (
              <> your face identification on process please wait... </>
            ) : (
              ""
            )}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12  mx-auto">
            {show21 ? <hr className="scanface" /> : ""}
            <img src={UserImage} alt="userimage" className="w-100" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            type="button"
            className="me-auto"
            onClick={() => setshow(false)}
          >
            {" "}
            Re Capture
          </Button>
          <Button
            variant="primary"
            type="button"
            onClick={() => scan_uese_face()}
          >
            {" "}
            Submit Now
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
