import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import Modal from "react-responsive-modal";
import { Button } from "react-bootstrap";
import moment from "moment";
import CustomerLoader from "../SuperAgent/CRM/Modal/CustomerLoder";
var jwt = require("jsonwebtoken");

const WithoutIdsWallet = ({ hadleradmin }) => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [pageCount, setpageCount] = useState("");
  const [orgmenu, setOrgMenu] = useState([]);
  const [open, setOpen] = useState(false);
  const [orgID2, setorgID2] = useState("");
  const [total, settotal] = useState("");
  const [listorg, setlistOrg] = useState([]);
  const [ShowLoader, setShowLoader] = useState(true);
  const limit = 10;
  let token = localStorage.getItem("ChromePayAdmitToken");
  var decode1 = jwt.decode(token);
  let adminID = decode1.admminID;
  const AddFormData = async (e, page) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    console.log(Formvlaues);
    axios
      .post(`v1/admin/get-incompleteids`, formData, {
        headers: { Authorization: `bearer ${token}` },
      })
      .then((resp) => {
        const data = resp.data.data;
        // console.log("@@@",data)
        setData(data);
        const total = resp.data.totalRow;
        console.log(total);
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
      });
  };

  const userlist = async () => {
    setShowLoader(true);
    await axios
      .post(
        `v1/admin/get-incompleteids`,
        {},
        { headers: { Authorization: `bearer ${token}` } }
      )
      .then((resp) => {
        const data = resp.data.data;
        setData(data);
        console.log("####" + data);
        const total = resp.data.totalRaw;
        console.log({ total });
        settotal(total);
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
        setShowLoader(false);
      });
  };
  const orgList = async () => {
    await axios.get(`/v1/admin/orgList`).then((resp) => {
      let data = resp.data.Org;
      setlistOrg(data);
      console.log(data);
    });
  };
  useEffect(() => {
    userlist();
    hadleradmin();
    orgList();
  }, []);

  ///////////////pagenestion///////////////
  const fetchComments = async (page) => {
    const senData = { page: page };
    axios
      .post(`v1/admin/get-incompleteids`, senData, {
        headers: { Authorization: `bearer ${token}` },
      })
      .then((resp) => {
        const data = resp.data.data;
        setData(data);
      });
    return data;
  };

  const handlePageClick = async (data) => {
    const page = data.selected + 1;
    const commentsFormServer = await fetchComments(page);
    // setData(commentsFormServer);
  };

  /////////////unblocked user api call ////////////
  // const handleView = (_id) => {
  //   console.log(_id);
  //   axios
  //     .post(
  //       `/v1/admin/add-wallet-hash`,
  //       { custId: _id },
  //       { headers: { Authorization: `bearer ${token}` } }
  //     )
  //     .then((res) => {
  //       if (res.status) {
  //         let data = res.data;
  //         toast.success(data.msg);
  //         return userlist();
  //       }
  //     });
  // };

  /////////////blocked user api call ////////////
  // const handlesuspend = (_id) =>
  // {
  // axios.put(`/suspend/${_id}`)
  // .then(res => {
  //     if (res.status) {
  //         let data = res.data;
  //           toast.success(data.msg);
  //           return  userlist();
  //         }
  // })
  // }
  /////////////Delete orgnization api Call///////

  /////////////////delete api call /////////////////
  const handleView = async (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to add the wallet of a selected customer?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Add it!",
      preConfirm: async () => {
        Swal.showLoading();
        const res = await axios.post(
          `/v1/admin/add-wallet-hash`,
          { custId: _id },
          { headers: { Authorization: `bearer ${token}` } }
        );
        if (res.status) {
          let data = res.data;
          if (data.status) {
            Swal.hideLoading();
            Swal.fire("Add!", "Customer Wallet Add Sucessfully", "success");
            userlist();
          } else {
            toast.error(data.msg);
          }
        } else {
          toast.error(data.msg);
        }
      },
    });
  };

  // const handledelete =(_id) =>
  // {

  // axios.delete(`/deleteOrganization/${_id}`)
  // .then(res =>{
  //     if(res.status){
  //         let data = res.data;
  //         toast.success(data.msg);
  //         return userlist();
  //     }
  // })
  // }
  const handledelete = (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete selected Customer ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`/v1/admin/deleteOrganization/${_id}`).then((res) => {
          if (res.status) {
            let data = res.data;
            if (data.status) {
              Swal.fire("Deleted!", "Customer deleted successfully", "success");
              return userlist();
            } else {
              toast.error(data.msg);
            }
          } else {
            toast.error(data.msg);
          }
        });
      }
    });
  };

  // add admin license

  const Addlicense = (_id) => {
    console.log(_id);
  };

  // add document function
  const Adddocument = () => {
    alert("hello");
  };

  const subAdminlist = async () => {
    await axios
      .post(
        `/v1/admin/subAdminRole/${adminID}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data.find;
        // console.log('@@@@@',data.customer.addCustomer)
        // setMenu(data.customer);
        setOrgMenu(data.Organisation);
        // setAgent(data.Agent)
        // setIP(data.IP)
      });
  };

  useEffect(() => {
    subAdminlist();
  }, []);

  const onOpenModal = (orgID) => {
    setOpen(true);

    console.log(orgID);

    setorgID2(orgID);
  };

  const onCloseModal = () => setOpen(false);

  console.log("Iddddddddddddddddddddddddddd", data);

  // Update License //

  const handleAddLicense = (_id) => {
    const { orgID } = { orgID: _id };
    Swal.fire({
      title: "Add Licenses ",
      input: "number",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Ok",
      showLoaderOnConfirm: true,
      preConfirm: (login) => {
        return axios
          .post(
            `/v1/admin/add_Licenses/${orgID}`,
            { Licenses: login },
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then((response) => {
            Swal.fire({
              title: "Are you sure?",
              text: "Are you sure you want to Add License ",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, Add it!",
            }).then((result) => {
              if (result.isConfirmed) {
                Swal.fire(
                  "Add License!",
                  "Add License   successfully",
                  "success"
                );
                userlist();
              }
            });
          })
          .catch();
      },
    });
  };
  return (
    <div>
      <ToastContainer />
      <CustomerLoader loader={ShowLoader} />
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            <div
              className="content d-flex flex-column flex-column-fluid"
              id="kt_content"
            >
              <div className="container-xxl" id="kt_content_container">
                <div className="row g-5 g-xl-8">
                  <div className="col-lg-12">
                    {/* style="background-color: #2c486d;background-size: auto 100%; background-image: url(assets_new/images/taieri.svg)" */}
                    <div className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover">
                      <div className="card-body container-xxl pt-10 pb-8">
                        <div className="d-flex align-items-center">
                          <h1 className="fw-semibold me-3 text-white">
                            Missing Wallet DID
                          </h1>
                          <span className="fw-semibold text-white opacity-50">
                            {/* Missing Wallet DiD List */}
                          </span>
                        </div>

                        <div className="d-flex flex-column">
                          <div className=" align-lg-items-center"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="card card-xxl-stretch mb-5 mb-xl-8">
                      <div className="card-header border-0 pt-5">
                        <h3 className="card-title align-items-start flex-column">
                          <span className="card-label fw-bold fs-3 mb-1">
                            Missing Wallet DID List
                          </span>
                          <span className="text-muted mt-1 fw-semibold fs-7">
                            Over {total} Missing Wallet{" "}
                          </span>
                        </h3>
                      </div>

                      <div className="card-body py-3">
                        <div className="table-responsive">
                          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                            <thead>
                              <tr className="fw-bold text-muted th-title">
                                <th className="min-w-125px">Image</th>
                                <th className="min-w-125px">First Name</th>

                                <th className="min-w-150px">Email</th>
                                <th className="min-w-150px">Contact No. </th>
                                <th className="min-w-100px">Gender</th>

                                <th className="min-w-125px">Nationality </th>
                                <th className="min-w-150px">
                                  Date of Registration{" "}
                                </th>
                                <th className="min-w-100px">Status</th>
                                {/* <th className="min-w-100px">City</th> */}
                                {/* <th className="min-w-100px ">postCode</th> */}
                                {/* <th className="min-w-100px ">Transection Limit</th> */}
                                <th className="min-w-100px text-center">
                                  Actions
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {data.map((item) => (
                                <tr>
                                  <td className="text-center">
                                    <img
                                      src={
                                        item.IDphoto ||
                                        "/assets_new/images/lodingwallet.gif"
                                      }
                                      alt="profile"
                                      width="80px"
                                      height="80px"
                                    />
                                  </td>
                                  <td className="text-center"> {item.fullname} </td>

                                  <td className="text-center"> {item.email} </td>
                                  <td className="text-center">
                                    <span>{item.phone}</span>{" "}
                                  </td>
                                  <td className="text-center">{item?.gender}</td>

                                  <td className="text-center"> {item.nationality} </td>
                                  <td className="text-center">
                                    {moment(item.createdAt || "N/A").format(
                                      "DD/MM/YYYY"
                                    )}
                                  </td>
                                  {/* <td className="text-center">{item.city}</td> */}
                                  <td className="text-center">
                                    <span class="badge badge-light-info  fs-5">
                                      {item.status}
                                    </span>
                                  </td>
                                  {/* <td className="text-center"><span class="badge badge-light-info fs-5 capitalize-text">{item.postCode}</span></td> */}
                                  {/* <td className="text-center"><span class="badge badge-light-info fs-5 capitalize-text">{item.transectionLimit}</span></td> */}
                                  <td className="text-center">
                                   <div className="d-flex justify-content-center flex-shrink-0">
                                      {/* { item.isBlocked == '1'? 
                <>
                <button onClick={(e) => { handleunblocked(item.isBlocked ="0",item._id);}}
                    className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                    data-bs-toggle="tooltip" data-bs-placement="top"
                    data-bs-trigger="hover" title="Suspend ">
                    <span className="svg-icon svg-icon-3">
                    <img src="/assets_new/images/blocked.png" width="20px" />
                    </span>
                </button>
                </>:
                <>
                 <button onClick={(e) => { handleunblocked(item.isBlocked="1",item._id);}}
                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                    data-bs-toggle="tooltip" data-bs-placement="top"
                    data-bs-trigger="hover" title="Block">
                    <span className="svg-icon svg-icon-3">
                    <img src="/assets_new/images/suspand.png" width="20px" />
                    </span>
                </button>
                </>
                } */}

                                      {/* <button onClick={(e)=>{handleReportagent(item._id)}}
                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                    data-bs-toggle="tooltip" data-bs-placement="top"
                    data-bs-trigger="hover" title="Report">
                    <span className="svg-icon svg-icon-3">
                
                    <i class="fad fa-file-chart-line fs-1" ></i>
                       
                    </span>

                </button> */}
                                      <button
                                        onClick={(e) => handleView(item._id)}
                                        className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-trigger="hover"
                                        title="Missing Wallet"
                                      >
                                        <span className="svg-icon svg-icon-3">
                                          <i className="fad fa-badge-check fs-4"></i>
                                        </span>
                                      </button>
                                      {/* <button onClick={(e)=>handleAddLoan(item._id)}
                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                    data-bs-toggle="tooltip" data-bs-placement="top"
                    data-bs-trigger="hover" title="Add Loan ">
                    <span className="svg-icon svg-icon-3">
                        <i class="fad fa-sack-dollar fs-1"></i>
                    </span>
                </button> */}
                                      {/* <button onClick={(e) => { handledelete(item._id)}}
                    className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                    data-bs-toggle="tooltip" data-bs-placement="top"
                    data-bs-trigger="hover" title="Delete">
                    <span className="svg-icon svg-icon-3">
                    <img src="/assets_new/images/cross.png" width="20px" />
                    </span>

                </button> */}
                                      {/* <a href='#'
                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                    data-bs-toggle="tooltip" data-bs-placement="top"
                    data-bs-trigger="hover" title="Disable OTP">
                    <span className="svg-icon svg-icon-3">
                        <i className="fad fa-eye-slash"></i>
                    </span>

                </a> */}
                                    </div>
                                  </td>
                                </tr>
                              ))}
                              {/* <tr>
        <td className="text-center">##41e8bd3f3ca</td>
        <td className="text-center"> Virendra Shilpkar </td>
        <td className="text-center"> virendra@gmail.com </td>
        <td className="text-center"><span>+221 123-456-7840</span> </td>
        <td className="text-center">05/06/1980</td>
        <td className="text-center">05/08/2020</td>
        <td className="text-center">Ethiopia</td>
        <td className="text-center">
        <td className="text-center"><span class="badge badge-light-info fs-5 capitalize-text">Verified</span></td> 
        </td>
        <td className="text-center">
           <div className="d-flex justify-content-center flex-shrink-0">
                <a href="#"
                    className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                    data-bs-toggle="tooltip" data-bs-placement="top"
                    data-bs-trigger="hover" title="Suspend ">
                    <span className="svg-icon svg-icon-3">
                        <i className="fad fa-minus-circle fs-4"></i>
                    </span>
                </a>
                <a href="#"
                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                    data-bs-toggle="tooltip" data-bs-placement="top"
                    data-bs-trigger="hover" title="Password Reset ">
                    <span className="svg-icon svg-icon-3">
                        <i className="fas fa-repeat-alt"></i>
                    </span>
                </a>
                <a href='organization_detail.html'
                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                    data-bs-toggle="tooltip" data-bs-placement="top"
                    data-bs-trigger="hover" title="View Detail">
                    <span className="svg-icon svg-icon-3">
                        <i className="fad fa-eye fs-4"></i>
                    </span>

                </a>
                <a href='#'
                    className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                    data-bs-toggle="tooltip" data-bs-placement="top"
                    data-bs-trigger="hover" title="Delete">
                    <span className="svg-icon svg-icon-3">
                        <i className="fad fa-trash-alt"></i>
                    </span>

                </a>
                <a href='#'
                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                    data-bs-toggle="tooltip" data-bs-placement="top"
                    data-bs-trigger="hover" title="Disable OTP">
                    <span className="svg-icon svg-icon-3">
                        <i className="fad fa-eye-slash"></i>
                    </span>

                </a>
            </div>
        </td>
    </tr> */}
                            </tbody>
                          </table>
                        </div>
                        <div className="col-lg-12 mt-2 text-end">
                          <ReactPaginate
                            PreviousLabel={"Previous"}
                            NextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName={
                              "pagination justify-content-end"
                            }
                            pageClassName={"page-item mx-4"}
                            pageLinkClassName={"page-link"}
                            PreviousClassName={"page-item mx-2"}
                            PreviousLinkClassName={"page-link pagestyle"}
                            NextClassName={"page-item"}
                            NextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Modal  open={open} onClose={onCloseModal} center>
                          <h2 className="mb-4  text-white">
                            Update Tips & Tricks
                          </h2>
                          <div className="mx-500">
                            <form
                              className="mt-3 w-100"
                              onSubmit={(e) => UpdateFormData(e)}
                            >
                              <div className="form-group mb-4">
                                <label className="title-col">
                                  {" "}
                                  Organisation{" "}
                                </label>
                              
                              </div>

                              <label className="title-col"> Add Licenses</label>
                              <input
                                type="number"
                                id="Licenses"
                                autoComplete="off"
                                className="form-control mb-4"
                                name="Licenses"
                              />

                              <div className="mt-3 mb-3">
                                <Button
                                  type="submit"
                                  className="mr-3 btn-pd btnBg"
                                >
                                  Update
                                </Button>
                              </div>
                            </form>
                          </div>
                        </Modal> */}
    </div>
  );
};

export default WithoutIdsWallet;
