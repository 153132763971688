


import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const OrgPrivatedRouting = () => {
  const isAuthenticated = !!localStorage.getItem("organizationToken"); // or use your own authentication logic
  const location = useLocation();

  return isAuthenticated ? <Outlet /> : <Navigate to="/loginorg" />;
};
export default OrgPrivatedRouting;
