import React from 'react'

function AlertMessage() {
  return (
    <div><div class="alert alert-danger" role="alert">
    Your Remaning License is below 10 Please update your licenses for continue customer service
        </div></div>
  )
}

export default AlertMessage