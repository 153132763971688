import React from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="container vh-100 d-flex flex-column justify-content-center align-items-center text-center">
      <img
        src="assets_new/images/logo.png"
        alt="Logo"
        className="mx-auto d-block login-logo mb-4"
      />
      <div className="row">
        <div className="col-12 mb-5">
          <h1 className="display-4 fs-1">404 Page Not Available</h1>
        </div>

        {/* Total Active Bruh IDs */}
        <div className="col-12 col-md-6 col-lg-3 ">
          <div className="relative  not-card  bg-white  card bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
            <div className="m-0 text-center">
              <span className="text-gray-700 fw-bolder d-block fs-4 lh-1 ls-n1 mb-1">
                {" "}
                <a href="/" className="text-gray-600 ">
                  {" "}
                  Login With Admin{" "}
                </a>
              </span>
            </div>
          </div>
        </div>

        {/* Number Of Agents */}
        <div className="col-12 col-md-6 col-lg-3">
          <div className=" card not-card  bg-white relative bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
            <div className="m-0 text-center">
              <span className="text-gray-700 fw-bolder d-block fs-4 lh-1 ls-n1 mb-1">
                {" "}
                <a href="/loginorg" className="text-gray-600 ">
                  Login With Organization{" "}
                </a>
              </span>
            </div>
          </div>
        </div>

        {/* Recurring Fees */}
        <div className="col-12 col-md-6 col-lg-3">
          <div className=" card not-card  bg-white relative bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
            <div className="m-0  text-center">
              <span className="text-gray-700 fw-bolder d-block fs-4 lh-1 ls-n1 mb-1">
                {" "}
                <a href="/superlogin" className="text-gray-600 ">
                  {" "}
                  Login With Super Agent{" "}
                </a>{" "}
              </span>
            </div>
          </div>
        </div>

        {/* Remaining Bruh IDs Licences */}
        <div className="col-12 col-md-6 col-lg-3">
          <div className=" card bg-white not-card  relative bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
            <div className="m-0 text-center">
              <span className="text-gray-700 fw-bolder d-block fs-4 lh-1 ls-n1 mb-1">
                <a href="/login-jdc" className="text-gray-600 ">
                  {" "}
                  Login With JDC{" "}
                </a>
              </span>
            </div>
          </div>
        </div>

        <div className="col-12 mt-3">
          <p className="lead">The page you are looking for does not exist.</p>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
