import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { ToastContainer,toast } from 'react-toastify';

function SuperAgentPasswordVerifyModal({
  showModal,
  setShowModal,
  handlerSubmit,
}) {
  let token = localStorage.getItem("SuperAgentChromepayToken");

  const [showLoader, setShowLoader] = useState(false);

  const handleVerifyDelete = async (e) => {
    e.preventDefault();
    setShowLoader(true);

    const formData = new FormData(e.target);

    try {
      const response = await axios.post(
        `/v1/super-Agent/match-agent-pass`,
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.data.status) {
        setShowLoader(false);
        setShowModal(false);
        handlerSubmit();
      } else {
        setShowModal(true);
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message);
      }
    } finally {
      setShowLoader(false);
    }
  };

  return (
    <Modal
      className="modal-center"
      show={showModal}
      onHide={() => setShowModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Verify Password</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleVerifyDelete}>
        <Modal.Body>
          <div className="col-lg-12">
            <div className="row">
              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                Password
              </label>
              <div className="col-lg-8 fv-row">
                <input
                  type="password"
                  name="password"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  placeholder="Enter password"
                  required
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            type="submit"
            className="center"
            disabled={showLoader}
          >
            {showLoader ? "Verifying..." : "Verify"}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default React.memo(SuperAgentPasswordVerifyModal);
