// authSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

// Async thunk to handle login
export const authJDCLogin = createAsyncThunk(
  "auth/login",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/v1/org/auth/login-jdc`, formData);
      const result = response.data;

      if (result.status) {
        // Store in localStorage
        localStorage.setItem("ID", result.ID);
        localStorage.setItem("token", result.token);
        localStorage.setItem("jdcRole", result.role);
        localStorage.setItem("jdcEmail", result.email);
        localStorage.setItem("authType", result.authType);

        return result;  // Return result as action payload
      } else {
        toast.error(result.msg);
        return rejectWithValue(result.msg);
      }
    } catch (error) {
      return rejectWithValue(error.response?.data?.msg || "Login failed");
    }
  }
);

// Authentication slice
const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: false,
    user: null,
    token: null,
    loading: false,
    error: null,
  },
  reducers: {
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.token = null;
      localStorage.clear();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authJDCLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(authJDCLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = true;
        state.user = action.payload;
        state.authType = action.payload.authType;
        state.token = action.payload.token;
        toast.success(action.payload.msg);
      })
      .addCase(authJDCLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error(action.payload);
      });
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
