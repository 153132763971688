// import React from "react";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { error } from "jquery";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
var jwt = require("jsonwebtoken");
function Header() {
  const { authType } = useSelector((state) => state.auth);
  const [section, setSection] = useState([]);
  const navigate = useNavigate();
  let image = localStorage.getItem("photo");
  let email = localStorage.getItem("jdcEmail");
  let jdcRole = localStorage.getItem("jdcRole");
  // let authType = localStorage.getItem("authType");
  let name = localStorage.getItem("name");

  let token = localStorage.getItem("token");

  // useEffect(() => {
  //   const orgTransectionsSection = async () => {
  //     await axios.post(`/v1/org/reports/org-detail`,{},{  headers:{"Authorization":`Bearer ${token}`}}).then((resp) => {
  //       const data = resp.data.data;
  //       setSection(data);
  //       console.log("@@@@@", data);
  //     });
  //   };
  //   orgTransectionsSection();
  // }, []);

  const box = useRef();

  const [Adddocument, setAdddocument] = useState([]);
  const [information, setinformation] = useState("");

  var decode1 = jwt.decode(token);
  // let adminID = decode1.admminID;
  const userlist = () => {
    axios
      .post(
        `/v1/org/dash/get-org-notifi-list`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data.data;
        console.log("@@@@" + data);
        setAdddocument(data);
      });
  };

  const Admininformation = () => {
    // axios
    //   .get(`/v1/admin/adminProfile/${adminID}`, { headers: { "Authorization": `Bearer ${token}` } })
    //   .then((resp) => {
    //     let data = resp.data.data;
    //     console.log(" admin dada   ==========>>>>>>>>>>>", data);
    //     setinformation(data);
    //   })
    //   .catch(error);
  };
  const READmeassageadmin = (ID) => {
    axios
      .post(
        `/v1/org/dash/org-read-notification/${ID}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        userlist();
        console.log("1111" + resp);
      })
      .catch(error);
  };

  useEffect(() => {
    userlist();
    Admininformation();
  }, []);

  const Readmeassage = (note, id) => {
    Swal.fire({
      title: "Fuse",
      text: `${note}`,
      padding: "2rem",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Go to page",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate(`/notification/org`);
        userlist();
        READmeassageadmin(id);
      } else {
        READmeassageadmin(id);
      }
    });
  };

  // setTimeout(function() { logout() }, 10000)

  // setTimeout()
  const handlernotification = () => {
    box.current.classList.toggle("notification");
  };

  const logout = () => {
    localStorage.clear();
    navigate(`/login-${authType}`);
    //window.location="/loginorg"
  };
  console.log("user image", image);
  return (
    <>
      <div id="kt_header" className="header align-items-stretch">
        <div className="container-fluid d-flex align-items-stretch justify-content-between">
          <div
            className="d-flex align-items-center d-lg-none ms-n3 me-1"
            title="Show aside menu"
          >
            <div
              className="btn btn-icon btn-active-color-primary w-40px h-40px"
              id="kt_aside_toggle"
            >
              <span className="svg-icon svg-icon-1">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                    fill="currentColor"
                  />
                  <path
                    opacity="0.3"
                    d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
          </div>

          <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
            <Link to="/organization" className="d-lg-none">
              <img
                alt="Logo"
                src="/assets_new/media/logos/demo4-mobile.svg"
                className="h-25px"
              />
            </Link>
          </div>
          <div className="d-flex align-items-center" id="kt_header_wrapper">
            <div
              className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-lg-20 pb-5 pb-lg-0"
              data-kt-swapper="true"
              data-kt-swapper-mode="prepend"
              data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_wrapper'}"
            >
              <h1 className="text-dark fw-bold my-1 fs-3 lh-1">
                <i className="fad fa-home-lg fs-3"></i> &nbsp;Welcome Back!
              </h1>
            </div>
          </div>

          <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
            <div
              className="d-flex align-items-stretch"
              id="kt_header_nav"
            ></div>
            <div className="d-flex align-items-stretch justify-self-end flex-shrink-0">
              <div className="d-flex align-items-center ms-1 ms-lg-3  mr-3"></div>
              <div className="d-flex align-items-center ms-1 ms-lg-3">
                <div
                  className="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px"
                  data-kt-menu="true"
                ></div>
              </div>

              <div
                className="d-flex align-items-center ms-1 ms-lg-3 icon"
                id="kt_header_user_menu_toggle"
              >
                <div
                  className="cursor-pointer symbol symbol-30px symbol-md-40px"
                  data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                  data-kt-menu-attach="parent"
                  data-kt-menu-placement="bottom-end"
                >
                  <img
                    src={
                      authType === "jdc"
                        ? "https://chromepaybucket.blr1.digitaloceanspaces.com/DID/360_F_770330620_g7i5OTbr6dziH09O0jKWvcxAXs41HHVY.jpg"
                        : "https://chromepaybucket.blr1.digitaloceanspaces.com/DID/IMG-20240916-WA0001.jpg"
                    }
                    alt="user"
                    id="imgprofile"
                  />
                </div>

                <div
                  className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px userprofile "
                  data-kt-menu="true"
                >
                  <div className="menu-item px-3">
                    <div className="menu-content d-flex align-items-center px-3">
                      <div className="symbol symbol-50px me-5">
                        <img
                          alt="Logo"
                          src={
                            authType === "jdc"
                              ? "https://chromepaybucket.blr1.digitaloceanspaces.com/DID/360_F_770330620_g7i5OTbr6dziH09O0jKWvcxAXs41HHVY.jpg"
                              : "https://chromepaybucket.blr1.digitaloceanspaces.com/DID/IMG-20240916-WA0001.jpg"
                          }
                        />
                      </div>
                      <div className="d-flex flex-column">
                        <div className="fw-bold d-flex align-items-center fs-5">
                          {localStorage.getItem("org-name")}
                          <span className="badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2">
                            {/* {jdcRole == "jdcAdminLogin" ? "JDC Admin" : "JDC"} */}
                          </span>
                        </div>
                        <Link
                          to="/organization"
                          className="fw-semibold text-muted text-hover-primary fs-7"
                        >
                          {localStorage.getItem("jdcEmail")}
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="separator my-2"></div>

                  <div className="menu-item px-5">
                    <Link to={`${authType}/profile`} className="menu-link px-3">
                      My Profile
                    </Link>
                  </div>

                  {/* <div className="menu-item px-5 my-1">
                    <Link to="/org-change-password" className="menu-link px-3">
                      Account Settings
                    </Link>
                  </div> */}

                  <div className="menu-item px-5">
                    <div
                      onClick={logout}
                      style={{ textIndent: "-5px" }}
                      className="menu-link px-5"
                    >
                      Sign Out
                    </div>
                  </div>
                  <div className="separator my-2"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
