
import React from 'react'
import { useState } from 'react';
import Webcam from "react-webcam";
import Sidebar from "./Sidebar";
import Header from './Header';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { error } from 'jquery';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';




export default function Orginization_customer_face_add({hadlerorg}) {

  const navigate =useNavigate();
  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) u8arr[n] = bstr.charCodeAt(n);

    return new File([u8arr], filename, { type: mime });
  };

  const [UserImage, setUserImage] = useState()
  const [show, setshow] = useState(false)
  const [show21, setshow21] = useState(false)
  const videoConstraints = {
    width: 450,
    height: 320,
    facingMode: "user"
  };


  const webcamRef = React.useRef(null);
  const capture = React.useCallback(
   
    async () => {
      const imageSrc = webcamRef.current.getScreenshot();
      setUserImage(imageSrc)


       setshow(true)
   
   

   
    
  

    },
    [webcamRef]

  );

const scan_uese_face= ()=>{
 

  setshow21(true);
  const convertedUrlToFile = dataURLtoFile(UserImage, "user-image.jpg");
  localStorage.setItem("userviewphoto", UserImage);
  localStorage.setItem("userphoto", convertedUrlToFile);
  if(!show21){
    navigate(`/addcustomer`);
  }
   

}


  console.log( "state",UserImage)
  return (
    <>




      <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
          <div className="container-xxl" >
            <div className="container w-75 m-auto"> 
              <div class="card  d-flex justify-content-center ">
                <div class="card-body">
                  <div class="border  border-success scanz-1 w-50 m-auto">

                    <Webcam
                      audio={false}

                      height={320}
                      ref={webcamRef}
                      screenshotFormat="image/png"
                      width={370}
                      // videoConstraints={videoConstraints()}
                    />
                  </div>

                </div>



          

              
                  {/* <button type='button' onClick={()=>sendData()}> submit</button> */}
            


                <div class="card-footer "> <button class=" btn btn-primary" onClick={() =>capture()} >Capture photo</button></div>


              </div>
              <ToastContainer />
            </div>



          </div>

        </div>

      </div>



     <Modal show={show} onHide={() => setshow(false)}>
        <Modal.Header closeButton>
        <Modal.Title>{show21?<> your face identification on process please wait... </>:""} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
             <div className="col-lg-12  mx-auto">
       
           <img src={UserImage} alt="userimage" className='w-100' />
                    </div>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="primary" type="button" className='me-auto' onClick={()=>setshow(false)}>  Re Capture
          </Button>
          <Button variant="primary" type="button" onClick={()=>scan_uese_face()}>  Submit Now
          </Button>
         
        </Modal.Footer>
      </Modal>

    </>
  )
}


