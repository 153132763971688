import React from "react";
import { Link } from "react-router-dom";
import Jwt from "jsonwebtoken";
import axios from "axios";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate,NavLink } from "react-router-dom";
import {  open } from '../ReduxToolkit/Drawer'
<script src="assets/js/scripts.bundle.js"></script>
export default function Header({showsuper}) {


  const dispatch = useDispatch()
  const navigate = useNavigate();

    let token = localStorage.getItem("SuperAgentChromepayToken");
    let role = localStorage.getItem("superLoginRole");
    console.log("=-=-=-=-===-=-==",{role})
  var decode1 = Jwt.decode(token);
  // let agentID = decode1.agentID;
  // console.log("agentID==", agentID);
  const logout = () => {   
    localStorage.clear();
    // navigate(`/superlogin`);
    window.location="/superlogin"

  };

  const [data, setData] = useState("");
  
  const agentData = () =>{
    axios.post("/v1/super-Agent/agent-Dash", {} , {headers : {"Authorization" :  `Bearer ${token}`}})
    .then((resp)=>{
        let data = resp.data.data;
        setData(data)
        console.log("data--Header===>", data)
    })
  }
  useEffect(() => {
    agentData();
  }, []);

  return (
    <>
      <div id="kt_header" className="header align-items-stretch">
        <div className="container-fluid d-flex align-items-stretch justify-content-between">
          <div
            className="d-flex align-items-center d-lg-none ms-n3 me-1"
            title="Show aside menu"
          >
            <button
              className="btn btn-icon btn-active-color-primary w-40px h-40px"
              id="kt_aside_toggle"
              // id="kt_drawer_example_basic_button"
            
        
            >
              <span className="svg-icon svg-icon-1">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                    fill="currentColor"
                  />
                  <path
                    opacity="0.3"
                    d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </button>
          </div>

          <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
            <Link to="/organization" className="d-lg-none">
              <img
                alt="Logo"
                src="/assets_new/images/logo.png"
                className="h-25px"
              />
            </Link>
          </div>
          <div className="d-flex align-items-center" id="kt_header_wrapper">
            <div
              className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-lg-20 pb-5 pb-lg-0"
              data-kt-swapper="true"
              data-kt-swapper-mode="prepend"
              data-kt-swapper-parent="{default: '#kt_content_container', lg: '#kt_header_wrapper'}"
            >
              <h1 className="text-dark fw-bold my-1 fs-3 lh-1">
                <i className="fad fa-home-lg fs-3"></i> &nbsp;Welcome Back!
              </h1>
            </div>
          </div>

          <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
            <div
              className="d-flex align-items-stretch"
              id="kt_header_nav"
            ></div>
            <div className="d-flex align-items-stretch justify-self-end flex-shrink-0">
              <div className="d-flex align-items-center ms-1 ms-lg-3"></div>

              <div
                className="d-flex align-items-center ms-1 ms-lg-3 icon"
                id="kt_header_user_menu_toggle"
              >
                <div
                  className="cursor-pointer symbol symbol-30px symbol-md-40px icon"
                  data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                  data-kt-menu-attach="parent"
                  data-kt-menu-placement="bottom-end"
                >
                  <img src={data?.Image || "assets_new/images/nouser.png"} alt="user" id="imgprofile"/>
                </div>
                <div
                  className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px userprofile"
                  data-kt-menu="true"
                >
                  <div className="menu-item px-3">
                    <div className="menu-content d-flex align-items-center px-3">
                      <div className="symbol symbol-50px me-5">
                      {data?.Image? <><img src={data?.Image} alt="user" id="imgprofile"      /></> : <>
                  <img src="assets_new/images/nouser.png" alt="user icon" id="imgprofile"      />
                  </>}
                      </div>
                      <div className="d-flex flex-column">
                        <div className="fw-bold d-flex align-items-center fs-5">
                       {data?.name}
                          <span className="badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2">
                            { role == "superAdmin" ? "Super Agent Admin" : "Super Agent"}
                          </span>
                        </div>
                        <NavLink
                          to="#"
                          className="fw-semibold text-muted text-hover-primary fs-7"
                        >
                        {data?.email}
                        </NavLink>
                      </div>
                    </div>
                  </div>

                  <div className="separator my-2"></div>

                  <div className="menu-item px-5">
                    <NavLink to="/superagent/profile" className="menu-link px-5">
                      My Profile
                    </NavLink>
                  </div>

                  <div className="menu-item px-5 my-1">
                    <NavLink to="/Agent-change-password" className="menu-link px-5">
                      Account Settings
                    </NavLink>
                  </div>

                  <div className="menu-item px-5">
                    <div
                      // to="/login-superagent"
                      onClick={() => logout()}
                      className="menu-link px-5"
                    >
                      Sign Out
                    </div>
                  </div>
                  <div className="separator my-2"></div>
                </div>
              </div>

              <div
                className="d-flex align-items-center d-lg-none ms-3 me-n1"
                title="Show header menu"
              >
                <div
                  className="btn btn-icon btn-active-color-primary w-30px h-30px w-md-40px h-md-40px"
                  id="kt_header_menu_mobile_toggle"
                >
                  <span className="svg-icon svg-icon-1">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 11H3C2.4 11 2 10.6 2 10V9C2 8.4 2.4 8 3 8H13C13.6 8 14 8.4 14 9V10C14 10.6 13.6 11 13 11ZM22 5V4C22 3.4 21.6 3 21 3H3C2.4 3 2 3.4 2 4V5C2 5.6 2.4 6 3 6H21C21.6 6 22 5.6 22 5Z"
                        fill="currentColor"
                      />
                      <path
                        opacity="0.3"
                        d="M21 16H3C2.4 16 2 15.6 2 15V14C2 13.4 2.4 13 3 13H21C21.6 13 22 13.4 22 14V15C22 15.6 21.6 16 21 16ZM14 20V19C14 18.4 13.6 18 13 18H3C2.4 18 2 18.4 2 19V20C2 20.6 2.4 21 3 21H13C13.6 21 14 20.6 14 20Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}