import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Uses localStorage
import Drawer from './Drawer';
import authSlice from './authSlice';

// Combine reducers if you have multiple
const rootReducer = combineReducers({
  counter: Drawer,
  auth: authSlice,
});

// Define persist config
const persistConfig = {
  key: 'root',
  storage,
};

// Create persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create store with persisted reducer
const store = configureStore({
  reducer: persistedReducer,
});

// Create persistor
const persistor = persistStore(store);

export { store, persistor };
