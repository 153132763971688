import React from "react";
import { useState, useEffect } from "react";
import Moment from "moment";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Modal";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import GaugeChart from "react-gauge-chart";
import { useLocation, useNavigate } from "react-router-dom";

const JdcAcceptLoan = ({ hadlerjdc }) => {
    const navigate = useNavigate();

    let token = localStorage.getItem("token");
    const location = useLocation();
    const stateData = location?.state;
    const detail = stateData.custId;
    const { _id, custID } = useParams();
    const [show1, setShow1] = useState(false);
    const [photo, setphoto] = useState();
    const [userPro, setUserinfo] = useState([]);
    const [userLoanDetail, setUserLoan] = useState([]);
    // const {custID} = useParams();
    console.log("--------------------------------->>>>>>>>>>>>>>", {
        detail,
        stateData,
    });

    // const [detail, setcustData] = useState("");
    const [data, setLoanData] = useState("");

    const [card, setCard] = useState("");
    const [score, setScore] = useState("");
    // const [customerloanid, setcustomerloanid] = useState('');
    const [Delethandler, setDelethandler] = useState(false);

    const [repaymentSchedule, setRepaymentSchedule] = useState(
        detail?.repaymentSchedule || "monthly"
    );

    const handleRepaymentScheduleChange = (event) => {
        // Update the state with the selected value
        console.log(event.target.value, "repaymentSchedule");
        setRepaymentSchedule(event.target.value);
    };

    const userlist = async () => {
        const { LoanID } = { LoanID: _id };
        await axios
            .post(
                `/v1/org/settings/org_cust_loan`,
                { LoanID, custID },
                { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((resp) => {
                let data = resp?.data;
                let custData = data?.cust;
                let lonaData = data?.Loan;
                // setcustData(custData);
                setLoanData(lonaData);
            });
    };
    const VerfiyDelete = async (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        const Formvlaues = Object.fromEntries(data.entries());
        const formData = Formvlaues;
        try {
            const response = await axios.post(
                `/v1/super-Agent/match-agent-pass`,
                formData,
                { headers: { Authorization: `Bearer ${token}` } }
            );
            setDelethandler(false);
            let data = response.data;
            if (data.status) {
                LoanRejected();
            }
        } catch (error) { }
    };

    const LoanRejected = () => {
        const { LoanID } = { LoanID: _id };

        Swal.fire({
            title: "Enter Loan Reject Ression",
            input: "text",
            inputAttributes: {
                autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "Submit ",
            showLoaderOnConfirm: true,
            preConfirm: async (rejectReason) => {
                return await axios
                    .post(
                        `/v1/super-Agent/loan-Reject`,
                        { rejectReason: rejectReason, loanId: LoanID, status: "REJECT" },
                        { headers: { Authorization: `bearer ${token}` } }
                    )
                    .then((response) => {
                        let data = response.data;
                        {
                            data.status
                                ? Swal.fire(
                                    "Successfully",
                                    navigate(`/superagent/Loan-Reject-customer-List`)
                                    // ActiveCustomerpending()
                                )
                                : Swal.fire({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Invalid OTP!",
                                });
                        }
                    })
                    .catch();
            },
        });
    };

    const custumerDetails = () => {
        axios
            .post(
                `/v1/super-Agent/cust-detail `,
                { custID: custID },
                { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((resp) => {
                let data = resp.data.Data;
                console.log("---->>>>--->>>", data);
                setUserinfo(data);

                console.log("details", data);
            });
    };

    const custumerLoanDetail = () => {
        axios
            .post(
                `/v1/super-Agent/trasaction-dash`,
                { custId: custID },
                { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((resp) => {
                let data = resp.data.data;
                console.log("---->>>>--->>>Loan", data);
                setUserLoan(data);

                console.log("data===== filter", data);
            });
    };
    useEffect(() => {
        userlist();
        hadlerjdc();
        custumerDetails();
        custumerLoanDetail();
    }, []);

    useEffect(() => {
        const cardScore = async () => {
            await axios
                .post(
                    `/v1/org/settings/Calculate_credit_Score`,
                    { custID },
                    { headers: { Authorization: `Bearer ${token}` } }
                )
                .then((resp) => {
                    let data = resp.data;
                    let point = data?.CREDIT_PERCENTEGE;
                    const score = data?.CREDIT_SCORE;
                    setScore(score);
                    setCard(point);
                    console.log("@@@@@P", point);
                });
        };
        cardScore();
    }, [custID]);

    const handleAccept = (_id, custID) => {
        console.log(_id);
        const { LoanID } = { LoanID: _id };

        Swal.fire({
            title: "Are you sure?",
            text: "Are you sure you want to Accept this selected Loan ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Accept it!",
        }).then((result) => {
            if (result.isConfirmed) {
                axios
                    .post(
                        `/v1/org/settings/org_loan_accept`,
                        { LoanID },
                        { headers: { Authorization: `Bearer ${token}` } }
                    )
                    .then((resp) => {
                        if (resp.status) {
                            let data = resp.data;
                            if (data?.status) {
                                toast.success(data?.msg);
                                Swal.fire(
                                    "verified!",
                                    "Customer Accept successfully",
                                    "success"
                                );
                            } else {
                                toast.error(data?.msg);
                            }
                        } else {
                            toast.error(data?.msg);
                        }
                    });
            }
        });
    };
    const imageview = (image) => {
        setphoto(image);
        setShow1(true);
    };

    const AddFormData = async (e) => {
        e.preventDefault();
        var data = new FormData(e.target);

        var Formvlaues = Object.fromEntries(data.entries());
        Formvlaues.custId = _id;
        const formData = Formvlaues;
        console.log(Formvlaues);

        axios
            .post(`/v1/super-Agent/pass-loans`, formData, {
                headers: { Authorization: `Bearer ${token}` },
            })

            .then((resp) => {
                if (resp.status) {
                    let data = resp.data;
                    if (data.status) {
                        toast.success(data.msg);
                        // window.location = "/agent-list";

                        setTimeout(() => {
                            navigate("/Super-Agent-pendingloan");
                        }, 2000);

                        e.target.reset();
                    } else {
                        toast.error(data.msg);
                    }
                } else {
                    toast.error(data.msg);
                }
            });
    };

    return (
        <>
            <ToastContainer />
            <div
                className="wrapper d-flex flex-column flex-row-fluid"
                id="kt_wrapper"
            >
                <div
                    className="content d-flex flex-column flex-column-fluid"
                    id="kt_content"
                >
                    <div className="container-xxl">
                        <div className="row g-5 g-xl-8">
                            <div className="col-lg-12">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div
                                            className="card mb-12 rounded-15 bgi-no-repeat bgi-position-x-end 
          bgi-size-cover bg_card"
                                        >
                                            <div className="card-body flex-column p-5">
                                                <div className="d-flex align-items-center ">
                                                    <div className="card-body  pt-9 pb-0 ">
                                                        {/* <!--begin::Details--> */}
                                                        <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                                                            {/* <!--begin: Pic--> */}
                                                            <div className="me-7 mb-4">
                                                                <div className="symbol border border-gray-300 border-dashed symbol-100px symbol-lg-160px symbol-fixed position-relative text-white">
                                                                    <img
                                                                        src={userPro?.IDphoto}
                                                                        alt="image"
                                                                        className="rounded-circle mx-2"
                                                                    />
                                                                </div>
                                                            </div>

                                                            {/* <!--end::Pic--> */}

                                                            <div className="flex-grow-1">
                                                                <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                                                    {/* <!--begin::User--> */}
                                                                    <div className="d-flex flex-column">
                                                                        {/* <!--begin::Name--> */}
                                                                        <div className="d-flex align-items-center mb-2">
                                                                            <a
                                                                                href="#"
                                                                                className="-900 text-hover-primary fs-2 text-white fw-bold me-1"
                                                                            >
                                                                                {userPro?.fullname}
                                                                            </a>

                                                                            {/* <!--  <a href="#" className="btn btn-sm btn-light-success fw-bold ms-2 fs-8 py-1 px-3" data-bs-toggle="modal" data-bs-target="#kt_modal_upgrade_plan">Verified</a> --> */}
                                                                        </div>
                                                                        {/* <!--end::Name--> */}

                                                                        <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                                                                            <a
                                                                                href="#"
                                                                                className="d-flex align-items-center -400 text-hover-primary me-5 mb-2"
                                                                            >
                                                                                {/* <!--begin::Svg Icon | path: icons/duotune/communication/com006.svg--> */}
                                                                                <span className="svg-icon svg-icon-4 me-1 text-white">
                                                                                    <svg
                                                                                        width="18"
                                                                                        height="18"
                                                                                        viewBox="0 0 18 18"
                                                                                        fill="none"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                    >
                                                                                        <path
                                                                                            opacity="0.3"
                                                                                            d="M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z"
                                                                                            fill="currentColor"
                                                                                        />
                                                                                        <path
                                                                                            d="M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z"
                                                                                            fill="currentColor"
                                                                                        />
                                                                                        <rect
                                                                                            x="7"
                                                                                            y="6"
                                                                                            width="4"
                                                                                            height="4"
                                                                                            rx="2"
                                                                                            fill="currentColor"
                                                                                        />
                                                                                    </svg>
                                                                                </span>
                                                                                {userPro?.professoin || "N/A"}
                                                                            </a>
                                                                            <a
                                                                                href="#"
                                                                                className="d-flex align-items-center -400 text-hover-primary me-5 mb-2"
                                                                            >
                                                                                {/* <!--begin::Svg Icon | path: icons/duotune/general/gen018.svg--> */}
                                                                                <span className="svg-icon text-white  svg-icon-4 me-1">
                                                                                    <svg
                                                                                        width="24"
                                                                                        height="24"
                                                                                        viewBox="0 0 24 24"
                                                                                        fill="none"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                    >
                                                                                        <path
                                                                                            opacity="0.3"
                                                                                            d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                                                                                            fill="currentColor"
                                                                                        />
                                                                                        <path
                                                                                            d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                                                                                            fill="currentColor"
                                                                                        />
                                                                                    </svg>
                                                                                </span>
                                                                                {userPro?.nationality || "Ethiopia"}
                                                                            </a>
                                                                            <a
                                                                                href="#"
                                                                                className="d-flex align-items-center -400 text-white mb-2"
                                                                            >
                                                                                {/* <!--begin::Svg Icon | path: icons/duotune/communication/com011.svg--> */}
                                                                                <span className="svg-icon svg-icon-4 me-1 text-white">
                                                                                    <svg
                                                                                        width="24"
                                                                                        height="24"
                                                                                        viewBox="0 0 24 24"
                                                                                        fill="none"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                    >
                                                                                        <path
                                                                                            opacity="0.3"
                                                                                            d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
                                                                                            fill="currentColor"
                                                                                        />
                                                                                        <path
                                                                                            d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
                                                                                            fill="currentColor"
                                                                                        />
                                                                                    </svg>
                                                                                </span>
                                                                                {userPro?.email}
                                                                            </a>
                                                                        </div>
                                                                        {/* <!--end::Info--> */}
                                                                    </div>
                                                                    {/* <!--end::User--> */}
                                                                </div>

                                                                <div className="d-flex flex-wrap flex-stack">
                                                                    <div className="d-flex flex-column flex-grow-1 pe-8">
                                                                        <div className="d-flex flex-wrap"></div>
                                                                        <div className="d-flex mt-3 flex-wrap">
                                                                            <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 bg_ab96  w-135">
                                                                                <div class="d-flex align-items-center justify-content-center">
                                                                                    <span class="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                                                                    <div
                                                                                        class="fs-2 fw-bold text-white"
                                                                                        data-kt-countup="true"
                                                                                        data-kt-countup-prefix="$"
                                                                                    >
                                                                                        {`${(
                                                                                            userLoanDetail?.totalLoanAmount ||
                                                                                            0
                                                                                        ).toFixed(2)}`}
                                                                                    </div>
                                                                                </div>
                                                                                <div class="fw-semibold fs-6 text-white text-center">
                                                                                    Total Loan Amount (ETB)
                                                                                </div>
                                                                            </div>
                                                                            <div class="border bg_ab96 border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                                                <div class="d-flex align-items-center justify-content-center">
                                                                                    <span class="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                                                                    <div
                                                                                        class="fs-2 fw-bold text-white"
                                                                                        data-kt-countup="true"
                                                                                        data-kt-countup-prefix="$"
                                                                                    >
                                                                                        {`${(
                                                                                            userLoanDetail?.totalPaidAmount ||
                                                                                            0
                                                                                        ).toFixed(2)}`}
                                                                                    </div>
                                                                                </div>
                                                                                <div class="fw-semibold fs-6 text-white text-center">
                                                                                    Total Paid Amount
                                                                                </div>
                                                                            </div>
                                                                            <div class="border bg_ab96 border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                                                <div class="d-flex align-items-center justify-content-center">
                                                                                    <span class="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                                                                    <div
                                                                                        class="fs-2 fw-bold text-white"
                                                                                        data-kt-countup="true"
                                                                                        data-kt-countup-prefix="$"
                                                                                    >
                                                                                        {`${(
                                                                                            userLoanDetail?.remainingamount ||
                                                                                            0
                                                                                        ).toFixed(2)}`}
                                                                                    </div>
                                                                                </div>
                                                                                <div class="fw-semibold fs-6 text-white text-center">
                                                                                    Principal (ETB)
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex mt-3">
                                                                            <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 bg_ab96  w-135">
                                                                                <div class="d-flex align-items-center justify-content-center">
                                                                                    <span class="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                                                                    <div
                                                                                        class="fs-2 fw-bold text-white"
                                                                                        data-kt-countup="true"
                                                                                        data-kt-countup-prefix="$"
                                                                                    >
                                                                                        {`${(
                                                                                            userLoanDetail?.totalIntrestAmount ||
                                                                                            0
                                                                                        ).toFixed(2)}`}
                                                                                    </div>
                                                                                </div>
                                                                                <div class="fw-semibold fs-6 text-white text-center">
                                                                                    Total Intrest Amounte
                                                                                </div>
                                                                            </div>
                                                                            <div class="border bg_ab96 border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                                                <div class="d-flex align-items-center justify-content-center">
                                                                                    <span class="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                                                                    <div
                                                                                        class="fs-2 fw-bold text-white"
                                                                                        data-kt-countup="true"
                                                                                        data-kt-countup-prefix="$"
                                                                                    >
                                                                                        {`${(
                                                                                            userLoanDetail?.Mandatory || 0
                                                                                        ).toFixed(2)}`}
                                                                                    </div>
                                                                                </div>
                                                                                <div class="fw-semibold fs-6 text-white text-center">
                                                                                    Mandatory Savings
                                                                                </div>
                                                                            </div>
                                                                            <div class="border bg_ab96 border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                                                <div class="d-flex align-items-center justify-content-center">
                                                                                    <span class="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                                                                    <div
                                                                                        class="fs-2 fw-bold text-white"
                                                                                        data-kt-countup="true"
                                                                                        data-kt-countup-prefix="$"
                                                                                    >
                                                                                        {`${(
                                                                                            userLoanDetail?.Volantary || 0
                                                                                        ).toFixed(2)}`}
                                                                                    </div>
                                                                                </div>
                                                                                <div class="fw-semibold fs-6 text-white text-center">
                                                                                    Voluntary Savings
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {/* <!--begin::Progress--> */}

                                                                    {/* <!--end::Progress--> */}
                                                                </div>
                                                            </div>
                                                            {/* <!--end::Info--> */}
                                                        </div>
                                                    </div>

                                                    <div className="flex-equal d-flex justify-content-center align-items-end ms-5">
                                                        <img
                                                            src="../../assets/media/illustrations/dozzy-1/20.png"
                                                            alt=""
                                                            className="mw-100 mh-125px mh-lg-275px mb-lg-n12"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="card mb-5 mb-xl-10">
                                    <div
                                        className="card-header border-0 cursor-pointer"
                                        role="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#kt_account_profile_details"
                                        aria-expanded="true"
                                        aria-controls="kt_account_profile_details"
                                    >
                                        <div className="card-title m-0">
                                            <h3 className="fw-bold m-0">Loan Details</h3>
                                        </div>
                                    </div>
                                    <div
                                        id="kt_account_settings_profile_details"
                                        className="collapse show"
                                    >
                                        <form
                                            onSubmit={(e) => AddFormData(e)}
                                            id="kt_account_profile_details_form"
                                            className="form"
                                        >
                                            <div className="card-body border-top p-9">
                                                <div className="row mb-8">
                                                    <div className="col-lg-6 mb-8">
                                                        <div className="row">
                                                            <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                                                                Full Name
                                                            </label>

                                                            <div className="col-lg-8 fv-row">
                                                                <input
                                                                    name="fullName"
                                                                    className=" position-relative form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                                    type="text"
                                                                    placeholder="Full Name"
                                                                    defaultValue={stateData?.fullName}
                                                                    readOnly
                                                                />
                                                                <input
                                                                    name="loanId"
                                                                    className=" position-relative form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                                    type="hidden"
                                                                    defaultValue={_id}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-8">
                                                        <div className="row">
                                                            <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                                                                Mother's Name
                                                            </label>

                                                            <div className="col-lg-8 fv-row">
                                                                <input
                                                                    name="motherName"
                                                                    className=" position-relative form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                                    type="text"
                                                                    placeholder="Mother's Name"
                                                                    defaultValue={stateData?.motherName}
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-8">
                                                        <div className="row">
                                                            <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                                                                Contact Number
                                                            </label>

                                                            <div className="col-lg-8 fv-row">
                                                                <input
                                                                    name="phoneNumber"
                                                                    className=" position-relative form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                                    type="text"
                                                                    placeholder="Contact Number"
                                                                    defaultValue={stateData?.phoneNumber}
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-8">
                                                        <div className="row">
                                                            <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                                                                Home Address
                                                            </label>

                                                            <div className="col-lg-8 fv-row">
                                                                <input
                                                                    name="homeAddress"
                                                                    className=" position-relative form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                                    type="text"
                                                                    placeholder="Home Address"
                                                                    defaultValue={stateData?.homeAddress}
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-8">
                                                        <div className="row">
                                                            <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                                                                Marriage Status
                                                            </label>

                                                            <div className="col-lg-8 fv-row">
                                                                <input
                                                                    name="marriegeStatus"
                                                                    className="form-select border-0 flex-grow-1"
                                                                    defaultValue={
                                                                        stateData?.marriegeStatus ||
                                                                        userPro?.marriegeStatus
                                                                    }
                                                                    readOnly
                                                                ></input>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-8">
                                                        <div className="row">
                                                            <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                                                                Date Of Birth
                                                            </label>

                                                            <div className="col-lg-8 fv-row">
                                                                <input
                                                                    name="dateOfBirth"
                                                                    className=" position-relative form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                                    type="date"
                                                                    defaultValue={stateData?.dateOfBirth}
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 mb-8">
                                                        <div className="row">
                                                            <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                                                                Education
                                                            </label>
                                                            <div className="col-lg-8 fv-row">
                                                                <input
                                                                    name="education"
                                                                    className="form-select border-0 flex-grow-1"
                                                                    defaultValue={stateData?.education}
                                                                    readOnly
                                                                ></input>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 mb-8">
                                                        <div className="row">
                                                            <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                                                                Loan Type
                                                            </label>
                                                            <div className="col-lg-8 fv-row">
                                                                <input
                                                                    name="typeOfLoan"
                                                                    className="form-select border-0 flex-grow-1"
                                                                    type="text"
                                                                    defaultValue={stateData?.typeOfLoan}
                                                                    readOnly
                                                                >
                                                                    {/* <option value="Non-collateral">
                                      Selected Loan Type
                                    </option> */}
                                                                    {/* <option value="Collateral">Collateral</option>
                                    <option value="Non-collateral">
                                      Non-collateral
                                    </option> */}
                                                                </input>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 mb-8">
                                                        <div className="row">
                                                            <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                                                                Number of Children
                                                            </label>
                                                            <div className="col-lg-8 fv-row">
                                                                <input
                                                                    type="Number"
                                                                    name="numberChildren"
                                                                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                                    placeholder="Number of Children"
                                                                    defaultValue={stateData?.numberOfChildren}
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-8">
                                                        <div className="row">
                                                            <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                                                                Monthly Income
                                                            </label>
                                                            <div className="col-lg-8 fv-row">
                                                                <input
                                                                    type="Number"
                                                                    name="monthlyIncome"
                                                                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                                    placeholder="Monthly Income"
                                                                    step="any"
                                                                    defaultValue={stateData?.monthlyIncome}
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-8">
                                                        <div className="row">
                                                            <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                                                                Loan Amount (ETB)
                                                            </label>
                                                            <div className="col-lg-8 fv-row">
                                                                <input
                                                                    type="Number"
                                                                    name="loanAmount"
                                                                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                                    placeholder="Loan Amount"
                                                                    step="any"
                                                                    required
                                                                    defaultValue={stateData?.loanAmount}
                                                                    readOnly
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-8">
                                                        <div className="row">
                                                            <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                                                                Repayment Schedule
                                                            </label>
                                                            <div className="col-lg-8 fv-row">
                                                                <input
                                                                    name="repaymentSchedule"
                                                                    className="form-select border-0 flex-grow-1"
                                                                    defaultValue={stateData?.repaymentSchedule}
                                                                    readOnly
                                                                ></input>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 mb-8">
                                                        <div className="row">
                                                            <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                                                                Loan Duration
                                                            </label>

                                                            <div className="col-lg-8 fv-row">
                                                                <input
                                                                    name="loanDuration"
                                                                    className="form-select border-0 flex-grow-1"
                                                                    defaultValue={stateData?.loanDuration}
                                                                    readOnly
                                                                ></input>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 mb-8">
                                                        <div className="row">
                                                            <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                                                                Reason
                                                            </label>
                                                            <div className="col-lg-8 fv-row">
                                                                <input
                                                                    className="form-select border-0 flex-grow-1"
                                                                    name="reason"
                                                                    placeholder="Reason"
                                                                    defaultValue={stateData?.reason || "N/A"}
                                                                    readOnly
                                                                ></input>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 mb-8">
                                                        <div className="row">
                                                            <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                                                                Professoin
                                                            </label>
                                                            <div className="col-lg-8 fv-row">
                                                                <input
                                                                    name="professoin"
                                                                    className="form-select border-0 flex-grow-1"
                                                                    defaultValue={
                                                                        stateData?.professoin || userPro?.professoin
                                                                    }
                                                                    readOnly
                                                                ></input>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {stateData?.colletralAsset == "Collateral" ? (
                                                        <>
                                                            <div className="col-lg-6 mb-8">
                                                                <div className="row">
                                                                    <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                                                                        Collateral Asset
                                                                    </label>
                                                                    <div className="col-lg-8 fv-row">
                                                                        <input
                                                                            type="text"
                                                                            name="colletralAsset"
                                                                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                                            placeholder="Collateral Asset"
                                                                            defaultValue={stateData?.colletralAsset}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 mb-8">
                                                                <div className="row">
                                                                    <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                                                                        Type Of Collateral
                                                                    </label>
                                                                    <div className="col-lg-8 fv-row">
                                                                        <input
                                                                            type="text"
                                                                            name="typeOfCollateral"
                                                                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                                            placeholder="Type Of Collateral"
                                                                            defaultValue={stateData?.typeOfCollateral}
                                                                            readOnly
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 mb-8">
                                                                <div className="row">
                                                                    <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                                                                        Value
                                                                    </label>
                                                                    <div className="col-lg-8 fv-row">
                                                                        <input
                                                                            type="number"
                                                                            name="value"
                                                                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                                            placeholder="Value"
                                                                            step="any"
                                                                            defaultValue={stateData?.value}
                                                                            readOnly
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        ""
                                                    )}
                                                    <div className="col-lg-6 mb-8">
                                                        <div className="row">
                                                            <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                                                                Interest Rate
                                                            </label>
                                                            <div className="col-lg-8 fv-row">
                                                                <input
                                                                    name="interestRate"
                                                                    className="form-select border-0 flex-grow-1"
                                                                    defaultValue={stateData?.interestRate}
                                                                    readOnly
                                                                ></input>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-lg-6 mb-8">
                                              <div className="row">
                                                <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                                                  Repayment Schedule
                                                </label>
  
                                                <div className="col-lg-8 fv-row">
                                                  <select
                                                    name="repaymentSchedule"
                                                    className="form-select border-0 flex-grow-1"
                                                    data-control="select2"
                                                    data-placeholder="Please Select"
                                                  >
                                                    <option>
                                                      Selected Repayment Schedule
                                                    </option>
                                                    <option value="Auto Detact">
                                                      Auto Detact
                                                    </option>
                                                    <option value="Cash">
                                                      Cash
                                                    </option>
                                                  </select>
                                                </div>
                                              </div>
                                            </div> */}
                                                </div>

                                                <div className="card-footer d-flex justify-content-end py-6 px-9">
                                                    {/* <button
                                                        type="button"
                                                        onClick={(e) => {
                                                            setDelethandler(true);
                                                            // setcustomerloanid();
                                                        }}
                                                        className="btn btn-light btn-light-danger me-2 mx-3"
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="top"
                                                        data-bs-trigger="hover"
                                                        title="Loan Reject"
                                                    >
                                                        Reject
                                                        <span className="svg-icon svg-icon-3">
                             <img
                               src="/assets_new/images/cross.png"
                               width="20px"
                             />
                           </span>
                                                    </button> */}

                                                    {/* <button
                                                        type="submit"
                                                        className="btn btn-light btn-active-light-primary me-2"
                                                    >
                                                        Accept
                                                    </button> */}
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row">
                  <div className="col-lg-12 mb-4">
                    <h3 className="fw-bold mt-3">Credit Score</h3>
                  </div>
                  <div className="col-lg-12 mb-4 ">
                    <div className="card mb-5 mb-xl-10">
                  
                      <div
                        id="kt_account_settings_profile_stateDatas"
                        className="collapse show row"
                      >
                        <div className="col-lg-8 mt-5 mb-0">
                          <div className="text-center ">
                            <h1 className="text-pda">{score}</h1>
                          </div>
                          <GaugeChart
                            id="gauge-chart5"
                            nrOfLevels={100}
                            arcsLength={[0.2, 0.6, 0.2]}
                            colors={["#EA4228", "#F5CD19", "#5BE12C"]}
                            textColor={["#000000"]}
                            fontSize={0.5}
                            percent={card}
                            arcPadding={0.02}
                          />
                          <div className="row">
                            <div className="col-lg-6">
                              <h1 className="text-padd">300</h1>
                            </div>
  
                            <div className="col-lg-6">
                              <h1 className="text-end text-padd1">900</h1>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 mt-5">
                          <div className="text-center">
                            <div className="row">
                              <div className="col-lg-12">
                                <h1 className="card-score">
                                  Your Credit Card Score{" "}
                                </h1>
                              </div>
                              <div className="row">
                                <div className="col-lg-12 row">
                                  <div className="col-lg-2 mt-5">
                                    <div className="box-poor"></div>
                                  </div>
                                  <div className="col-lg-9 mt-5">
                                    <h1>Poor</h1>
                                  </div>
                                </div>
                                <div className="col-lg-12 row">
                                  <div className="col-lg-2 mt-5">
                                    <div className="box-good"></div>
                                  </div>
                                  <div className="col-lg-9 mt-5">
                                    <h1>Good</h1>
                                  </div>
                                </div>
                                <div className="col-lg-12 row">
                                  <div className="col-lg-2 mt-5">
                                    <div className="box-excellent"></div>
                                  </div>
                                  <div className="col-lg-9 mt-5">
                                    <h1>Excellent</h1>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                            <div className="row">
                                <div className="col-lg-12 mb-4">
                                    <h3 className="fw-bold mt-3">User Documents</h3>
                                </div>

                                <div className="row mt-4  mb-5">
                                    <div className="col-lg-4 basicdetail mb-2">
                                        <div className="carduser">
                                            <div
                                                className="text-center"
                                                onClick={() => imageview(detail?.landRegistration)}
                                            >
                                                <div className="img_div">
                                                    <img src={detail?.landRegistration} />
                                                </div>
                                                <div className="viewimg">
                                                    <i
                                                        className="fa fa-eye"
                                                        data-toggle="lightbox"
                                                        data-gallery="gallery"
                                                        aria-hidden="true"
                                                    ></i>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between p-3">
                                                <h5>Asset Certificate</h5>
                                                <div>
                                                    <a href={detail?.landRegistration} className="ml-2">
                                                        <i
                                                            className="fa fa-download"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 basicdetail mb-2">
                                        <div className="carduser">
                                            <div
                                                className="text-center"
                                                onClick={() => imageview(detail?.locaDocument)}
                                            >
                                                <div className="img_div">
                                                    <img src={detail?.locaDocument} />
                                                </div>

                                                <div
                                                    className="viewimg"
                                                    href={detail?.locaDocument}
                                                    data-toggle="lightbox"
                                                    data-gallery="gallery"
                                                >
                                                    <i
                                                        className="fa fa-eye"
                                                        aria-hidden="true"
                                                        data-toggle="lightbox"
                                                        data-gallery="gallery"
                                                    ></i>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between p-3">
                                                <h5>Local Document</h5>
                                                <div>
                                                    <a href={detail?.locaDocument} className="ml-2">
                                                        <i
                                                            className="fa fa-download"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 basicdetail mb-2">
                                        <div className="carduser">
                                            <div
                                                className="text-center"
                                                onClick={() => imageview(detail?.residance)}
                                            >
                                                <div className="img_div">
                                                    <img src={detail?.residance} />
                                                </div>
                                                <div
                                                    className="viewimg"
                                                    href={detail?.residance}
                                                    data-toggle="lightbox"
                                                    data-gallery="gallery"
                                                >
                                                    <i className="fa fa-eye" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between p-3">
                                                <h5>Terms And Conditions</h5>
                                                <div>
                                                    <a href={detail?.residance} className="ml-2">
                                                        <i
                                                            className="fa fa-download"
                                                            aria-hidden="true"
                                                        ></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
                                <div className="container-xxl d-flex flex-column flex-md-row flex-stack">
                                    <div className="text-dark order-2 order-md-1">
                                        <span className="text-gray-400 fw-semibold me-1">
                                            Created by
                                        </span>
                                        <a
                                            href="#"
                                            className="text-muted text-hover-primary fw-semibold me-2 fs-6"
                                        >
                                            Chromepay
                                        </a>
                                    </div>

                                    <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
                                        <li className="menu-item">
                                            <a href="#" className="menu-link px-2">
                                                About
                                            </a>
                                        </li>
                                        <li className="menu-item">
                                            <a href="#" className="menu-link px-2">
                                                Support
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal
                        className="modal-center"
                        show={Delethandler}
                        onHide={() => setDelethandler(false)}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Verify Password</Modal.Title>
                        </Modal.Header>
                        <form onSubmit={VerfiyDelete}>
                            <Modal.Body>
                                <div className="col-lg-12 ">
                                    <div className="row">
                                        <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                            {" "}
                                            Password
                                        </label>
                                        <div className="col-lg-8 fv-row">
                                            <input
                                                type="password"
                                                name="password"
                                                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                placeholder="Enter password"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button
                                    type="submit"
                                    className="btn btn-light btn-active-light-primary me-2"
                                >
                                    Submit
                                </button>
                            </Modal.Footer>
                        </form>
                    </Modal>
                </div>

                <Modal show={show1} onHide={() => setShow1(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>DOCUMENT</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container">
                            <img src={photo} alt="document" style={{ width: "100%" }} />
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    );
}


export default JdcAcceptLoan
