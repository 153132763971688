import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { error } from "jquery";
import Moment from "moment";
import { FormatListBulletedRounded } from "@material-ui/icons";
import CustomerLoader from "../CRM/Modal/CustomerLoder";
var jwt = require("jsonwebtoken");

function AgentsuperList({ hadlersuper }) {
  const [pageCount, setpageCount] = useState("");
  const [data, setData] = useState([]);
  const [defaultData, setDefaultData] = useState([]);
  const navigate = useNavigate();
  const ID = localStorage.getItem("ID");

  const [ShowLoader, setShowLoader] = useState(true);
  const limit = 10;

  let token = localStorage.getItem("SuperAgentChromepayToken");
  var decode1 = jwt.decode(token);
  let orgID = decode1.OrganisationID;
  const [error, setError] = useState(null);
  const [Delethandler, setDelethandler] = useState(false);
  const [Blockhandler, setBlockhandler] = useState(false);
  const [custID, setcustID] = useState("");
  const [block, setBlock] = useState("");
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    agentCode: "",
    image: "",
    address: "",
    city: "",
    postCode: "",
    transactionLimit: "",
    country: "",
    agentId: "658128bb067f77eeb741b51a", // replace with the actual agent ID
  });

  const handleUpdateProfile = (item) => {
    setShowUpdateModal(true);

    console.log({ item });

    setFormData({
      name: item?.name,
      email: item?.email,
      phone: item?.phone,
      agentCode: item?.agentCode,
      image: item?.image,
      address: item?.address,
      city: item?.city,
      postCode: item?.postCode,
      transectionLimit: item?.transectionLimit,
      country: item?.country,
      agentId: "658128bb067f77eeb741b51a", // replace with the actual agent ID
    });
  };

  const handleCloseUpdateModal = () => {
    // Close the update profile modal
    setShowUpdateModal(false);
  };

  const handleUpdateProfileSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());

    // console.log({formData})
    try {
      axios
        .post(`/v1/super-Agent/update-agent-profile`, Formvlaues, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          const data = res.data;

          if (res?.status) {
            console.log({ data });
            toast.success(data.msg);
            userlist();
          }
        });
    } catch (error) {
      console.error("API request error:", error);
    }

    // Close the modal after updating
    setShowUpdateModal(false);
  };
  const AddFormData = async (e, page) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    console.log(Formvlaues);
    axios
      .post(`/v1/super-Agent/get-agents`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        const data = resp.data.data;
        setData(data);
        const total = resp.data.totalRows;
        console.log(total);
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
      });
  };
  const userlist = async () => {
    setShowLoader(true);
    await axios
      .post(
        `/v1/super-Agent/get-agents`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        const data = resp.data.data;
        setData(data);
        setDefaultData(data[0]);
        console.log(data);
        const total = resp.data.totalRows;
        console.log(total);
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
        setShowLoader(false);
      });
  };

  useEffect(() => {
    userlist();
    hadlersuper();
  }, []);

  const fetchComments = async (page) => {
    const sentdata = { page: page };
    await axios
      .post(`/v1/super-Agent/get-agents`, sentdata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        let data = resp.data.data;
        setData(data);
      });
    return data;
  };
  const handlePageClick = async (data) => {
    const page = data.selected + 1;
    const commentsFormServer = await fetchComments(page);
    setData(commentsFormServer);
  };

  const paymentsendprogessuser = (_id) => {
    setcustID(_id);
    setDelethandler(true);
  };
  const HandleBlock = (_id, blocked) => {
    setcustID(_id);
    setBlock(blocked);
    console.log("1425" + _id, blocked);
    setBlockhandler(true);
  };
  //----------------blocked section ------------------//

  const handleunblocked = (_id) => {
    console.log(_id);
    axios
      .put(`/v1/org/Agent/Unsuspend-agent/:agentID`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        if (resp.status) {
          let data = resp.data;
          toast.success(data.msg);
          return userlist();
        }
      });
  };

  const handlesuspend = (_id) => {
    console.log(_id);
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to block selected Agent",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, block it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(
            `/v1/org/Agent/suspend-agent/${_id}`,
            {},
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then((resp) => {
            if (resp.status) {
              let data = resp.data;
              Swal.fire("Blocked!", "Your Agent has been block.", "success");
              return userlist();
            }
          });
      }
    });
  };
  const handleblocked = () => {
    let agentId = custID;
    let status = block;
    // const {_id}= {_id:custID};

    let body = { agentId: agentId, status: status };
    console.log("+++", body);
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to block selected Agent",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, block it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(`/v1/super-Agent/block-unblock-agent`, body, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((resp) => {
            if (resp.status) {
              let data = resp.data;
              if (data.status) {
                toast.success(data.message);
                Swal.fire(
                  "Blocked!",
                  "Your Agent has been blocked.",
                  "success"
                );
                return userlist();
              } else {
                toast.error(data.message);
                Swal.fire(
                  "Blocked!",
                  "Your Agent has been blocked.",
                  "success"
                );
              }
            } else {
              toast.error(data.message);
            }
          });
      }
    });
  };
  //--------------delete-----------------//

  // const handledelete = (_id) =>{
  //     console.log(_id);
  //     axios.post(`/v1/org/Agent/delete-agent/${_id}`,{}, {  headers:{"Authorization":`Bearer ${token}`}})
  //     .then(resp =>{
  //         if(resp.status){
  //             let data =resp.data;
  //             toast.success(data.msg);
  //             return userlist();
  //         }
  //     })
  // }
  const handledelete = () => {
    const agentId = custID;
    console.log(agentId);
    let body = { agentId: agentId };
    console.log("agentId", body);
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to Delete selected Agent",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(`/v1/super-Agent/delete-agent`, body, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((resp) => {
            if (resp.status) {
              let data = resp.data;
              Swal.fire("Deleted!", "Your Agent has been Deleted.", "success");
              return userlist();
            }
          });
      }
    });
  };
  //-------------detail-----------///
  const handleView = (_id) => {
    console.log(_id);
    navigate(`/superagent/super-agent-view/${_id}`);
    // window.location = `/agent-view-org/${_id}`;
    return false;
  };
  const handleReportagent = (_id) => {
    navigate(`/superagent/agent-report/${_id}`);
    console.log(_id);
    // window.location =`/Agent_Performance_repost/${_id}`;
  };

  const VerfiyDelete = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    try {
      const response = await axios.post(
        `/v1/super-Agent/match-agent-pass`,
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setDelethandler(false);
      let data = response.data;
      if (data.status) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: `${data.message}`,
          showConfirmButton: false,
          timer: 3500,
        });
        // toast.success(data.message);

        handledelete();

        return userlist();
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setError(error.response.data.message);
        // toast.error(error.response.data.message)
        Swal.fire({
          position: "center",
          icon: "error",
          title: `${error.response.data.message}`,
          showConfirmButton: false,
          timer: 3500,
        });
      }
    }
  };
  const VerfiyBlock = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    try {
      const response = await axios.post(
        `/v1/super-Agent/match-agent-pass`,
        formData,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setBlockhandler(false);
      let data = response.data;
      if (data.status) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: `${data.message}`,
          showConfirmButton: false,
          timer: 3500,
        });
        // toast.success(data.message);

        handleblocked();

        return userlist();
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setError(error.response.data.message);
        // toast.error(error.response.data.message)
        Swal.fire({
          position: "center",
          icon: "error",
          title: `${error.response.data.message}`,
          showConfirmButton: false,
          timer: 3500,
        });
        setBlockhandler(false);
      }
    }
  };
  return (
    <>
      <ToastContainer />
      <CustomerLoader loader={ShowLoader} />
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div
                  className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                  style={{ marginTop: "2rem" }}
                >
                  <div className="card-body container-xxl pt-10 pb-8">
                    <div className="d-flex align-items-center">
                      <h1 className="fw-semibold me-3 text-white">Filter</h1>
                      <span className="fw-semibold text-white opacity-50">
                        Agent List
                      </span>
                    </div>

                    <div className="d-flex flex-column">
                      <div className="d-lg-flex align-lg-items-center">
                        <form onSubmit={(e) => AddFormData(e)}>
                          <div className="rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body p-5  h-lg-60px me-lg-10 my-5">
                            <div className="row flex-grow-1 mb-5 mb-lg-0">
                              <div className="col-lg-3 col-sm-4 d-flex align-items-center mb-3 mb-lg-0">
                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      opacity="0.5"
                                      x="17.0365"
                                      y="15.1223"
                                      width="8.15546"
                                      height="2"
                                      rx="1"
                                      transform="rotate(45 17.0365 15.1223)"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                <input
                                  type="text"
                                  className="form-control form-control-flush flex-grow-1"
                                  name="agentCode"
                                  placeholder="Agent Code"
                                />
                              </div>
                              <div className="col-lg-3 col-sm-4 d-flex align-items-center mb-3 mb-lg-0">
                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      opacity="0.5"
                                      x="17.0365"
                                      y="15.1223"
                                      width="8.15546"
                                      height="2"
                                      rx="1"
                                      transform="rotate(45 17.0365 15.1223)"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                <input
                                  type="text"
                                  className="form-control form-control-flush flex-grow-1"
                                  name="name"
                                  placeholder="Agent Name"
                                />
                              </div>

                              <div className="col-lg-3 col-sm-4 d-flex align-items-center mb-3 mb-lg-0">
                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-phone"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
                                    <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                  </svg>
                                </span>
                                <input
                                  type="text"
                                  className="form-control form-control-flush flex-grow-1"
                                  name="phone"
                                  placeholder="Contact No."
                                />
                              </div>

                              <div className="col-lg-3 col-sm-4 d-flex align-items-center mb-5 mb-lg-0">
                                <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                  <i className="fas fa-globe-americas fs-1"></i>
                                </span>
                                <select
                                  className="form-select border-0 flex-grow-1"
                                  data-control="select2"
                                  data-placeholder="country"
                                  data-hide-search="true"
                                  name="country"
                                >
                                  <option value="" selected disabled>
                                    Country
                                  </option>
                                  <option value="India">India</option>
                                  <option value="USA">USA</option>
                                  <option value="Uganda">Uganda</option>
                                </select>
                              </div>
                            </div>

                            <div className="min-w-175px text-end gap-1 d-flex gap-sm-5">
                              <button
                                type="submit"
                                className="btn btn-dark"
                                id="kt_advanced_search_button_1"
                              >
                                Search
                              </button>
                              <button
                                type="reset"
                                onClick={userlist}
                                className="btn btn-secondary"
                                id="kt_advanced_search_button_1"
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold fs-3 mb-1">
                        Agent List
                      </span>
                      <span className="text-muted mt-1 fw-semibold fs-7"></span>
                    </h3>
                  </div>

                  <div className="card-body py-3">
                    <div className="table-responsive">
                      <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                          <tr className="fw-bold text-muted th-title">
                            {/* <th className="min-w-125px">D-ID</th> */}
                            <th className="min-w-125px">Full Name</th>
                            <th className="min-w-150px">Email </th>
                            <th className="min-w-150px">Contact No. </th>
                            <th className="min-w-100px">Agent Ref No.</th>
                            <th className="min-w-100px">Address</th>
                            <th className="min-w-100px">
                              Date of Registration
                            </th>
                            <th className="min-w-100px">Country</th>
                            <th className="min-w-100px">City</th>
                            {/* <th className="min-w-100px ">postCode</th> */}
                            <th className="min-w-100px ">Transaction Limit</th>
                            <th className="min-w-100px text-center">Actions</th>
                          </tr>
                        </thead>

                        <tbody>
                          {data.map((item) => (
                            <tr>
                              {/* <td className="text-center">{item._id}</td> */}
                              <td className="text-center"> {item.name} </td>
                              <td className="text-center"> {item.email} </td>
                              <td className="text-center">
                                <span>{item.phone}</span>{" "}
                              </td>
                              <td className="text-center">{item.agentCode}</td>
                              <td className="text-center">{item.address}</td>
                              <td className="text-center">
                                {Moment(item?.createdAt || "N/A").format(
                                  "DD/MM/YYYY"
                                )}
                              </td>
                              <td className="text-center">{item.country}</td>
                              <td className="text-center">{item.city}</td>
                              {/* <td className="text-center"><span class="badge badge-light-info fs-5 capitalize-text">{item.postCode}</span></td> */}
                              <td className="text-center">
                                <span class="badge badge-light-info fs-5 capitalize-text">
                                  {item.transectionLimit}
                                </span>
                              </td>
                              <td className="text-center">
                               <div className="d-flex justify-content-center flex-shrink-0">
                                  {item.blocked == "1" ? (
                                    <>
                                      <button
                                        onClick={(e) => {
                                          handleunblocked(item._id);
                                        }}
                                        className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-trigger="hover"
                                        title="Suspend "
                                      >
                                        <span className="svg-icon svg-icon-3">
                                          <img
                                            src="/assets_new/images/blocked.png"
                                            width="20px"
                                          />
                                        </span>
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        //   onClick={(e) => { handlesuspend(item._id);}}
                                        onClick={(e) => {
                                          HandleBlock(
                                            item._id,
                                            (item.blocked = "1")
                                          );
                                        }}
                                        className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-trigger="hover"
                                        title="Block"
                                      >
                                        <span className="svg-icon svg-icon-3">
                                          {/* <img src="/assets_new/images/suspand.png" width="20px" /> */}
                                          <img
                                            src="/assets_new/images/blocked.png"
                                            width="20px"
                                          />
                                        </span>
                                      </button>
                                    </>
                                  )}

                                  <button
                                    onClick={(e) => {
                                      handleReportagent(item._id);
                                    }}
                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    title="Report"
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <i class="fad fa-file-chart-line fs-1"></i>
                                      {/* <img src="/assets_new/images/report.png" width="35px" /> */}
                                    </span>
                                  </button>

                                  <button
                                    onClick={(e) => {
                                      handleUpdateProfile(item);
                                      userlist();
                                    }}
                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    title="Profile Update"
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <i class="fa-solid fa-pen-to-square fs-1"></i>
                                      {/* <img src="/assets_new/images/report.png" width="35px" /> */}
                                    </span>
                                  </button>
                                  {/* <button onClick={(e) => handleView(item._id)}
                                                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                                    data-bs-trigger="hover" title="View Detail">
                                                                    <span className="svg-icon svg-icon-3">
                                                                        <i className="fad fa-eye fs-4"></i>
                                                                    </span>

                                                                </button> */}
                                  <button
                                    // onClick={(e) => { handledelete(item._id)}}
                                    onClick={(e) => {
                                      paymentsendprogessuser(item._id);
                                    }}
                                    className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    title="Delete"
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <img
                                        src="/assets_new/images/cross.png"
                                        width="20px"
                                      />
                                    </span>
                                  </button>
                         
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="col-lg-12 mt-2 text-end">
                      <ReactPaginate
                        PreviousLabel={"Previous"}
                        NextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        onPageChange={handlePageClick}
                        containerClassName={
                          "pagination justify-content-end gap-1 "
                        }
                        pageClassName={"page-item mx-2"}
                        pageLinkClassName={"page-link"}
                        PreviousClassName={"page-item"}
                        PreviousLinkClassName={"page-link pagestyle"}
                        NextClassName={"page-item"}
                        NextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="modal-center"
        show={Delethandler}
        onHide={() => setDelethandler(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Verify Password</Modal.Title>
        </Modal.Header>
        <form onSubmit={VerfiyDelete}>
          <Modal.Body>
            <div className="col-lg-12 ">
              <div className="row">
                <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                  {" "}
                  Password
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type="password"
                    name="password"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Enter password"
                  />
                </div>
              </div>
              {/* <div className="row mt-3">
                        <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                          Amount
                        </label>
                        <div className="col-lg-8 fv-row">
                          <input
                            type="text"
                            name="amount"
                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                            placeholder="Amount"
                          />
                        </div>
                      </div> */}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit" className="center">
              {" "}
              Verify
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal
        className="modal-center"
        show={Blockhandler}
        onHide={() => setBlockhandler(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Verify Password</Modal.Title>
        </Modal.Header>
        <form onSubmit={VerfiyBlock}>
          <Modal.Body>
            <div className="col-lg-12 ">
              <div className="row">
                <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                  {" "}
                  Password
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type="password"
                    name="password"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Enter password"
                  />
                </div>
              </div>
              {/* <div className="row mt-3">
                        <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                          Amount
                        </label>
                        <div className="col-lg-8 fv-row">
                          <input
                            type="text"
                            name="amount"
                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                            placeholder="Amount"
                          />
                        </div>
                      </div> */}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit" className="center">
              {" "}
              Submit
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal show={showUpdateModal} onHide={handleCloseUpdateModal}>
        <Modal.Header closeButton>
          <Modal.Title>Update Profile</Modal.Title>
        </Modal.Header>
        {/* {data.map((item) => ( */}

        <form onSubmit={handleUpdateProfileSubmit}>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-6">
                <div className="mb-3">
                  <label className="form-label">Full Name</label>
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={defaultData?.name}
                    name="name"
                    placeholder="Enter full name"
                  />
                  <input
                    type="hidden"
                    className="form-control"
                    defaultValue={defaultData?.image}
                    name="image"
                  />
                  <input
                    type="hidden"
                    className="form-control"
                    defaultValue={defaultData?._id}
                    name="agentId"
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    defaultValue={defaultData?.email}
                    placeholder="Enter email"
                  />
                </div>

                {/* Add other fields as needed */}
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <label className="form-label">Agent Code</label>
                  <input
                    type="text"
                    className="form-control"
                    name="agentCode"
                    defaultValue={defaultData?.agentCode}
                    placeholder="Enter agent code"
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Phone</label>
                  <input
                    type="tel"
                    className="form-control"
                    name="phone"
                    defaultValue={defaultData?.phone}
                    placeholder="Enter phone number"
                  />
                </div>
                {/* Add other fields as needed */}
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className="mb-3">
                  <label className="form-label">Address</label>
                  <input
                    type="text"
                    className="form-control"
                    name="address"
                    defaultValue={defaultData?.address}
                    placeholder="Enter address"
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">City</label>
                  <input
                    type="text"
                    className="form-control"
                    name="city"
                    defaultValue={defaultData?.city}
                    placeholder="Enter city"
                  />
                </div>
                {/* Add other fields as needed */}
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <label className="form-label">Post Code</label>
                  <input
                    type="text"
                    className="form-control"
                    name="postCode"
                    defaultValue={defaultData?.postCode}
                    placeholder="Enter post code"
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Transaction Limit</label>
                  <input
                    type="text"
                    className="form-control"
                    name="transactionLimit"
                    defaultValue={defaultData?.transectionLimit}
                    placeholder="Enter transaction limit"
                  />
                </div>
                {/* Add other fields as needed */}
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className="mb-3">
                  <label className="form-label">Country</label>
                  <input
                    type="text"
                    className="form-control"
                    name="country"
                    defaultValue={defaultData?.country}
                    placeholder="Enter country"
                  />
                </div>
                {/* Add other fields as needed */}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">
              Update
            </Button>
            <Button variant="secondary" onClick={handleCloseUpdateModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </form>
        {/* ))} */}
      </Modal>
    </>
  );
}

export default AgentsuperList;
