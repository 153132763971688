import React from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { useState, useEffect } from "react";
import Moment from 'moment';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from "react-paginate";

function AgentLogsList({ hadlerorg }) {

     let token = localStorage.getItem("organizationToken")
    const [pageCount, setpageCount] = useState('');

    let ID = localStorage.getItem('ID')
    const [data, setData] = useState([])


    const limit = 10;


    const formsave = (e, page) => {
        e.preventDefault();
        const data = new FormData(e.target);
        const Formvlaues = Object.fromEntries(data.entries());
        const formData = Formvlaues
        console.log('Formvlaues === ', Formvlaues);
        axios.post(`/v1/org/logs/get_agent_LogHistory`, formData, { headers: { "Authorization": `Bearer ${token}` } })
            .then(resp => {
                const data = resp.data.data;
                setData(data)
                const total = resp.data.totalPages
                console.log(total);
                const totalPage = (Math.ceil(total / limit));
                setpageCount(totalPage);

            })

    }



    const userlist = async () => {
        await axios.post(`/v1/org/logs/get_agent_LogHistory`, {}, { headers: { "Authorization": `Bearer ${token}` } })
            .then(resp => {
                // console.log(resp)
                const data = resp.data.data;
                setData(data)
                console.log('@@@@', data)
                const total = resp.data.totalPages
                console.log(total);
                const totalPage = (Math.ceil(total / limit));
                setpageCount(totalPage);
            })
    }
    useEffect(() => {
        userlist()
        hadlerorg()
    }, [])

    ///////////////pagenestion///////////////
    const fetchComments = async (page) => {
        const senData = { page: page }

        axios.post(`/v1/org/logs/get_agent_LogHistory`, senData, { headers: { "Authorization": `Bearer ${token}` } })
            .then(resp => {
                const data = resp.data.data;
                setData(data);
            })
        return data;
    };

    const handlePageClick = async (data) => {

        const page = data.selected + 1;
        const commentsFormServer = await fetchComments(page);
        setData(commentsFormServer);
    };

    /////////////unblocked user api call ////////////
    const handleunblocked = (_id) => {
        axios.put(`/UnBlockCustomer/${_id}`)
            .then(res => {
                if (res.status) {
                    let data = res.data;
                    toast.success(data.msg);
                    return userlist();
                }

            })
    }

    /////////////blocked user api call ////////////
    const handlesuspend = (_id) => {
        axios.put(`/BlockCustomer/${_id}`)
            .then(res => {
                if (res.status) {
                    let data = res.data;
                    toast.success(data.msg);
                    return userlist();
                }
            })
    }

    /////////////delete user api call ////////////
    const handledelete = (_id) => {
        axios.delete(`/Delete/${_id}`)
            .then(res => {
                if (res.status) {
                    let data = res.data;
                    toast.success(data.msg);
                    return userlist();
                }
            })
    }


    return (
        <>

            <ToastContainer />
            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">

                    <div className="container-xxl" >
                        <div className="row g-5 g-xl-8">
                            <div className="col-lg-12">
                                <div className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover" style={{ marginTop: '2rem' }}>
                                    <div className="card-body container-xxl pt-10 pb-8">
                                        <div className="d-flex align-items-center">
                                            <h1 className="fw-semibold me-3 text-white">Filter</h1>
                                            <span className="fw-semibold text-white opacity-50"> Agent Logs</span>
                                        </div>
                                        {/* <form onSubmit={(e) => formsave(e)}>
                                            <div className="d-flex flex-column">
                                                <div className="d-lg-flex align-lg-items-center">
                                                    <div className="rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body p-5  h-lg-60px me-lg-10 my-5">
                                                        <div className="row flex-grow-1 mb-5 mb-lg-0">
                                                           


                                                            <div className="col-lg-2 d-flex input-container align-items-center mb-3  mb-lg-0">
                                                                <label className="small_label">Start Date</label>
                                                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                                                    <i className="fad fa-calendar fs-2"></i>
                                                                </span>
                                                                <input type="date" className="form-control unstyled form-control-flush flex-grow-1" name="fromDate" placeholder="User Name.." />
                                                                <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5">
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-2 d-flex align-items-center mb-3 input-container mb-lg-0">
                                                                <label className="small_label">End Date</label>
                                                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                                                    <i className="fad fa-calendar fs-2"></i>
                                                                </span>
                                                                <input type="date" className="form-control unstyled form-control-flush flex-grow-1" name="toDate" placeholder="User Name.." />

                                                            </div>

                                                        </div>

                                                        <div className="min-w-150px text-end d-flex">
                                                            <button type="submit" className="btn btn-dark mr-3" id="kt_advanced_search_button_1">Search</button>
                                                            <button type="reset" onClick={userlist} className="btn btn-secondary" id="kt_advanced_search_button_1">Reset</button>
                                                        </div>

                                                    </div>



                                                </div>

                                            </div>
                                        </form> */}
                                            {/* <div className="d-flex flex-column">
                                                <div className="d-lg-flex align-lg-items-center">
                                                    <form >
                                                        <div className="rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body w-xxl-850px  me-lg-10 my-5">
                                                            <div className="row flex-grow-1 mb-5 mb-lg-0 h-lg-60px">
                                                                <div className="col-lg-6 d-flex input-container align-items-center mb-3  mb-lg-0">
                                                                    <label className="small_label">
                                                                        Start Date
                                                                    </label>
                                                                    <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                                                        <i className="fad fa-calendar fs-2"></i>
                                                                    </span>
                                                                    <input
                                                                        type="date"
                                                                        className="form-control unstyled form-control-flush flex-grow-1"
                                                                        name="fromDate"
                                                                        placeholder="User Name.."
                                                                    />
                                                                    <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                                                </div>

                                                                <div className="col-lg-6 d-flex align-items-center mb-3 input-container mb-lg-0">
                                                                    <label className="small_label">End Date</label>
                                                                    <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                                                        <i className="fad fa-calendar fs-2"></i>
                                                                    </span>
                                                                    <input
                                                                        type="date"
                                                                        className="form-control unstyled form-control-flush flex-grow-1"
                                                                        name="toDate"
                                                                        placeholder="User Name.."
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="min-w-150px text-center">
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-dark"
                                                                    id="kt_advanced_search_button_1"
                                                                >
                                                                    Search
                                                                </button>
                                                                <button
                                                                    type="reset"
                                                                    onClick={userlist}
                                                                    className="btn btn-secondary"
                                                                    id="kt_advanced_search_button_1"
                                                                >
                                                                    Reset
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div> */}

                                    </div>

                                </div>
                            </div>

                            <div className="col-lg-12">

                                <div className="card card-xxl-stretch mb-5 mb-xl-8">

                                    <div className="card-header border-0 pt-5">
                                        <h3 className="card-title align-items-start flex-column">
                                            <span className="card-label fw-bold fs-3 mb-1">Agent Logs</span>
                                            <span className="text-muted mt-1 fw-semibold fs-7"></span>
                                        </h3>

                                    </div>

                                    <div className="card-body py-3">

                                        <div className="table-responsive">

                                            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">

                                                <thead>

                                                    <tr className="fw-bold text-muted th-title">


                                                        {/* <th className="min-w-125px">D-ID</th>
                                                        <th className="min-w-125px">Name</th> */}
                                                        <th className="min-w-150px">Email Address</th>
                                                        {/* <th className="min-w-150px">Mobile No. </th> */}
                                                        {/* <th className="min-w-100px">DOB</th> */}
                                                        <th className="min-w-150px"> Date of Registration</th>
                                                        <th className="min-w-100px">Time</th>
                                                        <th className="min-w-100px ">Status</th>
                                                        {/* <th className="min-w-100px text-center">Actions</th> */}
                                                    </tr>
                                                </thead>

                                                <tbody>

                                                    {data.map((item) =>

                                                        <tr>
                                                            {/* <td>{item.ID}</td>
                                                        <td>{item.Name}</td> */}
                                                            <td>{item.Email}</td>
                                                            {/* <td><span>{item.phone}</span> </td> */}

                                                            <td>{item.Date}</td>
                                                            <td>{item.Time}</td>
                                                            <td><span className="badge badge-light-info fs-5">{item.status}</span></td>
                                                            {/* <td>
                                                            <div className="d-flex justify-content-end flex-shrink-0">
                                                             
                                                            {
                                                               item.blocked == '1' ? <> 
                                                               <button  onClick={(e) => { handleunblocked(item._id); }}
                                                               className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                                               data-bs-toggle="tooltip" data-bs-placement="top" 
                                                               data-bs-trigger="hover" title="Suspend ">
                                                               <span className="svg-icon svg-icon-3">
                                                                  <img src="/assets_new/images/blocked.png" width="20px" />
                                                               </span>
                                                               </button> 

                                                             </> : 
                                                               <><button  onClick={(e) => { handlesuspend(item._id); }}
                                                               className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                                               data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Password Reset "
                                                               data-bs-trigger="hover" title="Suspend ">
                                                               <span className="svg-icon svg-icon-3">
                                                               <img src="/assets_new/images/suspand.png" width="20px" />
                                                               </span>
                                                               </button></> 
                                                               }
                                                              
                                                                <button
                                                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                                    data-bs-trigger="hover" title="Password Reset ">
                                                                    <span className="svg-icon svg-icon-3">
                                                                        <i className="fas fa-repeat-alt"></i>
                                                                    </span>
                                                                </button>

                                                                <button
                                                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                                    data-bs-trigger="hover" title="View Detail">
                                                                    <span className="svg-icon svg-icon-3">
                                                                        <i className="fad fa-eye fs-4"></i>
                                                                    </span>

                                                                </button>
                                                                <button onClick={(e) => { handledelete(item._id); }}
                                                                    className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                                    data-bs-trigger="hover" title="Delete">
                                                                    <span className="svg-icon svg-icon-3">
                                                                        <i className="fad fa-trash-alt"></i>
                                                                    </span>

                                                                </button>
                                                                <button
                                                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                                    data-bs-trigger="hover" title="Disable OTP">
                                                                    <span className="svg-icon svg-icon-3">
                                                                        <i className="fad fa-eye-slash"></i>
                                                                    </span>

                                                                </button>
                                                            </div>
                                                        </td> */}
                                                        </tr>
                                                    )}

                                                    {/* <tr>
                                                        <td>##41e8bd3f3ca</td>
                                                        <td> Virendra Shilpkar </td>
                                                        <td> virendra@gmail.com </td>
                                                        <td><span>+221 123-456-7840</span> </td>
                                                        <td>05/06/1980</td>
                                                        <td>05/08/2020</td>
                                                        <td>Ethiopia</td>
                                                        <td>
                                                        <td><span className="badge badge-light-info fs-5">Verified</span></td> 
                                                        </td>
                                                        <td>
                                                            <div className="d-flex justify-content-end flex-shrink-0">
                                                                <button
                                                                    className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                                    data-bs-trigger="hover" title="Suspend ">
                                                                    <span className="svg-icon svg-icon-3">
                                                                        <i className="fad fa-minus-circle fs-4"></i>
                                                                    </span>
                                                                </a>
                                                                <button
                                                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                                    data-bs-trigger="hover" title="Password Reset ">
                                                                    <span className="svg-icon svg-icon-3">
                                                                        <i className="fas fa-repeat-alt"></i>
                                                                    </span>
                                                                </a>
                                                                <a href='organization_detail.html'
                                                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                                    data-bs-trigger="hover" title="View Detail">
                                                                    <span className="svg-icon svg-icon-3">
                                                                        <i className="fad fa-eye fs-4"></i>
                                                                    </span>

                                                                </a>
                                                                <a href='#'
                                                                    className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                                    data-bs-trigger="hover" title="Delete">
                                                                    <span className="svg-icon svg-icon-3">
                                                                        <i className="fad fa-trash-alt"></i>
                                                                    </span>

                                                                </a>
                                                                <a href='#'
                                                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                                                    data-bs-toggle="tooltip" data-bs-placement="top"
                                                                    data-bs-trigger="hover" title="Disable OTP">
                                                                    <span className="svg-icon svg-icon-3">
                                                                        <i className="fad fa-eye-slash"></i>
                                                                    </span>

                                                                </a>
                                                            </div>
                                                        </td>
                                                    </tr> */}


                                                </tbody>

                                            </table>

                                        </div>

                                        <div className="col-lg-12 mt-2 text-end">
                                            <ReactPaginate
                                                previousLabel={"Previous"}
                                                nextLabel={"Next"}
                                                breakLabel={"..."}
                                                pageCount={pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={2}
                                                onPageChange={handlePageClick}
                                                containerClassName={"pagination justify-content-end gap-2"}
                                                pageClassName={"page-item"}
                                                pageLinkClassName={"page-link"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link pagestyle"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link"}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                                activeClassName={"active"}
                                            />
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </>
    );
}

export default AgentLogsList