import React, { useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Jwt from "jsonwebtoken";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import Moment from "moment";
import CustomerLoader from "../../SuperAgent/CRM/Modal/CustomerLoder";

function RoleManagment({ hadleradmin }) {
  const navigate = useNavigate();
  const { _id } = useParams();
  const [data, setData] = useState([]);
  const [license, setLicense] = useState([]);
  const [Paymentprocess, setPaymentprocess] = useState(false);
  const [custom, setCustom] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [inputValue, setInputValue] = useState("");
  const focusPoint = useRef(null);
  const [pageCount, setpageCount] = useState("");
  const [typeId, settypeId] = useState(true);
  const [loanType, setLoanType] = useState("");
  const [loanInterestRate, setInterestRate] = useState("");
  const [mandatoryAmount, setMandatory] = useState([]);
  const [volantoryAmount, setVolantory] = useState([]);
  const [Blockhandler, setBlockhandler] = useState(false);
  const [Blockhandler1, setBlockhandler1] = useState(false);
  const [imagepassword, setimagepassword] = useState(-1);
  const [userinfo, setUserinfo] = useState([]);
  const fileInputRef = useRef(null);
  const fileInputRef2 = useRef(null);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [ShowLoader, setShowLoader] = useState(false);

  const fileInputRef1 = useRef(null);

  const currentFont = localStorage.getItem("liesenapply");
  let token = localStorage.getItem("SuperAgentChromepayToken");

  const updateRoleStatus = async (agentId, type, status) => {
    setShowLoader(true);
    console.log("Update successfulllllllll:", status);
    status = status == true ? false : true;
    axios
      .post("/v1/admin/update-role", {
        agentId: agentId,
        type: type,
        status: status,
      })
      .then((response) => {
        // console.log('Update successful:', response.data);
        let data = response.data;
        toast.success(data.msg);
        setShowLoader(false);
        Licenseslist();
        // Perform any additional actions you need
      })
      .catch((error) => {
        console.error("There was a problem updating the role:", error);
      });
  };

  let getSavingAccounts = () => {
    axios
      .post(
        "/v1/super-Agent/get-saving-Accs",
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let mandatoryAmount = resp.data.mandatoryAmount;
        let volantoryAmount = resp.data.volantoryAmount;

        setMandatory(mandatoryAmount);
        setVolantory(volantoryAmount);
      });
  };

  const addactive = () => {
    focusPoint.current.classList.toggle("processactive");
  };
  const focusPointpayment = useRef(null);
  const addactivepayment = () => {
    focusPoint.current.classList.toggle("processactivepayment");
  };

  const handleEditClick1 = () => {
    // Trigger file input click when edit icon is clicked
    fileInputRef1.current.click();
  };

  const AddFormData1 = async (e) => {
    e.preventDefault();

    let data = new FormData(e.target);
    let Formvlaues = Object.fromEntries(data.entries());
    let formData = Formvlaues;
    formData.typeId = typeId;

    console.log(Formvlaues);

    axios
      .post(`/v1/admin/update-role`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        const data = resp.data;
        console.log(data);
        if (data.status) {
          toast.success(data.msg);
          Licenseslist();
          settypeId(null);
          setShow1(false);
          //   return custumerDetails();
        } else {
          toast.error(data.msg);
        }
      });
  };

  const AddFormData2 = async (e) => {
    e.preventDefault();

    let data = new FormData(e.target);
    let Formvlaues = Object.fromEntries(data.entries());
    let formData = Formvlaues;
    formData.agentId = _id;

    console.log(Formvlaues);

    axios
      .post(`/v1/admin/update-role`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        const data = resp.data;
        console.log(data);
        if (data.status) {
          toast.success(data.msg);
          Licenseslist();

          setShow2(false);
          setShow1(false);

          //   return custumerDetails();
        } else {
          toast.error(data.msg);
        }
      });
  };

  const VerfiyBlock = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const FormValues = Object.fromEntries(data.entries());

    try {
      const response = await axios.post(
        `/v1/super-Agent/match-agent-pass`,
        FormValues,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setBlockhandler(false);
      let responseData = response.data; // Renamed variable
      console.log("+++++typeId+++++++", { typeId });

      if (responseData.status) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: `${responseData.message}`,
          showConfirmButton: false,
          timer: 3500,
        });
        if (typeId) {
          setShow2(true);
        } else {
          setShow1(true);
        }
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: `${responseData.message}`,
          showConfirmButton: false,
          timer: 3500,
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: `${error.response.data.message}`,
          showConfirmButton: false,
          timer: 3500,
        });
        setBlockhandler(false);
      }
    }
  };

  const VerfiyBlock1 = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const FormValues = Object.fromEntries(data.entries());

    try {
      const response = await axios.post(
        `/v1/super-Agent/match-agent-pass`,
        FormValues,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setBlockhandler1(false);
      let responseData = response.data; // Renamed variable
      console.log("++++++++++++", { responseData });

      if (responseData.status) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: `${responseData.message}`,
          showConfirmButton: false,
          timer: 3500,
        });
        if (imagepassword == 0) {
          return handleEditClick();
        } else if (imagepassword == 1) {
          return handleEditClick1();
        } else if (imagepassword == 2) {
          return handleEditClick2();
        } else if (imagepassword == 3) {
          setTimeout(() => {
            // setShow1(true);
            setShow2(true);
          }, 4000);
        } else {
          setTimeout(() => {
            handleShow(userinfo._id);
          }, 200);
        }
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: `${responseData.message}`,
          showConfirmButton: false,
          timer: 3500,
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: `${error.response.data.message}`,
          showConfirmButton: false,
          timer: 3500,
        });
        setBlockhandler1(false);
      }
    }
  };

  const options = [
    { value: "1000", label: "1K" },
    { value: "10000", label: "10K" },
    { value: "50000", label: "50K" },
    { value: "100000", label: "100K" },
    { value: "500000", label: "500K" },
    { value: "1000000", label: "1M" },
    { value: "2000000", label: "2M" },
    { value: "5000000", label: "5M" },
    { value: "option2", label: "Custom" },
  ];

  const Paymentprocessbank = async () => {
    await axios
      .post(
        `/v1/org/dash/LicenseProcess`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data.status;
        setPaymentprocess(data);
        console.log("+++++++++++++++++++", data);
      });
  };

  const handleEditClick = () => {
    // Trigger file input click when edit icon is clicked
    fileInputRef.current.click();
  };

  const handleEditClick2 = () => {
    // Trigger file input click when edit icon is clicked
    fileInputRef2.current.click();
  };

  function handleShow(_id) {
    console.log("idddd", _id);
    setShow1(true);
  }

  useEffect(() => {
    Paymentprocessbank();
    getSavingAccounts();
    if (currentFont) {
      addactive();
    }
  }, []);
  useEffect(() => {
    if (Paymentprocess) {
      addactivepayment();
    }
  }, [Paymentprocess]);
  useEffect(() => {
    hadleradmin();
    userlist();
  }, []);

  console.log("jjjjjjjjjjjjjjjjjjj", Paymentprocess);

  const AddLicense = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    console.log("@@@" + Formvlaues.Licenses);
    axios
      .post(`/v1/super-Agent/send-request`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        if (resp.status) {
          let data = resp.data;
          if (data.status) {
            Swal.fire("SuccessFully!", data.msg, "success");
            toast.success();

            e.target.reset();
            return Licenseslist();
          } else {
            toast.error(data.msg);
          }
        } else {
          toast.error(data.msg);
        }
      });
  };

  const userlist = () => {
    axios
      .post(
        `/v1/org/dash/detail`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data;

        setLicense(data);
      });
  };

  const limit = 5;

  const formsave = (e, page) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    console.log("Formvlaues === ", Formvlaues);
    axios
      .post(
        `/v1/admin/get-roles`,
        { agentId: _id },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        const data = resp.data.data;
        setData(data);
        const total = resp.data.totalRow;
        console.log({ data });
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
      });
  };
  const Licenseslist = async () => {
    await axios
      .post(
        `/v1/admin/get-roles`,
        { agentId: _id },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        const data = resp.data.data;
        setData(data);
        console.log(data);
        const total = resp.data.totalRow;
        console.log(total);
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
      });
  };
  useEffect(() => {
    Licenseslist();
    hadleradmin();
  }, []);
  const fetchComments = async (page) => {
    const senData = { page: page };
    // const cosole = Fromvalue;
    // console.log(Fromvalue);
    axios
      .post(`/v1/admin/get-roles`, senData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        const data = resp.data.data;
        setData(data);
      });
    return data;
  };

  const handlePageClick = async (data) => {
    // console.log(data.selected);
    const page = data.selected + 1;
    const commentsFormServer = await fetchComments(page);
    setData(commentsFormServer);
  };
  return (
    <>
      <div>
        <ToastContainer />

        <CustomerLoader loader={ShowLoader} />

        <div
          className="wrapper d-flex flex-column flex-row-fluid"
          id="kt_wrapper"
        >
          <div
            className="content d-flex flex-column flex-column-fluid"
            id="kt_content"
          >
            <div className="container-xxl">
              <div className="row g-5 g-xl-8"></div>
              <div className="col-lg-12">
                <div className="row g-5 g-xl-8">
                  <div className="col-lg-12">
                    <div
                      className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                      style={{ marginTop: "2rem" }}
                    >
                      <div className="card-body container-xxl pt-10 pb-8">
                        <div className="d-flex align-items-center">
                          <h1 className="fw-semibold me-3 text-white">
                            ROLE'S LIST
                          </h1>
                          <span className="fw-semibold text-white opacity-50">
                            {" "}
                          </span>
                        </div>

                        {/* <div className="d-flex flex-column">
                                                    <div className="d-lg-flex align-lg-items-center">
                                                        <form onSubmit={(e) => formsave(e)}>
                                                            <div className="d-flex flex-column">
                                                                <div className="d-lg-flex align-lg-items-center">
                                                                    <div className="rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body p-5  h-lg-60px me-lg-10 my-5">
                                                                        <div className="row flex-grow-1 mb-5 mb-lg-0">
                                                                            

                                                                            <div className="col-lg-4 d-flex align-items-center mb-5 mb-lg-0">
                                                                                <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                                                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                                                                    <svg
                                                                                        width="24"
                                                                                        height="24"
                                                                                        viewBox="0 0 24 24"
                                                                                        fill="none"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                    >
                                                                                        <rect
                                                                                            x="2"
                                                                                            y="2"
                                                                                            width="9"
                                                                                            height="9"
                                                                                            rx="2"
                                                                                            fill="currentColor"
                                                                                        />
                                                                                        <rect
                                                                                            opacity="0.3"
                                                                                            x="13"
                                                                                            y="2"
                                                                                            width="9"
                                                                                            height="9"
                                                                                            rx="2"
                                                                                            fill="currentColor"
                                                                                        />
                                                                                        <rect
                                                                                            opacity="0.3"
                                                                                            x="13"
                                                                                            y="13"
                                                                                            width="9"
                                                                                            height="9"
                                                                                            rx="2"
                                                                                            fill="currentColor"
                                                                                        />
                                                                                        <rect
                                                                                            opacity="0.3"
                                                                                            x="2"
                                                                                            y="13"
                                                                                            width="9"
                                                                                            height="9"
                                                                                            rx="2"
                                                                                            fill="currentColor"
                                                                                        />
                                                                                    </svg>
                                                                                </span>
                                                                                <select
                                                                                    name="status"
                                                                                    className="form-select border-0 flex-grow-1"
                                                                                    data-control="select2"
                                                                                    data-placeholder="Status"
                                                                                    data-hide-search="true"
                                                                                >
                                                                                    <option value="" selected disabled>
                                                                                        Status
                                                                                    </option>
                                                                                    <option value="PENDING">Pending</option>
                                                                                    <option value="PASS">Approved</option>
                                                                                    <option value="PASS">Pass</option>
                                                                                </select>
                                                                            </div>

                                                                            <div className="col-lg-4 d-flex input-container align-items-center mb-3  mb-lg-0">
                                                                                <label className="small_label">
                                                                                    Start Date
                                                                                </label>
                                                                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                                                                    <i className="fad fa-calendar fs-2"></i>
                                                                                </span>
                                                                                <input
                                                                                    type="date"
                                                                                    className="form-control unstyled form-control-flush flex-grow-1"
                                                                                    name="fromDate"
                                                                                    placeholder="Date"
                                                                                />
                                                                                <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                                                            </div>

                                                                            <div className="col-lg-4 d-flex align-items-center mb-3 input-container mb-lg-0">
                                                                                <label className="small_label">End Date</label>
                                                                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                                                                    <i className="fad fa-calendar fs-2"></i>
                                                                                </span>
                                                                                <input
                                                                                    type="date"
                                                                                    className="form-control unstyled form-control-flush flex-grow-1"
                                                                                    name="toDate"
                                                                                    placeholder="Date"
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="min-w-150px text-end d-flex">
                                                                            <button
                                                                                type="submit"
                                                                                className="btn btn-dark mr-3"
                                                                                id="kt_advanced_search_button_1"
                                                                            >
                                                                                Search
                                                                            </button>
                                                                            <button
                                                                                type="reset"
                                                                                onClick={Licenseslist}
                                                                                className="btn btn-secondary"
                                                                                id="kt_advanced_search_button_1"
                                                                            >
                                                                                Reset
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div> */}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="card card-xxl-stretch mb-5 mb-xl-5">
                      {/* <div className='d-flex align-item center justify-content-end'>
                                                <button onClick={(e) => {
                                                    setBlockhandler(true)
                                                }} type="button" className="btn btn-primary ms-3 " style={{ marginRight: "40px", marginTop: "30px" }}>Add Role</button>
                                            </div> */}
                      <div className="card-header border-0 pt-5">
                        <h3 className="card-title align-items-start flex-column">
                          <span className="card-label fw-bold fs-3 mb-1"></span>
                          <span className="text-muted mt-1 fw-semibold fs-7"></span>
                        </h3>
                      </div>

                      <div className="card-body py-3">
                        <div className="table-responsive">
                          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                            <thead>
                              <tr className="fw-bold text-muted th-title">
                                <th className="min-w-125px">Icon</th>
                                <th className="min-w-150px">Type</th>
                                <th className="min-w-150px"> Last Update</th>
                                <th className="min-w-100px">Status</th>
                              </tr>
                            </thead>

                            <tbody>
                              {data.map((item) => (
                                <tr key={item._id}>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <div className="symbol symbol-45px me-5">
                                        <img
                                          src="https://chromepaybucket.blr1.digitaloceanspaces.com/DID/5146077.png"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    {item.type === "buyingAndSelling"
                                      ? "Buying & Selling"
                                      : item.type}
                                  </td>
                                  <td>
                                    {Moment(item.updatedAt).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </td>
                                  <td>
                                    <div className="form-check form-switch">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={`toggle-${item._id}`}
                                        checked={item.status} // Assuming isActive indicates the current status of the role
                                        onChange={() =>
                                          updateRoleStatus(
                                            item.agentId,
                                            item.type,
                                            item.status
                                          )
                                        }
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`toggle-${item._id}`}
                                      >
                                        {item.status ? "Active" : "Inactive"}
                                      </label>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>

                        <div className="col-lg-12 mt-2 text-end">
                          <ReactPaginate
                            PreviousLabel={"Previous"}
                            NextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName={
                              "pagination justify-content-end"
                            }
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            PreviousClassName={"page-item mx-2"}
                            PreviousLinkClassName={"page-link pagestyle"}
                            NextClassName={"page-item"}
                            NextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="modal-center"
        show={Blockhandler}
        onHide={() => setBlockhandler(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Verify Password</Modal.Title>
        </Modal.Header>
        <form onSubmit={VerfiyBlock}>
          <Modal.Body>
            <div className="col-lg-12 ">
              <div className="row">
                <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                  {" "}
                  Password
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type="password"
                    name="password"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Enter password"
                  />
                </div>
              </div>
              {/* <div className="row mt-3">
                        <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                          Amount
                        </label>
                        <div className="col-lg-8 fv-row">
                          <input
                            type="text"
                            name="amount"
                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                            placeholder="Amount"
                          />
                        </div>
                      </div> */}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit" className="center">
              {" "}
              Submit
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal
        show={show1}
        onHide={() => setShow1(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Loan</Modal.Title>
        </Modal.Header>
        <form onSubmit={(e) => AddFormData1(e)}>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-6 mt-3">
                <label className="mb-2">Loan Type</label>
                <input
                  className="form-control"
                  placeholder="Loan Type"
                  type="text"
                  name="loanType"
                  defaultValue={loanType}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 mt-3 ">
                <label className=" fs-6">
                  <span className="">Interest Rate</span>
                </label>

                <input
                  type="number"
                  name="interestRate"
                  defaultValue={loanInterestRate}
                  pattern="[0-9]{10}"
                  title=" Only number allow  Ten digits code"
                  required
                  className="form-control form-control-lg form-control-solid"
                  placeholder="Interest Rate"
                  step="any"
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow1(false)}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              {" "}
              Save Changes
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal
        show={show2}
        onHide={() => setShow2(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Role</Modal.Title>
        </Modal.Header>
        <form onSubmit={(e) => AddFormData2(e)}>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-6 mt-3">
                <label className="mb-2">Role Type</label>
                <input
                  className="form-control"
                  placeholder="Role Type"
                  type="text"
                  name="type"
                />
              </div>

              <div className="col-lg-6 mt-3">
                <label className="mb-2">Status</label>
                <select className="form-select" name="status">
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </select>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow1(false)}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              {" "}
              Submit
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      {/* added new progess bar  */}
    </>
  );
}

export default RoleManagment;
