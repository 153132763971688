import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import CustomerLoader from "../../SuperAgent/CRM/Modal/CustomerLoder";

const GraphComponent = ({ id }) => {
  const [frequency, setFrequency] = useState("month");
  const [cumulative, setCumulative] = useState("cumulative");

  const [JdcFilter, setJdcFilter] = useState("all"); // Default to saving data
  const [genderFilter, setJdcGender] = useState("");
  const [transmonths, setTransMonths] = useState([]);
  const [transvalue, setTransValue] = useState([]);
  const [fromDate, setFromDate] = useState(""); // State for start date
  const [toDate, setToDate] = useState(""); // State for end date
  const [JdcProfession, setJdcProfession] = useState([]);
  const [ShowLoader, setShowLoader] = useState(true);

  let token = localStorage.getItem("SuperAgentChromepayToken");
  if (!token) {
    token = localStorage.getItem("ChromePayAdmitToken"); //ChromePayAdmitToken
  }
  // Fetch data from API
  useEffect(() => {
    setShowLoader(true);
    const fetchData = () => {
      let body = {
        Type: frequency,
        category: JdcFilter,
        gender: genderFilter,
        fromDate: fromDate,
        toDate: toDate,
        agentId: id,
        format: JdcFilter === 'all' ?  'nonCumulative' : cumulative,
      };
      axios
        .post(`/v1/super-Agent/get_admin_transaction_data_graph`, body, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          const userData = res.data;

          console.log("graph data", { userData });

          const savingTransactions = res.data.savingTransactions;
          const loanTransactions = res.data.loanTransactions;
          const shareTransactions = res.data.shareTransactions;
          const storeTransactions = res.data.storeTransactions;

          // Data for chart

          // Conditionally show loan or saving data
          if (JdcFilter === "loan") {
            let loanTransactionsData = loanTransactions.map(
              (item) => item.totalAmount
            );
            setTransValue(loanTransactionsData);
            const months = loanTransactions.map((item) => item.value);
            setTransMonths(months);
          } else if (JdcFilter === "share") {
            let shareTransactionsData = shareTransactions.map(
              (item) => item.totalAmount
            );
            setTransValue(shareTransactionsData);
            const months = shareTransactions.map((item) => item.value);
            setTransMonths(months);
          } else if (JdcFilter === "store") {
            let storeTransactionsData = storeTransactions.map(
              (item) => item.totalAmount
            );

            setTransValue(storeTransactionsData);

            const months = storeTransactions.map((item) => item.value);
            setTransMonths(months);
          } else if (JdcFilter === "saving") {
            let savingTransactionsData = savingTransactions.map(
              (item) => item.totalAmount
            );
            setTransValue(savingTransactionsData);
            const months = savingTransactions.map((item) => item.value);
            setTransMonths(months);

            // console.log({ months, transactionData })
          } else {
            if (cumulative === "noncumulative") {
              console.log("test-status",{cumulative})
              const totalAmountsByMonth = {};

              // Function to add totalAmount for each month
              const addTransactionTotals = (transactions) => {
                transactions.forEach(({ month, value, totalAmount }) => {
                  if (!totalAmountsByMonth[month]) {
                    totalAmountsByMonth[month] = {
                      monthName: value,
                      totalAmount: 0,
                    };
                  }
                  totalAmountsByMonth[month].totalAmount += totalAmount;
                });
              };

              // Process each category of transactions
              addTransactionTotals(userData?.loanTransactions || []);
              addTransactionTotals(userData?.savingTransactions || []);
              addTransactionTotals(userData?.shareTransactions || []);
              addTransactionTotals(userData?.storeTransactions || []);

              // Filter out months with a totalAmount of 0 and prepare data for display
              const filteredMonths = Object.keys(totalAmountsByMonth)
                .filter((month) => totalAmountsByMonth[month].totalAmount > 0) // Exclude months with 0 totalAmount
                .sort((a, b) => a - b) // Sort by month number
                .map((month) => totalAmountsByMonth[month]);

              const totalAmountsArray = filteredMonths.map(
                (item) => item.totalAmount
              );
              const months = filteredMonths.map((item) => item.monthName);

              console.log("Filtered and sorted data:", {
                months,
                totalAmountsArray,
                totalAmountsByMonth,
              });

              // Set state with filtered and sorted data
              setTransValue(totalAmountsArray);
              setTransMonths(months);
            } else if(cumulative === 'cumulative') {

              const totalAmountsByMonth = {};

              // Function to add totalAmount for each month
              const addTransactionTotals = (transactions) => {
                transactions.forEach(({ month, value, totalAmount }) => {
                  if (!totalAmountsByMonth[month]) {
                    totalAmountsByMonth[month] = {
                      monthName: value,
                      totalAmount: 0,
                    };
                  }
                  totalAmountsByMonth[month].totalAmount += totalAmount;
                });
              };

              // Process each category of transactions
              addTransactionTotals(userData?.loanTransactions || []);
              addTransactionTotals(userData?.savingTransactions || []);
              addTransactionTotals(userData?.shareTransactions || []);
              addTransactionTotals(userData?.storeTransactions || []);

              // Filter out months with a totalAmount of 0 and prepare data for display
              const filteredMonths = Object.keys(totalAmountsByMonth)
                .filter((month) => totalAmountsByMonth[month].totalAmount > 0) // Exclude months with 0 totalAmount
                .sort((a, b) => a - b) // Sort by month number
                .map((month) => totalAmountsByMonth[month]);

              const totalAmountsArray = filteredMonths.map(
                (item) => item.totalAmount
              );
              const months = filteredMonths.map((item) => item.monthName);

              console.log("Filtered and sorted data non cumulative:", {
                months,
                totalAmountsArray,
                totalAmountsByMonth,
              });



              function convertToCumulative(array) {
                const cumulativeArray = [];
                let cumulativeTotal = 0;
              
                array.forEach((value) => {
                  cumulativeTotal += value;
                  cumulativeArray.push(cumulativeTotal);
                });
              
                return cumulativeArray;
              }
              
              // Example usage:
              const originalArray =totalAmountsArray
              const cumulativeArray = convertToCumulative(originalArray);
              
              console.log("Cumulative Array:", cumulativeArray);
              

              // Set state with filtered and sorted data
              setTransValue(cumulativeArray);
              setTransMonths(months);
            }
          }

          setShowLoader(false);
        });
    };

    fetchData();
  }, [frequency, JdcFilter, genderFilter, fromDate, toDate, cumulative]);

  // Handle date input changes
  const handleFromDateChange = (e) => setFromDate(e.target.value);
  const handleToDateChange = (e) => setToDate(e.target.value);

  const handleResetDID = () => {
    setToDate("");
    setFromDate("");
    setJdcGender("");
    setJdcFilter("");
    setFrequency("month");
  };

  return (
    <div className="col-lg-12 mt-5">
      <CustomerLoader loader={ShowLoader} />

      <div className="card card-flush h-xl-100">
        <div className="card-header row pt-7">
          <h3 className="card-title col-md-4 align-items-start flex-column">
            <span className="card-label fw-bold text-gray-800">
              Transaction Activity
            </span>
            <span className="text-gray-400 mt-1 fw-semibold fs-6"></span>
          </h3>
          <div className="row mb-4 align-items-center">
            <div className="col-md-3">
              <form>
                <div class="row mb-6">
                  <label class="col-lg-4 col-form-label  fw-semibold fs-6">
                    Cumulative
                  </label>
                  <div class="col-lg-8 fv-row">
                    <div class="d-flex align-items-center mt-3">
                      <label class="form-check form-check-inline form-check-solid me-5">
                        <input
                          class="form-check-input"
                          name="format"
                          type="radio"
                          value="cumulative"
                          defaultChecked
                          onChange={(e) => setCumulative(e.target.value)}
                        />
                        <span class="fw-semibold ps-2 fs-6">True</span>
                      </label>

                      <label class="form-check form-check-inline form-check-solid">
                        <input
                          class="form-check-input"
                          name="format"
                          type="radio"
                          value="noncumulative"
                          onChange={(e) => setCumulative(e.target.value)}
                        />
                        <span class="fw-semibold ps-2 fs-6">False</span>
                      </label>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            {/* Frequency Filter */}
            <div className="col-md-3">
              <form>
                <div className="d-flex align-items-center gap-2">
                  <select
                    value={frequency}
                    onChange={(e) => setFrequency(e.target.value)}
                    className="form-select graph-select shadow-sm"
                  >
                    {/* <option value="day">Day</option> */}
                    <option value="week">Week</option>
                    <option value="month">Monthly</option>
                    <option value="year">Yearly</option>
                  </select>
                </div>
              </form>
            </div>

            {/* Data Filter (Loan or Saving) */}
            <div className="col-md-3">
              <form>
                <div className="d-flex align-items-center gap-2">
                  <select
                    value={JdcFilter}
                    onChange={(e) => setJdcFilter(e.target.value)}
                    className="form-select graph-select shadow-sm"
                  >
                    <option value="all">All</option>
                    <option value="saving">Saving</option>
                    <option value="loan">Loan</option>
                    <option value="share">Share</option>
                    <option value="store">Store</option>
                  </select>
                </div>
              </form>
            </div>

            {/* Date Range Filter */}
            <div className="rounded d-flex flex-column flex-lg-row mend align-items-lg-center bg-body p-5 h-lg-60px me-lg-10 my-5">
              <div className="row  justify-content-end    flex-grow-1 mb-5 mb-lg-0">
                <div className="col-md-2 d-flex ps-1 input-container align-items-center mb-3 mb-lg-0">
                  <label className="small_label my-label">Start Date</label>
                  <span className="svg-icon svg-icon-1 svg-icon-gray-400 ">
                    <i className="fad fa-calendar"></i>
                  </span>
                  <input
                    type="date"
                    className="form-control unstyled form-control-flush ps-0 flex-grow-1 my-input"
                    value={fromDate}
                    onChange={handleFromDateChange}
                  />
                  <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                </div>

                <div className="col-md-3 d-flex ps-1 align-items-center mb-3 input-container mb-lg-0">
                  <label className="small_label my-label">End Date</label>
                  <span className="svg-icon svg-icon-1 svg-icon-gray-400 ">
                    <i className="fad fa-calendar"></i>
                  </span>
                  <input
                    type="date"
                    className="form-control unstyled form-control-flush flex-grow-1 p-0 my-input"
                    value={toDate}
                    onChange={handleToDateChange}
                  />
                </div>

                <div className="col-md-2 d-flex align-items-center mb-3 justify-content-start ps-1 input-container mb-lg-0">
                  <button
                    type="reset"
                    onClick={() => handleResetDID()}
                    className="btn btn-light btn-active-light-primary"
                    id="kt_advanced_search_button_1"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Chart Section */}
        <div className="bg-white overflow-auto">
          <Chart
            type="area"
            width={"100%"}
            height={400}
            series={[
              {
                name: JdcFilter === "loan" ? "Loan Amount" : "Saving Amount",
                data: transvalue,
              },
            ]}
            options={{
              plotOptions: {
                bar: {
                  borderRadius: 2,
                  columnWidth: 50,
                },
              },
              markers: {
                size: 0,
              },
              stroke: {
                width: 3,
                curve: "smooth",
              },
              colors: ["#3c7f8c"],
              theme: { mode: "light" },
              xaxis: {
                tickPlacement: "on",
                categories: transmonths,
                title: {
                  style: { color: "#f90000", fontSize: 0 },
                },
              },
              yaxis: {
                labels: {
                  formatter: (val) => `${val}`,
                },
              },
              legend: {
                show: true,
                position: "right",
              },
              dataLabels: {
                formatter: (val) => `${val}`,
                style: {
                  colors: ["#3c7f8c"],
                  fontSize: 15,
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default GraphComponent;
