import React from "react";
import {  useParams } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useState, } from "react";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
var jwt = require("jsonwebtoken");

function AddRoles({hadleradmin}) {
  const ID = localStorage.getItem("ID");
  //   console.log(ID);

  let token = localStorage.getItem("ChromePayAdmitToken");
  var decode1 = jwt.decode(token);
  let adminID = decode1.admminID;
  const { _id } = useParams("");
 
  const AddFormData = async (e) => {
   
  
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    Object.keys(Formvlaues).forEach((key,) => {
          
      Formvlaues[key] = Formvlaues[key]==="on"? 1:Formvlaues[key]
      Formvlaues[key] = Formvlaues[key]==="off"? 0:Formvlaues[key]
      console.log("empolylistdata.....",Formvlaues)
    });
  
    
    console.log(Formvlaues);
    const { subAdminID } = { subAdminID: _id };
    axios
      .post(`/v1/admin/addsubadminrole/${adminID}/${subAdminID}`, Formvlaues,{ headers: {"Authorization" : `Bearer ${token}`}})

      .then((resp) => {
        if (resp.status) {
          let data = resp.data;
          if (data.status) {
            // window.location="/agent-list";
            toast.success(data.msg);

            // e.target.reset();
          } else {
            toast.error(data.msg);
          }
        } else {
          toast.error(data.msg);
        }
      });
  };

  useEffect(()=>{
    hadleradmin();
  },[])
  return (
    <>
      <ToastContainer />
    
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl" id="kt_content_container">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div className="card mb-5 mb-xl-10">
                  <div
                    className="card-header border-0 cursor-pointer"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_account_profile_details"
                    aria-expanded="true"
                    aria-controls="kt_account_profile_details"
                  >
                    <div className="card-title m-0">
                      <h3 className="fw-bold m-0">Add Roles</h3>
                    </div>
                  </div>
                  <div
                    id="kt_account_settings_profile_details"
                    className="collapse show"
                  >
                    <form
                      onSubmit={(e) => AddFormData(e)}
                      id="kt_account_profile_details_form"
                      className="form"
                    >
                      <div className="card-body border-top p-9">
                        <div className="mb-6">
                          <h6 className="required fw-semibold fs-66">
                            Customer Role
                          </h6>
                        </div>

                        <div className="row">
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Add Customer
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="addCustomer"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                        
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Edit Customer
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="editCustomer"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                               
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Approve D-ID
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="approveDID"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                View Customer
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="viewCustomer"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                             
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Block Customer
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="blockCustomer"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                               
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                              Unblock Customer
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="unBlockCustomer"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                             
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Delete Customer
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="deleteCustomer"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                              
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                               Set Customer OTP Limit
                               
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="setcustomerOtpLimit"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                             
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Set Customer Password Limit
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="setCustomerPasswordLimit"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Update Customer
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="updateCustomer"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                              
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="mb-6 ">
                          <h6 className="required fw-semibold fs-66">
                          Organisation Role
                          </h6>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Add Organisation
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="addOrganisation"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Block Organisation
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="blockorganisation"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                              
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Unblock Organisation
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="unBlockOrganisation"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                               
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Delete Organisation
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="deleteOrganisation"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                              
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Set Organisation OTP Limit
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="setOrgOptLimit"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                               
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Set Organisation Password Limit
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="setOrgPasswordLimit"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                               
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Update Organisation
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="updateOrganisation"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                               
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mb-6 ">
                          <h6 className="required fw-semibold fs-66">
                            Agent Role
                          </h6>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Add Agent
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="addAgent"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                     
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Block Agent
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="blockAgent"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                              
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Unblock Agent
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="unBlockAgent"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                               
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Delete Agent
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="deleteAgent"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Set Agent Otp Limit
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="setagentOtpLimit"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Set Agent Password Limit
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="setagentPasswordLimit"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                               
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Update Agent
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="updateAgent"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                             
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mb-6 ">
                          <h6 className="required fw-semibold fs-66">
                            IP Role
                          </h6>
                        </div>

                        <div className="row">
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                IP Black Listing
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="IPblackListing"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                IP White Listing
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="IPwhiteListing"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                              
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mb-6 ">
                          <h6 className="required fw-semibold fs-66">
                            Sub Admin
                          </h6>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Add Sub Admin
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="addSubAdmin"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Block Sub Admin
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="blockSubAdmin"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                                
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Unblock Sub Admin
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="unBlockSubAdmin"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                               
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Delete Sub Admin
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="deleteSubAdmin"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                             
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Update Sub Agent
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="updateSubAgent"
                                  role="switch"
                                  id="flexSwitchCheckDefault"
                              
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button
                          type="submit"
                          className="btn btn-light btn-active-light-primary me-2"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddRoles;
