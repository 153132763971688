import React from "react";

export default function CustomerLoader({ loader }) {
  if (!loader) return null;

  return (
    <div style={styles.overlay}>
      <div style={styles.loaderContainer}>
        <div style={styles.spinner}></div>
        <img
          src="/assets_new/images/logo.png"
          // src="/assets_new/images/logo.png"
          alt="Loading..."
          style={styles.image}
        />
      </div>
    </div>
  );
}

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
  },
  loaderContainer: {
    position: "relative",
    width: "100px",
    height: "100px",
  },
  spinner: {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "100px",
    height: "100px",
    margin: "-50px 0 0 -50px", // Adjust to center the spinner
    border: "8px solid #f3f3f3",
    borderRadius: "50%",
    borderTop: "8px solid #3498db",
    animation: "spin 2s linear infinite",
  },
  image: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "50%",
  },
};

// Add the keyframes for the spinner animation
const styleSheet = document.styleSheets[0];
const keyframes = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;
styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
