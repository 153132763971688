import React from "react";
import { Link } from "react-router-dom";
import Jwt from "jsonwebtoken";
import axios from "axios";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, NavLink } from "react-router-dom";
<script src="assets/js/scripts.bundle.js"></script>
function MainDashboard() {

  const SaveTokenSuperAgent = () => {
    localStorage.setItem("SuperAgentChromepayToken", 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImRlbW9AY2hyb21lcGF5LmlvIiwiYWdlbnRJZCI6IjY1ZDQ3YTRjMTBhZTY3ZGZmZGM5MWNjOCIsIm9yZ0lkIjoiNjVkM2I2MTM0YjAzZTYxZTI1YTRmYTYwIiwicGhvbmUiOjg5ODI4NDQ1OCwiaWF0IjoxNzE0NTgzOTM2fQ.P-pJPBZhZWGc9TKGnoBTOhPHjqBHbyyr98b6OuPrGNg')
    window.location.href = "/Super-agent-dashbord"
  }
  //SaveTokenOrganization
  const SaveTokenOrganization = () => {
    localStorage.setItem("organizationToken", 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJPcmdhbmlzYXRpb25JRCI6IjY1ZDNiNjEzNGIwM2U2MWUyNWE0ZmE2MCIsIm5hbWUiOiJEZW1vIENQIiwiZW1haWwiOiJkZW1vQGNocm9tZXBheS5pbyIsInBob25lIjo3Nzc5MTgxOTEsImFjY2Vzc0tleUlkIjoiNkxZTXU1UzVPbFlCa004S0lrdkYiLCJzZWNyZXRBY2Nlc3NLZXkiOiJlcnhHZ2MvTVV5dkNyNnlsI3lHZUpMTyNWYktVbC8iLCJpYXQiOjE3MTQ1ODQ0MzZ9.LougK_HD37bBybd6JeYASqWAlVCgvIlyDkPucaRhlHE')
    window.location.href = "/organization"
  }


  return (
    <div>
      {/* Header */}
      <div
        id="kt_header"
        className="header align-items-stretch"
        style={{
          // marginRight: "80px",
          background: "linear-gradient(135deg, #008080, #00CED1)",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)"
        }}
      >
        <div className="container-fluid ">
          
          {/* Logo */}
          <div className="d-flex align-items-center justify-content-center">
            <Link to="/organization">
             
               <h1 className="text-white fw-bold my-2 fs-3" style={{fontSize: "100px" }}>
                <img
                  src="https://chromepay.s3.ap-south-1.amazonaws.com/abc/logo.png"
                  alt="Logo"
                  className="me-3"
                  style={{ height: "50px" }}
                />
                Welcome To Chrome!
              </h1>
            </Link>
          </div>


      {/* Main Content */}
        <div className="row gap-3 align-items-end justify-content-center" style={{marginTop:"5rem"}}>
          {/* Agent Login */}
          <div className="col-md-5 col-12 rounded-3" style={{ background: "linear-gradient(135deg, #008080, #00CED1)" }}>
            <NavLink to="#" onClick={SaveTokenSuperAgent} className="text-decoration-none">
              <div className="text-center mb-5 ">
                <div className="relative animation-bank bg-opacity-70 rounded-3 p-6">
                  <div className="symbol symbol-50px mx-auto mb-3">
                    <span className="symbol-label">
                      <span className="svg-icon svg-icon-3x svg-icon-primary">
                        <i className="fas fa-user"></i>
                      </span>
                    </span>
                  </div>
                  <div className="m-0">
                    <span className="text-white fw-bolder d-block fs-3 lh-1 ls-n1 mb-1">
                      Agent Login
                    </span>
                    <span className="text-white fw-semibold fs-6">
                      Click here to login as an agent
                    </span>
                  </div>
                </div>
              </div>
            </NavLink>
          </div>

          {/* Website */}
          <div className="col-md-5 col-12 rounded-3" style={{ background: "linear-gradient(135deg, #008080, #00CED1)"}}>
            <NavLink to="https://chromepay.io/admin_ui/" className="text-decoration-none">
              <div className="text-center mb-5">
                <div className="relative animation-bank bg-opacity-70 rounded-3 p-6">
                  <div className="symbol symbol-50px mx-auto mb-3">
                    <span className="symbol-label">
                      <span className="svg-icon svg-icon-3x svg-icon-primary">
                        <i className="fas fa-globe"></i>
                      </span>
                    </span>
                  </div>
                  <div className="m-0">
                    <span className="text-white fw-bolder d-block fs-3 lh-1 ls-n1 mb-1">
                    Financial Services
                    </span>
                    <span className="text-white fw-semibold fs-6">
                      Visit our website
                    </span>
                  </div>
                </div>
              </div>
            </NavLink>
          </div>

          {/* Organization Login */}
          <div className="col-md-5 col-12 rounded-3" style={{ background: "linear-gradient(135deg, #008080, #00CED1)" }}>
            <NavLink href="#" onClick={SaveTokenOrganization} className="text-decoration-none">
              <div className="text-center mb-5">
                <div className="relative animation-bank bg-opacity-70 rounded-3 p-6">
                  <div className="symbol symbol-50px mx-auto mb-3">
                    <span className="symbol-label">
                      <span className="svg-icon svg-icon-3x svg-icon-primary">
                        <i className="fas fa-building"></i>
                      </span>
                    </span>
                  </div>
                  <div className="m-0">
                    <span className="text-white fw-bolder d-block fs-3 lh-1 ls-n1 mb-1">
                      Organization Login
                    </span>
                    <span className="text-white fw-semibold fs-6">
                      Click here to login as an organization
                    </span>
                  </div>
                </div>
              </div>
            </NavLink>
          </div>
        </div>
    </div>
    </div>
    </div>

  );





}

export default MainDashboard