





import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const AgentPrivatedRouting = () => {
  const isAuthenticated = !!localStorage.getItem("CromepayAgentToken"); // or use your own authentication logic
  const location = useLocation();

  return isAuthenticated ? <Outlet /> : <Navigate to="/login-agent"  />;
};
export default AgentPrivatedRouting;
