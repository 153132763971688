import axios from "axios";
import React, { useState, useEffect } from "react";

import 'react-toastify/dist/ReactToastify.css'; 

var jwt = require("jsonwebtoken");


function AgentAdminList() {
  return (
    <div>AgentAdminList</div>
  )
}

export default AgentAdminList