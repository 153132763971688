import React from "react";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AutoAddress from "../../Mapcomponents/AutoAddress";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

function RegisterCustomer({ hadlersuper }) {
  const navigate = useNavigate();
  const [residances, setresidance] = useState("");
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
    let token = localStorage.getItem("SuperAgentChromepayToken");

  //console.log("@" + token)
  const handleClick = () => {
    const latInnerLat = document.getElementById("lat").innerHTML;
    console.log(latInnerLat);
    setLat(latInnerLat);
    const latInnerLong = document.getElementById("lon").innerHTML;
    console.log(latInnerLong);
    setLong(latInnerLong);
  };

  useEffect(() => {
    hadlersuper();
  }, []);


  const handleresidance = (data) => {


      let token = localStorage.getItem("SuperAgentChromepayToken");
    console.log("image data", data)
    let formdata = new FormData();
    formdata.append('IDphoto', data)

    console.log("image first datav ", formdata)
    axios.post(`/v1/DID/ImageUploader`, formdata, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        const imgurl = res.data.data
        console.log(" imagr url ", imgurl)
        setresidance(imgurl)




      })

  }

  const AddFormData = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());

    console.log(Formvlaues);
    let dataToSend2 = new FormData();
    dataToSend2.append("IDphoto", residances);
    dataToSend2.append("fullname", Formvlaues.fullname);
    dataToSend2.append("dateOfBirth", Formvlaues.dateOfBirth);
    // dataToSend2.append("phone", Formvlaues.phone.trim());
    dataToSend2.append("phone", Formvlaues.phone);
    dataToSend2.append("email", Formvlaues.email);
    dataToSend2.append("gender", Formvlaues.gender);
    dataToSend2.append("nationality", Formvlaues.nationality);
    dataToSend2.append("professoin", Formvlaues.professoin);
    dataToSend2.append("address", Formvlaues.address);

    dataToSend2.append("NextFOKinName", Formvlaues.NextFOKinName);
    dataToSend2.append("NextFOKniPhone", Formvlaues.NextFOKniPhone);

    dataToSend2.append("Latitude", Formvlaues.Latitude);
    dataToSend2.append("Longitude", Formvlaues.Longitude);


    localStorage.setItem("phone", Formvlaues.phone);
    // console.log("pushpak", Formvlaues.phone);

    axios.post(`/v1/super-Agent/registerDID1`, dataToSend2, { headers: { "Authorization": `Bearer ${token}` } })
      .then((res) => {
        if (res.status) {
          let data = res.data;
          console.log("first data ", data)
          if (data.status) {
            toast.success(data.msg);
            // setTimeout(() => {

            navigate("/Super-agent-Customer2")
            //  }, 3000);

          } else {
            toast.error(data.msg);
          }
        } else {
          toast.error(data.msg);
        }
      });

  };


  return (
    <>
      <ToastContainer position="top-right" />
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div class="container-xxl" >

            <div class="row g-5 g-xl-8">
              <div class="col-lg-12">

                <div class="card mb-5 mb-xl-10">

                  <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
                    <div class="card-title m-0">
                      <h3 class="fw-bold m-0">Add Customer</h3>
                    </div>

                  </div>


                  <div id="kt_account_settings_profile_details" class="collapse show">

                    <form onSubmit={(e) => AddFormData(e)}>

                      <div class="card-body border-top p-9">

                        <div class="row mb-6">

                          <label class="col-lg-4 col-form-label fw-semibold fs-6">ID Photo</label>


                          <div class="col-lg-8">

                            <div class="image-input image-input-outline" data-kt-image-input="true">

                              <div class="image-input-wrapper w-125px h-125px" ></div>
                              {/* //////////map input filed hidden */}
                              <input
                                type="hidden"
                                name="Latitude"
                                value={lat}
                              />
                              <input
                                type="hidden"
                                name="Longitude"
                                value={long}
                              />
                              {/* ////////// end map input filed hidden */}

                              <label class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="Upload Image">
                                <i class="bi bi-pencil-fill fs-7"></i>

                                <input type="file" name="IDphoto" onChange={(e) => handleresidance(e.target.files[0])} />
                                {/* <input type="hidden" name="avatar_remove" /> */}

                              </label>


                              <span class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="cancel" data-bs-toggle="tooltip" title="Cancel avatar">
                                <i class="bi bi-x fs-2"></i>
                              </span>

                              <span class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow" data-kt-image-input-action="remove" data-bs-toggle="tooltip" title="Remove avatar">
                                <i class="bi bi-x fs-2"></i>
                              </span>

                            </div>

                            <div class="form-text">Allowed file types: png, jpg, jpeg.</div>

                          </div>

                        </div>


                        <div class="row mb-6">

                          <label class="col-lg-4 col-form-label required fw-semibold fs-6">Full Name</label>
                          <div class="col-lg-8">
                            <div class="col-lg-8 fv-row">
                              <input type="text" name="fullname" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Full Name" />
                            </div>
                          </div>

                        </div>


                        <div class="row mb-6">

                          <label class="col-lg-4 col-form-label required fw-semibold fs-6">Date of Birth</label>


                          <div class="col-lg-8 fv-row">
                            <input type="date" name="dateOfBirth" class="form-control form-control-lg form-control-solid position-relative" />
                          </div>

                        </div>


                        <div class="row mb-6">

                          <label class="col-lg-4 col-form-label fw-semibold fs-6">
                            <span class="required">Contact No.</span>
                            <i class="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="Phone number must be active"></i>
                          </label>


                          <div class="col-lg-8 fv-row">
                            <input type="number" name="phone" class="form-control form-control-lg form-control-solid" placeholder="Contact No." />
                            {/* <PhoneInput
                              country={"eg"}
                              enableSearch={true}
                              inputProps={{
                                name: "phone",
                                required: true,
                                placeholder: "Enter phone number",
                              }}
                              className="form-control form-control-lg form-control-solid"
                              placeholder="Phone number"
                            /> */}
                          </div>
                        </div>


                        <div class="row mb-6">
                          <label class="col-lg-4 col-form-label fw-semibold fs-6">Email Address</label>
                          <div class="col-lg-8 fv-row">
                            <input type="email" name="email" class="form-control form-control-lg form-control-solid" placeholder="Email Address" />
                          </div>
                        </div>

                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                            Gender
                          </label>
                          <div className="col-lg-8 fv-row">
                            <div className="d-flex align-items-center mt-3">
                              <label className="form-check form-check-inline form-check-solid me-5">
                                <input
                                  className="form-check-input"
                                  required
                                  name="gender"
                                  type="radio"
                                  value="Male"
                                />
                                <span className="fw-semibold ps-2 fs-6">
                                  Male
                                </span>
                              </label>
                              <label className="form-check form-check-inline form-check-solid">
                                <input
                                  className="form-check-input"
                                  required
                                  name="gender"
                                  type="radio"
                                  value="Fmale"
                                />
                                <span className="fw-semibold ps-2 fs-6">
                                  Female
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>


                        <div class="row mb-6">
                          <label class="col-lg-4 col-form-label fw-semibold fs-6">Nationality</label>
                          <div class="col-lg-8 fv-row">
                            <input type="text" name="nationality" class="form-control form-control-lg form-control-solid" placeholder="Nationality" />
                          </div>
                        </div>

                        <div class="row mb-6">
                          <label class="col-lg-4 col-form-label fw-semibold fs-6">Profession</label>
                          <div class="col-lg-8 fv-row">
                            <input type="text" name="professoin" class="form-control form-control-lg form-control-solid" placeholder="Profession" />
                          </div>
                        </div>


                        <AutoAddress style={{ width: "100% !important" }} />
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                            Next Of Kin Name
                          </label>

                          <div className="col-lg-8 fv-row">
                            <input
                              type="text"
                              required
                              name="NextFOKinName"
                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                              placeholder="Next Of Kin Name"
                            />
                          </div>
                        </div>
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            <span className="">Next Of Kin Phone</span>
                            {/* <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="Phone number must be active"></i> */}
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="number"
                              name="NextFOKniPhone"
                              pattern="[0-9]{10}"
                              title=" Only number allow  Ten digits code"
                              required
                              className="form-control form-control-lg form-control-solid"
                              placeholder="Next Of Kin Number"
                            />
                          </div>
                        </div>




                      </div>
                      <div class="card-footer d-flex justify-content-end py-6 px-9">
                        {/* <button type="reset" class="btn btn-light btn-active-light-primary me-2">Reset</button> */}
                        <button type="submit" onClick={handleClick} class="btn btn-primary" id="kt_account_profile_details_submit">Next</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RegisterCustomer