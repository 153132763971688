import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

function Otp_login() {

  const navigate = useNavigate();

  const [OTP, setOtp] = useState("");

  let ID = localStorage.getItem("ID");

  // setTimeout(function() { logout(); }, 20000)

  const otpLogin = async (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    console.log(Formvlaues);

    axios.post(`/v1/admin/otpVerificationAdmin/${ID}`, formData).then((resp) => {
      const data = resp.data;
      console.log(data);
      if (data.status) {
        toast.success(data.msg);
        localStorage.setItem("token", data.token);
        localStorage.setItem("ID", data.ID);

        navigate("/admin");
      } else {
        toast.error(data.msg);
      }
    });
  };

  async function loginOtp() {
    let data = { OTP };
    console.log(data);
    let result = await fetch(`/otpVerificationAdmin/${ID}`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    result = await result.json();
    if (result.status) {
      console.log(result.token);

      localStorage.setItem("token", result.token);
      localStorage.setItem("ID", result.ID);
      navigate("admin");
      // history.push ('admin');
      toast.success(result.msg);
    } else {
      toast.error(result.msg);
    }
  }

  return (
    <div>
      <ToastContainer position="top-right" />
      <section className="bg-gradient position-relative h-100vh p-0">
        <div className="home-table">
          <div className="home-table-center">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-5 mt-5">
                  <div className="text-center"></div>
                  <div className="account_box bg-gradient">
                    <div className="text-center">
                      <a
                        href="https://chromepay.io/"
                        className="d-inline-block"
                      >
                        <img
                          src="assets/img/logo.png"
                          alt=""
                          className="img-fluid mx-auto d-block login-logo boot"
                        />
                      </a>
                    </div>
                    {/* <h5>Operator Enter OTP</h5> */}
                    <p id="errors" style={{ color: "red" }}>
                      {" "}
                    </p>
                    <form onSubmit={(e) => otpLogin(e)}>
                      <div className="col-lg-12 mt-5">
                        <label className="mb-2">Enter OTP</label>
                        <input
                          className="form-control"
                          placeholder="Enter your Otp"
                          type="number"
                          name="OTP"
                        />
                      </div>

                      <div className="col-lg-12 mt-5 mb-5 bottom-space">
                        <button
                          type="submit"
                          className="btn ripple btn-success w-100 mt-3"
                        >
                          Validate OTP
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Otp_login;
