import React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import Sidebar from '../Sidebar';
import Header from '../Header';
import { error } from 'jquery';

function OtpVerifyCutomer({hadlersuper}) {

  const navigate = useNavigate();
  let token = localStorage.getItem("SuperAgentChromepayToken");
  const phone = localStorage.getItem('phone');

 let update = phone.replace('-','')
 let update1 = update.replace('+','')
 let  modifynumber = update1.replace(' ','');

      
    const OtpFormData = async (e) =>{
        e.preventDefault();

        const data = new FormData(e.target);
        const FormValues = Object.fromEntries(data.entries());
        const formData = FormValues;
        console.log(FormValues);
     
        axios.post(`/v1/super-Agent/verifyOTP_demo`,formData,{ headers: {"Authorization" : `Bearer ${token}`} })
        .then(resp=>{
         if(resp.status){
           let data =resp.data
           if(data.status){
             toast.success(data.msg);
           navigate( "/Super-agent/customer-list");
           }else{
             toast.error(data.msg)
           }
         }else{
           toast.error(data.msg)
         }
        })

        
  }
console.log("phonecontact",modifynumber)
  const Resendotp = ()=>{
axios.post(`/Resend_otp/${modifynumber}`)
.then(resp=>{
  let data = resp.data;
  console.log(data)
  if(data.status){
    toast.success(data.msg);
    localStorage.removeItem("userviewphoto")
    localStorage.removeItem("userphoto")
  }else{
    toast.error(data.msg)
  }

}).catch(console.error())

  }

  useEffect(()=>{
    hadlersuper();
  },[])
   return (
       <div>
           <ToastContainer position="top-right"  />
         
           <section className="bg-gradient position-relative h-100vh p-0" style={{marginTop:"3rem"}}>
     <div className="home-table">
       <div className="home-table-center">
         <div className="container">
           <div className="row justify-content-center">
             <div className="col-lg-5 mt-5">
               <div className="text-center">
               </div>
               <div className="account_box bg-gradient">
                 <div className="text-center">
                   <a href="https://chromepay.io/" className="d-inline-block">
                     <img src="assets/img/logo.png" alt="" className="img-fluid mx-auto d-block login-logo boot" style={{width:"200px"}}/>
                   </a>
                 </div>
                 {/* <h5>Operator Enter OTP</h5> */}
                 <p id = 'errors' style = {{"color":"red"}} > </p>
                 <form onSubmit={(e) => OtpFormData(e)}>
                   <div className="col-lg-12 mt-5">
                     <label className="mb-2">OTP</label>
                     <input className="form-control" placeholder="Enter your Otp" type="number" name="OTP"  />
                   </div>
                   <div className="col-lg-12 mt-5">
                     <label className="mb-2">Phone</label>
                     <input className="form-control" placeholder="Enter your Phone" type="text" name="phoneNo" value={phone}  />
                   </div>
                   <div className="col-lg-12 mt-2">
                     <label className="offset-9 mb-2 me-auto fs-5 cursor-pointer " onClick={()=>Resendotp()}>Resent OTP</label>
                     </div>
                   <div className="col-lg-12 mt-2 mb-5 bottom-space">
                     <button type="submit" className="btn ripple btn-success w-100 mt-3" >Validate OTP</button>
                   </div>
                 </form>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
   </section> 
   
       </div>
   );
      
}  




export default OtpVerifyCutomer