import React from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";

var jwt = require("jsonwebtoken");

const SuperAgentLogin = ({hadlersuper}) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  useEffect(() => {
    hadlersuper();
    const auth = localStorage.getItem("token");
    if (auth) {
      ///  history.push("/organization")
    }
    document.body.className = "header-none header-fixed header-tablet-and-mobile-fixed aside-fixed aside-secondary-disabled";
    return () => {
        document.body.className = "header-fixed header-tablet-and-mobile-fixed aside-fixed aside-secondary-disabled";
       }
  });

  const [email, setEmail] = useState("");
  const [password, setPass] = useState("");
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [error, setError] = useState(null);


  const fetchLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const lat = position.coords.latitude;
          const lon = position.coords.longitude;
          
          // Update state with the user's location
          setLocation({ latitude: lat, longitude: lon, address: '' });
          setError(null); // Clear any previous errors

          // Fetch address using reverse geocoding
          try {
            const response = await fetch(`https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lon}&format=json`);
            const data = await response.json();
            const address = data.address ? `${data.address.city || ''}, ${data.address.country || ''}` : 'Address not found';
            setLocation(prevLocation => ({ ...prevLocation, address }));
          } catch (error) {
            setError('Failed to fetch address.');
          }
        },
        (err) => {
          // Update state with the error message
          setError(err.message);
        }
      );
    } else {
      setError('Geolocation is not supported by this browser.');
    }}

  // Use useEffect to fetch the location when the component mounts
  useEffect(() => {
    fetchLocation();
  }, []);

  const login = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
   
    axios.post(`/v1/super-Agent/Login`, {...formData, location}).then((res) => {
      let result = res.data;
      console.log({result});
      if (result.status) {
        const orgType = result.organizationType.orgType;
        localStorage.setItem("SuperAgentChromepayToken", result.data);
        localStorage.setItem("superAgentRoles", JSON.stringify(result.roles));
        localStorage.setItem("superLoginRole", result?.role);
        localStorage.setItem("orgType",orgType);
      
        toast.success(result.msg);
        //  navigate('/Super-agent-dashbord');
        window.location="/Super-agent-dashbord";
        //hadlerSuper();
    
      } else {
        toast.error(result.msg);
      }
    });
  };
  //-------model---------------///
  function handleShow() {
    setShow(true);
  }

  const AddFormData = async (e) => {
    e.preventDefault();
      let token = localStorage.getItem("SuperAgentChromepayToken");
    var decode1 = jwt.decode(token);
    let agentID = decode1.agentID;

    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    console.log(Formvlaues);

    axios.post(`/v1/auth/ForgotPassword`, formData).then((resp) => {
      const data = resp.data;
      console.log(data);
      if (data.status) {
        toast.success(data.msg);
   
      } else {
        toast.error(data.msg);
      }
    });
  };



  const AddFormData2 = async (e) => {
    e.preventDefault();
    let token = localStorage.getItem("SuperAgentChromepayToken");
    var decode1 = jwt.decode(token);
    let agentID = decode1.agentID;

    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    console.log(Formvlaues);

    axios.post(`/v1/auth/ForgetPassVerifyOtp`, formData).then((resp) => {
      const data = resp.data;
      console.log(data);
      if (data.status) {
        toast.success(data.msg);
         setShow1(true)
      } else {
        toast.error(data.msg);
      }
    });
  };

  ///-------------------------------------////

  const AddFormData1 = async (e) => {
    e.preventDefault();
      let token = localStorage.getItem("SuperAgentChromepayToken");
    var decode1 = jwt.decode(token);
    let agentID = decode1.agentID;

    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    console.log(Formvlaues);

    axios.put(`/v1/auth/ForgotchangePass`, formData).then((resp) => {
      const data = resp.data;
      console.log(data);
      if (data.status) {
        toast.success(data.msg);
      } else {
        toast.error(data.msg);
      }
    });
  };

  return (
    <div>
      <section className="bg-gradient position-relative h-100vh p-0">
        <div className="home-table">
          <div className="home-table-center">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-5">
                  <div className="text-center"></div>
                  <div className="account_box bg-gradient">
                    <div className="text-center">
                      <div className="formdetail">
                        <a
                          href="https://chromepay.io/"
                          className="d-inline-block"
                        >
                         <img
                            src="assets_new/images/logo.png"
                            alt=""
                            className="mx-auto d-block login-logo"
                          />
                        </a>
                      </div>
                    </div>
                    {/* <h5>Operator Login</h5> */}
                    <p id="errors" style={{ color: "red" }}>
                      {" "}
                    </p>

                    <form onSubmit={login}>
                      <div className="col-lg-12 ">
                        <label className="mb-2">Email</label>
                        <input
                          className="form-control"
                          placeholder="Enter your email"
                          name="userName"
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="col-lg-12 mt-3 ">
                        <label className="mb-2">Password</label>
                        <input
                          className="form-control"
                          placeholder="Enter your password"
                          name="password"
                          type="password"
                          value={password}
                          onChange={(e) => setPass(e.target.value)}
                        />
                      </div>
                      <div className="col-lg-12 ">
                        <div className="text-end mt-3">
                          <h6>
                            <a href="#" onClick={() => handleShow()}>
                              Forgot Password ?
                            </a>
                          </h6>
                        </div>
                      </div>
                      <div className="col-lg-12 mt-5">
                        <button
                          type="submit"
                          className="btn ripple btn-success w-100 mt-3"
                        >
                          Sign In
                        </button>
                      </div>
                      <div></div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer position="top-right" />

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Recover Password </Modal.Title>
        </Modal.Header>
        <form onSubmit={(e) => AddFormData(e)}>
          <Modal.Body>
            <div className="col-lg-12 ">
              <label className="mb-2">Email</label>
              <input
                className="form-control"
                placeholder="Enter your email"
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow(false)}>
              Close
            </Button>
            <Button
              variant="primary"
              type="Submit"
              onClick={() => [setShow(false), setShow2(true)]}
            >
            
              Save Changes
            </Button>
          </Modal.Footer>
        </form>
      </Modal>






      <Modal show={show2} onHide={() => setShow2(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Recover Password </Modal.Title>
        </Modal.Header>
        <form onSubmit={(e) => AddFormData2(e)}>
          <Modal.Body>

          <div className="col-lg-12 ">
              <label className="mb-2">Email</label>
              <input
                className="form-control"
                placeholder="Enter your email"
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>


            <div className="col-lg-12 ">
              <label className="mb-2">OTP</label>
              <input
                className="form-control"
                placeholder="Enter your OTP"
                type="number"
                name="otp"
             
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow(false)}>
              Close
            </Button>
            <Button
              variant="primary"
              type="Submit"
              onClick={() => [setShow(false),setShow2(false)]}
            >
            
          Otp 
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      {/* //--------------------------- */}
      <Modal show={show1} onHide={() => setShow1(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Password Reset</Modal.Title>
        </Modal.Header>
        <form onSubmit={(e) => AddFormData1(e)}>
          <Modal.Body>
            <div className="col-lg-12 ">
              <label className="mb-2">Email</label>
              <input
                className="form-control"
                placeholder="Enter your email"
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="col-lg-12 mt-3 ">
              <label className="mb-2" style={{ float: "left" }}>
                New Password
              </label>
              <input
                className="form-control"
                type="password"
                placeholder="Enter your password"
                name="newPassword"
              />
            </div>
            <div className="col-lg-12 mt-3 ">
              <label className="mb-2" style={{ float: "left" }}>
                {" "}
                Confirm Password
              </label>
              <input
                className="form-control"
                type="password"
                placeholder="Enter your password"
                name="confirmPassword"
              />
            </div>
         
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow1(false)}>
              Close
            </Button>
            <Button
              variant="primary"
              type="submit"
              onClick={() => setShow1(false)}
            >
              {" "}
              Save Changes
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};




export default SuperAgentLogin