import axios from "axios";
import React, { useState, useEffect } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

function SuperAgentAddLoan({ hadlerorg }) {
    const navigate = useNavigate();
  const { _id } = useParams();
   let token = localStorage.getItem("organizationToken");

  const [loanlist, setloanlist] = useState([]);

  const Submitloanapply = (event) => {
    event.preventDefault();

    const data = new FormData(event.target);
    const Formvlaues = Object.fromEntries(data.entries());
    console.log("Formvlaues", Formvlaues);
    axios
      .post(`v1/org/CRM/add-loan-superAgent`, Formvlaues, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((resp) => {
        let data = resp.data;
        console.log("data ", data);
        if (data.status) {
          toast.success(data.msg);
          setTimeout(() => {
            navigate("/superagent/loan-list")
          }, 2000);
       
        } else {
          toast.error(data.msg);
        }
      })
      .catch(console.error());
  };

  useEffect(() => {
    hadlerorg();
  }, []);
  return (
    <>
      <ToastContainer position="top-right" />
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl" >
         
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div className="card mb-5 mb-xl-10">
                  <div
                    className="card-header border-0 cursor-pointer"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_account_profile_details"
                    aria-expanded="true"
                    aria-controls="kt_account_profile_details"
                  >
                    <div className="card-title m-0">
                    <div className="row my-5">
                        <label className="col-lg-12 col-form-label  fw-semibold fs-6">
                          {" "}
                          <i
                            class="fas fa-sack-dollar"
                            style={{ fontSize: "2rem" }}
                          ></i>{" "}
                          <span
                            style={{ fontSize: "2rem", marginLeft: "1rem" }}
                          >
                            {" "}
                            Add Loan{" "}
                          </span>{" "}
                        </label>

                        
                      </div>
                    </div>
                    
                  </div>

                  <div
                    id="kt_account_settings_profile_details"
                    className="collapse show"
                  >
                    
                    <form onSubmit={Submitloanapply}>
                      <div className="card-body border-top p-9">
                        <input
                          name="agentId"
                          defaultValue={_id}
                          style={{ display: "none" }}
                        />

                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                            Loan Type
                          </label>

                          <div className="col-lg-8 fv-row">
                            <select
                              className="form-select"
                              name="loanType"
                              aria-label="Default select example"
                            >
                              <option selected>Select Loan Type</option>
                              <option value="Money">Money</option>
                              <option value="Fertilizer">Fertilizer</option>
                              <option value="Chemicals">Chemicals</option>
                              <option value="Goods">Goods</option>
                            </select>
                          </div>
                        </div>

                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                            interest
                          </label>

                          <div className="col-lg-8 fv-row">
                            <select
                              className="form-select"
                              name="interest"
                              aria-label="Default select example"
                            >
                              <option selected>Select interest</option>
                              <option value="1">1%</option>
                              <option value="2">2%</option>
                              <option value="3">3%</option>
                              <option value="4">4%</option>
                              <option value="5">5%</option>
                              <option value="6">6%</option>
                              <option value="7">7%</option>
                              <option value="8">8%</option>
                              <option value="9">9%</option>
                              <option value="10">10%</option>
                              <option value="11">11%</option>
                              <option value="12">12%</option>
                              <option value="13">13%</option>
                              <option value="14">14%</option>
                              <option value="15">15%</option>
                              <option value="16">16%</option>
                              <option value="17">17%</option>
                              <option value="18">18%</option>
                              <option value="19">19%</option>
                              <option value="20">20%</option>
                            </select>
                          </div>
                        </div>

                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                            {" "}
                            Number Of EMI
                          </label>

                          <div className="col-lg-8 fv-row">
                            <select
                              className="form-select"
                              name="noOfEmis"
                              aria-label="Default select example"
                            >
                              <option selected>Number Of EMI</option>
                              <option value="10">1</option>
                              <option value="10">2</option>
                              <option value="10">3</option>
                              <option value="10">4</option>
                              <option value="10">5</option>
                              <option value="10">6</option>
                              <option value="10">7</option>
                              <option value="10">8</option>
                              <option value="10">9</option>
                              <option value="10">10</option>
                              <option value="10">11</option>
                              <option value="10">12</option>
                              <option value="10">13</option>
                              <option value="10">14</option>
                              <option value="10">15</option>
                              <option value="10">16</option>
                              <option value="10">17</option>
                              <option value="10">18</option>
                              <option value="10">19</option>
                              <option value="10">20</option>
                              <option value="10">21</option>
                              <option value="10">22</option>
                              <option value="10">23</option>
                              <option value="10">24</option>
                              <option value="10">25</option>
                              <option value="10">26</option>
                              <option value="10">27</option>
                              <option value="10">28</option>
                              <option value="10">29</option>
                              <option value="10">30</option>
                              <option value="10">31</option>
                              <option value="10">32</option>
                              <option value="10">33</option>
                              <option value="10">34</option>
                              <option value="10">35</option>
                              <option value="10">36</option>
                              <option value="10">37</option>
                              <option value="10">38</option>
                              <option value="10">39</option>
                              <option value="10">40</option>
                              <option value="10">41</option>
                              <option value="10">42</option>
                              <option value="10">43</option>
                              <option value="10">44</option>
                              <option value="10">45</option>
                              <option value="10">46</option>
                              <option value="10">47</option>
                              <option value="10">48</option>
                            </select>
                          </div>
                        </div>

                        
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                            {" "}
                          EMI Type
                          </label>

                          <div className="col-lg-8 fv-row">
                            <select
                              className="form-select"
                              name="emiType"
                              aria-label="Default select example"
                            >
                              <option selected>Select  EMI Type</option>
                              <option value="Monthly">Monthly</option>
                            </select>
                          </div>
                        </div>
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                            {" "}
                            Loan Amount
                          </label>

                          <div className="col-lg-8 fv-row">
                            {/* <select
                              className="form-select"
                              name="Loan Amount"
                              aria-label="Default select example"
                            >
                              <option selected>Select loan Amount</option>
                              <option value="30000">30000</option>
                            </select> */}
                              <input type="number" name="loanAmount" class="form-control form-control-lg form-control-solid" placeholder="Enter Loan Amount" />
                          </div>
                        </div>
                      </div>
                      <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button type="Submit" className="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}



export default SuperAgentAddLoan
