import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: false,
}

export const Drawer = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    Loginuser: (state) => {
    
      state.value = true
    },
    logout: (state) => {
      state.value = false
    },
 
  },
})

// Action creators are generated for each case reducer function
export const { Loginuser, logout } = Drawer.actions

export default Drawer.reducer