import axios from "axios";
import React, { useState, useEffect } from "react";

import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "moment";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
var jwt = require("jsonwebtoken");

const BlockedIPs = ({hadleradmin}) => {
    const [selectedOption, setSelectedOption] = useState('');
    const [inputValue, setInputValue] = useState('');
    const navigate = useNavigate();
  console.log("selectedOption"+selectedOption,inputValue)
    const [data, setData] = useState([]);
    const [pageCount, setpageCount] = useState("");
    const [menu, setMenu] = useState([]);
    const [total, settotal] = useState([]);
    const [Query, setQuery] = useState("");
    const [show, setShow] = useState(false);
    const [orgId, setOrgID] = useState("");
    const limit = 10;
  
    let token = localStorage.getItem("ChromePayAdmitToken");
    var decode1 = jwt.decode(token);
    let adminID = decode1.admminID;
    function handleShow(orgId) {
      setShow(true);
      setOrgID(orgId)
      // console.log("orgId"+orgId)
  
    }
    
    const options = [
      { value: '1000', label: '1K' },
      { value: '10000', label: '10K' },
      { value: '50000', label: '50K' },
      { value: '100000', label: '100K' },
      { value: '500000', label: '500K' },
      { value: '1000000', label: '1M' },
      { value: '2000000', label: '2M' },
      { value: '5000000', label: '5M' },
      { value: 'option2', label: 'Custom' },
      
    ];



    const handleununblocked = (IP) => {
        axios.post(`/v1/super-Agent/un-block-ip`, {IP},{ headers: {"Authorization" : `Bearer ${token}`}}).then((resp) => {
            if (resp.status) {
              const data = resp.data;
              if (data.status) {
                toast.success(data.msg);
                return userlist();
              } else {
                toast.error(data.msg);
              }
            } else {
              toast.error(data.msg);
            }
      
            // setData(data);
            console.warn(data);
      
            const total = resp.data.totlaRow;
            console.log(total);
            const totalPage = Math.ceil(total / limit);
            setpageCount(totalPage);
          })
    };
  
    const AddFormData = async (e, page) => {
      e.preventDefault();
      const data = new FormData(e.target);
      const Formvlaues = Object.fromEntries(data.entries());
      const formData = Formvlaues;
      console.log(Formvlaues);
      axios.post(`/v1/super-Agent/get-block-ips`, formData, { headers: { "Authorization": `Bearer ${token}` } }).then((resp) => {
        const data = resp.data.data;
        setData(data);
  
        const total = resp.data.totalRow;
        console.log(total);
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
      });
    };
  
    const userlist = async () => {
      await axios.post(`/v1/super-Agent/get-block-ips`, {}, { headers: { "Authorization": `Bearer ${token}` } }).then((resp) => {
        const data = resp.data.data;
        setData(data);
        console.log("CustomerList >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> userlist data show", data)
  
        const total = resp.data.totalRow;
        console.log(total);
        settotal(total)
        console.warn("CustomerList", data);
        const totalPage = Math.ceil(total / limit);
        setpageCount(totalPage);
  
      });
    };
    useEffect(() => {
      userlist();
    }, []);
  
    ///////////////pagenestion///////////////
    const fetchComments = async (page) => {
      const senData = { page: page };
      // const cosole = Fromvalue;
      // console.log(Fromvalue);
      axios.post(`/v1/super-Agent/get-block-ips`, senData, { headers: { "Authorization": `Bearer ${token}` } }).then((resp) => {
        const data = resp.data.data;
  
        setData(data);
      });
      return data;
    };
  
    const handlePageClick = async (data) => {
      // console.log(data.selected);
      const page = data.selected + 1;
      const commentsFormServer = await fetchComments(page);
      setData(commentsFormServer);
    };
  
    /////////////// unblocked  ////////////////////////
    const handleunblocked = (_id) => {
      console.warn(_id);
      axios.put(`/v1/admin/Unsuspendcustomer/${_id}/${adminID}`).then((res) => {
        if (res.status) {
          let data = res.data;
          toast.success(data.msg);
          return userlist();
        }
      });
    };
  
    /////////////////delete api call /////////////////
    const handlesuspend = (_id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to Suspend selected Customer ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Suspend it!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios.put(`/v1/admin/suspendcustomer/${_id}/${adminID}`).then((res) => {
            if (res.status) {
              let data = res.data;
              if (data.status) {
                Swal.fire("Suspend!", "Customer Suspend successfully", "success");
                return userlist();
              } else {
                toast.error(data.msg);
              }
            } else {
              toast.error(data.msg);
            }
          });
        }
      });
    };
  
    /////////////////delete api call /////////////////
    const handledelete = (_id) => {
      Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to delete selected Customer ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios.delete(`/v1/admin/deleteCustomer/${_id}/${adminID}`).then((res) => {
            if (res.status) {
              let data = res.data;
              if (data.status) {
                Swal.fire("Deleted!", "Customer deleted successfully", "success");
                return userlist();
              } else {
                toast.error(data.msg);
              }
            } else {
              toast.error(data.msg);
            }
          });
        }
      });
    };
    const handleView = (_id) => {
      console.log(_id);
      navigate(`/customer-view-admin/${_id}`);
      return false;
    };
  
    const handleVerfiy = (_id) => {
      const { custID } = { custID: _id };
      Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to verify selected Customer ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, verify it!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios.post(`/v1/admin/customerVerify/${custID}/${adminID}`).then((res) => {
            if (res.status) {
              let data = res.data;
              if (data.status) {
                Swal.fire(
                  "verified!",
                  "Customer verified successfully",
                  "success"
                );
                return userlist();
              } else {
                toast.error(data.msg);
              }
            } else {
              toast.error(data.msg);
            }
          });
        }
      });
    };
  
    const subAdminlist = async () => {
      await axios.post(`v1/admin/subAdminRole/${adminID}`, {}, { headers: { "Authorization": `Bearer ${token}` } }).then((resp) => {
        let data = resp.data.find;
        // console.log('@@@@@',data.customer.addCustomer)
        setMenu(data.customer);
      });
    };
  
    const AddLicense = (e) => {
      const orgID = orgId
      // console.log("@!@!"+ orgID)
      e.preventDefault();
      const data = new FormData(e.target);
      const Formvlaues = Object.fromEntries(data.entries());
      const formData = Formvlaues;
      console.log("@@@" + Formvlaues.Licenses)
      axios.post(`/v1/admin/add_Licenses/${orgID}`, formData, { headers: { "Authorization": `Bearer ${token}` } })
        .then(resp => {
          if (resp.status) {
            let data = resp.data
            if (data.status) {
              toast.success(data.msg);
              e.target.reset();
              //  return userlist();
              
               setTimeout(() => {
                   navigate("/admin")
                 }, 3000);
  
            } else {
              toast.error(data.msg)
            }
          } else {
            toast.error(data.msg)
          }
  
        })
      //   Swal.fire({
      //     title: "Add Licenses ",
      //     input: "number",
      //     inputAttributes: {
      //       autocapitalize: "off",
      //     },
      //     showCancelButton: true,
      //     confirmButtonText: "Ok",
      //     showLoaderOnConfirm: true,
      //     preConfirm: (login) => {
      //       return axios
      //         .post(`/v1/admin/add_Licenses/${orgID}`, { Licenses: login } ,{headers:{"Authorization":`Bearer ${token}`}} )
      //         .then((response) => {
      //           Swal.fire({
      //             title: "Are you sure?",
      //             text: "Are you sure you want to Add License ",
      //             icon: "warning",
      //             showCancelButton: true,
      //             confirmButtonColor: "#3085d6",
      //             cancelButtonColor: "#d33",
      //             confirmButtonText: "Yes, Add it!",
      //           }).then((result) => {
      //             if (result.isConfirmed) {
      //               Swal.fire(
      //                 "Add License!",
      //                 "Add License successfully",
      //                 "success"
      //               );
      //               navigate( `/admin`);
      //             }
      //           });
      //         })
      //         .catch();
      //     },
      //   });
  
    };
  
    const handleApprove = (_id,orgId, approveStatus) => {
      // console.log("!!!"+approveStatus,_id)
      let requestId=_id
      let orgId1 = orgId
      let approveStatus1 = approveStatus
     
  
      const body = {requestId:requestId, orgId: orgId1, approveStatus: approveStatus1 };
      Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to Approve selected Customer ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Approved it!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios.post(`/v1/admin/OrgLicenses-status-update`, body, { headers: { "Authorization": `Bearer ${token}` } }).then((res) => {
            // if (res.status) {
            let data = res.data;
            console.log("###" + data)
            if (data.status) {
              toast.success(data.msg)
              Swal.fire(
                "Approved!",
                "Customer Approved successfully",
                "success"
              );
              return userlist();
            } else {
              toast.error(data.msg);
            }
            // } else {
            //   toast.error(data.msg);
            // }
          });
        }
      });
    }
  
    const handleRejected = (_id,orgId, approveStatus) => {
      let requestId=_id
      let orgId1 = orgId
      let approveStatus1 = approveStatus
     
  
      const body = {requestId:requestId, orgId: orgId1, approveStatus: approveStatus1 };
      Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want to Rejected selected Customer ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Rejected it!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios.post(`/v1/admin/OrgLicenses-status-update`, body, { headers: { "Authorization": `Bearer ${token}` } }).then((res) => {
            // if (res.status) {
            let data = res.data;
            console.log("###" + data)
            if (data.status) {
              toast.success(data.msg)
              Swal.fire(
                "Rejected!",
                "Customer Rejected successfully",
                "success"
              );
              return userlist();
            } else {
              toast.error(data.msg);
            }
            // } else {
            //   toast.error(data.msg);
            // }
          });
        }
      });
    }
    useEffect(() => {
      subAdminlist();
      hadleradmin();
    }, []);
  
    const [selectedValue, setSelectedValue] = useState('');
    const handleChange = (event) => {
      setSelectedValue(event.target.value);
    };
  
    return (
      <>
        <ToastContainer />
  
        <div
          className="wrapper d-flex flex-column flex-row-fluid"
          id="kt_wrapper"
        >
          <div
            className="content d-flex flex-column flex-column-fluid"
            id="kt_content"
          >
            <div className="container-xxl" id="kt_content_container">
              <div className="row g-5 g-xl-8">
                <div className="col-lg-12">
                  <div
                    className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                    style={{ marginTop: "2rem" }}
                  >
                    <div className="card-body container-xxl pt-10 pb-8">
                      <div className="d-flex align-items-center">
                        <h1 className="fw-semibold me-3 text-white">Filter</h1>
                        <span className="fw-semibold text-white opacity-50">
                          Notifications
                        </span>
                      </div>
  
                      <div className="d-flex flex-column">
                        <div className="align-lg-items-center">
                          <form onSubmit={(e) => AddFormData(e)}>
                            <div className="rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body p-5  h-lg-60px me-lg-10 my-5">
                              <div className="row flex-grow-1 mb-5 mb-lg-0">
  
                                {/* <div className="col-lg-3 d-flex align-items-center mb-5 mb-lg-0">
                                  <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                  <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect
                                        x="2"
                                        y="2"
                                        width="9"
                                        height="9"
                                        rx="2"
                                        fill="currentColor"
                                      />
                                      <rect
                                        opacity="0.3"
                                        x="13"
                                        y="2"
                                        width="9"
                                        height="9"
                                        rx="2"
                                        fill="currentColor"
                                      />
                                      <rect
                                        opacity="0.3"
                                        x="13"
                                        y="13"
                                        width="9"
                                        height="9"
                                        rx="2"
                                        fill="currentColor"
                                      />
                                      <rect
                                        opacity="0.3"
                                        x="2"
                                        y="13"
                                        width="9"
                                        height="9"
                                        rx="2"
                                        fill="currentColor"
                                      />
                                    </svg>
                                  </span>
                                  <select
                                    className="form-select border-0 flex-grow-1"
                                    data-control="select2"
                                    data-placeholder="Status"
                                    data-hide-search="true"
                                    name="approveStatus"
                                  >
                                    <option value="" selected disabled>
                                      Status
                                    </option>
  
                                    <option value="pending">Pending</option>
                                    <option value="approve">Approved</option>
                                    <option value="reject">Reject</option>
                                  </select>
                                  <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                </div> */}
                                {/* <div className="col-lg-3 d-flex align-items-center mb-5 mb-lg-0">
                                  <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect
                                        x="2"
                                        y="2"
                                        width="9"
                                        height="9"
                                        rx="2"
                                        fill="currentColor"
                                      />
                                      <rect
                                        opacity="0.3"
                                        x="13"
                                        y="2"
                                        width="9"
                                        height="9"
                                        rx="2"
                                        fill="currentColor"
                                      />
                                      <rect
                                        opacity="0.3"
                                        x="13"
                                        y="13"
                                        width="9"
                                        height="9"
                                        rx="2"
                                        fill="currentColor"
                                      />
                                      <rect
                                        opacity="0.3"
                                        x="2"
                                        y="13"
                                        width="9"
                                        height="9"
                                        rx="2"
                                        fill="currentColor"
                                      />
                                    </svg>
                                  </span>
                                  <select
                                    className="form-select border-0 flex-grow-1"
                                    data-control="select2"
                                    data-placeholder="Payment Status"
                                    data-hide-search="true"
                                    name="paymentStatus"
                                  >
                                    <option value="" selected disabled>
                                      Payment Status
                                    </option>
  
                                    <option value="paid">Paid</option>
                                    <option value="pending">Pending</option>
                                    <option value="outstanding">Outstanding</option>
                                    <option value="credit">Credit</option>
                                  </select>
                                  <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                </div> */}
  
                                <div className="col-lg-3 d-flex input-container align-items-center mb-3  mb-lg-0">
                                  <label className="small_label">
                                    Start Date
                                  </label>
                                  <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                    <i className="fad fa-calendar fs-2"></i>
                                  </span>
                                  <input
                                    type="date"
                                    className="form-control unstyled form-control-flush flex-grow-1"
                                    name="fromDate"
                                    placeholder="from Date.."
                                  />
                                  <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                </div>
  
                                <div className="col-lg-3 d-flex align-items-center mb-3 input-container mb-lg-0">
                                  <label className="small_label">End Date</label>
                                  <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                    <i className="fad fa-calendar fs-2"></i>
                                  </span>
                                  <input
                                    type="date"
                                    className="form-control unstyled form-control-flush flex-grow-1"
                                    name="toDate"
                                    placeholder="to Date.."
                                  />
                                </div>
  
                              </div>
  
                              <div className="min-w-150px d-flex">
                                <button
                                  type="submit"
                                  className="btn btn-dark"
                                  id="kt_advanced_search_button_1"
                                >
                                  Search
                                </button>
                                <button
                                  type="reset"
                                  onClick={userlist}
                                  className="btn btn-secondary"
                                  id="kt_advanced_search_button_1"
                                >
                                  Reset
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
  
                <div className="col-lg-12">
                  <div className="card card-xxl-stretch mb-5 mb-xl-8">
                    <div class="card-header border-0 pt-6 d-flex align-items-center">
  
                      <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold fs-3 mb-1">
                        Blocked IP list
                        </span>
                        <span className="text-muted mt-1 fw-semibold fs-7"> Over  {total}  IPS</span>
                      </h3>
                      {/* <div class="card-title">
                              
                                 <div class="d-flex align-items-center position-relative my-1">
                         
                                   <span class="svg-icon svg-icon-1 position-absolute ms-6">
                                     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                       <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                       <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"></path>
                                     </svg>
                                   </span>
                                
                                   <input type="text" data-kt-customer-table-filter="search" class="form-control form-control-solid w-250px ps-15" placeholder="Search Customers" onChange={(e)=>setQuery(e.target.value)}/>
                                 </div>
                             
                               </div> */}
  
  
                    </div>
  
  
  
  
  
  
  
                    <div className="card-body py-3">
                      <div className="table-responsive">
                        <table
                          className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
                          id="table-to-xls"
                        >
                          <thead>
                            <tr className="fw-bold text-muted th-title">
                              <th className="min-w-300px">IP Address</th>
  
                              <th className="min-w-150px">Date of Blocking</th>
                              {/* <th className="min-w-150px">Organization</th>
                              <th className="min-w-100px">Country</th>
                              <th className="min-w-100px">Biometrics</th> */}
                              {/* <th className="min-w-100px">Payment Status</th>
                              <th className="min-w-100px "> Status</th> */}
                              <th className="min-w-100px text-center">Actions</th>
                            </tr>
                          </thead>
  
                          <tbody className="text-center">
                            {data.map((item) => (
                              <tr>
                                
  
  
  
                                <td>
                                  {item?.IP}
                                </td>
                                <td>
                                  {Moment(item.createdAt).format("DD/MM/YYYY")}
                                </td>
  
                                {/* <td>
                             {item.organisation_name}
                                </td>
                                <td>{item.nationality}</td> */}
  
                                {/*                    
                       {item.biometric==0?<td class="text-center">
  
  <span class="svg-icon svg-icon-muted svg-icon-2hx faild" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" data-kt-initialized="1"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="currentColor"></path>
          <rect x="9" y="13.0283" width="7.3536" height="1.2256" rx="0.6128" transform="rotate(-45 9 13.0283)" fill="currentColor"></rect>
          <rect x="9.86664" y="7.93359" width="7.3536" height="1.2256" rx="0.6128" transform="rotate(45 9.86664 7.93359)" fill="currentColor"></rect>
      </svg></span>
  
  </td>:
  <td class="text-center ">
  
                                                                  <span class="svg-icon svg-icon-muted svg-icon-2hx success" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" data-kt-initialized="1"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                          <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="currentColor"></path>
                                                                          <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="currentColor"></path>
                                                                      </svg></span>
  </td>} */}
                                {/* {item.facialIdentification==0?          <td class="text-center">
  
  <span class="svg-icon svg-icon-muted svg-icon-2hx faild" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" data-kt-initialized="1"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="currentColor"></path>
          <rect x="9" y="13.0283" width="7.3536" height="1.2256" rx="0.6128" transform="rotate(-45 9 13.0283)" fill="currentColor"></rect>
          <rect x="9.86664" y="7.93359" width="7.3536" height="1.2256" rx="0.6128" transform="rotate(45 9.86664 7.93359)" fill="currentColor"></rect>
      </svg></span>
  
  </td>:
  <td class="text-center ">
  
                                                                  <span class="svg-icon svg-icon-muted svg-icon-2hx success" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" data-kt-initialized="1"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                          <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="currentColor"></path>
                                                                          <path d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z" fill="currentColor"></path>
                                                                      </svg></span>
  
  </td>} */}
                                {/* <td>
                                  {item.paymentStatus == "pending" ? (
                                    <>
                                      <span class="badge badge-light-pending text-danger fs-5">
                                        {item.paymentStatus?.charAt(0).toUpperCase() + item.paymentStatus?.slice(1)}
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <span class="badge badge-light-info text-success  fs-5">
                                        {item.paymentStatus?.charAt(0).toUpperCase() + item.paymentStatus?.slice(1)}
                                      </span>
                                    </>
                                  )}
                                </td> */}
                                {/* <td>
                                  {item.approveStatus == "pending" ? (
                                    <>
                                      <span class="badge badge-light-pending text-danger fs-5">
                                        {item.approveStatus?.charAt(0).toUpperCase() + item.approveStatus?.slice(1)}
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      {item.approveStatus == "rejected" ?
                                        (
                                          <>
                                            <span class="badge badge-light-pending text-danger  fs-5">
                                              {item.approveStatus?.charAt(0).toUpperCase() + item.approveStatus?.slice(1)}
                                            </span>
                                          </>) : (
                                          <><span class="badge badge-light-info text-success fs-5">
                                            {item.approveStatus?.charAt(0).toUpperCase() + item.approveStatus?.slice(1)}
                                          </span>
                                          </>)}
  
                                    </>
                                  )}
  
                                
  
  
                                </td> */}
  
                                <td>
                                 <div className="d-flex justify-content-center flex-shrink-0">
                                   
  
  
  
                                    
                                    <button
                                         onClick={(e) => {
                                            handleununblocked(item.IP);
                                          }}
                                          className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          data-bs-trigger="hover"
                                          title="Block IP "
                                        >
                                          <span className="svg-icon svg-icon-3">
                                            <img
                                              src="/assets_new/images/blocked.png"
                                              width="20px"
                                            />
                                          </span>
                                        </button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
  
                      <div className="col-lg-12 mt-2 text-end">
                        <ReactPaginate
                          PreviousLabel={"Previous"}
                          NextLabel={"Next"}
                          breakLabel={"..."}
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={3}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination justify-content-end"}
                          pageClassName={"page-item mx-2"}
                          pageLinkClassName={"page-link"}
                          PreviousClassName={"page-item"}
                          PreviousLinkClassName={"page-link pagestyle"}
                          NextClassName={"page-item"}
                          NextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={show} onHide={() => setShow(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Add Licence</Modal.Title>
          </Modal.Header>
          <form onSubmit={(e) => AddLicense(e)}>
            <Modal.Body>
  
             
               <div>
               <label className="col-lg-12 col-form-label fw-semibold fs-6">Licence</label>
        
      <select
        value={selectedOption}
        onChange={(e) => {
          setSelectedOption(e.target.value);
          setInputValue('');
        }}
        name="Licenses"
        className="form-select border-0 flex-grow-1"
      >
        <option value="">Select an option</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
  
      {selectedOption === 'option2' && (
        <>
        <label className="col-lg-12 col-form-label fw-semibold fs-6">Licenses</label>
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          name="Licenses"
          className="form-control form-control-lg form-control-solid"
        />
        </>
      )}
    </div>
  
  
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShow(false)}>
                Close
              </Button>
              <Button
                variant="primary"
                type="submit"
                onClick={() => setShow(false)}
              >
                {" "}
                Submit
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </>
    );
  }

export default BlockedIPs