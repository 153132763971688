import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Chart from "react-apexcharts";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import Jwt from "jsonwebtoken";

function AgentDashbord({hadleragent}) {

  const navigate = useNavigate();

   let token = localStorage.getItem("CromepayAgentToken");
  var decode1 = Jwt.decode(token);
  let agentID = decode1.agentID;
  // console.log("agentID==", agentID);

  const [data, setData] = useState("");
  const [reccustomer, setRecCustomer] = useState("");
  const [recent, setRecent] = useState([]);
  const [totalTransection, settotalTrans] = useState([]);
  const [resdata, settransections] = useState([]);
  const [useractivedatayear, setuseractivedatayear] = useState([]);
  const [useractivedatamonths, setuseractivedatamonths] = useState([]);

  const [selectValue, setSelectValue] = React.useState("Month");





  const logout = () => {
    localStorage.clear();
    navigate(`/`);
  };
  setTimeout(logout, 60 * 60 * 1000);

  const userlist = async () => {
    await axios
      .post(`/v1/view/DID/Agent_dash_main`, {},{headers:{"Authorization" : `Bearer ${token}`}})

      .then((resp) => {
        let data = resp.data;
        let transactiondata = resp.data.findTrans
        settotalTrans(transactiondata)
        setData(data);
        setRecent(data.finduser)
         console.log("daata" + data)
    
        setTimeout();
      });
  };

  useEffect(() => {
    userlist();
 
    UserPermoance();
    hadleragent();
  }, []);





  const UserPermoance = () => {
    axios.post(`/v1/Agnet/get_agent_cut_month_react`,{},{headers:{"Authorization": `bearer ${token}`}}).then((res) => {
      const userData = res.data.obj;

      console.log("++++++++++++++token", Object.values(userData),Object.keys(userData));

      setuseractivedatayear(Object.values(userData));
      setuseractivedatamonths(Object.keys(userData))
    });
  };



  const datafilter = (e) => {
  

    e.preventDefault();
    const value = e.target.value;
    axios.post(`/v1/Agnet/get_agent_cut_month_react`, {filter:value},{headers:{"Authorization": `bearer ${token}`}}).then((res) => {
      const userData = res.data.obj;

    
      setuseractivedatayear(Object.values(userData));
      setuseractivedatamonths(Object.keys(userData));
    });
  };
  return (
    <>
      <ToastContainer position="top-right" />
    
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl" id="kt_content_container">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="card card-flush">
                      <div className="bg_div card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-top bgi-position-x-center align-items-start h-250px">
                        <h3 className="card-title align-items-start flex-column text-white pt-15">
                          <span className="fw-bold fs-2x mb-3">
                            Agent Dashboard
                          </span>
                          <div className="fs-4 text-white"></div>
                        </h3>
                      </div>

                      <div className="card-body mt-n20">
                        <div className="mt-n20 position-relative dashorgnastion">
                          <div className="row g-3 g-lg-6">
                            <div className="col-6">
                              <div className="retative bg-gray-100 ative animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i className="fad fa-users"></i>
                                    </span>
                                  </span>
                                </div>

                                <div className="m-0">
                                  <span
                                    data-kt-countup="true"
                                    data-kt-countup-value={data.findNoOfuser}
                                    className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                    {data.findNoOfuser}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Number Of Users
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i className="fad fa-hands-usd"></i>
                                    </span>
                                  </span>
                                </div>

                                <div className="m-0">
                                  <span
                                    data-kt-countup="true"
                                    // data-kt-countup-value={
                                    //   data.totalTransection
                                    // }
                                    data-kt-countup-prefix="$"
                                    className="text-gray-700  fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                    {`$${data.totalTrasections}`}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Total Transactions{" "}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className="retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i className="fas fa-id-card"></i>
                                    </span>
                                  </span>
                                </div>

                                <div className="m-0">
                                  <span
                                    data-kt-countup="true"
                                    data-kt-countup-value="100"
                                    className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                    {data.remaning_Licenses}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Total Licences{" "}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="col-6">
                              <div className=" retative bg-gray-100 animestion-bank bg-opacity-70 rounded-2 px-6 py-5">
                                <div className="symbol symbol-30px me-5 mb-8">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-1 svg-icon-primary">
                                      <i class="fad fa-hand-holding-usd"></i>
                                    </span>
                                  </span>
                                </div>
                                <div className="m-0">
                                  <span
                                    data-kt-countup="true"
                                    data-kt-countup-value="0"
                                    className="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                                  >
                                    {data.findLoanApplication}
                                  </span>
                                  <span className="text-gray-500 fw-semibold fs-6">
                                    Loan Applications
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="card card-flush h-xl-100">
                      <div className="card-header pt-7">
                      <div className="row">
                          <div className="col-md-12">
                            {" "}
                            <h3 className="card-title align-items-start flex-column">
                              <span className="card-label fw-bold text-gray-800">
                                Agent Activity
                              </span>
                              <span className="text-gray-400 mt-1 fw-semibold fs-6"></span>
                            </h3>
                          </div>

                        
                          
                        </div>


                        <div className="col-md-6 p-0">
                            <form >
                              <div className="d-flex align-items-center filterCss gap-1 ">
                                <div>
                                  <select
                                    className="form-select border-0 flex"
                                    
                                    onChange={datafilter}
                                    name="filter"
                                  >
                                    <option value="1" selected="selected">
                                      {" "}
                                      Data filler
                                    </option>
                                    <option value="Day"> Day</option>
                                    <option value="Month"> Month</option>
                                    <option value="Year"> Years</option>
                                  </select>
                                </div>

                               
                              </div>
                            </form>
                          </div>
                      </div>

                   


                      <div className="bg-white overflow-auto">
                        <Chart
                          type="bar"
                          width={'100%'}
                          height={400}
                          series={[
                            {
                                name: "User",
                              data: useractivedatayear,
                            },
                          ]}
                          options={{
                            plotOptions: {
                              bar: {
                                borderRadius: 2,
                                columnWidth: 40,
                              },
                              dataLabels: {
                                position: "Top",
                              },
                            },
                            title: {},

                            subtitle: {},

                            colors: ["#3c7f8c"],
                            theme: { mode: "light" },

                            xaxis: {
                              tickPlacement: "on",
                              categories: useractivedatamonths,
                              //   title: {
                              //     text: "Year BY data",
                              //     style: { color: "##3c7f8c", fontSize: 10 },
                              //   },
                            },

                            yaxis: {
                              labels: {
                                formatter: (val) => {
                                  return `${val}`;
                                },
                                style: { fontSize: "15", colors: ["#3c7f8c"] },
                              },
                              title: {
                                // text: "User In (K)",
                                // style: { color: "#f90000", fontSize: 15 },
                              },
                            },

                            legend: {
                              show: true,
                              position: "right",
                            },

                            dataLabels: {
                              formatter: (val) => {
                                return `${val}`;
                              },
                              style: {
                                colors: ["white"],
                                fontSize: 10,
                                position: "top",
                              },
                            },
                          }}
                        ></Chart>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                  style={{ marginTop: "2rem" }}
                >
                  <div className="card-body container-xxl pt-10 pb-8">
                    <div className="d-flex align-items-center">
                      <h1 className="fw-semibold me-3 text-white">
                        Recent 
                      </h1>
                      <span className="fw-semibold text-white opacity-50"></span>
                    </div>

                    <div className="d-flex flex-column">
                      <div className="d-lg-flex align-lg-items-center"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold fs-3 mb-1">
                        Recent Users
                      </span>
                      <span className="text-muted mt-1 fw-semibold fs-7">
                        Over {reccustomer} Customer
                      </span>
                    </h3>
                  </div>

                  <div className="card-body py-3">
                    <div className="table-responsive">
                      <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                          <tr className="fw-bold text-muted th-title">
                            <th className="min-w-125px">Name</th>
                            <th className="min-w-150px">Email</th>
                            <th className="min-w-150px">Contact No. </th>
                            <th className="min-w-100px">DOB</th>
                            <th className="min-w-100px ">Status</th>
                            <th className="min-w-100px text-end">Actions</th>
                          </tr>
                        </thead>

                        <tbody>
                          {/* {recent.map((item) => (
                            <tr>
                              <td>{item.fullname}</td>
                              <td>{item.email} </td>
                              <td>
                                <span>{item.phone} </span>
                              </td>
                              <td>
                                {moment(item.dateOfBirth).format("DD/MM/YYYY")}
                              </td>
                              <td>
                                <span className="text-success">
                                  {item.status}
                                </span>
                              </td>
                              <td>
                                <div className="d-flex justify-content-end flex-shrink-0">
                                  <a
                                    href="digital_user_detail.html"
                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    data-kt-initialized="1"
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <i className="fad fa-eye fs-4"></i>
                                    </span>
                                  </a>
                                </div>
                              </td>
                            </tr>
                          ))} */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold fs-3 mb-1">
                        Recent Transactions
                      </span>
                      <span className="text-muted mt-1 fw-semibold fs-7">
                        Over {} Customer
                      </span>
                    </h3>
                  </div>

                  <div className="card-body py-3">
                    <div className="table-responsive">
                      <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                          <tr className="fw-bold text-muted th-title">
                            <th className="min-w-125px">
                              Transaction <br /> Date{" "}
                            </th>
                            <th className="min-w-150px">
                              PCN <br />
                              Number
                            </th>
                            <th className="min-w-150px">
                              Sender <br /> Name{" "}
                            </th>
                            <th className="min-w-100px">
                              Beneficiary <br /> Name{" "}
                            </th>
                            <th className="min-w-100px text-center">
                              {" "}
                              <br /> Transaction amount
                            </th>
                            {/* <th className="min-w-100px text-center">
                              Receiver <br /> Amount
                            </th> */}
                            <th className="min-w-100px ">Status</th>
                            <th className="min-w-100px text-end">Actions</th>
                          </tr>
                        </thead>

                        <tbody>
                          {/* {totalTransection.map((item) => (
                            <tr>
                              <td>{item.transactionDate.slice(0, 10)}</td>
                              <td>{item.PCN} </td>
                              <td>
                                <span>{item.senderName}</span>
                              </td>
                              <td>{item.beneficiaryName}</td>

                              <td>${item.sendingAmount}</td>
                         
                              <td>
                                <span className="text-success">
                                  {item.status}
                                </span>
                              </td>
                              <td>
                                <div className="d-flex justify-content-end flex-shrink-0">
                                  <a
                                    href="digital_user_detail.html"
                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    data-kt-initialized="1"
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <i className="fad fa-eye fs-4"></i>
                                    </span>
                                  </a>
                                </div>
                              </td>
                            </tr>
                          ))} */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AgentDashbord;
