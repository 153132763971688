
import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useState, useEffect } from "react";
import Moment from "moment";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { error } from "jquery";
import { useNavigate } from "react-router-dom";

var jwt = require("jsonwebtoken");

export default function EmpolyList({hadlerorg}) {
  const navigate = useNavigate()
   let token = localStorage.getItem("organizationToken");
  var decode1 = jwt.decode(token);
  let orgID = decode1.OrganisationID;

  const [custID, setcustID] = useState("");
  const [pageCount, setpageCount] = useState("");
  const [notereason, setnotereason] = useState("");
  const [total, settotal] = useState([]); 
   const [Query, setQuery] = useState("");

  let ID = localStorage.getItem("ID");
  const [data, setData] = useState([]);
  const [paymenthandler, setpaymenthandler] = useState(false);

  const limit = 10;

  const formsave = (e, page) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;
    console.log("Formvlaues === ", Formvlaues);
    axios.post(`/v1/org/Agent/view-employee`, formData,{  headers:{"Authorization":`Bearer ${token}`}}).then((resp) => {
      const data = resp.data.find;
      setData(data);
      const total = resp.data.totlaRow;
      console.log(total);
      const totalPage = Math.ceil(total / limit);
      setpageCount(totalPage);
    });
  };

  const userlist = async () => {
    await axios.post(`/v1/org/Agent/view-employee`,{},{  headers:{"Authorization":`Bearer ${token}`}}).then((resp) => {
      const data = resp.data.find;
      setData(data);
      const total = resp.data.totlaRow;
      settotal(total)
      console.log("++++++++++++++++++++++++++++++++++++++++++++++++++++",data);
      const totalPage = Math.ceil(total / limit);
      setpageCount(totalPage);
    }).catch(error);
  };
  useEffect(() => {
    userlist();
  }, []);

  ///////////////pagenestion///////////////
  const fetchComments = async (page) => {
    const senData = { page: page };
  
    axios.post(`/v1/org/Agent/view-employee`, senData,{  headers:{"Authorization":`Bearer ${token}`}}).then((resp) => {
      const data = resp.data.find;
      setData(data);
    });
    return data;
  };

  const handlePageClick = async (data) => {
    // console.log(data.selected);
    const page = data.selected + 1;
    const commentsFormServer = await fetchComments(page);
    setData(commentsFormServer);
  };

  /////////////unblocked user api call ////////////
  const handleunblocked = (_id) => {
    axios.put(`/UnBlockCustomer/${_id}`).then((res) => {
      if (res.status) {
        let data = res.data;
        toast.success(data.msg);
        return userlist();
      }
    });
  };

  /////////////blocked user api call ////////////
  const handlesuspend = (_id) => {
    Swal.fire({
      title: "Reason for blocking ",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Ok Block",
      showLoaderOnConfirm: true,
      preConfirm: (login) => {
        return axios
          .put(`/BlockCustomer/${_id}`, { note: login })
          .then((response) => {
            Swal.fire({
              title: "Are you sure?",
              text: "Are you sure you want to block selected Customer ",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, Block it!",
            }).then((result) => {
              if (result.isConfirmed) {
                Swal.fire("Block!", "Customer block   successfully", "success");
                userlist();
              }
            });
          })
          .catch();
      },
    });
  };

  /////////////delete user api call ////////////
  const handledelete = (_id) => {
    Swal.fire({
      title: "Reason for Deleteing ",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Ok Delete",
      showLoaderOnConfirm: true,
      preConfirm: (login) => {
        return axios
          .delete(`/Delete/${_id}`, { note: login })
          .then((response) => {
            Swal.fire({
              title: "Are you sure?",
              text: "Are you sure you want to Delete selected Customer ",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, Delete it!",
            }).then((result) => {
              if (result.isConfirmed) {
                Swal.fire(
                  "Block!",
                  "Customer Delete   successfully",
                  "success"
                );
                userlist();
              }
            });
          })
          .catch();
      },
    });
  };
  const paymentsendprogessuser = (_id) => {
    setcustID(_id);
    setpaymenthandler(true);
  };

  const userpay = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    const formData = Formvlaues;

    axios.post(`/Chrome_pay_transection/${custID}`, formData).then((res) => {
      if (res.status) {
        setpaymenthandler(false);
        let data = res.data;
        {
          data.status
            ? Swal.fire({
                position: "center",
                icon: "success",
                title: `${data.msg}`,
                showConfirmButton: false,
                timer: 3500,
              })
            : Swal.fire({
                icon: "error",
                // title: 'Oops...',
                text: `${data.msg}`,
              });
        }
      }
    });
  };
  const handleView = (_id) => {
    console.log(_id);
    // window.location = `/employee-details/${_id}`;
    navigate(`/employee-details/${_id}`)
    return false;
  };
  const orgwallet = (_id) => {
    console.log(_id);
    window.location = `/OrgWallet/${_id}`;
    return false;
  };
  const userfacescan = (_id) => {
    window.location = `/Orgwebcamera/${_id}`;
  };
  const handleDigitalId = (_id) => {
    console.log(_id);
    const { custID } = { custID: _id };
    axios.post(`/updateDigitalID/${custID}`).then((resp) => {
      if (resp.status) {
        let data = resp.data;
        toast.success(data.msg);
        return userlist();
      }
    });
  };

  const handleVerfiy = (_id) => {
    const { employeeId } = { employeeId: _id };
    // let body ={}
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to verify selected Customer ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, verify it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`/v1/org/Agent/verify-Employee`,{employeeId},{  headers:{"Authorization":`Bearer ${token}`}}).then((res) => {
          if (res.status) {
            let data = res.data;
            console.log("datadata" ,data)
            if (data.status) {
              Swal.fire(
                "verified!",
                "Customer verified successfully",
                "success"
              );
              return userlist();
            } else {
              toast.error(data.msg);
            }
          } else {
            toast.error(data.msg);
          }
        });
      }
    });
  };
 

  useEffect(()=>{
    hadlerorg()
  },[])

  const handlerEdit =(_id) =>{
navigate(`/employee/edit-org/${_id}`)
  }










// user locked logic
const [Blockhandler, setBlockhandler] = useState(false);
const [Deletehandler, setDeletehandler] = useState(false);
const [userID, setuserID] = useState("");
const [userstatus, setuserstatus] = useState("");



const HandleBlockApi = () => {
  let body = {employeeId:userID,status:userstatus}

  Swal.fire({
    title: "Are you sure?",
    text: "Are you sure you want to Block selected Customer ",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, Block it!",
  }).then((result) => {
    if (result.isConfirmed) {
      axios
      .post(`/v1/org/Agent/block-unblock-employee`,body,{  headers:{"Authorization":`Bearer ${token}`}})
        .then((res) => {
          if (res.status) {
            Swal.fire(
              "Blocked!",
              "Customer Blocked  successfully",
              "success"
            );
          }
          return userlist();
        });
    }
  });
};

const VerfiyBlock = async (e) => {
  e.preventDefault();
  const data = new FormData(e.target);
  const Formvlaues = Object.fromEntries(data.entries());
  const formData = Formvlaues;
  try {
    const response = await axios.post(
      `/v1/ChromePay855658ADKI/kacha/verify-Password`,
      formData,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    setBlockhandler(false);
    let data = response.data;
    if (data.status) {
      Swal.fire({
        position: "center",
        icon: "success",
        title: `${data.message}`,
        showConfirmButton: false,
        timer: 3500,
      });
      // toast.success(data.message);

      HandleBlockApi();

      return userlist();
    }
  } catch (error) {
    if (error.response && error.response.status === 400) {
      // setError(error.response.data.message);
      // toast.error(error.response.data.message)
      Swal.fire({
        position: "center",
        icon: "error",
        title: `${error.response.data.message}`,
        showConfirmButton: false,
        timer: 3500,
      });
      setBlockhandler(false);
    }
  }
};

const HandleBlock = (_id, isBlocked) => {
  setuserID(_id);
  setBlockhandler(true);
  setuserstatus(isBlocked)
};



// use delete

const handleDeleteApi = () => {

  let body = {employeeId:userID}
  Swal.fire({
    title: "Are you sure?",
    text: "Are you sure you want to Delete selected Customer ",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, Delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      axios
      .post(`/v1/org/Agent/delete-employee`,body,{  headers:{"Authorization":`Bearer ${token}`}})
        .then((res) => {
          if (res.status) {
            Swal.fire(
              "Deleted!",
              "Customer Deleted  successfully",
              "success"
            );
          }
          return userlist();
        });
    }
  });
};

const VerfiyDelete = async (e) => {
  e.preventDefault();
  const data = new FormData(e.target);
  const Formvlaues = Object.fromEntries(data.entries());
  const formData = Formvlaues;
  try {
    const response = await axios.post(
      `/v1/ChromePay855658ADKI/kacha/verify-Password`,
      formData,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    setDeletehandler(false);
    let data = response.data;
    if (data.status) {
      Swal.fire({
        position: "center",
        icon: "success",
        title: `${data.message}`,
        showConfirmButton: false,
        timer: 3500,
      });
      // toast.success(data.message);
      handleDeleteApi();
    }
  } catch (error) {
    if (error.response && error.response.status === 400) {
      // setError(error.response.data.message);
      // toast.error(error.response.data.message)
      Swal.fire({
        position: "center",
        icon: "error",
        title: `${error.response.data.message}`,
        showConfirmButton: false,
        timer: 3500,
      });
      setDeletehandler(false);
    }
  }
};

const HandleDelete = (_id) => {
  console.log({_id})
  setuserID(_id);
  setDeletehandler(true);
};




// const HandleDelete = (_id) => {
//   let employeeId=_id;
// //  let status=isDelete;
// let body = {employeeId:employeeId}
//  // const { custID } = { custID: _id };
//  Swal.fire({
//    title: "Are you sure?",
//    text: "Are you sure you want to delete selected Employee ?",
//    icon: "warning",
//    showCancelButton: true,
//    confirmButtonColor: "#3085d6",
//    cancelButtonColor: "#d33",
//    confirmButtonText: "Yes, delete it!",
//  }).then((result) => {
//    if (result.isConfirmed) {
//      axios.post(`/v1/org/Agent/delete-employee`,body,{  headers:{"Authorization":`Bearer ${token}`}}).then((res) => {
//        if (res.status) {
//          let data = res.data;
//          if (data.status) {
//            toast.success(data.message)
//            Swal.fire(
//              "deleted!",
//              "Employee deleted successfully",
//              "success"
//            );
//            return userlist();
//          } else {
//            toast.error(data.msg);
//          }
//        } else {
//          toast.error(data.msg);
//        }
//      });
//    }
//  });
// };



  return (
    <>
   
      <ToastContainer />
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl" >
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
              <div
                  className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                  style={{ marginTop: "2rem" }}
                >
                  <div className="card-body container-xxl pt-10 pb-8">
                    <div className="d-flex align-items-center">
                      <h1 className="fw-semibold me-3 text-white">Filter</h1>
                      <span className="fw-semibold text-white opacity-50">
                      Organisation Employee List
                      </span>
                    </div>
                    {/* <form onSubmit={(e) => formsave(e)}>
                      <div className="d-flex flex-column">
                        <div className="d-lg-flex align-lg-items-center">
                          <div className="rounded d-flex flex-column flex-lg-row align-items-lg-center bg-body p-5  h-lg-60px me-lg-10 my-5">
                            <div className="row flex-grow-1 mb-5 mb-lg-0">
                              <div className="col-lg-2 d-flex align-items-center mb-3 mb-lg-0">
                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      opacity="0.5"
                                      x="17.0365"
                                      y="15.1223"
                                      width="8.15546"
                                      height="2"
                                      rx="1"
                                      transform="rotate(45 17.0365 15.1223)"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                <input
                                  type="text"
                                  className="form-control form-control-flush flex-grow-1"
                                  name="digitalrefID"
                                  placeholder="Enter D-ID ref"
                                />
                              </div>

                              <div className="col-lg-2 d-flex align-items-center mb-3 mb-lg-0">
                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-phone"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
                                    <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                  </svg>
                                </span>
                                <input
                                  type="number"
                                  className="form-control form-control-flush flex-grow-1"
                                  name="phone"
                                  placeholder="Mobile Number"
                                />
                              </div>


                              <div className="col-lg-2 d-flex align-items-center mb-5 mb-lg-0">
                                <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      x="2"
                                      y="2"
                                      width="9"
                                      height="9"
                                      rx="2"
                                      fill="currentColor"
                                    />
                                    <rect
                                      opacity="0.3"
                                      x="13"
                                      y="2"
                                      width="9"
                                      height="9"
                                      rx="2"
                                      fill="currentColor"
                                    />
                                    <rect
                                      opacity="0.3"
                                      x="13"
                                      y="13"
                                      width="9"
                                      height="9"
                                      rx="2"
                                      fill="currentColor"
                                    />
                                    <rect
                                      opacity="0.3"
                                      x="2"
                                      y="13"
                                      width="9"
                                      height="9"
                                      rx="2"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                                <select
                                  name="nationality"
                                  className="form-select border-0 flex-grow-1"
                                  data-control="select2"
                                  data-placeholder="Country"
                                  data-hide-search="true"
                                >
                                  <option value="" selected disabled>
                                    Country
                                  </option>
                                  <option value="India">India</option>
                                  <option value="USA">USA</option>
                                  <option value="Uganda">Uganda</option>
                                </select>
                              </div>

                              <div className="col-lg-2 d-flex input-container align-items-center mb-3  mb-lg-0">
                                <label className="small_label">
                                  Start Date
                                </label>
                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                  <i className="fad fa-calendar fs-2"></i>
                                </span>
                                <input
                                  type="date"
                                  className="form-control unstyled form-control-flush flex-grow-1"
                                  name="fromDate"
                                  placeholder="User Name.."
                                />
                                <div className="bullet bg-secondary d-none d-lg-block h-30px w-2px me-5"></div>
                              </div>

                              <div className="col-lg-2 d-flex align-items-center mb-3 input-container mb-lg-0">
                                <label className="small_label">End Date</label>
                                <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
                                  <i className="fad fa-calendar fs-2"></i>
                                </span>
                                <input
                                  type="date"
                                  className="form-control unstyled form-control-flush flex-grow-1"
                                  name="toDate"
                                  placeholder="User Name.."
                                />
                              </div>
                            </div>

                            <div className="min-w-150px text-end d-flex">
                              <button
                                type="submit"
                                className="btn btn-dark mr-3"
                                id="kt_advanced_search_button_1"
                              >
                                Search
                              </button>
                              <button
                                type="reset"
                                onClick={userlist}
                                className="btn btn-secondary"
                                id="kt_advanced_search_button_1"
                              >
                                Reset
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form> */}
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                <div class="card-header border-0 pt-6 d-flex align-items-center">
                             
                <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold fs-3 mb-1">
                       Organisation   Employee  List
                      </span>
                      <span className="text-muted mt-1 fw-semibold fs-7"> Over {total} Employees</span>
                    </h3>
                                          <div class="card-title">
                                         
                                            <div class="d-flex align-items-center position-relative my-1">
                                    
                                              <span class="svg-icon svg-icon-1 position-absolute ms-6">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                                  <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"></path>
                                                </svg>
                                              </span>
                                           
                                              <input type="text" data-kt-customer-table-filter="search" class="form-control form-control-solid w-250px ps-15" placeholder="Search Customers" onChange={(e)=>setQuery(e.target.value)}/>
                                            </div>
                                        
                                          </div>
             
             
                                        </div>




                  <div className="card-body py-3">
                    <div className="table-responsive">
                      <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                          <tr className="fw-bold text-muted th-title">
                            <th className="min-w-125px">Employee  id</th>
                            <th className="min-w-125px">First Name</th>

                            {/* <th className="min-w-150px">Last Name</th> */}
                            <th className="min-w-150px">Contact No.</th>
                            <th className="min-w-100px">Email</th>
                            <th className="min-w-100px ">Employee Type</th>
                            <th className="min-w-150px"> Date of Registration</th>
                          
                            <th className="min-w-100px ">Status</th>
                            
                            <th className="min-w-100px text-center">Actions</th>
                          </tr>
                        </thead>

                        <tbody>
                          {data.filter((user)=>user.first_name.toLowerCase().includes(Query)) .map((item) => (
                            <tr>
                           

                              <td>
                                ####{item._id.slice(19)}
                              </td>
                              <td>
                                {item.first_name}
                              </td>
                              {/* <td>
                                {item.last_name}
                              </td> */}
                              <td>
                                {item.phone}
                              </td>
                              <td>
                                {item.email}
                              </td>
                              <td>
                                {item.employee_type}
                              </td>
                              <td>
                                {Moment(item.createdAt).format("DD/MM//YYYY")}
                              </td>

                            
                              {item.status == "pending" ? (
                                <>
                                  <td>
                                    <span className="badge badge-light-pending text-denger fs-5">
                                      {item.status}
                                    </span>
                                  </td>
                                </>
                              ) : (
                                <>
                                  <td>
                                    <span className="badge badge-light-info text-success fs-5">
                                      {item.status}
                                    </span>
                                  </td>
                                </>
                              )}
                              <td>
                                <div className="d-flex justify-content-end flex-shrink-0">
                                  {item.status == "pending" ? (
                                    <>
                                      <button
                                        onClick={(e) => {
                                          handleVerfiy(item._id);
                                        }}
                                        className="btn btn-icon btn-active-color-primary btn-sm me-1 mr-4"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-trigger="hover"
                                        title="Pending "
                                      >
                                        <span className="svg-icon svg-icon-3">
                                        

                                          <img
                                            src="/assets_new/images/pendding1.png"
                                            width="30px"
                                          />
                                        </span>
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <button
                                    
                                        className="btn btn-icon btn-active-color-primary btn-sm me-1 mr-4"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-trigger="hover"
                                        title="Verified"
                                      >
                                        <span className="svg-icon svg-icon-3">
                                      

                                          <img
                                            src="/assets_new/images/verify.png"
                                            width="28px"
                                          />
                                        </span>
                                      </button>
                                    </>
                                  )}
                                  {item.isBlocked == "1" ? (
                                    <>
                                      <button
                                        onClick={(e) => {
                                          HandleBlock(item._id,item.isBlocked="0");
                                        }}
                                        className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-trigger="hover"
                                        title="Suspend "
                                      >
                                        <span className="svg-icon svg-icon-3">
                                          <img
                                            src="/assets_new/images/blocked.png"
                                            width="20px"
                                          />
                                        </span>
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        onClick={(e) => {
                                          HandleBlock(item._id,item.isBlocked="1");
                                        }}
                                        className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-title="Password Reset "
                                        data-bs-trigger="hover"
                                        title="Suspend "
                                      >
                                        <span className="svg-icon svg-icon-3">
                                          <img
                                            src="/assets_new/images/suspand.png"
                                            width="20px"
                                          />
                                        </span>
                                      </button>
                                    </>
                                  )}
                                  {/* <button
                                    onClick={(e) => {
                                      paymentsendprogessuser(item._id);
                                    }}
                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    title="Pay"
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <img
                                        src="\assets_new\images\pay-btn.png"
                                        width="20px"
                                      />
                                    </span>
                                  </button> */}

                                  {/* <button
                                    onClick={(e) => {
                                      orgwallet(item._id);
                                    }}
                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    title="wallet"
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <img
                                        src="\assets_new\images\wallet.png"
                                        width="20px"
                                      />
                                    </span>
                                  </button> */}
                                  <button
                                    onClick={(e) => {
                                      handleView(item._id);
                                    }}
                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    title="View Detail"
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <i className="fad fa-eye fs-4"></i>
                                    </span>
                                  </button>
                                  <button
                                    onClick={(e) => {
                                    handlerEdit(item._id);
                                    }}
                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    title="Edit Profile"
                                  >
                                    <span className="svg-icon svg-icon-3">
                                   
                                      {/* <img
                                        className=" bg-white"
                                        style={{width:"37px"}}
                                        src="\assets_new\images\edit-icon.jpg"
                                        alt=""
                                      /> */}
                                        <i class="far fa-edit fs-2"></i>
                                    </span>
                                  </button>

                                  <button
                                    onClick={(e) => {
                                      HandleDelete(item._id);
                                    }}
                                    className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    title="Delete"
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <img
                                        src="/assets_new/images/cross.png"
                                        width="20px"
                                      />
                                    </span>
                                  </button>
                                  <button
                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    title="Disable OTP"
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <i className="fad fa-eye-slash"></i>
                                    </span>
                                  </button>
                                  {/* <button
                                    onClick={(e) => {
                                      handleDigitalId(item._id);
                                    }}
                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    title="Digital ID "
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <i className="fas fa-repeat-alt"></i>
                                    </span>
                                  </button> */}
                                </div>
                              </td>
                            </tr>
                          ))}

                         
                        </tbody>
                      </table>
                    </div>

                    <div className="col-lg-12 mt-2 text-end">
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-end gap-2"}
                        pageClassName={"page-item mx-2"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link pagestyle"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <Modal
                show={paymenthandler}
                onHide={() => setpaymenthandler(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Payment details</Modal.Title>
                </Modal.Header>
                <form onSubmit={userpay}>
                  <Modal.Body>
                    <div className="col-lg-12 ">
                      <div className="row">
                        <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                          {" "}
                          User Number
                        </label>
                        <div className="col-lg-8 fv-row">
                          <input
                            type="text"
                            name="receiver_phone"
                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                            placeholder="Number"
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                          Amount
                        </label>
                        <div className="col-lg-8 fv-row">
                          <input
                            type="text"
                            name="amount"
                            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                            placeholder="Amount"
                          />
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="primary" type="submit" className="center">
                      {" "}
                      Pay
                    </Button>
                  </Modal.Footer>
                </form>
              </Modal>
            </div>
          </div>
        </div>


 {/* block modal */}
 <Modal
        className="modal-center"
        show={Blockhandler}
        onHide={() => setBlockhandler(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Verify Password</Modal.Title>
        </Modal.Header>
        <form onSubmit={VerfiyBlock}>
          <Modal.Body>
            <div className="col-lg-12 ">
              <div className="row">
                <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                  {" "}
                  Password
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type="password"
                    name="password"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Enter password"
                  />
                </div>
              </div>
           
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit" className="center">
              {" "}
              Submit
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      {/* delete modal */}
      <Modal
        className="modal-center"
        show={Deletehandler}
        onHide={() => setDeletehandler(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Verify Password</Modal.Title>
        </Modal.Header>
        <form onSubmit={VerfiyDelete}>
          <Modal.Body>
            <div className="col-lg-12 ">
              <div className="row">
                <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                  {" "}
                  Password
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type="password"
                    name="password"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Enter password"
                  />
                </div>
              </div>
        
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit" className="center">
              {" "}
              Submit
            </Button>
          </Modal.Footer>
        </form>
      </Modal>


      </div>
    </>
  );
}
