import { useNavigate } from "react-router-dom";
import Header from "../Header";
import React from "react";
import Sidebar from "../Sidebar";
import { error } from "jquery";
import { useState, useEffect } from "react";
import axios from "axios";
import Webcam from "react-webcam";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Jwt from "jsonwebtoken";
//import AutoAddress from "../Mapcomponents/AutoAddress";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function CreateAgent({ hadlerorg }) {
  const navigate = useNavigate();

  const [showmodal, setshowmodal] = useState("");
  const [userviewphoto, setuserviewphoto] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [show, setshow] = useState(false);
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [formview, setformview] = useState(true);

  const [residances, setresidance] = useState("");
  const [locaDocument, setlocaDocument] = useState("");
  const [landRegistrations, setlandRegistrations] = useState("");
  const [Phone, setPhone] = useState("");
  const [image, setImage] = useState("");
  const token = localStorage.getItem("token");
  const [previewImage, setPreviewImage] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [Cooperativetype, setCooperativetype] = useState();
  const [Cooperativetypeselected, setCooperativetypeselected] = useState();
  const [cooperativeLicenseFee, setcooperativeLicenseFee] = useState();
  const [cooperinput, setcooperinput] = useState();

  useEffect(() => {
    if (!selectedFile) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedFile);
  }, [selectedFile]);

  console.log("okk" + residances);

  const handleresidance = (data) => {
    const url = URL.createObjectURL(data);
    console.log("@#@#@" + url);
    setImageUrl(url);

    const token = localStorage.getItem("token");
    console.log("image data", data);
    let formdata = new FormData();
    formdata.append("Image", data);

    console.log("image first datav ", formdata);
    axios
      .post(`/v1/DID/ImageUploader`, formdata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const imgurl = res.data.data;
        console.log(" imagr url ", imgurl);
        setresidance(imgurl);
      })
      .catch(error);
  };

  const AddFormData = async (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    localStorage.setItem("Image", residances);
    localStorage.setItem("firstName", Formvlaues.firstName);
    localStorage.setItem("lastName", Formvlaues.lastName);
    localStorage.setItem("orgType", Formvlaues.orgType);
    localStorage.setItem("phone", Formvlaues.phone);
    localStorage.setItem("email", Formvlaues.email);
    localStorage.setItem("country", Formvlaues.country);
    localStorage.setItem("city", Formvlaues.city);
    localStorage.setItem("address", Formvlaues.address);
    localStorage.setItem("postalCode", Formvlaues.postalCode);
    localStorage.setItem(
      "lincenseCertificateNumber",
      Formvlaues.lincenseCertificateNumber
    );
    localStorage.setItem("password", Formvlaues.password);
    localStorage.setItem("confirmPassword", Formvlaues.confirmPassword);
    localStorage.setItem("registrationFee", Formvlaues.registrationFee);
    localStorage.setItem("shares", Formvlaues.shares);
    localStorage.setItem("Loans", Formvlaues.Loans);
    localStorage.setItem("Savings", Formvlaues.Savings);
    localStorage.setItem("voluntarySavings", Formvlaues.voluntarySavings);
    localStorage.setItem("mandatorySavings", Formvlaues.mandatorySavings);
    localStorage.setItem("childSaving", Formvlaues.childSaving);
    localStorage.setItem("phone", Formvlaues.phone);
    localStorage.setItem(
      "cooperativeType",
      Cooperativetypeselected || cooperinput
    );
    localStorage.setItem("cooperativeLicenses", licenceinput);
    localStorage.setItem("cooperativeLicenseFee", cooperativeLicenseFee);
    console.log({ cooperinput, licenceinput, cooperativeLicenseFee });
    setTimeout(() => {
      navigate("/super-agent-add/AddDocumentAgentCreated");
    }, 100);
  };

  useEffect(() => {
    hadlerorg();
  }, []);

  const selectedOrganisation = (event) => {
    let value = event.target.value;
    console.log({ value });
    setCooperativetype(value);
    if (value == "Cooperative") {
      // setshowmodal(true);
    } else {
      // setshowmodal(false);
    }
  };

  // county list dropdown
  const countries = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo (Congo-Brazzaville)",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czechia (Czech Republic)",
    "Democratic Republic of the Congo (Congo-Kinshasa)",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "East Timor (Timor-Leste)",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini (fmr. 'Swaziland')",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Holy See",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Ivory Coast",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar (formerly Burma)",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Korea",
    "North Macedonia (formerly Macedonia)",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine State",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Korea",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States of America",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  const [selectedCountry, setSelectedCountry] = useState("");

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  // add reseciense

  const [RemaningLicenses, setRemaningLicenses] = useState("");
  const [licenceinput, setlicenceinput] = useState("");

  const options = [
    { value: "1000", label: "1K" },
    { value: "10000", label: "10K" },
    { value: "50000", label: "50K" },
    { value: "100000", label: "100K" },
    { value: "500000", label: "500K" },
    { value: "1000000", label: "1M" },
    { value: "2000000", label: "2M" },
    { value: "5000000", label: "5M" },
    { value: "option2", label: "Custom" },
  ];

  const cooperativeoptions = [
    // { value: "1", label: "Cooperative Type" },
    { value: "SavingsandCredit", label: "Savings and Credit" },
    { value: "Multipurpose", label: "Multipurpose" },
    { value: "SeedFertilizer", label: "Seed Fertilizer and Irrigation" },
    // { value: "Irrigation", label: "Irrigation" },
    { value: "option2", label: "Others" },
  ];

  return (
    <>
      <ToastContainer position="top-right" />

      <div
        className="wrapper d-flex flex-column flex-row-fluid"
       
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
         
        >
          <div class="container-xxl" >
            <div class="row g-5 g-xl-8">
              <div class="col-lg-12">
                <div class="card mb-5 mb-xl-10">
                  <div
                    class="card-header border-0 cursor-pointer"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_account_profile_details"
                    aria-expanded="true"
                    aria-controls="kt_account_profile_details"
                  >
                    <div class="card-title m-0">
                      <h3 class="fw-bold m-0">Add Super Agent</h3>
                    </div>
                    <div class="my">
                      <div class="margin-area">
                        <div class="dot one">1</div>
                        <div class="dot two">2</div>
                        {/* <div class="dot three"></div> */}
                        {/* <div class="dot four">4</div> */}
                        <div class="progress-bar "></div>
                        {/* <div class="progress-bar second"></div> */}
                        {/* <div class="progress-bar third"></div> */}
                        <div class="message message-1">Profile</div>`
                        <div class="message message-2">Document</div>
                        {/* <div class="message message-3">Document</div> */}
                        {/* <div class="message message-4">Delivered</div> */}
                      </div>
                    </div>
                  </div>

                  <div
                    id="kt_account_settings_profile_details"
                    class="collapse show"
                  >
                    <form onSubmit={(e) => AddFormData(e)}>
                      <div class="card-body border-top p-9">
                        <div class="row mb-6">
                          <label class="col-lg-4 col-form-label fw-semibold fs-6">
                            ID Photo
                          </label>

                          <div class="col-lg-8">
                            <div
                              class="image-input image-input-outline"
                              data-kt-image-input="true"
                            >
                              <div class="image-input-wrapper w-125px h-125px"></div>
                              <div style={{ position: "absolute", top: "0px" }}>
                                {imageUrl && (
                                  <img
                                    src={imageUrl}
                                    alt="Uploaded"
                                    className="w-125px h-125px"
                                  />
                                )}
                              </div>

                              <label
                                class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                data-kt-image-input-action="change"
                                data-bs-toggle="tooltip"
                                title="Upload Image"
                              >
                                <i class="bi bi-pencil-fill fs-7"></i>

                                <input
                                  type="file"
                                  name="Image"
                                  onChange={(e) =>
                                    handleresidance(e.target.files[0])
                                  }
                                />
                                {/* <input type="hidden" name="avatar_remove" /> */}
                              </label>

                              <span
                                class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                data-kt-image-input-action="cancel"
                                data-bs-toggle="tooltip"
                                title="Cancel avatar"
                              >
                                <i class="bi bi-x fs-2"></i>
                              </span>

                              <span
                                class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                data-kt-image-input-action="remove"
                                data-bs-toggle="tooltip"
                                title="Remove avatar"
                              >
                                <i class="bi bi-x fs-2"></i>
                              </span>
                            </div>

                            <div class="form-text">
                              Allowed file types: png, jpg, jpeg.
                            </div>
                          </div>
                        </div>

                        <div class="row mb-6">
                          <label class="col-lg-4 col-form-label  fw-semibold fs-6">
                            Super Agent First Name
                          </label>
                          <div class="col-lg-8">
                            <div class="col-lg-8 fv-row">
                              <input
                                type="text"
                                name="firstName"
                                class="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                placeholder="First Name"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="row mb-6">
                          <label class="col-lg-4 col-form-label fw-semibold fs-6">
                            Super Agent Last Name
                          </label>
                          <div class="col-lg-8">
                            <div class="col-lg-8 fv-row">
                              <input
                                type="text"
                                name="lastName"
                                class="form-control form-control-lg form-control-solid"
                                placeholder="Last Name"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            Organisation Type
                          </label>

                          <div className="col-lg-8 fv-row">
                            <select
                              className="form-select border-0 flex-grow-1"
                              // data-control="select2"
                              // data-placeholder="organisation Type"
                              // data-hide-search="true"
                              name="orgType"
                              required
                              onChange={selectedOrganisation}
                            >
                              <option value="1">Organisation Type</option>

                              <option value="Cooperative">Cooperative</option>
                              <option value="Merchant">Merchant</option>
                              {/* <option  value="Microfinance Institution">Microfinance Institution</option> */}
                              <option value="Individual"> Individual</option>
                              {/* <option  value=" Fintech / E-Wallet"> Fintech / E-Wallet</option> */}
                            </select>
                          </div>
                        </div>

                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            Cooperative Type
                          </label>

                          <div className="col-lg-8 fv-row">
                            <select
                              value={Cooperativetypeselected}
                              onChange={(e) => {
                                setCooperativetypeselected(e.target.value);
                                e.target.value == "option2"
                                  ? setcooperinput("")
                                  : setcooperinput(e.target.value);
                              }}
                              name="Licenses"
                              className="form-select border-0 flex-grow-1"
                            >
                              <option value="">Select an option</option>
                              {cooperativeoptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div class="row mb-6">
                          <label class="col-lg-4 col-form-label fw-semibold fs-6">
                            <span class="">Contact No.</span>
                            {/* <i class="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="Phone number must be active"></i> */}
                          </label>

                          <div class="col-lg-8 fv-row">
                            <input
                              type="tel"
                              name="phone"
                              class="form-control form-control-lg form-control-solid"
                              placeholder="Contact No."
                            />
                          </div>
                        </div>

                        <div class="row mb-6">
                          <label class="col-lg-4 col-form-label fw-semibold fs-6">
                            Email Address
                          </label>
                          <div class="col-lg-8 fv-row">
                            <input
                              type="email"
                              name="email"
                              class="form-control form-control-lg form-control-solid"
                              placeholder="Email Address"
                            />
                          </div>
                        </div>

                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label  required fw-semibold fs-6">
                            Country
                          </label>
                          <div className="col-lg-8 fv-row">
                            {/* <input
                              type="text"
                              name="country"
                              className="form-control form-control-lg form-control-solid"
                              placeholder="Country"
                            /> */}
                            <select
                              className="form-select border-0 flex-grow-1"
                              value={selectedCountry}
                              defaultValue={"Ethiopia"}
                              onChange={handleCountryChange}
                              name="country"
                            >
                              <option value="">
                                {selectedCountry || "Ethiopia"}
                              </option>
                              {countries.map((country, index) => (
                                <option key={index} value={country}>
                                  {country}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            City
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="text"
                              name="city"
                              className="form-control form-control-lg form-control-solid"
                              placeholder="City"
                            />
                          </div>
                        </div>

                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                            Area/Postal Code
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="number"
                              name="postalCode"
                              className="form-control form-control-lg form-control-solid"
                              placeholder="Area/Postal Code"
                            />
                          </div>
                        </div>

                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            Address
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="text"
                              name="address"
                              className="form-control form-control-lg form-control-solid"
                              placeholder="Address"
                            />
                          </div>
                        </div>

                        <div class="row mb-6">
                          <label class="col-lg-4 col-form-label fw-semibold fs-6">
                            Password
                          </label>
                          <div class="col-lg-8 fv-row">
                            <input
                              type="password"
                              name="password"
                              class="form-control form-control-lg form-control-solid"
                              placeholder="Password"
                            />
                          </div>
                        </div>

                        <div class="row mb-6">
                          <label class="col-lg-4 col-form-label fw-semibold fs-6">
                            Confirm Password
                          </label>
                          <div class="col-lg-8 fv-row">
                            <input
                              type="password"
                              name="confirmPassword"
                              class="form-control form-control-lg form-control-solid"
                              placeholder="Confirm Password"
                            />
                          </div>
                        </div>
                        <div class="row mb-6">
                          <label class="col-lg-4 col-form-label fw-semibold fs-6">
                            Shares
                          </label>
                          <div class="col-lg-8 fv-row">
                            <input
                              type="number"
                              name="shares"
                              class="form-control form-control-lg form-control-solid"
                              placeholder="Enter Shares "
                              step="any"
                            />
                          </div>
                        </div>
                        <div class="row mb-6">
                          <label class="col-lg-4 col-form-label fw-semibold fs-6">
                            Loans (ETB)
                          </label>
                          <div class="col-lg-8 fv-row">
                            <input
                              type="number"
                              name="Loans"
                              class="form-control form-control-lg form-control-solid"
                              placeholder="Enter Loans "
                              step="any"
                            />
                          </div>
                        </div>
                        <div class="row mb-6">
                          <label class="col-lg-4 col-form-label fw-semibold fs-6">
                            Child Savings (ETB)
                          </label>
                          <div class="col-lg-8 fv-row">
                            <input
                              type="number"
                              name="childSaving"
                              class="form-control form-control-lg form-control-solid"
                              placeholder=" Enter Amount"
                              step="any"
                            />
                          </div>
                        </div>
                        <div class="row mb-6">
                          <label class="col-lg-4 col-form-label fw-semibold fs-6">
                            Voluntary Savings ETB
                          </label>
                          <div class="col-lg-8 fv-row">
                            <input
                              type="number"
                              name="voluntarySavings"
                              class="form-control form-control-lg form-control-solid"
                              placeholder=" Enter Amount"
                              step="any"
                            />
                          </div>
                        </div>

                        <div class="row mb-6">
                          <label class="col-lg-4 col-form-label fw-semibold fs-6">
                            Mandatory Savings (ETB)
                          </label>
                          <div class="col-lg-8 fv-row">
                            <input
                              type="number"
                              name="mandatorySavings"
                              class="form-control form-control-lg form-control-solid"
                              placeholder="Enter Amount "
                              step="any"
                            />
                          </div>
                        </div>
                        <div class="row mb-6">
                          <label class="col-lg-4 col-form-label fw-semibold fs-6">
                            Registration Fee (ETB)
                          </label>
                          <div class="col-lg-8 fv-row">
                            <input
                              type="number"
                              name="registrationFee"
                              class="form-control form-control-lg form-control-solid"
                              placeholder="Registration Fee   "
                              step="any"
                            />
                          </div>
                        </div>
                        <div class="row mb-6">
                          <label class="col-lg-4 col-form-label fw-semibold fs-6">
                            License Certificate Number
                          </label>
                          <div class="col-lg-8 fv-row">
                            <input
                              type="number"
                              name="lincenseCertificateNumber"
                              class="form-control form-control-lg form-control-solid"
                              placeholder="License  Certificate Number "
                            />
                          </div>
                        </div>
                      </div>
                      <div class="card-footer d-flex justify-content-end py-6 px-9">
                        {/* <button type="reset" class="btn btn-light btn-active-light-primary me-2">Reset</button> */}
                        <button
                          type="submit"
                          class="btn btn-primary"
                          id="kt_account_profile_details_submit"
                        >
                          Next
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          className="modal-center"
          show={showmodal}
          onHide={() => setshowmodal(false)}
        >
          <Modal.Header closeButton></Modal.Header>
          <form>
            <Modal.Body>
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-semibold fs-6">
                  Cooperative Type  
                </label>

                <div className="col-lg-8 fv-row">
                  <select
                    value={Cooperativetypeselected}
                    onChange={(e) => {
                      setCooperativetypeselected(e.target.value);

                      // e.target.value == "option2"
                      //   ? setcooperinput("")
                      //   : setcooperinput(e.target.value);
                    }}
                    name="Licenses"
                    className="form-select border-0 flex-grow-1"
                  >
                    <option value="">Select an option</option>
                    {cooperativeoptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                {/* {Cooperativetypeselected === "option2" && (
                  <>
                    <div className=" offset-md-4 col-lg-8 fv-row">
                      <input
                        type="text"
                        value={cooperinput}
                        onChange={(e) => setcooperinput(e.target.value)}
                        name="Licenses"
                        className="form-control form-control-lg form-control-solid"
                        placeholder=" Enter Cooperative Type "
                      />
                    </div>
                  </>
                )} */}
              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-semibold fs-6">
                  License Number
                </label>
                <div className="col-lg-8 fv-row">
                  <select
                    value={RemaningLicenses}
                    onChange={(e) => {
                      setRemaningLicenses(e.target.value);
                      e.target.value === "option2"
                        ? setlicenceinput("")
                        : setlicenceinput(e.target.value);
                    }}
                    name="cooperativeLicenses"
                    className="form-select border-0 flex-grow-1"
                  >
                    <option value="">Select an option</option>
                    {options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>{" "}
                {RemaningLicenses === "option2" && (
                  <>
                    <div className=" offset-md-4 col-lg-8 fv-row">
                      <input
                        type="text"
                        value={licenceinput}
                        onChange={(e) => setlicenceinput(e.target.value)}
                        name="Licenses"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="Amount of required licenses"
                      />
                    </div>
                  </>
                )}
              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-semibold fs-6">
                  License Fee
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type="number"
                    value={cooperativeLicenseFee}
                    onChange={(e) => setcooperativeLicenseFee(e.target.value)}
                    name="Licenses"
                    className="form-control form-control-lg form-control-solid"
                    placeholder="Amount of   License Fee"
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                type="button"
                className="center"
                onClick={() => setshowmodal(false)}
              >
                Submit
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    </>
  );
}

export default CreateAgent;
