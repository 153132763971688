import React from "react";

import Header from "./Header";
import Sidebar from "./Sidebar";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AutoAddress from "../Mapcomponents/AutoAddress";


var jwt = require("jsonwebtoken");

function AdminCustomerDemo({hadleradmin}) {
  const [listorg, setlistOrg] = useState([]);

  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");

  const userlist = async () => {
    await axios.get(`/v1/admin/orgList`).then((resp) => {
      let data = resp.data.Org;
      setlistOrg(data);
      console.log(data);
    });
  };

  useEffect(() => {
    userlist();
    hadleradmin();
  }, []);

  const handleClick = () => {
    const latInnerLat = document.getElementById("lat").innerHTML;
    console.log(latInnerLat);
    setLat(latInnerLat);
    const latInnerLong = document.getElementById("lon").innerHTML;
    console.log(latInnerLong);
    setLong(latInnerLong);
  };

  let token = localStorage.getItem("ChromePayAdmitToken");

 

  const AddFormData = async (e) => {
    e.preventDefault();
  };

  const gotophoto = () => {
    window.location = "/Admin_customer_face_add";
  };

  return (
    <>
    
      <ToastContainer position="top-right" />
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl" id="kt_content_container">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div className="card mb-5 mb-xl-10">
                  <div
                    className="card-header border-0 cursor-pointer"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_account_profile_details"
                    aria-expanded="true"
                    aria-controls="kt_account_profile_details"
                  >
                    <div className="card-title m-0">
                      <h3 className="fw-bold m-0">Add Customer</h3>
                    </div>
                  </div>
                  <div
                    id="kt_account_settings_profile_details"
                    className="collapse show"
                  >
                    <form
                      onSubmit={(e) => AddFormData(e)}
                      id="kt_account_profile_details_form"
                      className="form"
                    >
                      <div className="card-body border-top p-9">
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            ID Photo
                          </label>
                          <div className="col-lg-6">
                            <div
                              className="image-input image-input-outline"
                              data-kt-image-input="true"
                              style={{
                                backgroundImage:
                                  "url('/metronic8/demo4/assets/media/svg/avatars/blank.svg')",
                              }}
                            >
                              <div
                                className="image-input-wrapper w-125px h-125px"
                                onClick={() => gotophoto()}
                              ></div>

                              {/* //////////map input filed hidden */}
                              <input
                                type="hidden"
                                name="Latitude"
                                value={lat}
                              />
                              <input
                                type="hidden"
                                name="Longitude"
                                value={long}
                              />
                              {/* ////////// end map input filed hidden */}
                              {/* onClick={()=>gotophoto()}  */}
                              <label
                                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                data-kt-image-input-action="change"
                                data-bs-toggle="tooltip"
                                title="Upload Image"
                              >
                                <i className="bi bi-pencil-fill fs-7"></i>
                                <input type="hidden" required name="image" />
                                {/* <input type="hidden" name="avatar_remove" /> */}
                              </label>
                              <span
                                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                data-kt-image-input-action="cancel"
                                data-bs-toggle="tooltip"
                                title="Cancel avatar"
                              >
                                <i className="bi bi-x fs-2"></i>
                              </span>
                              <span
                                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                data-kt-image-input-action="remove"
                                data-bs-toggle="tooltip"
                                title="Remove avatar"
                              >
                                <i className="bi bi-x fs-2"></i>
                              </span>
                            </div>
                            <div className="form-text">
                              Allowed file types: png, jpg, jpeg.
                            </div>
                          </div>
                        </div>

                        {/* <input type="file" name="image"  /> */}

                        {/* //////////map input filed hidden */}
                        <input type="hidden" name="Latitude" value={lat} />
                        <input type="hidden" name="Longitude" value={long} />
                        {/* ////////// end map input filed hidden */}

                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                            Full Name
                          </label>

                          <div className="col-lg-8 fv-row">
                            <input
                              type="text"
                              name="fullname"
                              required
                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                              placeholder="Full Name"
                            />
                          </div>
                        </div>
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                          Organisation
                          </label>

                          <div className="col-lg-8 fv-row">
                            {/* <input type="text" name="organisation" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Organisation"  /> */}

                            <select
                              className="form-select border-0 flex-grow-1"
                              data-control="select2"
                              data-placeholder="organisation"
                              data-hide-search="true"
                              name="organisation"
                              required
                            >
                              <option value="1"> Select Organisation</option>
                              {listorg.map((item) => (
                                <option key={item._id} value={item._id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                            Date of Birth
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="date"
                              name="dateOfBirth"
                              className="form-control form-control-lg form-control-solid position-relative"
                              required
                            />
                          </div>
                        </div>

                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            <span className="required">Contact No</span>
                            <i
                              className="fas fa-exclamation-circle ms-1 fs-7"
                              data-bs-toggle="tooltip"
                              title="Phone number must be active"
                            ></i>
                          </label>
                          <div className="col-lg-8 fv-row">
                            <PhoneInput
                              country={"eg"}
                              enableSearch={true}
                              inputProps={{
                                name: "phone",
                                required: true,
                                placeholder: "Enter phone number",
                              }}
                              className="form-control form-control-lg form-control-solid"
                              placeholder="Contact No"
                            />
                          </div>
                        </div>
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            Email Address
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="email"
                              name="email"
                              required
                              className="form-control form-control-lg form-control-solid"
                              placeholder="Email Address"
                            />
                          </div>
                        </div>
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                            Gender
                          </label>
                          <div className="col-lg-8 fv-row">
                            <div className="d-flex align-items-center mt-3">
                              <label className="form-check form-check-inline form-check-solid me-5">
                                <input
                                  className="form-check-input"
                                  required
                                  name="gender"
                                  type="radio"
                                  value="Male"
                                />
                                <span className="fw-semibold ps-2 fs-6">
                                  Male
                                </span>
                              </label>
                              <label className="form-check form-check-inline form-check-solid">
                                <input
                                  className="form-check-input"
                                  required
                                  name="gender"
                                  type="radio"
                                  value="Fmale"
                                />
                                <span className="fw-semibold ps-2 fs-6">
                                  Female
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            Nationality
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="text"
                              name="nationality"
                              required
                              className="form-control form-control-lg form-control-solid"
                              placeholder="Nationality"
                            />
                          </div>
                        </div>
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            Age
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="number"
                              name="age"
                              required
                              className="form-control form-control-lg form-control-solid"
                              placeholder="Age"
                            />
                          </div>
                        </div>
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            City
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="text"
                              name="city"
                              required
                              className="form-control form-control-lg form-control-solid"
                              placeholder="City"
                            />
                          </div>
                        </div>

                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            Profession
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="text"
                              name="profession"
                              required
                              className="form-control form-control-lg form-control-solid"
                              placeholder="Profession"
                            />
                          </div>
                        </div>

                        <AutoAddress style={{ width: "100% !important" }} />
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                            Next Of Kin Name
                          </label>

                          <div className="col-lg-8 fv-row">
                            <input
                              type="text"
                              required
                              name="NextFOKinName"
                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                              placeholder="Next Of Kin Name"
                            />
                          </div>
                        </div>
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            <span className="">Next Of Kin Phone</span>
                            {/* <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="Phone number must be active"></i> */}
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="number"
                              name="NextFOKniPhone"
                              pattern="[0-9]{10}"
                              title=" Only number allow  Ten digits code"
                              required
                              className="form-control form-control-lg form-control-solid"
                              placeholder="Next Of Kin Number"
                            />
                          </div>
                        </div>
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            <span className="">Land Size (HAC.)</span>
                            {/* <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="Phone number must be active"></i> */}
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="tel"
                              name="landSize"
                              required
                              className="form-control form-control-lg form-control-solid"
                              placeholder=" Land Size "
                            />
                          </div>
                        </div>

                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                            Asset Types
                          </label>

                          <div className="col-lg-8 fv-row">
                            {/* <input type="text" name="organisation" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Organisation"  /> */}

                            <select
                              className="form-select border-0 flex-grow-1"
                              required
                              data-control="select2"
                              data-placeholder="assetType"
                              data-hide-search="true"
                              name="assetType"
                            >
                              <option value="1" selected="selected">
                                {" "}
                                Select Asset
                              </option>
                              <option value="Land"> Land</option>
                              <option value="House"> House</option>
                              <option value="Car"> Car</option>
                              <option value="Store"> Store</option>
                              {/* {listorg.map((item)=>(
                            <option key={item._id} value={item._id}>{item.name}</option>
                        ))} */}
                            </select>
                          </div>
                        </div>
                        {/* <LandMap style={{width : "100% !important"}} /> */}
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                            ID Type
                          </label>

                          <div className="col-lg-8 fv-row">
                            {/* <input type="text" name="organisation" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Organisation"  /> */}

                            <select
                              className="form-select border-0 flex-grow-1"
                              data-control="select2"
                              data-placeholder="assetID"
                              required
                              data-hide-search="true"
                              name="assetID"
                            >
                              <option value="1" selected="selected">
                                {" "}
                                Select ID
                              </option>
                              <option value="National"> National ID</option>
                              <option value="Passport"> Passport</option>
                              <option value="Drivers"> Drivers Licence</option>
                              <option value="Notarised">
                                {" "}
                                Notarised Document
                              </option>
                              {/* {listorg.map((item)=>(
                                                                        <option key={item._id} value={item._id}>{item.name}</option>
                                                                    ))} */}
                            </select>
                          </div>
                        </div>
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                          Identification Document Image
                          </label>

                          <div className="col-lg-8 fv-row">
                            <input
                              type="file"
                              required
                              name="residance"
                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                              placeholder="Residance Image"
                            />
                          </div>
                        </div>

                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                          Proof of Residence
                          </label>

                          <div className="col-lg-8 fv-row">
                            <input
                              type="file"
                              required
                              name="locaDocument"
                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                              placeholder="Local Document"
                            />
                          </div>
                        </div>
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                          Assets Ownership Certificate
                          </label>

                          <div className="col-lg-8 fv-row">
                            <input
                              type="file"
                              required
                              name="landRegistration"
                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                              placeholder="Land Registration"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button
                          type="submit"
                          onClick={handleClick}
                          className="btn btn-light btn-active-light-primary me-2"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AdminCustomerDemo;
