import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import CustomerLoader from "../CRM/Modal/CustomerLoder";
const BuyProductSuper = ({ hadlersuper }) => {
    let token = localStorage.getItem("SuperAgentChromepayToken");
    const navigate = useNavigate();
    const [imageUrl, setImageUrl] = useState("");
    const [quntity, setQuantity] = useState("");
    const [price, setPrice] = useState("");
    const [formData, setFormData] = useState();
    const [ShowPassword, setShowPassword] = useState(false);
    const [ShowLoader, setShowLoader] = useState(false);
    const [show1, setShow1] = useState(false);
    const [depositeBank, setDeposite] = useState("");


    const handleChange = (e) => {
      const { name, value } = e.target;
      if(name == "pricePerKg"){
        setPrice(value)
      }
      if(name == "availableQuantity"){
        setQuantity(value)
      }
      setFormData({
        ...formData,
        type: "buy",
        [name]: value,
      });
    };
  
    const handleFileChange = (data) => {
      let formdata = new FormData();
      // console.log("@#@#@123" + data);
  
      if (data) {
        const url = URL.createObjectURL(data);
        setImageUrl(url);
        formdata.append("file", data);
  
        console.log("image first data", formdata);
        formdata.append("Image", data);
        axios
          .post(`/v1/DID/ImageUploader`, formdata, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            const imgurl = res.data.data;
            console.log("image url", imgurl);
            //   setCutsmerImage(imgurl);
            setFormData({
              ...formData,
              image: imgurl,
            });
          })
          .catch((error) => {
            console.error("Error uploading image:", error);
          });
      } else {
        toast.error(data.msg);
      }
      // })
      // .catch(error => {
      //   console.error("Error checking face API:", error);
      // });
      // } else {
  
      // }
    };
  
    const formcheck = (e) => {
      e.preventDefault();
      setShowPassword(true);
    };

    const AddFormData = async (e) => {
      e.preventDefault();

      // const data = new FormData(e.target);
      // const Formvlaues = Object.fromEntries(data.entries());
  
      axios
        .post(`/v1/super-Agent/buy-product`, formData, {
          headers: { Authorization: `Bearer ${token}` },
        })
  
        .then((resp) => {
          let data = resp.data;
          if (resp.status) {
            let data = resp.data;
            if (data.status) {
          setShow1(false)
              toast.success(data.msg);
              navigate(`/buying/product/list`)
              // e.target.reset();
            } else {
              toast.error(data.msg);
            }
          } else {
            toast.error(data.msg);
          }
        });
    };
  
    const VerifyPassword = async (e) => {
      e.preventDefault();
      setShowLoader(true);
      const data = new FormData(e.target);
      const Formvlaues = Object.fromEntries(data.entries());
      const formData = Formvlaues;
  
      try {
        const response = await axios.post(
          `/v1/super-Agent/match-agent-pass`,
          formData,
          { headers: { Authorization: `Bearer ${token}` } }
        );
  
        let data = response.data;
  
        if (data.status) {
          setShowLoader(false);
          setShow1(true)
        } else {
          toast.error(response.data.message);
          setShowPassword(false);
          setShowLoader(false);
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message);
        }
      }
    };
  
    useEffect(() => {
      hadlersuper();
    }, []);
    return (
      <>
        <ToastContainer />
        <CustomerLoader loader={ShowLoader} />
        <div
          className="wrapper d-flex flex-column flex-row-fluid"
          id="kt_wrapper"
        >
          <div
            className="content d-flex flex-column flex-column-fluid"
            id="kt_content"
          >
            <div className="container-xxl">
              <div className="row g-5 g-xl-8">
                <div className="col-lg-12">
                  <div className="card mb-5 mb-xl-10">
                    <div
                      className="card-header border-0 cursor-pointer"
                      role="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#kt_account_profile_details"
                      aria-expanded="true"
                      aria-controls="kt_account_profile_details"
                    >
                      <div className="card-title m-0">
                        <h3 className="fw-bold m-0">Add Products</h3>
                      </div>
                      <div className="card-title m-0">
                        <button
                          type="button"
                          onClick={() => navigate(-1)}
                          className="btn btn-dark"
                          id="kt_advanced_search_button_1"
                        >
                          Back
                        </button>
                      </div>
                    </div>
                    <div
                      id="kt_account_settings_profile_details"
                      className="collapse show"
                    >
                      <form
                        onSubmit={(e) => formcheck(e)}
                        id="kt_account_profile_details_form"
                        className="form"
                      >
                        <div className="card-body border-top p-9">
                          <div className="row mb-8">
                            <label className="col-lg-4 col-form-label fw-semibold fs-6">
                              Product Image
                            </label>
                            <div className="col-lg-8">
                              <div
                                className="image-input image-input-outline"
                                data-kt-image-input="true"
                              >
                                <div className="image-input-wrapper w-125px h-125px"></div>
                                <div style={{ position: "absolute", top: "0px" }}>
                                  {imageUrl && (
                                    <img
                                      src={imageUrl}
                                      alt="Uploaded"
                                      className="w-125px h-125px"
                                    />
                                  )}
                                </div>
                                <label
                                  className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                  data-kt-image-input-action="change"
                                  data-bs-toggle="tooltip"
                                  title="Product Image"
                                >
                                  <i className="bi bi-pencil-fill fs-7"></i>
                                  <input
                                    type="file"
                                    name="IDphoto"
                                    onChange={(e) =>
                                      handleFileChange(e.target.files[0])
                                    }
                                  />
                                </label>
                                <span
                                  className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                  data-kt-image-input-action="cancel"
                                  data-bs-toggle="tooltip"
                                  title="Cancel avatar"
                                >
                                  <i className="bi bi-x fs-2"></i>
                                </span>
                                <span
                                  className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                  data-kt-image-input-action="remove"
                                  data-bs-toggle="tooltip"
                                  title="Remove avatar"
                                >
                                  <i className="bi bi-x fs-2"></i>
                                </span>
                              </div>
                              <div className="form-text">
                                Allowed file types: png, jpg, jpeg.
                              </div>
                            </div>
                          </div>
  
                          <div className="row mb-8">
                            <div className="col-lg-6 mb-6">
                              <div className="row">
                                <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                  Product Name
                                </label>
                                <div className="col-lg-8 fv-row">
                                  <input
                                    type="text"
                                    name="productName"
                                    onChange={handleChange}
                                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                    placeholder="Product Name"
                                  />
                                </div>
                              </div>
                            </div>
  
                            <div className="col-lg-6  mb-8 ">
                              <div className="row">
                                <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                  Price Per (ETB)
                                </label>
                                <div className="col-lg-8 fv-row">
                                  <input
                                    type="number"
                                    min={0}
                                    step={0.01}
                                    name="pricePerKg"
                                    onChange={handleChange}
                                    className="form-control form-control-lg form-control-solid"
                                    placeholder=" Price Per (Kg)"
                                  />
                                </div>
                              </div>
                            </div>
  
                            <div className="col-lg-6 mb-8">
                              <div className="row">
                                <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                  Unit
                                </label>
                                <div className="col-lg-8 fv-row">
                                  <select
                                    className="form-select  flex-grow-1"
                                    // data-control="select2"
                                    data-placeholder="Please Select Category"
                                    data-hide-search="true"
                                    name="unit"
                                    onChange={handleChange}
                                    required
                                  >
                                    <option value="" selected disabled>
                                      Please Select Unit
                                    </option>
                                    <option value="Kg">KG</option>
                                    <option value="Ltr">Liter</option>
                                    <option value="GM">Gram</option>
                                    <option value="ML">Mililiter</option>
                                    <option value="Sachet">Sachet</option>
                                    <option value="Pcs">Pcs</option>
                                    <option value="Rolls">Rolls</option>
                                    <option value="Syring">Syring</option>
                                    <option value="Packs">Packs</option>
  
                                  </select>
                                </div>
                              </div>
                            </div>
  
  
                            <div className="col-lg-6 mb-8">
                              <div className="row">
                                <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                  Variety
                                </label>
                                <div className="col-lg-8 fv-row">
                                  <input
                                    type="text"
                                    name="variety"
                                    onChange={handleChange}
                                    min={0}
                                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                    placeholder="Optional"
                                  />
                                </div>
                              </div>
                            </div>
  
                            <div className="col-lg-6 mb-8">
                              <div className="row">
                                <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                  Description
                                </label>
                                <div className="col-lg-8 fv-row">
                                  <input
                                    type="text"
                                    name="description"
                                    onChange={handleChange}
                                    min={0}
                                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                    required
                                    placeholder="Enter Description"
                                  />
                                </div>
                              </div>
                            </div>
  
                            <div className="col-lg-6 mb-6">
                              <div className="row ">
                                <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                  Product Brand Name
                                </label>
                                <div className="col-lg-8 fv-row">
                                  <input
                                    type="test"
                                    name="companyName"
                                    className="form-control form-control-lg form-control-solid"
                                    placeholder="Enter Company Namewh"
                                    onChange={handleChange}
                                    required
                                    min={0}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 mb-6">
                              <div className="row ">
                                <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                   Quantity
                                </label>
                                <div className="col-lg-8 fv-row">
                                  <input
                                    type="number"
                                    min={0}
                                    step={0.01}
                                    name="availableQuantity"
                                    className="form-control form-control-lg form-control-solid"
                                    placeholder="Available Quantity"
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-6 mb-6">
                              <div className="row ">
                                <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                   Total Amount
                                </label>
                                <div className="col-lg-8 fv-row">
                                  <input
                                    type="number"
                                    min={0}
                                    name="availableQuantity"
                                    className="form-control form-control-lg form-control-solid"
                                    placeholder="Available Quantity"
                                    value={quntity * price}
                                    onChange={handleChange}
                                    step={0.01}

                                  />
                                </div>
                              </div>
                            </div>
  
                            <div className="col-lg-6 mb-8">
                              <div className="row">
                                <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                  Category
                                </label>
                                <div className="col-lg-8 fv-row">
                                  <select
                                    className="form-select  flex-grow-1"
                                    // data-control="select2"
                                    data-placeholder="Please Select Category"
                                    data-hide-search="true"
                                    name="category"
                                    onChange={handleChange}
                                    required
                                  >
                                    <option value="" selected disabled>
                                      Please Select Category
                                    </option>
                                    <option value="Seeds">Seeds</option>
                                    <option value="Fertilizer">Fertilizer</option>
                                    <option value="chemicals">Chemicals</option>
                                    <option value="Veterinary Drugs">Veterinary Drugs</option>
                                    <option value="mechanazaionService">Mechanazaion service</option>
                                    <option value="technology">Technology</option>
  
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-footer d-flex justify-content-end py-6 px-9">
                          <button
                            type="submit"
                            className="btn btn-light btn-active-light-primary me-2"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <Modal
          className="modal-center"
          show={ShowPassword}
          // show={Candelhandler}
          onHide={() => setShowPassword(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Verify Password</Modal.Title>
          </Modal.Header>
          <form onSubmit={VerifyPassword}>
            <Modal.Body>
              <div className="col-lg-12 ">
                <div className="row">
                  <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                    {" "}
                    Password
                  </label>
                  <div className="col-lg-8 fv-row">
                    <input
                      type="password"
                      name="password"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                      placeholder="Enter password"
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" type="submit" className="center">
                {" "}
                Verify
              </Button>
            </Modal.Footer>
          </form>
        </Modal>

        <Modal
                show={show1}
                onHide={() => setShow1(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Product Payment</Modal.Title>
                </Modal.Header>
                <form onSubmit={(e) => AddFormData(e)}>
                    <Modal.Body>

                        <div className="row">
                            <div className="col-lg-6 mt-3 ">
                                <label className=" fs-6">
                                    <span className="">Total (ETB)</span>
                                </label>

                                <input
                                    type="number"
                                    name="interestRate"
                                    defaultValue={price * quntity}
                                    pattern="[0-9]{10}"
                                    title=" Only number allow  Ten digits code"
                                    required
                                    readOnly
                                    className="form-control form-control-lg form-control-solid"
                                    placeholder="Interest Rate"
                                    step='any'
                                />
                            </div>

                            <div className="col-lg-6 mt-3 ">
                                <label className=" fs-6">
                                    <span className=""> Payment Method</span>
                                </label>

                                <select
                                    name="pay_method"
                                    required
                                    className="form-select  flex-grow-1"
                                    // data-control="select2"
                                    data-placeholder="Please Select"
                                    onChange={(e) =>
                                        setDeposite(e.target.value)
                                    }
                                >
                                    <option value="">
                                        Choose the Pay Method
                                    </option>

                                    <option value={"Cash"}>
                                        Cash
                                    </option>
                                    <option value={"BackDeposit"}>
                                        Bank Deposit
                                    </option>

                                </select>
                            </div>

                        </div>


                        {/* <div className="col-lg-12 mb-8">
                            <div className="row">
                                <label className="col-lg-5 col-form-label required fw-semibold fs-6">
                                    Payment Method
                                </label>
                                <div className="col-lg-7 fv-row">
                                    <select
                                        name="pay_method"
                                        required
                                        className="form-select  flex-grow-1"
                                        // data-control="select2"
                                        data-placeholder="Please Select"
                                        onChange={(e) =>
                                            setDeposite(e.target.value)
                                        }
                                    >
                                        <option value="">
                                            Choose the Pay Method
                                        </option>

                                        <option value={"Cash"}>
                                            Cash
                                        </option>
                                        <option value={"BackDeposit"}>
                                            Bank Deposit
                                        </option>

                                    </select>
                                </div>
                            </div>
                        </div> */}

                        {depositeBank === "BackDeposit" ? (
                            <div className="row mt-5">
                                <label className="col-lg-5 col-form-label required fw-semibold fs-6">
                                    Deposit Slip
                                </label>
                                <div className="col-lg-7 fv-row">
                                    <input
                                        type="file"
                                        name="depositSlip"
                                        accept=".jpg, .jpeg, .png, .pdf"
                                        className="form-control"
                                        required
                                        onChange={(e) =>
                                            handleFileChange(
                                                e.target.files[0]
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        ) : (
                            ""
                        )}







                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShow1(false)}>
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            type="submit"
                        >
                            {" "}
                            Make Payment
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
      </>
    );
  }

export default BuyProductSuper