import React, { useState, useEffect } from 'react';
import axios from 'axios';

const SearchComponent = ({lgColSize}) => {
  const [search, setSearch] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  // Fetch suggestions from API when search input changes
  useEffect(() => {
    const fetchSuggestions = async () => {
      if (search.length > 0) {
        try {
          const response = await axios.post(
            'http://localhost:5000/v1/super-Agent/search-auto-fill',
            { search },
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer YOUR_TOKEN_HERE', 
              },
            }
          );
          setSuggestions(response.data.data); 
        } catch (error) {
          console.error('Error fetching suggestions:', error);
        }
      } else {
        setSuggestions([]); // Clear suggestions when input is empty
      }
    };

    fetchSuggestions();
  }, [search]);

  // Update search input when a suggestion is clicked
  const handleSuggestionClick = (suggestion) => {
    setSearch(suggestion);
    setSuggestions([]); // Hide suggestions after selection
  };

  return (
<div className={`col-lg-${lgColSize} col-sm-6 d-flex align-items-center mb-3 mb-lg-0 position-relative`}>
      <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            opacity="0.5"
            x="17.0365"
            y="15.1223"
            width="8.15546"
            height="2"
            rx="1"
            transform="rotate(45 17.0365 15.1223)"
            fill="currentColor"
          />
          <path
            d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
            fill="currentColor"
          />
        </svg>
      </span>
      <input
        type="text"
        className="form-control form-control-flush flex-grow-1"
        name="search"
        value={search}
        onChange={(e) => setSearch(e.target.value)} 
        placeholder="Search.."
        autoComplete="off"
      />

      {suggestions.length > 0 && (
        <ul className="list-group position-absolute w-100 shadow rounded" style={{ maxHeight: '200px', overflowY: 'auto', zIndex: '10', marginTop: "261px" }}>
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              className="list-group-item list-group-item-action"
              onClick={() => handleSuggestionClick(suggestion)}
            >
              {suggestion}
            </li>
          ))}
        </ul>
      )}
    </div>
    
  );
};

export default SearchComponent;
