import Header from "../Header";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { useNavigate, useLocation } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Chart from "react-apexcharts";
import Sidebar from "../Sidebar";
import { useHistory } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { JWT } from "jsonwebtoken";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { error } from "jquery";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import moment from "moment";
import { Pagination } from "react-bootstrap";
import CustomerLoader from "../CRM/Modal/CustomerLoder";
import SuperAgentVerifyModal from "../ApplyShareOrgManagement/Componets/SuperAgentVerifyModal";
// import CanvasJSReact from './canvasjs.react';
// var CanvasJS = CanvasJSReact.CanvasJS;
// var CanvasJSChart = CanvasJSReact.CanvasJSChart;
var jwt = require("jsonwebtoken");

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function SuperAgentAloowLoan({ hadlersuper }) {
  const location = useLocation();
  const { state } = location;
  const { _id } = useParams("");
  const navigate = useNavigate();

  const [AgricultureloanSystem, setAgricultureloanSystem] = useState("");

  const [document, setdocument] = useState([]);
  const [transmonths, settransmonths] = useState([]);
  const [transvalue, settransvalue] = useState([]);
  const [percentageRate, setpercentageRate] = useState("");
  const [Query, setQuery] = useState("");
  const [searching, setsearching] = useState("");
  const [show, setshow] = useState(false);
  const [photo, setphoto] = useState("");
  const [pageCount, setpageCount] = useState("");
  const [pageCountEMI, setpageCountEMI] = useState("");
  const [Blockhandler, setBlockhandler] = useState(false);
  const [Orguselistdata, setOrguselistdata] = useState([]);
  const [userPro, setUserinfo] = useState([]);
  const [userLoanDetail, setUserLoan] = useState([]);
  const [InterestList, setInterestList] = useState([]);
  const [imagepassword, setimagepassword] = useState(0);
  const [selectedId, setSelectedId] = useState("");
  const [show1, setShow1] = useState(false);
  const [Addloanhistory, setAddloanhistory] = useState([]);
  const [loanhistory, setloanhistory] = useState([]);
  const [Collateral, setCollateral] = useState("Collateral");
  const [Deposite, setDeposite] = useState(null);
  const [ShowModel, setShowModel] = useState(false);
  const [LoanRessionshow, setLoanRessionshow] = useState(false);
  const [LoanotherText, setLoanotherText] = useState("");
  const [btnshow, setbtnshow] = useState(false);

  const [loanAmountNumeric, setLoanAmountNumeric] = useState("");
  const [loanAmountDisplay, setLoanAmountDisplay] = useState("");
  console.log("loanAmountNumeric", loanAmountNumeric);
  const [ShowLoader, setShowLoader] = useState(true);

  const handleLoanAmountChange = (event) => {
    const enteredValue = event.target.value;
    const numericValue = parseFloat(enteredValue.replace(/,/g, ""));

    if (!isNaN(numericValue)) {
      setLoanAmountNumeric(numericValue);
      const formattedValue = new Intl.NumberFormat("en-US").format(
        numericValue
      );
      setLoanAmountDisplay(formattedValue);
    } else {
      // Handle invalid input (e.g., non-numeric characters)
      setLoanAmountDisplay(enteredValue);
    }
  };

  useEffect(() => {
    if (!isNaN(loanAmountNumeric)) {
      setLoanAmountDisplay(loanAmountNumeric.toLocaleString());
    }
  }, [loanAmountNumeric]);

  const [MonthlyAmountNumeric, setMonthlyAmountNumeric] = useState("");
  const [MonthyAmountDisplay, setMonthyAmountDisplay] = useState("");
  console.log("loanAmountNumeric", loanAmountNumeric);

  const handleMonthyAmountChange = (event) => {
    const enteredValue = event.target.value;
    const numericValue = parseFloat(enteredValue.replace(/,/g, ""));

    if (!isNaN(numericValue)) {
      setMonthlyAmountNumeric(numericValue);
      const formattedValue = new Intl.NumberFormat("en-US").format(
        numericValue
      );
      setMonthyAmountDisplay(formattedValue);
    } else {
      // Handle invalid input (e.g., non-numeric characters)
      setMonthyAmountDisplay(enteredValue);
    }
  };

  useEffect(() => {
    if (!isNaN(MonthlyAmountNumeric)) {
      setMonthyAmountDisplay(MonthlyAmountNumeric.toLocaleString());
    }
  }, [MonthlyAmountNumeric]);

  const [CollateralNumeric, setCollateralNumeric] = useState("");
  const [CollateraltDisplay, setCollateraltDisplay] = useState("");
  console.log("loanAmountNumeric", loanAmountNumeric);

  const handleCollateraltChange = (event) => {
    const enteredValue = event.target.value;
    const numericValue = parseFloat(enteredValue.replace(/,/g, ""));

    if (!isNaN(numericValue)) {
      setCollateralNumeric(numericValue);
      const formattedValue = new Intl.NumberFormat("en-US").format(
        numericValue
      );
      setCollateraltDisplay(formattedValue);
    } else {
      // Handle invalid input (e.g., non-numeric characters)
      setCollateraltDisplay(enteredValue);
    }
  };

  useEffect(() => {
    if (!isNaN(CollateralNumeric)) {
      setCollateraltDisplay(CollateralNumeric.toLocaleString());
    }
  }, [CollateralNumeric]);

  let token = localStorage.getItem("SuperAgentChromepayToken");
  let orgAgentType = localStorage.getItem("orgAgentType");

  console.log({ state });

  const [repaymentSchedule, setRepaymentSchedule] = useState("monthly");

  const handleRepaymentScheduleChange = (event) => {
    // Update the state with the selected value
    console.log(event.target.value, "repaymentSchedule");
    setRepaymentSchedule(event.target.value);
  };

  const handleLoanTypeChange = (e) => {
    const selectedLoanType = e.target.value;
    const selectedRate = InterestList?.find(
      (item) => item.loanType === selectedLoanType
    );

    setAgricultureloanSystem(selectedRate?.loanType || "");
    setpercentageRate(selectedRate?.interestRate || "");
    console.log("Selected Loan Type:", selectedRate);
  };

  const renderLoanDurationOptions = () => {
    switch (repaymentSchedule || "monthly") {
      case "monthly":
        return (
          <>
            <option value="">Selected Monthly</option>
            {Array.from({ length: 50 }, (_, i) => (
              <option key={i} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </>
        );
      case "quarterly":
        return (
          <>
            <option value="">Selected Quarterly</option>
            {[3, 6, 9, 12, 15, 18, 21, 24, 27, 30].map((value) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </>
        );
      case "halfYearly":
        return (
          <>
            <option value="">Selected Half Yearly</option>
            {[6, 12, 18, 24, 30].map((value) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </>
        );
      case "Yearly":
        return (
          <>
            <option value="">Selected Yearly</option>
            {[1, 2, 3].map((value) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </>
        );
      default:
        return <option value="">First Selected Repayment Schedule</option>;
    }
  };

  const [landformData, setlandformData] = useState("");

  const handleLoandChange = (e) => {
    const { name, value } = e.target;
    setlandformData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    console.log({ landformData });
  };

  //integrate create loan api

  const AddLoanApplyAfter = (e) => {
    e.preventDefault();
    setShowModel(true);
  };

  const AddLoanApplyFormData = async (e) => {
    setShowLoader(true);
    let payload = {
      custId: _id,
      fullName: state?.fullName,
      motherName: state?.motherName,
      phoneNumber: state?.phoneNumber,
      homeAddress: state?.homeAddress,
      marriageStatus: state?.marriageStatus,
      dateOfBirth: state?.dateOfBirth,
      education: state?.education,
      numberChildren: state?.numberChildren,
      typeOfLoan: AgricultureloanSystem,
      interestRate: percentageRate,
      monthlyIncome: MonthlyAmountNumeric,
      loanAmount: loanAmountNumeric,
      repaymentMethod: Deposite,
      repaymentSchedule: repaymentSchedule,
      irrigatable_land: landformData?.irrigatable_land,
      sufficient_water_supply: landformData?.sufficient_water_supply,
      permanent_resident: landformData?.permanent_resident,
      land_certificate_or_house_certificate:
        landformData?.land_certificate_or_house_certificate,
      loanDuration: parseInt(landformData?.loanDuration),
      colletralAsset: landformData?.colletralAsset,
      typeOfCollateral: landformData?.typeOfCollateral,
      automatedType: landformData?.automatedType,
      value: CollateralNumeric,
    };

    axios
      .post(`/v1/super-Agent/update-customer-profile`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {})
      .catch(error);

    axios
      .post(`/v1/DID/apply-loan-union`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })

      .then((resp) => {
        let data = resp.data;
        if (resp.status) {
          if (data.status) {
            toast.success(data.msg);
            setShowLoader(false);
            navigate(`/superagent/Loan-Apply-customer-List`);
          } else {
            toast.error(data.msg);
            setShowLoader(false);
          }
        } else {
          setShowLoader(false);
          toast.error(data.msg);
        }
      });
  };

  const custumerDetails = () => {
    setShowLoader(true);
    axios
      .post(
        `/v1/super-Agent/cust-detail `,
        { custID: _id },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data.Data;
        console.log("---->>>>--->>>", data);
        setUserinfo(data);
        setShowLoader(false);
        console.log("details", data);
      });
  };

  const custumerLoanDetail = () => {
    setShowLoader(true);
    axios
      .post(
        `/v1/super-Agent/trasaction-dash`,
        { custId: _id },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data.data;
        console.log("---->>>>--->>>Loan", data);
        setUserLoan(data);
        setShowLoader(false);
        console.log("data===== filter", data);
      });
  };

  const customerLoanInterestList = () => {
    axios
      .post(
        `/v1/super-Agent/get-loans-type-with-interest`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data.data;

        let listOfInterest = data?.map((item) => ({
          loanType: item?.loanType,
          interestRate: item?.interestRate,
        }));

        // listOfInterest.push({ loanType: "other", interestRate: "25" });
        console.log("---->>>>--->>>Loan list", data, listOfInterest);
        setInterestList(listOfInterest);
      });
  };

  // api call
  useEffect(() => {
    hadlersuper();
    // Orguselist();
    custumerDetails();
    customerLoanInterestList();
    custumerLoanDetail();
  }, []);

  return (
    <>
      <ToastContainer position="top-right" />
      <CustomerLoader loader={ShowLoader} />
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-12">
                    <div
                      className="card mb-12 rounded-15 bgi-no-repeat bgi-position-x-end 
        bgi-size-cover bg_card"
                    >
                      <div className="card-body flex-column p-5">
                        <div className="d-flex align-items-center ">
                          <div className="card-body  pt-9 pb-0 ">
                            {/* <!--begin::Details--> */}
                            <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                              {/* <!--begin: Pic--> */}
                              <div className="me-7 mb-4">
                                <div className="symbol border border-gray-300 border-dashed symbol-100px symbol-lg-160px symbol-fixed position-relative text-white">
                                  <img
                                    src={userPro?.IDphoto}
                                    alt="image"
                                    className="rounded-circle mx-2"
                                  />
                                </div>
                              </div>

                              {/* <!--end::Pic--> */}

                              <div className="flex-grow-1">
                                <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                  {/* <!--begin::User--> */}
                                  <div className="d-flex flex-column">
                                    {/* <!--begin::Name--> */}
                                    <div className="d-flex align-items-center mb-2">
                                      <a
                                        href="#"
                                        className="-900 text-hover-primary fs-2 text-white fw-bold me-1"
                                      >
                                        {userPro?.fullname}
                                      </a>

                                      {/* <!--  <a href="#" className="btn btn-sm btn-light-success fw-bold ms-2 fs-8 py-1 px-3" data-bs-toggle="modal" data-bs-target="#kt_modal_upgrade_plan">Verified</a> --> */}
                                    </div>
                                    {/* <!--end::Name--> */}

                                    <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                                      <a
                                        href="#"
                                        className="d-flex align-items-center -400 text-hover-primary me-5 mb-2"
                                      >
                                        {/* <!--begin::Svg Icon | path: icons/duotune/communication/com006.svg--> */}
                                        <span className="svg-icon svg-icon-4 me-1 text-white">
                                          <svg
                                            width="18"
                                            height="18"
                                            viewBox="0 0 18 18"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              opacity="0.3"
                                              d="M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z"
                                              fill="currentColor"
                                            />
                                            <path
                                              d="M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z"
                                              fill="currentColor"
                                            />
                                            <rect
                                              x="7"
                                              y="6"
                                              width="4"
                                              height="4"
                                              rx="2"
                                              fill="currentColor"
                                            />
                                          </svg>
                                        </span>
                                        {userPro?.professoin}
                                      </a>
                                      <a
                                        href="#"
                                        className="d-flex align-items-center -400 text-hover-primary me-5 mb-2"
                                      >
                                        {/* <!--begin::Svg Icon | path: icons/duotune/general/gen018.svg--> */}
                                        <span className="svg-icon text-white  svg-icon-4 me-1">
                                          <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              opacity="0.3"
                                              d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z"
                                              fill="currentColor"
                                            />
                                            <path
                                              d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z"
                                              fill="currentColor"
                                            />
                                          </svg>
                                        </span>
                                        {userPro?.nationality || "Ethiopia"}
                                      </a>
                                      <a
                                        href="#"
                                        className="d-flex align-items-center -400 text-white mb-2"
                                      >
                                        {/* <!--begin::Svg Icon | path: icons/duotune/communication/com011.svg--> */}
                                        <span className="svg-icon svg-icon-4 me-1 text-white">
                                          <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              opacity="0.3"
                                              d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z"
                                              fill="currentColor"
                                            />
                                            <path
                                              d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z"
                                              fill="currentColor"
                                            />
                                          </svg>
                                        </span>
                                        {userPro?.email}
                                      </a>
                                    </div>
                                    {/* <!--end::Info--> */}
                                  </div>
                                  {/* <!--end::User--> */}
                                </div>

                                <div className="d-flex flex-wrap flex-stack">
                                  <div className="d-flex flex-column flex-grow-1 pe-8">
                                    <div className="d-flex flex-wrap"></div>
                                    <div className="d-flex mt-3 flex-wrap">
                                      <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 bg_ab96  w-135">
                                        <div className="d-flex align-items-center justify-content-center">
                                          <span className="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                          <div
                                            className="fs-2 fw-bold text-white"
                                            data-kt-countup="true"
                                            data-kt-countup-prefix="$"
                                          >
                                            {`${(
                                              userLoanDetail?.totalLoanAmount ||
                                              0
                                            ).toFixed(2)}`}
                                          </div>
                                        </div>
                                        <div className="fw-semibold fs-6 text-white text-center">
                                          Total Loan Amount (ETB)
                                        </div>
                                      </div>
                                      <div className="border bg_ab96 border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                        <div className="d-flex align-items-center justify-content-center">
                                          <span className="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                          <div
                                            className="fs-2 fw-bold text-white"
                                            data-kt-countup="true"
                                            data-kt-countup-prefix="$"
                                          >
                                            {`${(
                                              userLoanDetail?.totalPaidAmount ||
                                              0
                                            ).toFixed(2)}`}
                                          </div>
                                        </div>
                                        <div className="fw-semibold fs-6 text-white text-center">
                                          Total Paid Amount
                                        </div>
                                      </div>
                                      <div className="border bg_ab96 border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                        <div className="d-flex align-items-center justify-content-center">
                                          <span className="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                          <div
                                            className="fs-2 fw-bold text-white"
                                            data-kt-countup="true"
                                            data-kt-countup-prefix="$"
                                          >
                                            {`${(
                                              userLoanDetail?.remainingamount ||
                                              0
                                            ).toFixed(2)}`}
                                          </div>
                                        </div>
                                        <div className="fw-semibold fs-6 text-white text-center">
                                          Remaining Loan Amount (ETB)
                                        </div>
                                      </div>
                                    </div>
                                    <div className="d-flex mt-3">
                                      <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3 bg_ab96  w-135">
                                        <div className="d-flex align-items-center justify-content-center">
                                          <span className="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                          <div
                                            className="fs-2 fw-bold text-white"
                                            data-kt-countup="true"
                                            data-kt-countup-prefix="$"
                                          >
                                            {`${(
                                              userLoanDetail?.totalIntrestAmount ||
                                              0
                                            ).toFixed(2)}`}
                                          </div>
                                        </div>
                                        <div className="fw-semibold fs-6 text-white text-center">
                                          Total Interest Amount
                                        </div>
                                      </div>
                                      <div className="border bg_ab96 border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                        <div className="d-flex align-items-center justify-content-center">
                                          <span className="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                          <div
                                            className="fs-2 fw-bold text-white"
                                            data-kt-countup="true"
                                            data-kt-countup-prefix="$"
                                          >
                                            {`${(
                                              userLoanDetail?.Mandatory || 0
                                            ).toFixed(2)}`}
                                          </div>
                                        </div>
                                        <div className="fw-semibold fs-6 text-white text-center">
                                          Mandatory Savings
                                        </div>
                                      </div>
                                      <div className="border bg_ab96 border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                        <div className="d-flex align-items-center justify-content-center">
                                          <span className="svg-icon svg-icon-3 svg-icon-success me-2"></span>
                                          <div
                                            className="fs-2 fw-bold text-white"
                                            data-kt-countup="true"
                                            data-kt-countup-prefix="$"
                                          >
                                            {`${(
                                              userLoanDetail?.Volantary || 0
                                            ).toFixed(2)}`}
                                          </div>
                                        </div>
                                        <div className="fw-semibold fs-6 text-white text-center">
                                          Voluntary Savings
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* <!--begin::Progress--> */}

                                  {/* <!--end::Progress--> */}
                                </div>
                              </div>
                              {/* <!--end::Info--> */}
                            </div>
                          </div>

                          <div className="flex-equal d-flex justify-content-center align-items-end ms-5">
                            <img
                              src="../../assets/media/illustrations/dozzy-1/20.png"
                              alt=""
                              className="mw-100 mh-125px mh-lg-275px mb-lg-n12"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="tab-content">
                    <div
                      className="tab-pane fade active show"
                      id="overview"
                      role="tabpanel"
                    >
                      {/* make loan */}

                      <div
                        id="kt_account_settings_profile_details"
                        className="collapse show"
                      >
                        <div className="row">
                          <div className="col-lg-12">
                            <div
                              className="card mb-5 mb-xl-10"
                              id="kt_profile_details_view"
                            >
                              <div className="card-header cursor-pointer">
                                <div className="card-body p-9">
                                  <div
                                    id="kt_account_settings_profile_details"
                                    className="collapse show"
                                  >
                                    <h3 className="card-title align-items-center flex-column">
                                      <span className="card-label fw-bold fs-3 mb-1">
                                        Loan Details
                                      </span>
                                    </h3>
                                    <form
                                      onSubmit={(e) => AddLoanApplyAfter(e)}
                                      id="kt_account_profile_details_form"
                                      className="form"
                                    >
                                      <div className="card-body border-top p-9">
                                        <div className="row mb-8">
                                          <div className="col-lg-6 mb-8">
                                            <div className="row">
                                              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                                Loan Type
                                              </label>
                                              <div className="col-lg-8 fv-row">
                                                <select
                                                  name="typeOfLoan"
                                                  className="form-select border-0 flex-grow-1"
                                                  data-placeholder="Selected Loan Type"
                                                  required
                                                  defaultValue=""
                                                  onChange={
                                                    handleLoanTypeChange
                                                  }
                                                >
                                                  <option value="" disabled>
                                                    Selected Loan
                                                  </option>
                                                  {InterestList?.map((item) => (
                                                    <option
                                                      key={item.loanType}
                                                      value={item.loanType}
                                                    >
                                                      {item.loanType}
                                                    </option>
                                                  ))}
                                                </select>
                                              </div>
                                            </div>
                                          </div>

                                          {percentageRate && (
                                            <div className="col-lg-6 mb-8">
                                              <div className="row">
                                                <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                                  Interest Rate
                                                </label>
                                                <div className="col-lg-8 fv-row">
                                                  <input
                                                    type="number"
                                                    name="interestRate"
                                                    readOnly
                                                    Value={percentageRate}
                                                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                    placeholder="Interest Rate"
                                                    step="any"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          )}

                                          {(AgricultureloanSystem ===
                                            "Agriculture Seeds and Fertilizers" ||
                                            AgricultureloanSystem ===
                                              "Agriculture Drip System loan") && (
                                            <>
                                              <div className="col-lg-6 mb-8">
                                                <div className="row">
                                                  <label className="col-lg-6 col-form-label required fw-semibold fs-6">
                                                    Farmer has irrigatable land
                                                  </label>
                                                  <div className="col-lg-6 fv-row">
                                                    <div className="d-flex align-items-center mt-3">
                                                      <label className="form-check form-check-inline form-check-solid me-5">
                                                        <input
                                                          className="form-check-input"
                                                          name="irrigatable_land"
                                                          type="radio"
                                                          value={true}
                                                          onChange={
                                                            handleLoandChange
                                                          }
                                                        />
                                                        <span className="fw-semibold ps-2 fs-6">
                                                          Yes
                                                        </span>
                                                      </label>
                                                      <label className="form-check form-check-inline form-check-solid">
                                                        <input
                                                          className="form-check-input"
                                                          name="irrigatable_land"
                                                          type="radio"
                                                          value={false}
                                                          onChange={
                                                            handleLoandChange
                                                          }
                                                        />
                                                        <span className="fw-semibold ps-2 fs-6">
                                                          No
                                                        </span>
                                                      </label>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="col-lg-6 mb-8">
                                                <div className="row">
                                                  <label className="col-lg-6 col-form-label required fw-semibold fs-6">
                                                    Farmer has access to water
                                                    for agriculture
                                                  </label>
                                                  <div className="col-lg-6 fv-row">
                                                    <div className="d-flex align-items-center mt-3">
                                                      <label className="form-check form-check-inline form-check-solid me-5">
                                                        <input
                                                          className="form-check-input"
                                                          name="sufficient_water_supply"
                                                          type="radio"
                                                          value={true}
                                                          onChange={
                                                            handleLoandChange
                                                          }
                                                        />
                                                        <span className="fw-semibold ps-2 fs-6">
                                                          Yes
                                                        </span>
                                                      </label>
                                                      <label className="form-check form-check-inline form-check-solid">
                                                        <input
                                                          className="form-check-input"
                                                          name="sufficient_water_supply"
                                                          type="radio"
                                                          value={false}
                                                          onChange={
                                                            handleLoandChange
                                                          }
                                                        />
                                                        <span className="fw-semibold ps-2 fs-6">
                                                          No
                                                        </span>
                                                      </label>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="col-lg-6 mb-8">
                                                <div className="row">
                                                  <label className="col-lg-6 col-form-label required fw-semibold fs-6">
                                                    Farmer is a permanent
                                                    resident of the area
                                                  </label>
                                                  <div className="col-lg-6 fv-row">
                                                    <div className="d-flex align-items-center mt-3">
                                                      <label className="form-check form-check-inline form-check-solid me-5">
                                                        <input
                                                          className="form-check-input"
                                                          name="permanent_resident"
                                                          type="radio"
                                                          value={true}
                                                          onChange={
                                                            handleLoandChange
                                                          }
                                                        />
                                                        <span className="fw-semibold ps-2 fs-6">
                                                          Yes
                                                        </span>
                                                      </label>
                                                      <label className="form-check form-check-inline form-check-solid">
                                                        <input
                                                          className="form-check-input"
                                                          name="permanent_resident"
                                                          type="radio"
                                                          value={false}
                                                          onChange={
                                                            handleLoandChange
                                                          }
                                                        />
                                                        <span className="fw-semibold ps-2 fs-6">
                                                          No
                                                        </span>
                                                      </label>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="col-lg-6 mb-8">
                                                <div className="row">
                                                  <label className="col-lg-6 col-form-label required fw-semibold fs-6">
                                                    Farmer has land or home
                                                    ownership deed
                                                  </label>
                                                  <div className="col-lg-6 fv-row">
                                                    <div className="d-flex align-items-center mt-3">
                                                      <label className="form-check form-check-inline form-check-solid me-5">
                                                        <input
                                                          className="form-check-input"
                                                          name="land_certificate_or_house_certificate"
                                                          type="radio"
                                                          value={true}
                                                          onChange={
                                                            handleLoandChange
                                                          }
                                                        />
                                                        <span className="fw-semibold ps-2 fs-6">
                                                          Yes
                                                        </span>
                                                      </label>
                                                      <label className="form-check form-check-inline form-check-solid">
                                                        <input
                                                          className="form-check-input"
                                                          name="land_certificate_or_house_certificate"
                                                          type="radio"
                                                          value={false}
                                                          onChange={
                                                            handleLoandChange
                                                          }
                                                        />
                                                        <span className="fw-semibold ps-2 fs-6">
                                                          No
                                                        </span>
                                                      </label>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          )}

                                          <div className="col-lg-6 mb-8">
                                            <div className="row">
                                              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                                Monthly Income (ETB){" "}
                                              </label>
                                              <div className="col-lg-8 fv-row">
                                                <input
                                                  type="text"
                                                  name="monthlyIncome"
                                                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                  placeholder="Monthly Income"
                                                  value={MonthyAmountDisplay}
                                                  onChange={
                                                    handleMonthyAmountChange
                                                  }
                                                  required
                                                />
                                              </div>
                                            </div>
                                          </div>

                                          <div className="col-lg-6 mb-8">
                                            <div className="row">
                                              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                                Loan Amount (ETB)
                                              </label>
                                              <div className="col-lg-8 fv-row">
                                                <input
                                                  type="text"
                                                  name="loanAmount"
                                                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                  placeholder="Loan Amount"
                                                  value={loanAmountDisplay}
                                                  onChange={
                                                    handleLoanAmountChange
                                                  }
                                                  required
                                                />
                                              </div>
                                            </div>
                                          </div>


                                          <div className="col-lg-6 mb-8">
                                            <div className="row">
                                              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                                Repayment method
                                              </label>
                                              <div className="col-lg-8 fv-row">
                                                <select
                                                  name="repaymentMethod"
                                                  className="form-select border-0 flex-grow-1"
                                                  data-placeholder="Selected  Repayment Method"
                                                  required
                                                  onChange={(e) => {
                                                    const selectedValue =
                                                      e.target.value;

                                                    if (
                                                      selectedValue ===
                                                      "bankDeposit"
                                                    ) {
                                                      setDeposite(
                                                        selectedValue
                                                      );
                                                    } else {
                                                      setDeposite(null);
                                                    }
                                                  }}
                                                >
                                                  <option disabled value="">
                                                    Selected Repayment method
                                                  </option>
                                                  <option value="cash">
                                                    Cash
                                                  </option>
                                                  <option value="bankDeposit">
                                                    Bank Deposit
                                                  </option>
                                                </select>
                                              </div>
                                            </div>
                                          </div>

                                          
                                          <div className="col-lg-6 mb-8">
                                            <div className="row">
                                              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                                Repayment Schedule
                                              </label>
                                              <div className="col-lg-8 fv-row">
                                                <select
                                                  name="repaymentSchedule"
                                                  className="form-select border-0 flex-grow-1"
                                                  data-placeholder="Please Select"
                                                  required
                                                  value={repaymentSchedule}
                                                  onChange={
                                                    handleRepaymentScheduleChange
                                                  }
                                                >
                                                  <option disabled value="">
                                                    Selected Repayment Schedule
                                                  </option>
                                                  <option value="monthly">
                                                    Monthly
                                                  </option>
                                                  <option value="quarterly">
                                                    Quarterly
                                                  </option>
                                                  <option value="halfYearly">
                                                    Every 6 months
                                                  </option>
                                                  <option value="Yearly">
                                                    Yearly
                                                  </option>
                                                  <option value="manually">
                                                    Manually Pay
                                                  </option>
                                                </select>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="col-lg-6 mb-8">
                                            <div className="row">
                                              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                                Loan Duration
                                              </label>
                                              <div className="col-lg-8 fv-row">
                                                <select
                                                  name="loanDuration"
                                                  className="form-select border-0 flex-grow-1"
                                                  data-placeholder="First Selected Repayment Schedule"
                                                  required
                                                  onChange={handleLoandChange}
                                                >
                                                  {renderLoanDurationOptions()}
                                                </select>
                                              </div>
                                            </div>
                                          </div>

                                          {Collateral && (
                                            <>
                                              <div className="col-lg-6 mb-8">
                                                <div className="row">
                                                  <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                                    Collateral Asset
                                                  </label>
                                                  <div className="col-lg-8 fv-row">
                                                    <input
                                                      type="text"
                                                      name="colletralAsset"
                                                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                      placeholder="Collateral Asset"
                                                      required
                                                      onChange={
                                                        handleLoandChange
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-lg-6 mb-8">
                                                <div className="row">
                                                  <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                                    Type Of Collateral
                                                  </label>
                                                  <div className="col-lg-8 fv-row">
                                                    <input
                                                      type="text"
                                                      name="typeOfCollateral"
                                                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                      placeholder="Type Of Collateral"
                                                      required
                                                      onChange={
                                                        handleLoandChange
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-lg-6 mb-8">
                                                <div className="row">
                                                  <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                                    Collateral Value
                                                  </label>
                                                  <div className="col-lg-8 fv-row">
                                                    <input
                                                      type="text"
                                                      name="value"
                                                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                      placeholder=" Collateral Value"
                                                      step="any"
                                                      required
                                                      value={CollateraltDisplay}
                                                      onChange={
                                                        handleCollateraltChange
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          )}

                                          {Deposite && (
                                            <div className="col-lg-6 mb-8">
                                              <div className="row">
                                                <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                                  Auto-deduct Type
                                                </label>
                                                <div className="col-lg-8 fv-row">
                                                  <select
                                                    name="automatedType"
                                                    className="form-select border-0 flex-grow-1"
                                                    data-control="select2"
                                                    data-placeholder="Please Select"
                                                    required
                                                    onChange={handleLoandChange}
                                                  >
                                                    {" "}
                                                    <option disabled value="">
                                                      Selected Deduct Type
                                                    </option>
                                                    <option value="Automated">
                                                      Automated
                                                    </option>
                                                    <option value="Non-automated">
                                                      Non-automated
                                                    </option>
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </div>

                                        <div className="card-footer d-flex justify-content-end py-6 px-9">
                                          <button
                                            type="submit"
                                            className="btn btn-light btn-active-light-primary me-2"
                                          >
                                            Submit
                                          </button>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
                <div className="container-xxl d-flex flex-column flex-md-row flex-stack">
                  <div className="text-dark order-2 order-md-1">
                    <span className="-400 fw-semibold me-1">Powered by</span>
                    <a
                      href="#"
                      className="text-muted text-hover-primary fw-semibold me-2 fs-6"
                    >
                      Chromepay
                    </a>
                  </div>

                  <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
                    <li className="menu-item">
                      <a href="#" className="menu-link px-2">
                        About
                      </a>
                    </li>
                    <li className="menu-item">
                      <a href="#" className="menu-link px-2">
                        Support
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <SuperAgentVerifyModal
            ShowModel={ShowModel}
            setShowModel={setShowModel}
            handleSubmit={AddLoanApplyFormData}
          />

          <Modal
            className="modal-center"
            show={LoanRessionshow}
            onHide={() => setLoanRessionshow(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Why do you need a loan</Modal.Title>
            </Modal.Header>
            <form>
              <Modal.Body>
                <div className="col-lg-12 ">
                  <div className="row">
                    <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                      {" "}
                      Reason
                    </label>
                    <div className="col-lg-8 fv-row">
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                        placeholder="Enter Reason"
                        onChange={(e) => setLoanotherText(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => setLoanRessionshow(false)}
                  variant="primary"
                  type="button"
                  className="center"
                >
                  Submit
                </Button>
              </Modal.Footer>
            </form>
          </Modal>
        </div>
      </div>
    </>
  );
}
