import axios from "axios";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "moment";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
var jwt = require("jsonwebtoken");

function UserCustView({hadleradmin}) {

  const navigate = useNavigate();
  const { _id } = useParams("");
  const [data, setData] = useState([]);
  const [pageCount, setpageCount] = useState("");
  const [orgname, setOrgName] = useState("");
  const limit = 10;

  let token = localStorage.getItem("ChromePayAdmitToken");
  var decode1 = jwt.decode(token);
  let adminID = decode1.admminID;



  const userlist = async () => {
    const { custID } = { custID: _id };
    await axios.post(`/v1/admin/OrgCust/${custID}`).then((resp) => {
      const data = resp.data.findCust;
      const orgname = resp.data.OrganisationName;
      setOrgName(orgname);
      setData(data);
      console.log(data);
      const total = resp.data.totlaRow;
      console.log(total);
      const totalPage = Math.ceil(total / limit);
      setpageCount(totalPage);
    });
  };
  useEffect(() => {
    userlist();
    hadleradmin()
  }, []);

  ///////////////pagenestion///////////////
  const fetchComments = async (page) => {
    const senData = { page: page };
    // const cosole = Fromvalue;
    // console.log(Fromvalue);
    axios.post(`/v1/admin/CustomerList`, senData).then((resp) => {
      const data = resp.data.filter;
      setData(data);
    });
    return data;
  };

  const handlePageClick = async (data) => {
    // console.log(data.selected);
    const page = data.selected + 1;
    const commentsFormServer = await fetchComments(page);
    setData(commentsFormServer);
  };

  /////////////// unblocked  ////////////////////////
  const handleunblocked = (_id) => {
    console.warn(_id);
    axios.put(`/v1/admin/Unsuspendcustomer/${_id}/${adminID}`).then((res) => {
      if (res.status) {
        let data = res.data;
        toast.success(data.msg);
        return userlist();
      }
    });
  };

  /////////////////delete api call /////////////////
  const handlesuspend = (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to Suspend selected Customer ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Suspend it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios.put(`/v1/admin/suspendcustomer/${_id}/${adminID}`).then((res) => {
          if (res.status) {
            let data = res.data;
            if (data.status) {
              Swal.fire("Suspend!", "Customer Suspend successfully", "success");
              return userlist();
            } else {
              toast.error(data.msg);
            }
          } else {
            toast.error(data.msg);
          }
        });
      }
    });
  };

  /////////////////delete api call /////////////////
  const handledelete = (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete selected Customer ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`/v1/admin/deleteCustomer/${_id}/${adminID}`).then((res) => {
          if (res.status) {
            let data = res.data;
            if (data.status) {
              Swal.fire("Deleted!", "Customer deleted successfully", "success");
              return userlist();
            } else {
              toast.error(data.msg);
            }
          } else {
            toast.error(data.msg);
          }
        });
      }
    });
  };
  const handleView = (_id) => {
    console.log(_id);
    navigate( `/customer-view-admin/${_id}`);
    return false;
  };

  const handleVerfiy = (_id) => {
    const { custID } = { custID: _id };
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to verify selected Customer ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, verify it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`/v1/admin/customerVerify/${custID}/${adminID}`).then((res) => {
          if (res.status) {
            let data = res.data;
            if (data.status) {
              Swal.fire(
                "verified!",
                "Customer verified successfully",
                "success"
              );
              return userlist();
            } else {
              toast.error(data.msg);
            }
          } else {
            toast.error(data.msg);
          }
        });
      }
    });
  };

  return (
    <>
      <ToastContainer />
    
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl" id="kt_content_container">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div
                  className="card bg_card rounded-15 bgi-no-repeat bgi-position-x-end bgi-size-cover"
                  style={{ marginTop: "2rem" }}
                >
                  <div className="card-body container-xxl pt-10 pb-8">
                    <div className="d-flex align-items-center">
                      <h1 className="fw-semibold me-3 text-white">{orgname}</h1>
                      <span className="fw-semibold text-white opacity-50">
                        Customers
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold fs-3 mb-1">
                        Customers List
                      </span>
                      <span className="text-muted mt-1 fw-semibold fs-7"></span>
                    </h3>
                  </div>

                  <div className="card-body py-3">
                    <div className="table-responsive">
                      <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                          <tr className="fw-bold text-muted th-title">
                            <th className="min-w-125px">D-ID</th>
                            <th className="min-w-125px">Name</th>
                            <th className="min-w-150px">Email Address</th>
                            <th className="min-w-150px">Contact No.  </th>
                            <th className="min-w-100px">DOB</th>
                            <th className="min-w-100px">Date of Creation</th>
                            <th className="min-w-100px">Country</th>
                            <th className="min-w-100px ">Status</th>
                            <th className="min-w-100px text-center">Actions</th>
                          </tr>
                        </thead>

                        <tbody>
                          {data.map((item) => (
                            <tr>
                              <td>{item.digitalID}</td>
                              <td> {item.fullname} </td>
                              <td> {item.email} </td>
                              <td>
                                <span>{item.phone}</span>{" "}
                              </td>

                              <td>
                                {Moment(item.dateOfBirth).format("DD/MM//YYYY")}
                              </td>
                              <td>
                                {Moment(item.createdAt).format("DD/MM//YYYY")}
                              </td>
                              <td>
                                <span class="badge badge-light-info fs-5 capitalize-text">
                                  {item.nationality}
                                </span>
                              </td>
                              <td>
                                <span class="badge badge-light-info fs-5 capitalize-text">
                                {item.status}
                                </span>
                                </td>
                              <td>
                               <div className="d-flex justify-content-center flex-shrink-0">
                                  {item.status == "pending" ? (
                                    <>
                                      <button
                                        onClick={(e) => {
                                          handleVerfiy(item._id);
                                        }}
                                        className="btn btn-icon btn-active-color-primary btn-sm me-1 mr-4"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-trigger="hover"
                                        title="Pendding "
                                      >
                                        <span className="svg-icon svg-icon-3">
                                          {/* <i className="fad fa-eye-slash"></i> */}

                                          <img
                                            src="/assets_new/images/pendding1.png"
                                            width="30px"
                                          />
                                        </span>
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        onClick={(e) => {
                                          handleVerfiy(item._id);
                                        }}
                                        className="btn btn-icon btn-active-color-primary btn-sm me-1 mr-4"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-trigger="hover"
                                        title="Verify"
                                      >
                                        <span className="svg-icon svg-icon-3">
                                          {/* <i className="fad fa-eye-slash"></i> */}

                                          <img
                                            src="/assets_new/images/verify.png"
                                            width="28px"
                                          />
                                        </span>
                                      </button>
                                    </>
                                  )}
                                  {item.blocked == 1 ? (
                                    <>
                                      <button
                                        onClick={(e) => {
                                          handleunblocked(item._id);
                                        }}
                                        className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-trigger="hover"
                                        title="Delete "
                                      >
                                        <span className="svg-icon svg-icon-3">
                                          <img
                                            src="/assets_new/images/blocked.png"
                                            width="20px"
                                          />
                                        </span>
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        onClick={(e) => {
                                          handlesuspend(item._id);
                                        }}
                                        className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-title="Password Reset "
                                        data-bs-trigger="hover"
                                        title="Suspend "
                                      >
                                        <span className="svg-icon svg-icon-3">
                                          <img
                                            src="/assets_new/images/suspand.png"
                                            width="20px"
                                          />
                                        </span>
                                      </button>
                                    </>
                                  )}
                                  <button
                                    onClick={(e) => {
                                      handleView(item._id);
                                    }}
                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    title="View Detail"
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <i className="fad fa-eye fs-4"></i>
                                    </span>
                                  </button>
                                  <button
                                    onClick={(e) => {
                                      handledelete(item._id);
                                    }}
                                    className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    title="Delete"
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <img
                                        src="/assets_new/images/cross.png"
                                        width="20px"
                                      />
                                    </span>
                                  </button>
                                  <a
                                    href="#"
                                    className="btn btn-icon btn-detail btn-active-color-primary btn-sm me-1 mr-4"
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    data-bs-trigger="hover"
                                    title="Disable OTP"
                                  >
                                    <span className="svg-icon svg-icon-3">
                                      <i className="fad fa-eye-slash"></i>
                                    </span>
                                  </a>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="col-lg-12 mt-2 text-end">
                      <ReactPaginate
                        PreviousLabel={"Previous"}
                        NextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-end"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        PreviousClassName={"page-item"}
                        PreviousLinkClassName={"page-link pagestyle"}
                        NextClassName={"page-item"}
                        NextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserCustView;
