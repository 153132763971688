import React from "react";

import Header from "./Header";
import Sidebar from "./Sidebar";
import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
var jwt = require("jsonwebtoken");

function SubAdminView({hadleradmin}) {
  const { _id } = useParams("");

  const [RoleUpdated, setRoleUpdated] = useState([{
    RoleaddCustomers: false,
    RoleeditCustomers: false,
    RoleapproveDIDs: false,
    RoleviewCustomers: false,
    RoleblockCustomers: false,
    RoleunBlockCustomers: false,
    RoledeleteCustomers: false,
    RolesetcustomerOtpLimits: false,
    RolesetCustomerPasswordLimits: false,
    RoleupdateCustomers: false,
   RoleaddOrganisations:false,
   Roleblockorganisations:false,
    RoleunBlockOrganisations:false,
    RoledeleteOrganisations:false,
    RolesetOrgOptLimits:false,
    RolesetOrgPasswordLimits:false,
    RoleupdateOrganisations:false,
    RoleaddAgents:false,
    RoleblockAgents:false,
    RoleunBlockAgents:false,
    deleteAgents:false,
    RolesetagentOtpLimits:false,
    RolesetagentPasswordLimits:false,
    RoleupdateAgents:false,
    RoleIPblackListings:false,
    RoleIPwhiteListings:false,
    RoleaddSubAdmins:false,
    RoleblockSubAdmins:false,
    RoleunBlockSubAdmins:false,
    RoledeleteSubAdmins:false,
    RolupdateSubAgents:false,
  }]);
  const [subAdmin, setsubAdmin] = useState([]);
  const [Organisation, setOrganisation] = useState([]);
  const [Agent, setAgent] = useState([]);
  const [IP, setIP] = useState([]);
  const [adminIDRole, setadminIDRole] = useState([]);
  const ID = localStorage.getItem("ID");
  //   console.log(ID);

  let token = localStorage.getItem("ChromePayAdmitToken");
  var decode1 = jwt.decode(token);
  let adminID = decode1.admminID;

  const AddFormData =  (e) => {
    const { subAdminID } = { subAdminID: _id };
    console.log(subAdminID);
    e.preventDefault();
    const data = new FormData(e.target);
         const Formvlaues = Object.fromEntries(data.entries());
    console.log(" formdata in >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>to server",Formvlaues);


 

    console.log(" formdata in filter data  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>to server",Formvlaues);

    axios
      .post(`/updateSubAdminRoles/${subAdminID}`, Formvlaues)

      .then((resp) => {
        if (resp.status) {
          let data = resp.data;
          if (data.status) {
            // window.location="/agent-list";
            toast.success(data.msg);

            // e.target.reset();
          } else {
            toast.error(data.msg);
          }
        } else {
          toast.error(data.msg);
        }
      });
  };

  const userlist = async () => {
    const { adminID } = { adminID: _id };

    await axios.post(`/v1/admin/subAdminRole/${adminID}`,{},{ headers: {"Authorization" : `Bearer ${token}`}}).then((resp) => {
      let data = resp.data.find;
      const CustomerChecked = data.customer;
      console.log("subadmit role  view data ", data);
      setsubAdmin(CustomerChecked);
      setOrganisation(data.Organisation);
      setAgent(data.Agent);
      setIP(data.IP);
      setadminIDRole(data.subAdmin);

   
    });
  };

  useEffect(() => {
    userlist();
    hadleradmin();
  }, []);

  console.log("RoleUpdated",RoleUpdated)

  return (
    <>
      <ToastContainer />
    
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl" id="kt_content_container">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div className="card mb-5 mb-xl-10">
                  <div
                    className="card-header border-0 cursor-pointer"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_account_profile_details"
                    aria-expanded="true"
                    aria-controls="kt_account_profile_details"
                  >
                    <div className="card-title m-0">
                      <h3 className="fw-bold m-0">Add Roles</h3>
                    </div>
                  </div>
                  <div
                    id="kt_account_settings_profile_details"
                    className="collapse show"
                  >
                    <form
                      onSubmit={(e) => AddFormData(e)}
                      id="kt_account_profile_details_form"
                      className="form"
                    >
                      <div className="card-body border-top p-9">
                        <div className="mb-6">
                          <h6 className="required fw-semibold fs-66">
                            Customer Role
                          </h6>
                        </div>

                        <div className="row">
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Add Customer
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                {RoleUpdated.RoleaddCustomers ? (
                                  <input
                                    class="form-check-input mt-5"
                                    type="checkbox"
                                    name="addCustomer"
                                    role="switch"
                                 
                                  
                                  />
                                ) : (
                                  <input
                                    class="form-check-input mt-5"
                                    type="checkbox"
                                    checked={
                                      subAdmin.addCustomer == 1 ? true :false
                                    }
                                    defaultValue={subAdmin.addCustomer}
                                    onClick={() =>
                                      setRoleUpdated({
                                        ...RoleUpdated,
                                        RoleaddCustomers: true,
                                   
                                      })
                                    }
                                    name="addCustomer"
                                    role="switch"
                                  
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Edit Customer
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                {RoleUpdated.RoleeditCustomers ? (
                                  <input
                                    class="form-check-input mt-5"
                                    type="checkbox"
                                    name="editCustomer"
                                    role="switch"
                                  
                                  />
                                ) : (
                                  <input
                                    class="form-check-input mt-5"
                                    type="checkbox"
                                    name="editCustomer"
                                    checked={
                                      subAdmin.editCustomer == 1 ? true : false
                                    }
                                    defaultValue={ subAdmin.editCustomer}
                                    onClick={() =>
                                      setRoleUpdated({
                                        ...RoleUpdated,
                                        RoleeditCustomers: true,
                                      })
                                    }
                                    role="switch"
                                  
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Approve DID
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                {RoleUpdated.RoleapproveDIDs ? (
                                  <input
                                    class="form-check-input mt-5"
                                    type="checkbox"
                                    name="approveDID"
                                    role="switch"
                                  
                                  />
                                ) : (
                                  <input
                                    class="form-check-input mt-5"
                                    type="checkbox"
                                    name="approveDID"
                                    checked={
                                     
                                      subAdmin.approveDID == 1 ? true : false
                                    }
                                    onClick={() =>
                                      setRoleUpdated({
                                        ...RoleUpdated,
                                        RoleapproveDIDs: true,
                                      })
                                    }
                                    defaultValue={subAdmin.approveDID}
                                    role="switch"
                                  
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                View Customer
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                                {RoleUpdated.RoleviewCustomers ? (
                                  <input
                                    class="form-check-input mt-5"
                                    type="checkbox"
                                    name="viewCustomer"
                                    role="switch"
                                
                                  />
                                ) : (
                                  <input
                                    class="form-check-input mt-5"
                                    type="checkbox"
                                    name="viewCustomer"
                                    role="switch"
                                  
                                    checked={
                                      subAdmin.viewCustomer == 1 ? true : false
                                    }
                                    defaultValue={subAdmin.viewCustomer}
                                    onClick={() =>
                                      setRoleUpdated({
                                        ...RoleUpdated,
                                        RoleviewCustomers: true,
                                      })
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Block Customer
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                           {RoleUpdated.RoleblockCustomers?    <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                               
                                  name="blockCustomer"
                                  role="switch"
                                
                                />:<input
                                class="form-check-input mt-5"
                                type="checkbox"
                                checked={
                                  subAdmin.blockCustomer == 1 ? true : false
                                }
                                defaultValue={ subAdmin.blockCustomer}
                                onClick={() =>
                                  setRoleUpdated({
                                    ...RoleUpdated,
                                    RoleblockCustomers: true,
                                  })
                                }
                                name="blockCustomer"
                                role="switch"
                              
                              />} 
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Unblock Customer
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                           {RoleUpdated.RoleunBlockCustomers?    <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                 
                                  name="unBlockCustomer"
                                  role="switch"
                                
                                />: <input
                                class="form-check-input mt-5"
                                type="checkbox"
                                checked={
                                  subAdmin.unBlockCustomer == 1 ? true : false
                                }
                                onClick={() =>
                                  setRoleUpdated({
                                    ...RoleUpdated,
                                    RoleunBlockCustomers: true,
                                  })
                                }
                                defaultValue={subAdmin.unBlockCustomer}
                                name="unBlockCustomer"
                                role="switch"
                              
                              />} 
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Delete Customer
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                     {RoleUpdated.RoledeleteCustomers?          <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                
                                  name="deleteCustomer"
                                  role="switch"
                                
                                />:  <input
                                class="form-check-input mt-5"
                                type="checkbox"
                                checked={
                                  subAdmin.deleteCustomer == 1 ? true : false
                                }
                                onClick={() =>
                                  setRoleUpdated({
                                    ...RoleUpdated,
                                    RoledeleteCustomers: true,
                                  })
                                }
                                defaultValue={subAdmin.deleteCustomer}
                                name="deleteCustomer"
                                role="switch"
                              
                              />} 
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Setcustomer OtpLimit
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                          {RoleUpdated.RolesetcustomerOtpLimits?      <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                 
                                  name="setcustomerOtpLimit"
                                  role="switch"
                                
                                />:   <input
                                class="form-check-input mt-5"
                                type="checkbox"
                                checked={
                                  subAdmin.setcustomerOtpLimit == 1
                                    ? true
                                    : false
                                }   onClick={() =>
                                  setRoleUpdated({
                                    ...RoleUpdated,
                                    RolesetcustomerOtpLimits: true,
                                  })
                                }
                                defaultValue={ subAdmin.setcustomerOtpLimit}
                                name="setcustomerOtpLimit"
                                role="switch"
                              
                              />}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Set Customer OTP Limit
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                        {RoleUpdated.RolesetCustomerPasswordLimits?      <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                
                                  name="setCustomerPasswordLimit"
                                  role="switch"
                                
                                />:     <input
                                class="form-check-input mt-5"
                                type="checkbox"
                                checked={
                                  subAdmin.setCustomerPasswordLimit == 1
                                    ? true
                                    : false
                                }
                                defaultValue={subAdmin.setCustomerPasswordLimit}
                                onClick={() =>
                                  setRoleUpdated({
                                    ...RoleUpdated,
                                    RolesetCustomerPasswordLimits: true,
                                  })
                                }
                                name="setCustomerPasswordLimit"
                                role="switch"
                              
                              />}  
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Update Customer
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                             
                             
                             {RoleUpdated.RoleupdateCustomers?
                             
                             
                                <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                
                                  name="updateCustomer"
                                  role="switch"
                                  defaultValue={subAdmin.updateCustomer}
                                />:
                                <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  checked={
                                    subAdmin.updateCustomer == 1 ? true : false
                                  }
                                  onClick={() =>
                                    setRoleUpdated({
                                      ...RoleUpdated,
                                      RoleupdateCustomers: true,
                                    })
                                  }
                                  defaultValue={subAdmin.updateCustomer}
                                  name="updateCustomer"
                                  role="switch"
                                
                                />}
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <div className="mb-6 ">
                          <h6 className="required fw-semibold fs-66">
                            Organzation Role
                          </h6>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Add Organisation
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                       {RoleUpdated.addOrganisations?        <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  defaultValue={Organisation.addOrganisation}
                                  name="addOrganisation"
                                  role="switch"
                                
                                />:   <input
                                class="form-check-input mt-5"
                                type="checkbox"
                                checked={
                                  Organisation.addOrganisation == 1
                                    ? true
                                    : false
                                }
                                defaultValue={Organisation.addOrganisation}
                                onClick={() =>
                                  setRoleUpdated({
                                    ...RoleUpdated,
                                   RoleaddOrganisations: true,
                                  })
                                }
                                name="addOrganisation"
                                role="switch"
                              
                              />}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Block Organisation
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                              {RoleUpdated.blockorganisations?           <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  defaultValue={ Organisation.blockorganisation}
                                  name="blockorganisation"
                                  role="switch"
                                
                                />:          <input
                                class="form-check-input mt-5"
                                type="checkbox"
                                checked={
                                  Organisation.blockorganisation == 1
                                    ? true
                                    : false
                                }
                                defaultValue={ Organisation.blockorganisation}
                                onClick={() =>
                                  setRoleUpdated({
                                    ...RoleUpdated,
                                   Roleblockorganisations: true,
                                  })
                                }
                                name="blockorganisation"
                                role="switch"
                              
                              /> }
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                UnBlock Organisation
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                            {RoleUpdated.RoleunBlockOrganisations?    <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  defaultValue={  Organisation.unBlockOrganisation}
                                  name="unBlockOrganisation"
                                  role="switch"
                                
                                />: <input
                                class="form-check-input mt-5"
                                type="checkbox"
                                checked={
                                  Organisation.unBlockOrganisation == 1
                                    ? true
                                    : false
                                }
                                defaultValue={  Organisation.unBlockOrganisation}
                                onClick={() =>
                                  setRoleUpdated({
                                    ...RoleUpdated,
                                    RoleunBlockOrganisations: true,
                                  })
                                }
                                name="unBlockOrganisation"
                                role="switch"
                              
                              />}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Delete Organisation
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                            {RoleUpdated.RoledeleteOrganisations?    <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  defaultValue={   Organisation.deleteOrganisation}
                                  name="deleteOrganisation"
                                  role="switch"
                                
                                />: <input
                                class="form-check-input mt-5"
                                type="checkbox"
                                checked={
                                  Organisation.deleteOrganisation == 1
                                    ? true
                                    : false
                                }
                                defaultValue={   Organisation.deleteOrganisation}
                                onClick={() =>
                                  setRoleUpdated({
                                    ...RoleUpdated,
                                    RoledeleteOrganisations: true,
                                  })
                                }
                                name="deleteOrganisation"
                                role="switch"
                              
                              />}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Set Organisation OTP Limit
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                          {RoleUpdated.RolesetOrgOptLimits?     <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  defaultValue={ Organisation.setOrgOptLimit}
                                  name="setOrgOptLimit"
                                  role="switch"
                                
                                />:  <input
                                class="form-check-input mt-5"
                                type="checkbox"
                                checked={
                                  Organisation.setOrgOptLimit == 1
                                    ? true
                                    : false
                                }
                                onClick={() =>
                                  setRoleUpdated({
                                    ...RoleUpdated,
                                    RolesetOrgOptLimits: true,
                                  })
                                }
                                defaultValue={ Organisation.setOrgOptLimit}
                                name="setOrgOptLimit"
                                role="switch"
                              
                              />} 
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Set Organisation Password Limit
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                            {RoleUpdated.RolesetOrgPasswordLimits?    <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  defaultValue={Organisation.setOrgPasswordLimit}
                                  name="setOrgPasswordLimit"
                                  role="switch"
                                
                                />:  <input
                                class="form-check-input mt-5"
                                type="checkbox"
                                checked={
                                  Organisation.setOrgPasswordLimit == 1
                                    ? true
                                    : false
                                }
                                onClick={() =>
                                  setRoleUpdated({
                                    ...RoleUpdated,
                                    RolesetOrgPasswordLimits: true,
                                  })
                                }
                                defaultValue={Organisation.setOrgPasswordLimit}
                                name="setOrgPasswordLimit"
                                role="switch"
                              
                              />}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Update Organisation
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                            {RoleUpdated.RoleupdateOrganisations?    <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  defaultValue={ Organisation.updateOrganisation}
                                  name="updateOrganisation"
                                  role="switch"
                                
                                />: <input
                                class="form-check-input mt-5"
                                type="checkbox"
                                checked={
                                  Organisation.updateOrganisation == 1
                                    ? true
                                    : false
                                }   onClick={() =>
                                  setRoleUpdated({
                                    ...RoleUpdated,
                                    RoleupdateOrganisations: true,
                                  })
                                }
                                defaultValue={ Organisation.updateOrganisation}
                                name="updateOrganisation"
                                role="switch"
                              
                              />}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mb-6 ">
                          <h6 className="required fw-semibold fs-66">
                            Agent Role
                          </h6>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Add Agent
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                       {RoleUpdated.RoleaddAgents?         <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="addAgent"
                                  role="switch"
                                  defaultValue={ Agent.addAgent}

                                />:   <input
                                class="form-check-input mt-5"
                                type="checkbox"
                                name="addAgent"
                                role="switch"
                              
                                checked={Agent.addAgent == 1 ? true : false}
                                onClick={() =>
                                  setRoleUpdated({
                                    ...RoleUpdated,
                                    RoleaddAgents: true,
                                  })
                                }
                                defaultValue={ Agent.addAgent}
                              />}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Block Agent
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                            {RoleUpdated.RoleblockAgents?    <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="blockAgent"
                                  role="switch"
                                  defaultValue={ Agent.blockAgent}
                            
                                />: <input
                                class="form-check-input mt-5"
                                type="checkbox"
                                name="blockAgent"
                                role="switch"
                                defaultValue={ Agent.blockAgent}
                                checked={Agent.blockAgent == 1 ? true : false}
                                onClick={() =>
                                  setRoleUpdated({
                                    ...RoleUpdated,
                                    RoleblockAgents: true,
                                  })
                                }
                              />}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Unblock Agent
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                             {RoleUpdated.RoleunBlockAgents?   <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="unBlockAgent"
                                  role="switch"
                                  defaultValue={Agent.unBlockAgent}
                                
                                />:<input
                                class="form-check-input mt-5"
                                type="checkbox"
                                name="unBlockAgent"
                                role="switch"
                              
                                checked={
                                  Agent.unBlockAgent == 1 ? true : false
                                }
                                onClick={() =>
                                  setRoleUpdated({
                                    ...RoleUpdated,
                                    RoleunBlockAgents: true,
                                  })
                                }
                              />}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Delete Agent
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                             {RoleUpdated.deleteAgents?   <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="deleteAgent"
                                  role="switch"
                                  defaultValue={Agent.deleteAgent}
                                
                                />: <input
                                class="form-check-input mt-5"
                                type="checkbox"
                                name="deleteAgent"
                                role="switch"
                              
                                checked={
                                  Agent.deleteAgent == 1 ? true : false
                                }
                                defaultValue={Agent.deleteAgent}
                                onClick={() =>
                                  setRoleUpdated({
                                    ...RoleUpdated,
                                    deleteAgents: true,
                                  })
                                }
                              />}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Set Agent Otp Limit
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                             {RoleUpdated.RolesetagentOtpLimits?   <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="setagentOtpLimit"
                                  role="switch"
                                  defaultValue={Agent.setagentOtpLimit}
                                
                                />: <input
                                class="form-check-input mt-5"
                                type="checkbox"
                                name="setagentOtpLimit"
                                role="switch"
                              
                                checked={
                                  Agent.setagentOtpLimit == 1 ? true : false
                                }
                                defaultValue={Agent.setagentOtpLimit}
                                onClick={() =>
                                  setRoleUpdated({
                                    ...RoleUpdated,
                                    RolesetagentOtpLimits: true,
                                  })
                                }
                              />}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Set Agent Password Limit
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                        {RoleUpdated.RolesetagentPasswordLimits?        <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="setagentPasswordLimit"
                                  role="switch"
                                  defaultValue={Agent.setagentPasswordLimit}
                               
                                />:  <input
                                class="form-check-input mt-5"
                                type="checkbox"
                                name="setagentPasswordLimit"
                                role="switch"
                              
                                checked={
                                  Agent.setagentPasswordLimit == 1
                                    ? true
                                    : false
                                }
                                defaultValue={Agent.setagentPasswordLimit}
                                onClick={() =>
                                  setRoleUpdated({
                                    ...RoleUpdated,
                                    RolesetagentPasswordLimits: true,
                                  })
                                }
                              />}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Update Agent
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                             {RoleUpdated.RoleupdateAgents?   <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="updateAgent"
                                  role="switch"
                                  defaultValue={ Agent.updateAgent}
                               
                                />:<input
                                class="form-check-input mt-5"
                                type="checkbox"
                                name="updateAgent"
                                role="switch"
                                defaultValue={ Agent.updateAgent}
                                checked={
                                  Agent.updateAgent == 1 ? true : false
                                }
                                onClick={() =>
                                  setRoleUpdated({
                                    ...RoleUpdated,
                                    RoleupdateAgents: true,
                                  })
                                }
                              />}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mb-6 ">
                          <h6 className="required fw-semibold fs-66">
                            IP Role
                          </h6>
                        </div> */}

                        {/* <div className="row">
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                IP Black Listing
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                              
                            {RoleUpdated.RoleIPblackListings?   <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="IPblackListing"
                                  role="switch"
                                  defaultValue={  IP.IPblackListing }
                                
                                />: <input
                                class="form-check-input mt-5"
                                type="checkbox"
                                name="IPblackListing"
                                role="switch"
                              
                                checked={
                                  IP.IPblackListing == 1 ? true : false
                                }
                                defaultValue={  IP.IPblackListing }
                                onClick={() =>
                                  setRoleUpdated({
                                    ...RoleUpdated,
                                    RoleIPblackListings: true,
                                  })
                                }
                              />}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                IP White Listing
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                            {RoleUpdated.RoleIPwhiteListings?   <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="IPwhiteListing"
                                  role="switch"
                                  defaultValue={IP.IPwhiteListing }
                               
                                />: <input
                                class="form-check-input mt-5"
                                type="checkbox"
                                name="IPwhiteListing"
                                role="switch"
                              
                                checked={
                                  IP.IPwhiteListing == 1 ? true : false
                                }
                                defaultValue={IP.IPwhiteListing }
                                onClick={() =>
                                  setRoleUpdated({
                                    ...RoleUpdated,
                                    RoleIPwhiteListings: true,
                                  })
                                }
                              />} 
                              </div>
                            </div>
                          </div>
                        </div> */}
                        {/* <div className="mb-6 ">
                          <h6 className="required fw-semibold fs-66">
                            Sub Admin
                          </h6>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Add Sub Admin
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                            {RoleUpdated.RoleaddSubAdmins?    <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="addSubAdmin"
                                  role="switch"
                                  defaultValue={ adminIDRole.addSubAdmin }
                                
                                />:<input
                                class="form-check-input mt-5"
                                type="checkbox"
                                name="addSubAdmin"
                                role="switch"
                              
                                checked={
                                  adminIDRole.addSubAdmin == 1 ? true : false
                                }
                                defaultValue={ adminIDRole.addSubAdmin }
                                onClick={() =>
                                  setRoleUpdated({
                                    ...RoleUpdated,
                                    RoleaddSubAdmins: true,
                                  })
                                }
                              />}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Block Sub Admin
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                             
                             {RoleUpdated.RoleblockSubAdmins?   <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="blockSubAdmin"
                                  role="switch"
                                
                                  defaultValue={  adminIDRole.blockSubAdmin}
                                />: <input
                                class="form-check-input mt-5"
                                type="checkbox"
                                name="blockSubAdmin"
                                role="switch"
                              
                                checked={
                                  adminIDRole.blockSubAdmin == 1
                                    ? true
                                    : false
                                }
                                defaultValue={  adminIDRole.blockSubAdmin}
                                onClick={() =>
                                  setRoleUpdated({
                                    ...RoleUpdated,
                                    RoleblockSubAdmins: true,
                                  })
                                }
                              />}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Unblock Sub Admin
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                            {RoleUpdated.RoleunBlockSubAdmins?   <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="unBlockSubAdmin"
                                  role="switch"
                                  defaultValue={adminIDRole.unBlockSubAdmin}
                               
                                />:<input
                                class="form-check-input mt-5"
                                type="checkbox"
                                name="unBlockSubAdmin"
                                role="switch"
                              
                                checked={
                                  adminIDRole.unBlockSubAdmin == 1
                                    ? true
                                    : false
                                }
                                defaultValue={adminIDRole.unBlockSubAdmin}
                                onClick={() =>
                                  setRoleUpdated({
                                    ...RoleUpdated,
                                    RoleunBlockSubAdmins: true,
                                  })
                                }
                              />} 
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Delete Sub Admin
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                           {RoleUpdated.RoledeleteSubAdmins?     <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="deleteSubAdmin"
                                  role="switch"
                                  defaultValue={adminIDRole.deleteSubAdmin}
                               
                                />:    <input
                                class="form-check-input mt-5"
                                type="checkbox"
                                name="deleteSubAdmin"
                                role="switch"
                              
                                checked={
                                  adminIDRole.deleteSubAdmin == 1
                                    ? true
                                    : false
                                }
                                defaultValue={adminIDRole.deleteSubAdmin}
                                onClick={() =>
                                  setRoleUpdated({
                                    ...RoleUpdated,
                                    RoledeleteSubAdmins: true,
                                  })
                                }
                              />}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label
                                class="col-lg-10  form-check-label col-form-label  fw-semibold fs-6"
                                for="flexSwitchCheckDefault"
                              >
                                Update Sub Agent
                              </label>
                              <div class="col-lg-2 form-check form-switch  ">
                             {RoleUpdated.RolupdateSubAgents?   <input
                                  class="form-check-input mt-5"
                                  type="checkbox"
                                  name="updateSubAgent"
                                  role="switch"
                                  defaultValue={adminIDRole.updateSubAgent}
        
                                />:<input
                                class="form-check-input mt-5"
                                type="checkbox"
                                name="updateSubAgent"
                                role="switch"
                              
                                checked={
                                  adminIDRole.updateSubAgent == 1
                                    ? true
                                    : false
                                }
                                defaultValue={adminIDRole.updateSubAgent}
                                onClick={() =>
                                  setRoleUpdated({
                                    ...RoleUpdated,
                                    RolupdateSubAgents: true,
                                  })
                                }
                              />}
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                      <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button
                          type="submit"
                          className="btn btn-light btn-active-light-primary me-2"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SubAdminView;
