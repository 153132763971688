import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import CustomerLoader from "../CRM/Modal/CustomerLoder";

function ApplyforPersionLoan({ hadlersuper }) {
  let token = localStorage.getItem("SuperAgentChromepayToken");
  const navigate = useNavigate();
  const [InterestList, setInterestList] = useState([]);
  const [ShowPassword, setShowPassword] = useState(false);
  const [ShowLoader, setShowLoader] = useState(false);
  const [formData, setFormData] = useState({
    typeOfLoan: "",
    interestRate: "",
    loanDuration: "",
    repaymentSchedule: "",
    loanAmount: "",
    repaymentMethod: "",
    automatedType: "",
  });

  const handleChange = (e) => {
    const findPercentage = InterestList?.find(
      (item) => item.loanType === e?.target?.value
    );

    const { name, value } = e.target;

    setFormData((prevState) => {
      const newState = {
        ...prevState,
        [name]: name == "loanAmount" ? Number(value) : value,
      };

      if (name === "typeOfLoan") {
        newState.interestRate = findPercentage?.interestRate || "";
      }

      console.log({ newState });
      return newState;
    });
  };

  const AddFormData = async (e) => {
    e.preventDefault();
    setShowPassword(true);
  };

  const customerLoanInterestList = () => {
    axios
      .post(
        `/v1/super-Agent/get-loans-type-with-interest`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let data = resp.data.data;

        let listOfInterest = data?.map((item) => ({
          loanType: item?.loanType,
          interestRate: item?.interestRate,
        }));

        // listOfInterest.push({ loanType: "other", interestRate: "25" });
        console.log("---->>>>--->>>Loan list", data, listOfInterest);
        setInterestList(listOfInterest);
      });
  };

  useEffect(() => {
    hadlersuper();
    customerLoanInterestList();
  }, []);

  const handlerFormMatchPassword = async (e) => {
    e.preventDefault();
    setShowLoader(true);

    const form = new FormData(e.target);
    const Formulates = Object.fromEntries(form.entries());

    try {
      const matchPassResponse = await axios.post(
        `/v1/super-Agent/match-agent-pass`,
        Formulates,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const matchPassData = matchPassResponse.data;

      console.log({ matchPassData });

      if (matchPassData.status) {
        const createLoanResponse = await axios.post(
          `/v1/super-Agent/create-superAgentLoan`,
          formData,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        const createLoanData = createLoanResponse.data;

        if (createLoanData.status) {
          toast.success(createLoanData.msg);
          e.target.reset();
          setShowPassword(false);
        } else {
          toast.error(createLoanData.msg);
        }
      } else {
        toast.error(matchPassData.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: `${error.response.data.message}`,
          showConfirmButton: false,
          timer: 3500,
        });
      } else {
        toast.error("An unexpected error occurred.");
      }
    } finally {
      setShowLoader(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <CustomerLoader loader={ShowLoader} />
      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl" id="kt_content_container">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div className="card mb-5 mb-xl-10">
                  <div
                    className="card-header border cursor-pointer"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_account_profile_details"
                    aria-expanded="true"
                    aria-controls="kt_account_profile_details"
                  >
                    <div className="card-title m-0">
                      <h3 className="fw-bold m-0">Loan Apply</h3>
                    </div>
                  </div>
                  <div
                    id="kt_account_settings_profile_details"
                    className="collapse show"
                  >
                    <form
                      onSubmit={(e) => AddFormData(e)}
                      id="kt_account_profile_details_form"
                      className="form"
                    >
                      <div className="card-body border-top p-9">
                        <div className="row mb-8">
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                Type Of Loan:
                              </label>
                              <div className="col-lg-8 fv-row">
                                <select
                                  name="typeOfLoan"
                                  className="form-select border flex-grow- "
                                  data-placeholder="Selected Loan Type"
                                  required
                                  onChange={handleChange}
                                >
                                  {" "}
                                  <option selected>Selected Loan</option>
                                  {InterestList?.map((item) => (
                                    <option value={item?.loanType}>
                                      {item?.loanType}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 mb-6">
                            <div className="row">
                              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                Interest Rate
                              </label>
                              <div className="col-lg-8 fv-row">
                                <input
                                  type="number"
                                  min={1}
                                  name="interestRate"
                                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                  placeholder="interest Rate"
                                  value={formData?.interestRate}
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6 mb-8">
                            <div className="row">
                              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                Repayment Schedule
                              </label>
                              <div className="col-lg-8 fv-row">
                                <select
                                  name="repaymentSchedule"
                                  className="form-select border flex-grow-1"
                                  // data-placeholder="Please Select"
                                  required
                                  onChange={handleChange}
                                >
                                  <option disabled value="">
                                    Selected Repayment Schedule
                                  </option>
                                  <option value="monthly">Monthly</option>
                                  <option value="quarterly">Quarterly</option>
                                  <option value="halfyearly">
                                    Every 6 months
                                  </option>
                                  <option value="yearly">Yearly</option>
                                  {/* <option value="manually">Manually Pay</option> */}
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6 mb-8">
                            <div className="row">
                              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                Loan Duration
                              </label>
                              <div className="col-lg-8 fv-row">
                                <select
                                  name="loanDuration"
                                  className="form-select border flex-grow-1"
                                  data-placeholder="First Selected Repayment
                                                  Schedule"
                                  required
                                  onChange={handleChange}
                                >
                                  {formData?.repaymentSchedule=='' && (
                                    <option>
                                      First Selected Repayment Schedule
                                    </option>
                                  )}
                                  {formData?.repaymentSchedule == "monthly" ? (
                                    <>
                                      <option>Selected Monthly</option>
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                      <option value="4">4</option>
                                      <option value="5">5</option>
                                      <option value="6">6</option>
                                      <option value="7">7</option>
                                      <option value="8">8</option>
                                      <option value="9">9</option>
                                      <option value="10">10</option>
                                      <option value="11">11</option>
                                      <option value="12">12</option>
                                      <option value="13">13</option>
                                      <option value="14">14</option>
                                      <option value="15">15</option>
                                      <option value="16">16</option>
                                      <option value="17">17</option>
                                      <option value="18">18</option>
                                      <option value="19">19</option>
                                      <option value="20">20</option>
                                      <option value="21">21</option>
                                      <option value="22">22</option>
                                      <option value="23">23</option>
                                      <option value="24">24</option>
                                      <option value="25">25</option>
                                      <option value="26">26</option>
                                      <option value="27">27</option>
                                      <option value="28">28</option>
                                      <option value="29">29</option>
                                      <option value="30">30</option>
                                      <option value="31">31</option>
                                      <option value="32">32</option>
                                      <option value="33">33</option>
                                      <option value="34">34</option>
                                      <option value="35">35</option>
                                      <option value="36">36</option>
                                      <option value="37">37</option>
                                      <option value="38">38</option>
                                      <option value="39">39</option>
                                      <option value="40">40</option>
                                      <option value="41">41</option>
                                      <option value="42">42</option>
                                      <option value="43">43</option>
                                      <option value="44">44</option>
                                      <option value="45">45</option>
                                      <option value="46">46</option>
                                      <option value="47">47</option>
                                      <option value="48">48</option>
                                      <option value="49">49</option>
                                      <option value="50">50</option>
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  {formData?.repaymentSchedule ==
                                  "quarterly" ? (
                                    <>
                                      <option>Selected Quarterly</option>

                                      <option value="3">3</option>

                                      <option value="6">6</option>

                                      <option value="9">9</option>

                                      <option value="12">12</option>

                                      <option value="15">15</option>

                                      <option value="18">18</option>

                                      <option value="21">21</option>

                                      <option value="24">24</option>

                                      <option value="27">27</option>

                                      <option value="30">30</option>
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  {formData?.repaymentSchedule ==
                                  "halfyearly" ? (
                                    <>
                                      <option>Selected Half Yearly</option>

                                      <option value="6">6</option>

                                      <option value="12">12</option>

                                      <option value="18">18</option>

                                      <option value="24">24</option>

                                      <option value="30">30</option>
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  {formData?.repaymentSchedule == "yearly" ? (
                                    <>
                                      <option>Selected Yearly</option>
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6  mb-8 ">
                            <div className="row">
                              <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                Loan Amount (ETB)
                              </label>
                              <div className="col-lg-8 fv-row">
                                <input
                                  type="number"
                                  min={1}
                                  name="loanAmount"
                                  className="form-control form-control-lg form-control-solid"
                                  placeholder="Loan Amount (ETB)"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6  mb-8 ">
                            <div className="row">
                              <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                <span className="required">
                                  Repayment Method
                                </span>
                                {/* <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="Phone number must be active"></i> */}
                              </label>
                              <div className="col-lg-8 fv-row">
                                <select
                                  name="repaymentMethod"
                                  className="form-select border flex-grow-1"
                                  data-placeholder="Selected  Repayment Method"
                                  required
                                  onChange={handleChange}
                                >
                                    <option>Repayment Method</option>
                                  <option  value="cash">Cash</option>
                                  <option value="bankDeposit">
                                    Bank Deposit
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>

                          {formData?.repaymentMethod == "bankDeposit" && (
                            <div className="col-lg-6 mb-6">
                              <div className="row ">
                                <label className="col-lg-4 col-form-label fw-semibold fs-6">
                                  Auto-deduct Type
                                </label>
                                <div className="col-lg-8 fv-row">
                                  <select
                                    name="automatedType"
                                    className="form-select border flex-grow-1"
                                    onChange={handleChange}
                                    required
                                  >
                                    {" "}
                                    <option value="8">
                                      Selected Deduct Type
                                    </option>
                                    <option value="Automated">Automated</option>
                                    <option value="Non-automated">
                                      Non-automated
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button
                          type="submit"
                          className="btn btn-primary btn-active-light-primary me-2"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="modal-center"
        show={ShowPassword}
        onHide={() => setShowPassword(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Verify Password</Modal.Title>
        </Modal.Header>
        <form onSubmit={handlerFormMatchPassword}>
          <Modal.Body>
            <div className="col-lg-12 ">
              <div className="row">
                <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                  {" "}
                  Password
                </label>
                <div className="col-lg-8 fv-row">
                  <input
                    type="password"
                    name="password"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                    placeholder="Enter password"
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit" className="center">
              {" "}
              Submit
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default ApplyforPersionLoan;
