import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { error } from "jquery";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
export default function AddDocumentAgentCreated({ hadlerorg }) {
   let token = localStorage.getItem("organizationToken");
  let Image = localStorage.getItem("Image");
  let firstName = localStorage.getItem("firstName");
  let lastName = localStorage.getItem("lastName");
  let orgType = localStorage.getItem("orgType");
  let phone = localStorage.getItem("phone");
  let email = localStorage.getItem("email");
  let country = localStorage.getItem("country");
  let city = localStorage.getItem("city");
  let address = localStorage.getItem("address");
  let postalCode = localStorage.getItem("postalCode");
  let password = localStorage.getItem("password");
  let confirmPassword = localStorage.getItem("confirmPassword");
  let cooperativeType = localStorage.getItem("cooperativeType");
  let cooperativeLicenses = localStorage.getItem("cooperativeLicenses");
  let cooperativeLicenseFee = localStorage.getItem("cooperativeLicenseFee");
  let lincenseCertificateNumber = localStorage.getItem("lincenseCertificateNumber");
  let registrationFee = localStorage.getItem("registrationFee");

  let shares = localStorage.getItem("shares");
  let Loans = localStorage.getItem("Loans");
  let voluntarySavings = localStorage.getItem("voluntarySavings");
  let mandatorySavings = localStorage.getItem("mandatorySavings");
  let childSaving = localStorage.getItem("childSaving");


  const navigate = useNavigate();

  const [imageUrl1, setImageUrl1] = useState("");
  const [imageUrl2, setImageUrl2] = useState("");
  const [imageUrl3, setImageUrl3] = useState("");
  const [licenceCertificate, setlicenceCertificate] = useState("");
  const [membershipRegistration, setmembershipRegistration] = useState("");
  const [applicationLetter, setapplicationLetter] = useState("");

  const [profile_View, setprofile_View] = useState(false);

  const handleresidance = (data) => {
    const url = URL.createObjectURL(data);
    console.log("@#@#@" + url);
    setImageUrl2(url);
    console.log("image data", data);
    const token = localStorage.getItem("token");

    let formdata = new FormData();
    formdata.append("image", data);

    console.log("image first datav ", formdata);
    axios
      .post(`/v1/DID/ImageUploader`, formdata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const imgurl = res.data.data;
        console.log(" imagr url ", imgurl);
        setlicenceCertificate(imgurl);
      })
      .catch(error);
  };

  const handlermembershipRegistration = (data) => {
    const url = URL.createObjectURL(data);
    console.log("@#@#@" + url);
    setImageUrl1(url);
    const token = localStorage.getItem("token");
    console.log("token", token);
    let formdata = new FormData();
    formdata.append("image", data);

    axios
      .post(`/v1/DID/ImageUploader`, formdata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const imgurl = res.data.data;
        console.log(" imagr url ", imgurl);
        setmembershipRegistration(imgurl);
      })
      .catch(error);
  };

  const handlelandRegistration = (data) => {
    const url = URL.createObjectURL(data);
    console.log("@#@#@" + url);
    setImageUrl3(url);
    console.log("file handle faya", data);
    const token = localStorage.getItem("token");

    let formdata = new FormData();
    formdata.append("image", data);

    axios
      .post(`/v1/DID/ImageUploader`, formdata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const imgurl = res.data.data;
        console.log(" imagr url ", imgurl);
        setapplicationLetter(imgurl);
      })
      .catch(error);
  };

  const AddFormData = async (e) => {
    e.preventDefault();

    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    console.log(Formvlaues);

    let dataToSend2 = new FormData();
    dataToSend2.append("Image", Image);
    dataToSend2.append("firstName", firstName);
    dataToSend2.append("lastName", lastName);
    dataToSend2.append("orgType", orgType);
    dataToSend2.append("phone", phone);
    dataToSend2.append("email", email);
    dataToSend2.append("country", country);
    dataToSend2.append("city", city);
    dataToSend2.append("address", address);
    dataToSend2.append("postalCode", postalCode);
    dataToSend2.append("password", password);
    dataToSend2.append("confirmPassword", confirmPassword);
    dataToSend2.append("licenceCertificate", licenceCertificate);
    dataToSend2.append("membershipRegistration", membershipRegistration);
    dataToSend2.append("applicationLetter", applicationLetter);
    dataToSend2.append("shares", shares);
    dataToSend2.append("Loans", Loans);
    dataToSend2.append("voluntarySavings", voluntarySavings);
    dataToSend2.append("mandatorySavings", mandatorySavings);
    dataToSend2.append("childSaving", childSaving);
    dataToSend2.append("lincenseCertificateNumber", lincenseCertificateNumber);
    dataToSend2.append("registrationFee", registrationFee);
    
    if(orgType=="Cooperative"){
      dataToSend2.append("cooperativeType", cooperativeType);
      // dataToSend2.append("cooperativeLicenses", cooperativeLicenses);
      // dataToSend2.append("cooperativeLicenseFee", cooperativeLicenseFee);
    }
  


    setprofile_View(true);
    await axios
      .post(`/v1/org/CRM/create-super-agent`, dataToSend2, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.status) {
          let data = res.data;
          toast.success(data.msg);
          setprofile_View(false);
          console.log("first data ", { res });
          if (data.status) {
            toast.success(data.msg);
            setprofile_View(false);
            setTimeout(() => {
                navigate("/super-agent-list");
            }, 3000);
          }
        } else {
          toast.error(data.msg);
          setprofile_View(false);
        }
      });

    // navigate("/agent/customer/address");
  };

  useEffect(() => {
    hadlerorg();
  }, []);

  return (
    <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
      <div
        className="content d-flex flex-column flex-column-fluid"
        id="kt_content"
      >
        <ToastContainer />

        <div class="container-xxl" >
          <div class="row g-5 g-xl-8">
            <div class="col-lg-12">
              <div class="card mb-5 mb-xl-10">
                <div
                  class="card-header border-0 cursor-pointer"
                  role="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#kt_account_profile_details"
                  aria-expanded="true"
                  aria-controls="kt_account_profile_details"
                >
                  <div class="card-title m-0">
                    <h3 class="fw-bold m-0">Add Document</h3>
                  </div>
                  <div class="my">
                    <div class="margin-area">
                      <div class="dot one">1</div>
                      <div class="dot two">2</div>
                      {/* <div class="dot four">4</div> */}
                      <div class="progress-bar first"></div>
                      <div class="message message-1">Profile</div>`
                      <div class="message message-2">Document</div>
                    </div>
                  </div>
                </div>

                <div
                  id="kt_account_settings_profile_details"
                  class="collapse show"
                >
                  <form onSubmit={(e) => AddFormData(e)}>
                    <div class="card-body border-top p-9">
                      <div className="row mt-5 mb-6">
                        <div className="col-lg-12">
                          <div className="row mt-5 mb-6">
                            <label class="col-lg-4 col-form-label fw-semibold fs-6">
                            Membership Registration Form
                            </label>
                            <div class="col-lg-8">
                              <div
                                class="image-input image-input-outline"
                                data-kt-image-input="true"
                                style={{
                                  backgroundImage:
                                    "url('/metronic8/demo4/assets/media/svg/avatars/blank.svg')",
                                }}
                              >
                                <div
                                  class="image-input-wrapper w-125px h-125px"
                                  style={{
                                    backgroundImage:
                                      "url(/metronic8/demo4/assets/media/avatars/300-1.jpg)",
                                  }}
                                ></div>

                                <div
                                  style={{ position: "absolute", top: "0px" }}
                                >
                                  {imageUrl1 && (
                                    <img
                                      src={imageUrl1}
                                      alt="Uploaded"
                                      className="w-125px h-125px"
                                    />
                                  )}
                                </div>
                                <label
                                  class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                  data-kt-image-input-action="change"
                                  data-bs-toggle="tooltip"
                                  title="Upload Image"
                                >
                                  <i class="bi bi-pencil-fill fs-7"></i>
                                  {/* onChange={(e)=>handleresidance(e.target.files[0])} */}
                                  <input
                                    type="file"
                                    name="localImage"
                                    onChange={(e) =>
                                      handlermembershipRegistration(
                                        e.target.files[0]
                                      )
                                    }
                                  />

                                  {/* <input type="hidden" name="avatar_remove" /> */}
                                </label>

                                <span
                                  class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                  data-kt-image-input-action="cancel"
                                  data-bs-toggle="tooltip"
                                  title="Cancel avatar"
                                >
                                  <i class="bi bi-x fs-2"></i>
                                </span>

                                <span
                                  class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                  data-kt-image-input-action="remove"
                                  data-bs-toggle="tooltip"
                                  title="Remove avatar"
                                >
                                  <i class="bi bi-x fs-2"></i>
                                </span>
                              </div>

                              <div class="form-text">
                                Allowed file types: png, jpg, jpeg.
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="row mt-5 mb-6">
                            <label class="col-lg-4 col-form-label fw-semibold fs-6">
                              {" "}
                          Licence Certificate
                            </label>
                            <div class="col-lg-8">
                              <div
                                class="image-input image-input-outline"
                                data-kt-image-input="true"
                                style={{
                                  backgroundImage:
                                    "url('/metronic8/demo4/assets/media/svg/avatars/blank.svg')",
                                }}
                              >
                                <div
                                  class="image-input-wrapper w-125px h-125px"
                                  style={{
                                    backgroundImage:
                                      "url(/metronic8/demo4/assets/media/avatars/300-1.jpg)",
                                  }}
                                ></div>

                                <div
                                  style={{ position: "absolute", top: "0px" }}
                                >
                                  {imageUrl2 && (
                                    <img
                                      src={imageUrl2}
                                      alt="Uploaded"
                                      className="w-125px h-125px"
                                    />
                                  )}
                                </div>
                                <label
                                  class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                  data-kt-image-input-action="change"
                                  data-bs-toggle="tooltip"
                                  title="Upload Image"
                                >
                                  <i class="bi bi-pencil-fill fs-7"></i>
                                  {/* onChange={(e)=>handleresidance(e.target.files[0])} */}
                                  <input
                                    type="file"
                                    name="residaceImage"
                                    onChange={(e) =>
                                      handleresidance(e.target.files[0])
                                    }
                                  />
                                  {/* <input type="hidden" name="avatar_remove" /> */}
                                </label>

                                <span
                                  class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                  data-kt-image-input-action="cancel"
                                  data-bs-toggle="tooltip"
                                  title="Cancel avatar"
                                >
                                  <i class="bi bi-x fs-2"></i>
                                </span>

                                <span
                                  class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                  data-kt-image-input-action="remove"
                                  data-bs-toggle="tooltip"
                                  title="Remove avatar"
                                >
                                  <i class="bi bi-x fs-2"></i>
                                </span>
                              </div>

                              <div class="form-text">
                                Allowed file types: png, jpg, jpeg.
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="row mt-5 mb-6">
                            <label class="col-lg-4 col-form-label fw-semibold fs-6">
                              {" "}
                              Application Letter 
                            </label>
                            <div class="col-lg-8">
                              <div
                                class="image-input image-input-outline"
                                data-kt-image-input="true"
                                style={{
                                  backgroundImage:
                                    "url('/metronic8/demo4/assets/media/svg/avatars/blank.svg')",
                                }}
                              >
                                <div
                                  class="image-input-wrapper w-125px h-125px"
                                  style={{
                                    backgroundImage:
                                      "url(/metronic8/demo4/assets/media/avatars/300-1.jpg)",
                                  }}
                                ></div>

                                <div
                                  style={{ position: "absolute", top: "0px" }}
                                >
                                  {imageUrl3 && (
                                    <img
                                      src={imageUrl3}
                                      alt="Uploaded"
                                      className="w-125px h-125px"
                                    />
                                  )}
                                </div>
                                <label
                                  class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                  data-kt-image-input-action="change"
                                  data-bs-toggle="tooltip"
                                  title="Upload Image"
                                >
                                  <i class="bi bi-pencil-fill fs-7"></i>
                                  {/* onChange={(e)=>handleresidance(e.target.files[0])} */}
                                  <input
                                    type="file"
                                    name="landImage"
                                    onChange={(e) =>
                                      handlelandRegistration(e.target.files[0])
                                    }
                                  />
                                  {/* <input type="hidden" name="avatar_remove" /> */}
                                </label>

                                <span
                                  class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                  data-kt-image-input-action="cancel"
                                  data-bs-toggle="tooltip"
                                  title="Cancel avatar"
                                >
                                  <i class="bi bi-x fs-2"></i>
                                </span>

                                <span
                                  class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                  data-kt-image-input-action="remove"
                                  data-bs-toggle="tooltip"
                                  title="Remove avatar"
                                >
                                  <i class="bi bi-x fs-2"></i>
                                </span>
                              </div>

                              <div class="form-text">
                                Allowed file types: png, jpg, jpeg.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="row mb-6">
                                                <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                                                    Identification Document Image
                                                </label>

                                                <div className="col-lg-8 fv-row">
                                                    <input
                                                        type="file"
                                                        name="local"
                                                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                        placeholder="Residance Image"
                                                        onChange={(e) => handleresidance(e.target.files[0])}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-6">
                                                <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                                                    Proof of Residence
                                                </label>

                                                <div className="col-lg-8 fv-row">
                                                    <input
                                                        type="file"
                                                        name="residace"
                                                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                        placeholder="Local Document"
                                                        onChange={(e) => handlermembershipRegistration(e.target.files[0])}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-6">
                                                <label className="col-lg-4 col-form-label  fw-semibold fs-6">
                                                    Assets Ownership Certificate
                                                </label>

                                                <div className="col-lg-8 fv-row">
                                                    <input
                                                        type="file"
                                                        name="land"
                                                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                                        placeholder="Land Registration"
                                                        onChange={(e) => handlelandRegistration(e.target.files[0])}
                                                    />
                                                </div>
                                            </div> */}
                    </div>
                    <div className="card-footer d-flex justify-content-end py-6 px-9">
                      <button
                        type="submit"
                        class="btn btn-primary"
                        id="kt_account_profile_details_submit"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer py-4 d-flex flex-lg-column" id="kt_footer">
          <div class="container-xxl d-flex flex-column flex-md-row flex-stack">
            <div class="text-dark order-2 order-md-1">
              <span class="text-gray-400 fw-semibold me-1">Powered by</span>
              <a
                href="#"
                class="text-muted text-hover-primary fw-semibold me-2 fs-6"
              >
                Chromepay
              </a>
            </div>

            <ul class="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
              <li class="menu-item">
                <a href="#" class="menu-link px-2">
                  About
                </a>
              </li>
              <li class="menu-item">
                <a href="#" class="menu-link px-2">
                  Support
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Modal
        style={{ marginTop: "15rem" }}
        show={profile_View}
        onHide={() => setprofile_View(false)}
      >
        <Modal.Header>
          <Modal.Title> Please Wait Work is Processing </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="d-flex justify-content-center align-items-center">
              <div
                class="spinner-border text-primary "
                style={{ width: "5rem", height: "5rem" }}
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
