import React from "react";
import { useState, useRef, useEffect } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ReactPaginate from "react-paginate";

import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
import SuperAgentVerifyModal from "./SuperAgentVerifyModal";
import CustomerLoader from "../../CRM/Modal/CustomerLoder";

export default function ShareWithdrawerComponets({ userPro, HandleDetails }) {
  let token = localStorage.getItem("SuperAgentChromepayToken");
  const [ShowModel, setShowModel] = useState(false);

  const [ShowLoader, setShowLoader] = useState(false);
  const [pageCount, setpageCount] = useState("");
  const [shareBuySell, setShareBuySell] = useState([]);

  const [filterdata, setfilterdata] = useState("approve");

  const WithdrawShareData = async (e) => {
    setShowLoader(true);
    axios
      .post(
        `/v1/super-Agent/share-request-for-withdrawal`,
        {
          numberOfShare: userPro?.numberOfShares,
          investAmount: userPro?.totalShareAmount,
          price: userPro?.currentSharePrice,
          shareType: "withdrawal",
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )

      .then((resp) => {
        let data = resp.data;
        if (data?.status) {
          toast.success(data?.msg);
          AddShareDeatailshistory();

          HandleDetails();
          setShowLoader(false);
        } else {
          toast.error(data.msg);
          setShowLoader(false);
        }
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("form");
    setShowModel(true);
  };

  let limit = 10;

  const AddShareDeatailshistory = () => {
    axios
      .post(
        `/v1/super-Agent/share-transaction`,
        { page: 1, status: filterdata },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let saveData = resp?.data?.data?.list;
        setShareBuySell(saveData);
        const total = resp.data?.data?.count;
        const totalPage = Math.ceil(total / limit);

        setpageCount(totalPage || 0);

        console.log("UserShare", total);
      });
  };

  useEffect(() => {
    AddShareDeatailshistory();
  }, []);

  useEffect(() => {
    AddShareDeatailshistory();
  }, [filterdata]);

  const handlePageClick = async (data) => {
    const page = data.selected + 1;

    try {
      const resp = await axios.post(
        `/v1/super-Agent/share-transaction`,
        { page: page },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      let saveData = resp?.data?.data?.list;
      setShareBuySell(saveData);
      const total = resp.data?.data?.count;
      const totalPage = Math.ceil(total / limit);

      setpageCount(totalPage || 0);

      console.log("UserShare", total);
      // ...
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDownloadInvoice = (invoiceData) => {
    // Create a new jsPDF instance
    const doc = new jsPDF();
    console.log({ invoiceData, userPro });

    // Set up styling for the PDF
    doc.setFont("helvetica", "bold");
    doc.setFontSize(18);

    const width = doc.internal.pageSize.getWidth();

    // Calculate the width of the text for the invoice heading
    const headingTextWidth =
      (doc.getStringUnitWidth("Your Withdrawal Deposit Invoice") *
        doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const headingX = (width - headingTextWidth) / 2;

    // Add the invoice heading
    doc.text("Your Withdrawal Deposit Invoice", headingX, 20);

    // Format the date as dd mm yyyy
    const formattedDate = new Date(invoiceData.createdAt).toLocaleDateString(
      "en-GB",
      {
        day: "2-digit",
        month: "short",
        year: "numeric",
      }
    );

    // Add the date on the right side
    const dateTextWidth =
      (doc.getStringUnitWidth(`Date: ${formattedDate}`) *
        doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const dateX = width - dateTextWidth - 10; // Subtracting 10 for padding
    doc.setFont("helvetica", "normal");
    doc.setFontSize(15);
    doc.text(`Date: ${formattedDate}`, dateX, 40);

    // Set up styling for the section headings
    doc.setFont("helvetica", "bold");
    doc.setFontSize(20);

    // Add the customer details heading with left alignment
    doc.text("Customer Details", 10, 60);

    // Add the customer details
    doc.setFont("helvetica", "normal");
    doc.setFontSize(15);
    doc.text(`Customer ID: ${userPro._id}`, 10, 80);
    doc.text(`Customer Name: ${userPro.firstName}`, 10, 90);
    doc.text(`Customer Phone: ${userPro.phone}`, 10, 100);
    doc.text(`Status: ${invoiceData?.status}`, 10, 110);
    // Add the saving details heading with left alignment
    doc.text("Share Details", 10, 120);

    // Add the share details table

    doc.autoTable({
      startY: 140,
      head: [
        [
          "Share ID",
          "Share Quantity",
          "Share Price (ETB)",
          invoiceData?.status == "cancel"
            ? "Refunded Amount (ETB)"
            : "Invest Amount (ETB)",
        ],
      ],
      body: [
        [
          invoiceData._id,
          invoiceData.numberOfShare,
          ` ${invoiceData.price}`,
          `${invoiceData.investAmount || 0}`,
        ],
      ],
    });

    // Add the total share amount and total saving amount on the right side in a smaller font size
    const totalTextSize = 10;
    const totalX = width - 70; // Adjust as needed
    doc.setFontSize(totalTextSize);

    doc.text(
      `Total Share Amount (ETB):  ${
        invoiceData.numberOfShare * invoiceData.price || 0
      }`,
      totalX,
      doc.autoTable.Previous.finalY + 10
    );

    // Save the PDF and initiate download
    doc.save("invoice.pdf");
  };

  return (
    <div className="row">
      <CustomerLoader loader={ShowLoader} />
      <ToastContainer />
      <div className="col-lg-6">
        <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
          <div className="card-header cursor-pointer">
            <div className="card-body p-9">
              <div
                id="kt_account_settings_profile_details"
                className="collapse show"
              >
                <form
                  id="kt_account_profile_details_form"
                  className="form"
                  onSubmit={handleSubmit}
                >
                  <div className="card-body border-top p-9">
                    <div className="row mb-8">
                      <div className="col-lg-12 mb-8">
                        <div className="row">
                          <label className="col-lg-5 col-form-label required fw-semibold fs-6">
                            Share Quantity
                          </label>
                          <div className="col-lg-7 fv-row">
                            <input
                              required
                              type="number"
                              name="quantity"
                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                              placeholder="Enter share quantity"
                              step="any"
                              min={1}
                              value={(userPro?.numberOfShares)?.toFixed(2)}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-12 mb-8">
                        <div className="row">
                          <label className="col-lg-5 col-form-label required fw-semibold fs-6">
                            Current Share Price (ETB)
                          </label>
                          <div className="col-lg-7 fv-row">
                            <input
                              required
                              readOnly
                              type="number"
                              name="sharePrice"
                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                              placeholder="Number of share Price "
                              min={1}
                              value={userPro?.currentSharePrice}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-12 mb-8">
                        <div className="row">
                          <label className="col-lg-5 col-form-label required fw-semibold fs-6">
                            Current Amount (ETB)
                          </label>
                          <div className="col-lg-7 fv-row">
                            <input
                              type="number"
                              name="investAmount"
                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                              placeholder="Enter Invest Amount"
                              min={1}
                              value={userPro?.totalShareAmount}
                            />
                          </div>
                        </div>
                      </div>

                      {/* <div className="col-lg-12 mb-8">
                        <div className="row">
                          <label className="col-lg-5 col-form-label required fw-semibold fs-6">
                            Pay Method
                          </label>
                          <div className="col-lg-7 fv-row">
                            <select
                              name="payType"
                              required
                              className="form-select  flex-grow-1"
                       
                              data-placeholder="Please Select"
                              onChange={(e) => setDeposit(e.target.value)}
                            >
                              <option value="">Choose the Pay Method</option>

                              <option value={"volantary"}>Voluntary</option>

                              <option value={"cash"}>Cash</option>
                              <option value={"BackDeposit"}>
                                Bank Deposit
                              </option>
                            </select>
                          </div>
                        </div>
                      </div> */}

                      {/* {depositBank === "BackDeposit" ? (
                        <div className="row mt-5">
                          <label className="col-lg-5 col-form-label required fw-semibold fs-6">
                            Deposit Slip
                          </label>
                          <div className="col-lg-7 fv-row">
                            <input
                              type="file"
                              name="depositSlip"
                              accept=".jpg, .jpeg, .png, .pdf"
                              className="form-control"
                              required
                              onChange={(e) =>
                                handleFileChange(e.target.files[0])
                              }
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )} */}
                    </div>

                    <div className="card-footer d-flex justify-content-end py-6 px-9">
                      <button
                        type="submit"
                        className="btn btn-light btn-active-light-primary me-2"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-6">
        <div className="card card-xxl-stretch mb-5 mb-xl-8">
          <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="cardlabel fw-bold fs-3 mb-1">
                Recent Transactions
              </span>
              <span className="text-muted mt-1 fw-semibold fs-7"></span>
            </h3>
            <h3 className="card-title align-items-start flex-column">
              <div className="row">
                <div className="col-lg-12 fv-row d-flex align-items-center justify-content-center">
                  <div className="d-flex">
                    <select
                      value={filterdata}
                      name="marriageStatus"
                      className="form-select  flex-grow-1"
                      data-placeholder="Please Select"
                      onChange={(e) => setfilterdata(e.target.value)}
                    >
                      <option value={"pending"}>Pending</option>
                      <option value={"approve"}>Approve</option>
                      <option value={"cancel"}>Cancel</option>
                    </select>
                  </div>
                </div>
              </div>
            </h3>
            <div className="text-end">
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button btn  btn-sm btn-SUCCESS "
                table="table-to-xls"
                filename="tablexls"
                sheet="tablexls"
                buttonText={
                  <>
                    <i className="fad fa-download fs-1"></i>
                  </>
                }
              />
            </div>
          </div>

          <div className="card-body py-3">
            <div className="table-responsive">
              <table
                id="table-to-xls"
                className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
              >
                <thead>
                  <tr className="fw-bold text-muted th-title">
                    <th className="min-w-125px">
                      Investing <br /> Date{" "}
                    </th>
                    <th className="min-w-150px">Quantity</th>
                    <th className="min-w-150px">Share Price (ETB)</th>
                    <th className="min-w-100px">Invest Amount (ETB)</th>
                    <th className="min-w-100px">Status</th>
                    <th className="min-w-100px">Invoice</th>
                  </tr>
                </thead>

                <tbody>
                  {shareBuySell?.map((item) => (
                    <tr key={item?.id}>
                      <td className="text-center">
                        {moment(item?.createdAt).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}
                      </td>
                      <td className="text-center">{Math.round(item?.numberOfShare)}</td>

                      <td className="text-center">{userPro?.currentSharePrice}</td>
                      <td className="text-center">{item?.investAmount}</td>
                      <td className="text-center">{item?.status}</td>

                      <td className="text-center" >
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={() => handleDownloadInvoice(item)}
                        >
                          Download Invoice
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="col-lg-12 mt-2 text-end">
              <ReactPaginate
                PreviousLabel={"Previous"}
                NextLabel={"Next"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-end gap-sm-3"}
                pageClassName={"page-item mx-2"}
                pageLinkClassName={"page-link"}
                PreviousClassName={"page-item"}
                PreviousLinkClassName={"page-link pagestyle"}
                NextClassName={"page-item"}
                NextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>
      </div>

      <SuperAgentVerifyModal
        ShowModel={ShowModel}
        setShowModel={setShowModel}
        handleSubmit={WithdrawShareData}
      />
    </div>
  );
}
