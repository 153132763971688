import React from 'react'
import axios from "axios";
import { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";

function OrgAllNameModal({ open, setOpen ,handlereload}) {
  const [data, setData] = useState([]);
  // const [pageCount, setpageCount] = useState("");
  const [total, settotal] = useState("");
  const [ShowLoader, setShowLoader] = useState(true);
  const [addApi, setAddApi] = useState(true)
  const limit = 10;
  const [OrgInfo, setOrgInfo] = useState([]);
  const [addOgn, setaddOgn] = useState([])

  let token = localStorage.getItem("ChromePayAdmitToken");

  // organization-List

  const organizationList = async () => {
    setShowLoader(true);
    await axios
      .post(`/v1/admin/OrganisationList`,
        {},
        { headers: { Authorization: `bearer ${token}` } })
        
      .then((resp) => {
        const data = resp.data.filter;
        setOrgInfo(data)
        //   console.log("fnjfjrfrehferfhrf",{data})
        setShowLoader(false);
      });
  };
  useEffect(() => {
    
    organizationList()

  }, [])

  // pagenestion //
  // const fetchComments = async (page) => {
  //   const senData = { page: page };
  //   axios
  //     .post(`/v1/admin/list-org-jdc-ice`, senData, {
  //       headers: { Authorization: `bearer ${token}` },
  //     })
  //     .then((resp) => {
  //       const data = resp.data.data;
  //       console.log('mydata');
  //       console.log(data);
  //       setData(data);
  //     });
  //   return data;
  // };

  const handlePageClick = async (data) => {

    // console.log(data.selected)
    // const page = data.selected + 1;
    // const commentsFormServer = await fetchComments(page);

  };
  
  // add+Api
  const AddorgProduct = async (item) => {
    // console.log(item)
    await axios
      .post(`/v1/admin/add-org-jdc-ice`,
        {orgId:item?._id,type:"ice"},
        { headers: { Authorization: `bearer ${token}` } }
      )
      .then((resp) => {
        const data = resp.data;
        if(data?.status){
          setOpen(false)
          handlereload()
        }
        // console.log("mydata" ,data)
      });
  };

  return (
    <>
      <Modal
        show={open}
        onHide={() => setOpen(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>

        <Modal.Header closeButton>
          <Modal.Title> Add Organisation</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="d-flex flex-column " >
            <div className="page d-flex flex-row flex-column-fluid m-0 p-0 m-0">
              <div className="wrapper d-flex flex-column flex-row-fluid p-0 "
                id="kt_wrapper">
                <div
                  className="content d-flex flex-column flex-column-fluid w-100 align-items-center justify-content-center"
                  id="kt_content">
                  <div className="" id="kt_content_container w-100">
                    <div className="row g-5 g-xxl-8 w-100 ">

                      <div className="col-lg-12 w-100 d-flex align-items-center justify-content-center">
                        <div className="card card-xxxl-stretch mb-5 mb-xl-8 w-100">

                          <div className="card-body p-0 m-0 ">
                            <div className="table-responsive">
                              <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                <thead>

                                  <tr className="fw-bold text-muted th-title">
                                    <th className="min-w-150px">Image</th>
                                    <th className="min-w-150px"> Organisation Name</th>
                                    <th className="min-w-100px">Code</th>
                                    <th className="min-w-100px">Country</th>
                                    <th className="min-w-100px">Action</th>
                                  </tr>
                                  
                                </thead>

                                <tbody>
                                  {OrgInfo?.map((item) => (
                                    <tr>
                                      <td className="text-center">
                                        <img
                                          src={
                                            item?.logo ||
                                            "/assets_new/images/origination.png"
                                          }
                                          width="80px"
                                        />
                                      </td>
                                      <td className="text-center">{item?.name}</td>
                                      <td className="text-center">{item?.code}</td>
                                      <td className="text-center">{item?.country}</td>
                                      <td className='text-center'> <Button variant="secondary" type='button' onClick={()=>AddorgProduct(item)}>+</Button></td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>

                            {/* <div className="col-lg-12 mt-2 text-center fs-3 p-5 text-dark">
                              <ReactPaginate
                                PreviousLabel={"Previous"}
                                NextLabel={"Next"}
                                breakLabel={"..."}
                                pageCount={2}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={handlePageClick}
                                containerClassName={
                                  "pagination justify-content-end"
                                }
                                pageClassName={"page-item mx-4"}
                                pageLinkClassName={"page-link"}
                                PreviousClassName={"page-item mx-2"}
                                PreviousLinkClassName={"page-link pagestyle"}
                                NextClassName={"page-item"}
                                NextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                              />
                            </div> */}

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </Modal.Body>

        <Modal.Footer>

          {/* <Button variant="secondary" >
            Close
          </Button>
          <Button
            variant="primary"
            type="submit">
            {" "}

            Save Changes

          </Button> */}

        </Modal.Footer>

      </Modal>
    </>
  )
}

export default React.memo(OrgAllNameModal)
