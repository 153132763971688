import React from "react";
import { Link, useLocation } from "react-router-dom";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import CustomerLoader from "../../SuperAgent/CRM/Modal/CustomerLoder";

function JdcSubAdminUpdate({ hadlerjdc }) {

  const { authType } = useSelector((state) => state.auth);

  const location = useLocation();
  const row = location.state;
  console.log({ row });
  const navigate = useNavigate();
  let token = localStorage.getItem("token");
  const [ShowLoader, setShowLoader] = useState(false);
  const [uploadedImageUrl, setuploadedImageUrl] = useState("");

  const AddFormData = async (e) => {
    e.preventDefault();
    setShowLoader(true);
    console.log({ uploadedImageUrl });
    
    try {
      // Create FormData from the form submission
      let formData = new FormData(e.target);

      const UpdateformData = Object.fromEntries(formData.entries());

      // Add uploaded image URL and ID to the form data
      UpdateformData.image = uploadedImageUrl;

      UpdateformData.id = row?._id;

      // Send the final form data to the backend
      const response = await axios.post(
        `/v1/super-Agent/update-jdc-admin`,
        UpdateformData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // Handle the response from the backend
      if (response.status && response.data) {
        const data = response.data;

        if (data.status) {
          toast.success(data.msg);
          setShowLoader(false);
          navigate(`/${authType}/subadmin-list`); // Navigate to the sub-admin list page
          e.target.reset(); // Reset the form after successful submission
        } else {
          toast.error(data.msg); // Show an error message if the submission failed
        }
      } else {
        toast.error("Unexpected error occurred. Please try again."); // Handle unexpected response structure
      }
    } catch (error) {
      console.error("Error uploading image or submitting form:", error);
      toast.error("Failed to submit the form. Please try again."); // Show an error message if the submission failed
      setShowLoader(false); // Stop loader if there's an error
    }
  };

  const handleFileChange = (data) => {
    if (!data) {
      toast.error("No file selected");
      return;
    }

    let formdata = new FormData();
    formdata.append("file", data);

    axios
      .post(`/v1/DID/ImageUploader`, formdata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        const imgurl = res.data.data;
        console.log("Uploaded image URL:", imgurl);
        setuploadedImageUrl(imgurl);
      })
      .catch((error) => {
        console.error("Error uploading image:", error);
        toast.error("Error uploading image");
      });
  };

  useEffect(() => {
    hadlerjdc();
  }, []);

  return (
    <>
      <ToastContainer position="top-right" />
      <CustomerLoader loader={ShowLoader} />

      <div
        className="wrapper d-flex flex-column flex-row-fluid"
        id="kt_wrapper"
      >
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content"
        >
          <div className="container-xxl" id="kt_content_container">
            <div className="row g-5 g-xl-8">
              <div className="col-lg-12">
                <div className="card mb-5 mb-xl-10">
                  <div
                    className="card-header border-0 cursor-pointer"
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_account_profile_details"
                    aria-expanded="true"
                    aria-controls="kt_account_profile_details"
                  >
                    <div className="card-title m-0">
                      <h3 className="fw-bold m-0">Updated Sub-admin</h3>
                    </div>
                  </div>
                  <div
                    id="kt_account_settings_profile_details"
                    className="collapse show"
                  >
                    <form
                      onSubmit={(e) => AddFormData(e)}
                      id="kt_account_profile_details_form"
                      className="form"
                    >
                      <div className="card-body border-top p-9">
                        <div className="row mb-8">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            Product Image
                          </label>
                          <div className="col-lg-8">
                            <div
                              className="image-input image-input-outline"
                              data-kt-image-input="true"
                            >
                              <div className="image-input-wrapper w-125px h-125px"></div>
                              <div style={{ position: "absolute", top: "0px" }}>
                                {row?.image && (
                                  <img
                                    src={row?.image}
                                    alt="Uploaded"
                                    className="w-125px h-125px"
                                  />
                                )}
                              </div>
                              <label
                                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                data-kt-image-input-action="change"
                                data-bs-toggle="tooltip"
                                title="Product Image"
                              >
                                <i className="bi bi-pencil-fill fs-7"></i>
                                <input
                                  type="file"
                                  name="image"
                                  onChange={(e) =>
                                    handleFileChange(e.target.files[0])
                                  }
                                />
                              </label>
                              <span
                                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                data-kt-image-input-action="cancel"
                                data-bs-toggle="tooltip"
                                title="Cancel avatar"
                              >
                                <i className="bi bi-x fs-2"></i>
                              </span>
                              <span
                                className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                data-kt-image-input-action="remove"
                                data-bs-toggle="tooltip"
                                title="Remove avatar"
                              >
                                <i className="bi bi-x fs-2"></i>
                              </span>
                            </div>
                            <div className="form-text">
                              Allowed file types: png, jpg, jpeg.
                            </div>
                          </div>
                        </div>
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                            Full Name
                          </label>

                          <div className="col-lg-8 fv-row">
                            <input
                              type="text"
                              name="fullName"
                              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                              placeholder="First Name"
                              defaultValue={row?.fullName}
                            />
                          </div>
                        </div>
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            <span className="required">Contact No.</span>
                            <i
                              className="fas fa-exclamation-circle ms-1 fs-7"
                              data-bs-toggle="tooltip"
                              title="Phone number must be active"
                            ></i>
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="tel"
                              name="phone"
                              className="form-control form-control-lg form-control-solid"
                              placeholder="Contact No."
                              defaultValue={row?.phone}
                            />
                          </div>
                        </div>
                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6 required">
                            Email Address
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="email"
                              name="email"
                              className="form-control form-control-lg form-control-solid"
                              placeholder="Email Address"
                              defaultValue={row?.email}
                            />
                          </div>
                        </div>

                        <div className="row mb-6">
                          <label className="col-lg-4 col-form-label fw-semibold fs-6">
                            Address
                          </label>
                          <div className="col-lg-8 fv-row">
                            <input
                              type="text"
                              name="address"
                              className="form-control form-control-lg form-control-solid"
                              placeholder="Address"
                              defaultValue={row?.address}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="card-footer d-flex justify-content-end py-6 px-9">
                        <button
                          type="submit"
                          className="btn btn-light btn-active-light-primary me-2"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default JdcSubAdminUpdate;
